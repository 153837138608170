import { computed, ref, SetupContext } from "vue";
import { useCashierRestService } from "@sportaq/services/cashier";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { Card, CardItem } from "@sportaq/model/cashier/card";
import { useSettings } from "@sportaq/services/index";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { getLocalizedQuotationTitle } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { useI18n } from "vue-i18n";
import { HorseItem, HorseItemStatus, HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { getParticipantsAsString } from "@sportaq/model/common/participants-functions";

export function useWinPaymentDialogCommon (context: SetupContext, isDesktop: boolean) {
    const i18n = useI18n();
    const restService = useCashierRestService();
    const store = useCashierStore();
    const paymentCode = ref();
    const card = ref<Card>();
    const settingsService = useSettings();
    const expressBonusMultiplier = settingsService.pointSettings.expressBonusMultiplier;
    const requestPending = ref(false);
    const expired = ref(false);
    const raceCard = ref<HorseWagerContainer>();
    const isPayButtonDisabled = computed(() => {
        if (card.value === undefined && raceCard.value === undefined) {
            return true;
        }
        if (requestPending.value) {
            return true;
        }
        if (card.value !== undefined) {
            if (card.value.isPaid || !card.value.isWin) {
                return true;
            }
        }
        if (raceCard.value) {
            if (!hasWinner(raceCard.value.items)) {
                return true;
            }
            if (raceCard.value.isPaid) {
                return true;
            }
        }
        return false;
    });

    function hasWinner (items: HorseItem[]): boolean {
        for (const item of items) {
            if (item.status === HorseItemStatus.Winner || item.status === HorseItemStatus.Return) {
                return true;
            }
        }
        return false;
    }

    function onFindCard () {
        if (paymentCode.value) {
            card.value = undefined;
            raceCard.value = undefined;
            requestPending.value = false;
            if (paymentCode.value.length === 12) {
                restService.getCardByPayCode(paymentCode.value, i18n.locale.value, isDesktop)
                    .then((c: Card[]) => {
                        if (c.length > 0) {
                            card.value = c[0];
                            const difference = (new Date().getTime() - c[0].acceptServerTime.getTime()) / (1000 * 60 * 60 * 24);
                            if (difference > 31) {
                                expired.value = true;
                                showMessageBox(i18n.t("cashier.winPaymentForm.expired"));
                                return;
                            }
                            if (c[0].isPaid) {
                                showMessageBox(i18n.t("cashier.winPaymentForm.alreadyPaid"));
                            }
                        } else {
                            showMessageBox(i18n.t("cashier.winPaymentForm.notfound"));
                        }
                    });
            } else {
                restService.getRaceByPayCode(paymentCode.value).then(r => {
                    raceCard.value = r;
                    if (r.isPaid) {
                        showMessageBox(i18n.t("cashier.winPaymentForm.alreadyPaid"));
                    }
                });
            }
        }
    }

    function getQuotationTitle (item: CardItem): string {
        return getLocalizedQuotationTitle(i18n, item);
    }

    function getParticipants (item: CardItem): string {
        return getParticipantsAsString(item, item.quotationKey);
    }

    function onPay () {
        if (requestPending.value) {
            return;
        }
        if (card.value) {
            requestPending.value = true;
            restService.makeWinPayment(card.value?.id)
                .then(() => {
                    const localizedMessage = i18n.t("cashier.winPaymentForm.success");
                    showMessageBox(localizedMessage);
                    const containers = card.value?.containers;
                    if (containers) {
                        containers[0].isPaid = true;
                        if (card.value?.id) {
                            restService.getCardByPayCode(card.value.payCode, i18n.locale.value, isDesktop).then(c => store.updateCard(c[0]));
                        }
                    }
                    onClose();
                })
                .catch(() => { requestPending.value = false; });
        }
        if (raceCard.value) {
            requestPending.value = true;
            restService.makeRaceWinPayment(raceCard.value.id, raceCard.value.serial)
                .then(() => {
                    const localizedMessage = i18n.t("cashier.winPaymentForm.success");
                    showMessageBox(localizedMessage);
                    onClose();
                    requestPending.value = false;
                })
                .catch(() => {
                    requestPending.value = false;
                });
        }
    }

    function onClose () {
        context.emit(CLOSE_EVENT);
    }
    return {
        paymentCode,
        card,
        raceCard,
        expressBonusMultiplier,
        requestPending,
        expired,
        isPayButtonDisabled,
        onFindCard,
        onClose,
        onPay,
        getQuotationTitle,
        getParticipants
    };
}
