import { EPointType } from "@sportaq/common/enums/EPointType";

export class ShortPointInfo {
    balance: number;
    casinoBalance: number;
    constructor (readonly id: number, readonly pointTypeId: EPointType, readonly bpGuid: string, readonly name: string, balance: number) {
        this.balance = balance;
        this.casinoBalance = 0;
    }
}
