import { computed, PropType, ref, SetupContext } from "vue";
import { Form, Field } from "vee-validate";
import { RegistrationData } from "@sportaq/model/cashier/registration-data";
import { CLOSE_EVENT, SUBMIT_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import PasswordField from "@sportaq/vue/components/web/password-field/PasswordField.vue";
import CountrySelect from "@sportaq/vue/components/web/registration/country-select/CountrySelect.vue";
import { countries } from "@sportaq/model/consts/countries";
import { useHttpService } from "@sportaq/services/index";

interface Props {
    registrationData: RegistrationData;
}

export default {
    props: {
        registrationData: { required: false, type: Object as PropType<RegistrationData> }
    },
    components: {
        Form, Field, PasswordField, CountrySelect
    },
    emits: [CLOSE_EVENT, SUBMIT_EVENT],
    setup (props: Props, context: SetupContext) {
        const httpService = useHttpService();
        const countryId = ref<number>(props.registrationData.country);
        if (!countryId.value) {
            httpService.getUserCountryId().then(id => {
                countryId.value = id;
            });
        }
        function onClose () {
            context.emit(CLOSE_EVENT);
        }
        function onSubmit (values: RegistrationData) {
            values.phoneCode = phoneCode.value ?? "";
            values.country = countryId.value;
            context.emit(SUBMIT_EVENT, values);
        }
        const phoneCode = computed(() => {
            return countryId.value ? countries.getById(countryId.value)?.phoneCode : "";
        });

        return {
            phoneCode,
            countryId,
            onClose,
            onSubmit
        };
    }
};
