import { TIMEOUTS } from "@sportaq/common/consts/default-consts";
import { computed, ref, watch } from "vue";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";

export interface PointBalanceProps {
   point: ShortPointInfo
}

export function usePointBalanceCommon (props: PointBalanceProps) {
    const highlightedRef = ref(false);
    const store = useCashierStore();
    const pointId = props.point.id;

    const balance = computed(() => {
        if (pointId < 0) {
            return "";
        }
        const point = store.getPoints().filter(p => p.id === pointId)[0];
        return point.balance.toFixed(2) + "/" + point.casinoBalance.toFixed(2);
    });

    watch(() => balance.value, () => {
        blink();
    });

    function blink () {
        let blinkCount = 0;
        const t = () => {
            if (blinkCount <= 9) {
                highlightedRef.value = !highlightedRef.value;
                setTimeout(t, TIMEOUTS.BLINK_DELAY);
                blinkCount++;
            }
        };
        setTimeout(t, TIMEOUTS.BLINK_DELAY);
    }

    return {
        highlightedRef,
        balance
    };
}
