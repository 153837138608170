<template>
    <div class="popup active" id="popup8-2">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.buyVoucherForm.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.buyVoucherForm.voucher") }}</label>
                        <input id="voucherInput" type="text" v-model="voucherCodeRef">
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-primary" :class="{disabled: isRequestPending}" @click.stop.prevent="onBuy">{{ $t("cashier.buyVoucherForm.buyButton") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
                <div class="popup-table">
                    <table>
                        <thead>
                        <tr>
                            <th>{{ $t("cashier.saleVoucherForm.table.time") }}</th>
                            <th>{{ $t("cashier.saleVoucherForm.table.num") }}</th>
                            <th>{{ $t("cashier.saleVoucherForm.table.amount") }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="voucher in vouchers" :key="voucher.voucherCode">
                            <td>{{ $d(voucher.sellDate, "long") }}</td>
                            <td>{{ voucher.voucherCode }}</td>
                            <td>{{ voucher.amount }}{{voucher.currency}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./buy-voucher-dialog-component.ts" />
