import {
    BaseSettingsServiceImpl
} from "@sportaq/services/base-setting-service/base-settings-service";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { EPaymentMethod } from "@sportaq/common/enums/bet-slip";

const LOGIN_KEY = "login";
const USER_NUMBER_KEY = "user.number";
const PRINT_PREVIEW_KEY = "printPreview";
const DEPOSIT_MENU_VISIBLE_KEY = "depositMenuVisible";
const LOGINS_KEY = "logins";

export class CashierSettingsService extends BaseSettingsServiceImpl {
    private _login: string;
    private _userNumber: string;
    private _usePrintPreview: boolean;
    private _depositMenuVisible: boolean;
    private readonly _logins: string[];

    constructor (uuidProvider: UUIDProvider) {
        super(uuidProvider, true, "cashier_");
        const storage = window.localStorage;
        this._login = storage.getItem(LOGIN_KEY) ?? "";
        this._userNumber = storage.getItem(USER_NUMBER_KEY) ?? "";
        if (storage.getItem(PRINT_PREVIEW_KEY)) {
            this._usePrintPreview = storage.getItem(PRINT_PREVIEW_KEY) === "1";
        } else {
            this._usePrintPreview = true;
        }
        this._depositMenuVisible = storage.getItem(DEPOSIT_MENU_VISIBLE_KEY) === "1";
        const loginsStr = storage.getItem(LOGINS_KEY);
        this._logins = loginsStr ? loginsStr.split(";") : [];
    }

    get login (): string {
        return this._login;
    }

    setLogin (login: string, userNumber: string) {
        this._login = login;
        window.localStorage.setItem(LOGIN_KEY, login);
        const fullName = userNumber + " (" + login + ")";
        if (!this.logins.find(l => l === fullName)) {
            this._logins.push(fullName);
            window.localStorage.setItem(LOGINS_KEY, this.logins.join(";"));
        }
    }

    get userNumber (): string {
        return this._userNumber;
    }

    set userNumber (userNumber: string) {
        this._userNumber = userNumber;
        window.localStorage.setItem(USER_NUMBER_KEY, userNumber);
    }

    get depositMenuVisible (): boolean {
        return this._depositMenuVisible;
    }

    set depositMenuVisible (value: boolean) {
        this._depositMenuVisible = value;
        window.localStorage.setItem(DEPOSIT_MENU_VISIBLE_KEY, this._depositMenuVisible ? "1" : "0");
    }

    set usePrintPreview (pp: boolean) {
        this._usePrintPreview = pp;
        window.localStorage.setItem(PRINT_PREVIEW_KEY, pp ? "1" : "0");
    }

    get usePrintPreview (): boolean {
        return this._usePrintPreview;
    }

    get paymentMethod (): EPaymentMethod {
        return EPaymentMethod.CashierAccountCredit;
    }

    get sessionCode (): string | undefined {
        return undefined;
    }

    get logins (): string[] {
        return this._logins;
    }
}
