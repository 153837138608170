import { useCashierRestService } from "@sportaq/services/cashier";
import { useSettings } from "@sportaq/services/index";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { ExpressAccountRegData, ExpressAccountUser } from "@sportaq/model/cashier/express-account-user";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { computed, ref, SetupContext } from "vue";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { getCurrencyName } from "@sportaq/model/common/point-settings";

export function useAddExpressAccountDialogCommon (isMobile: boolean, context: SetupContext, successCallback: () => void) {
    const restService = useCashierRestService();
    const settings = useSettings();
    const store = useCashierStore();
    const balanceStore = useBalanceStore();
    const currencyName = ref(settings.pointSettings.currencyName);

    const registrationData = ref<ExpressAccountRegData>({
        orderSum: "",
        currencyId: settings.pointSettings.currency.typeId.toString(),
        firstName: "",
        lastName: "",
        document: ""
    });

    const isSubmitButtonDisabled = computed(() => {
        if (registrationData.value.orderSum === undefined) {
            return true;
        }
        const sum = parseFloat(registrationData.value.orderSum);
        return balanceStore.balance.numberValue <= sum || sum < 0;
    });

    const expressAccountDataRef = ref<ExpressAccountUser>();

    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function onSubmit (values: ExpressAccountRegData) {
        registrationData.value.orderSum = values.orderSum;
        const primaryAccount = store.user.userAccounts.getPrimaryAccount();
        const currencyId = isMobile && primaryAccount ? primaryAccount.currencyId : settings.pointSettings.currency.typeId;
        values.currencyId = currencyId.toString();
        currencyName.value = getCurrencyName(currencyId);
        restService.addExpressAccount(values).then((user: ExpressAccountUser) => {
            expressAccountDataRef.value = user;
            successCallback();
        }).then();
    }

    return {
        registrationData,
        expressAccountDataRef,
        currencyName,
        isSubmitButtonDisabled,

        onClose,
        onSubmit,
        formatVoucherNumber
    };
}
