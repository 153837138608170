import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredIntValue
} from "@sportaq/common/utils/xml-helper-functions";

export class APo510FillAccountMessageHandler extends AbstractMessageHandler<number> {
    protected readonly requestType: string = "A.PO.5.10";
    protected readonly mobileCashierRequestType: string = "A.PO.5.11";
    constructor (
        readonly mobileCashier: boolean,
        readonly userId: number,
        readonly amount: number,
        readonly currencyId: number,
        readonly useBonus: boolean
    ) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.mobileCashier ? this.mobileCashierRequestType : this.requestType
        });
        request.addChildWithValue(actionElement, "UserId", this.userId.toString());
        request.addChildWithValue(actionElement, "OrderedSum", this.amount.toString());
        request.addChildWithValue(actionElement, "CurrencyId", this.currencyId.toString());
        request.addChildWithValue(actionElement, "FirstDepositBonus", this.useBonus ? "1" : "0");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): number {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "4300") {
            throw new LocalizedError(`errors.mtl.aPo510.code${serverCode}`);
        }
        return getRequiredIntValue(getRequiredChild(action, "OrderDepositId"));
    }
}
