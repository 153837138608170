import { DictionaryItem } from "@sportaq/common/types/types";

export interface GameProvider extends DictionaryItem {
    title: string;
    image: string;
    order: number;

    excludedCountries?: number[];
    excludedCurrencies?: number[];
}

export interface CountGameProvider extends GameProvider {
    count: number;
}

class GameProviderHolder {
    private readonly indexById = new Map<number, GameProvider>();
    private readonly indexByName = new Map<string, GameProvider>();

    setProviders (providers: GameProvider[]) {
        for (const provider of providers) {
            this.indexById.set(provider.id, provider);
            this.indexByName.set(provider.name, provider);
        }
    }

    public getById (id: number): GameProvider {
        return this.indexById.get(id) ?? {
            id,
            name: "_UNKNOWN_PROVIDER",
            title: "_UNKNOWN_PROVIDER",
            image: "",
            order: 0
        };
    }

    public getByName (name: string): GameProvider | undefined {
        return this.indexByName.get(name);
    }
}

export const gameProviders = new GameProviderHolder();
