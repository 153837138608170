import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute, getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";

export class QPi55PointListMessageHandler extends AbstractMessageHandler<ShortPointInfo[]> {
    protected readonly requestType: string = "Q.PI.5.5";
    constructor (readonly pointIds?: number[]) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter");
        const pointFilterElement = request.addChild(filterElement, "PointFilter");
        const pointListElement = request.addChild(pointFilterElement, "PointList");
        if (this.pointIds) {
            for (const pointId of this.pointIds) {
                request.addChildWithValue(pointListElement, "PointId", pointId.toString());
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): ShortPointInfo[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "2700") {
            throw new LocalizedError(`errors.mtl.aPi55.code${serverCode}`);
        }
        const pointListElement = getRequiredChild(action, "BetPlacesList");
        const result = new Map<number, ShortPointInfo>();
        for (let pointElement = pointListElement.firstElementChild; pointElement != null; pointElement = pointElement.nextElementSibling) {
            if (pointElement.nodeName === "BetPlace") {
                const point = new ShortPointInfo(
                    getRequiredIntAttribute(pointElement, "id"),
                    10,
                    getRequiredAttribute(pointElement, "bpGUID"),
                    getRequiredAttribute(pointElement, "name"),
                    getRequiredFloatAttribute(pointElement, "balance")
                );
                result.set(point.id, point);
            } else if (pointElement.nodeName === "BetPlaceCasino") {
                const id = getRequiredIntAttribute(pointElement, "id");
                const point = result.get(id);
                if (point) {
                    point.casinoBalance = getRequiredFloatAttribute(pointElement, "balance");
                }
            }
        }
        return Array.from(result.values());
    }
}
