import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { BetSlipComponent } from "@sportaq/vue/components/betting/bet-slip/common-bet-slip-component";
import useBetSlipComponent = BetSlipComponent.useBetSlipComponent;
import { computed, ref, watch } from "vue";
import EventType from "@sportaq/common/enums/event-type";
import MobileBetSlipStakeRow from "@sportaq/vue/components/mobile-betting/betslip/stake-row/MobileBetSlipStakeRow.vue";
import MobileCombinedStakeRow from "@sportaq/vue/components/mobile-betting/betslip/combined-stake-row/MobileCombinedStakeRow.vue";
import MobileDialogBetPrematchVerification from "@sportaq/vue/components/mobile-betting/betslip/dialogs/dialog-bet-prematch-verification/MobileDialogBetPrematchVerification.vue";
import MobileDialogBetAccepted from "@sportaq/vue/components/mobile-betting/betslip/dialogs/dialog-bet-accepted/MobileDialogBetAccepted.vue";

export default {
    components: {
        MobileBetSlipStakeRow,
        MobileCombinedStakeRow,
        MobileDialogBetPrematchVerification,
        MobileDialogBetAccepted
    },
    setup () {
        const betSlipStore = useBetSlipStore();
        const fullChecking = ref(betSlipStore.fullChecking);

        const {
            placeBetResponseSuccessInfo,
            simpleStakeDTOs,
            combinedStakeDTOs,
            prematchVerificationShowed,
            stakes,
            combined,
            isConfirmed,
            requestSent,
            topMessage,
            clearAllStakes,
            confirmAllChanges,
            placeBet,
            onBetAcceptedFormClose,

            closePrematchVerificationForm,
            updateAmount
        } = useBetSlipComponent();

        const containsLive = computed(() => betSlipStore.simples.some(value => value.event.eventType === EventType.LIVE));

        function couponClose () {
            betSlipStore.isShowed = false;
        }

        watch(() => fullChecking.value, value => {
            betSlipStore.fullChecking = value;
        });

        return {
            fullChecking,
            placeBetResponseSuccessInfo,
            simpleStakeDTOs,
            combinedStakeDTOs,
            prematchVerificationShowed,
            stakes,
            combined,
            isConfirmed,
            requestSent,
            topMessage,
            containsLive,
            clearAllStakes,
            confirmAllChanges,
            placeBet,
            onBetAcceptedFormClose,

            closePrematchVerificationForm,
            updateAmount,

            couponClose
        };
    }
};
