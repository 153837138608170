<template>
    <div class="popup active" id="popup9">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.cardsState.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.cardsState.cardFilter") }}</label>
                        <input type="text" v-model.number="cardsFilter">
                    </div>
                    <div class="popup-table">
                        <table>
                            <thead>
                            <th class="title width-20">{{ $t("cashier.cardsState.innerCardId") }}</th>
                            <th class="title width-20">{{ $t("cashier.cardsState.sumPay") }}</th>
                            <th class="title width-20">{{ $t("cashier.cardsState.status") }}</th>
                            </thead>
                            <tbody>
                            <tr v-for="(card) in cards.items" :key="card.id" :class="{ active: card.innerCardId === selectedItemId }"
                                class="line" @dblclick="card.expanded = !card.expanded" @click="selectedItemId = card.innerCardId" >
                                <td class="line-item width-20">{{ card.innerCardId }}</td>
                                <td class="line-item width-20">{{ card.sumPay.toFixed(2) }}</td>
                                <td class="line-item width-20">{{$t("cashier.cardsState.unpaid")}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div  class="form-line" v-if="cards.total">
                        <p class="info">{{ $t("cashier.cardsState.unpaidSum") }} {{ cards.total.toFixed(2) }}</p>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./cards-state-component.ts" />
