import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { handleValueChanger, ValueChanger } from "@sportaq/model/types/filter-value-checker";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import { ECasinoGameClassifier } from "@sportaq/common/enums/games/casino-game-classifier";
import { binarySearchIndex } from "@sportaq/common/utils/arrays";

export class GameViewFilter {
    gameType?: ECasinoGameType;
    classifierMask?: number;
    providerId?: number;
    nameFilter?: string;

    tryAccept (game: CasinoGame, favouriteMode: boolean, restrictedProviderIds: number[], restrictedGameIds: string[]): boolean {
        if (binarySearchIndex(restrictedProviderIds, game.provider, (a, b) => a - b) > -1) {
            return false;
        }
        if (binarySearchIndex(restrictedGameIds, game.id, (a, b) => a.localeCompare(b)) > -1) {
            return false;
        }
        if (favouriteMode) {
            return true;
        }
        const b = this.filterByGameType(game);
        const b1 = this.filterByClassifierMask(game);
        return b && b1 && this.filterByProvider(game) && this.filterByName(game);
    }

    private filterByName (game: CasinoGame) {
        return !this.nameFilter || game.title.toLowerCase().indexOf(this.nameFilter) !== -1;
    }

    private filterByGameType (game: CasinoGame) {
        return !this.gameType || game.gameType === this.gameType;
    }

    private filterByClassifierMask (game: CasinoGame) {
        if (this.classifierMask) {
            const result = game.classifiers & this.classifierMask;
            return result > 0 && result !== ECasinoGameClassifier.VIRTUAL_GAME;
        }
        return true;
    }

    private filterByProvider (game: CasinoGame) {
        return !this.providerId || game.provider === this.providerId;
    }

    change (changer: GameFilterChanger): boolean {
        return this.gameTypeChange(changer) ||
            this.classifierMaskChange(changer) ||
            this.providerChange(changer) ||
            this.nameFilterChange(changer);
    }

    clear () {
        this.gameType = undefined;
        this.classifierMask = undefined;
        this.providerId = undefined;
        this.nameFilter = undefined;
    }

    private nameFilterChange (changer: GameFilterChanger): boolean {
        // noinspection JSRemoveUnnecessaryParentheses
        return handleValueChanger(changer.nameFilter, () => this.nameFilter, value => (this.nameFilter = value?.toLowerCase()));
    }

    private gameTypeChange (changer: GameFilterChanger): boolean {
        // noinspection JSRemoveUnnecessaryParentheses
        const result = handleValueChanger(changer.changeGameType, () => this.gameType, value => (this.gameType = value));
        if (result) {
            this.classifierMaskChange({ changeClassifierMask: { type: "unset" } });
            this.providerChange({ changeProvider: { type: "unset" } });
        }
        return result;
    }

    private classifierMaskChange (changer: GameFilterChanger): boolean {
        // noinspection JSRemoveUnnecessaryParentheses
        const result = handleValueChanger(changer.changeClassifierMask, () => this.classifierMask, value => (this.classifierMask = value));
        if (result && changer.changeClassifierMask?.type === "set") {
            this.providerChange({ changeProvider: { type: "unset" } });
        }
        return result;
    }

    private providerChange (changer: GameFilterChanger) {
        // noinspection JSRemoveUnnecessaryParentheses
        const result = handleValueChanger(changer.changeProvider, () => this.providerId, value => (this.providerId = value));
        if (result && changer.changeProvider?.type === "set") {
            this.classifierMaskChange({ changeClassifierMask: { type: "unset" } });
        }
        return result;
    }
}

export interface GameFilterChanger {
    changeGameType?: ValueChanger<ECasinoGameType>;
    changeClassifierMask?: ValueChanger<number>;
    changeProvider?: ValueChanger<number>;
    nameFilter?: ValueChanger<string>;
}
