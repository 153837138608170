import { nextTick, onMounted, onUnmounted, ref } from "vue";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import html2canvas from "html2canvas";
import { appLogger } from "@sportaq/common/utils/logger";

export default {
    name: "ShareDialog",
    setup: function () {
        const printContent = ref<HTMLElement>();
        const isActive = ref(false);
        const isRendering = ref(false);

        function onClose () {
            isActive.value = false;
        }

        function handlePrintMessage (event: unknown) {
            isActive.value = true;
            nextTick(() => {
                if (printContent.value) {
                    printContent.value.innerHTML = event as string;
                }
            }).then();
        }

        onMounted(() => {
            eventBus.on(Events.PRINT_PREVIEW, handlePrintMessage);
        });

        onUnmounted(() => {
            eventBus.off(Events.PRINT_PREVIEW, handlePrintMessage);
        });

        async function onShare () {
            if (printContent.value) {
                isRendering.value = true;
                const options = {
                    allowTaint: true,
                    useCORS: true
                };
                html2canvas(printContent.value, options).then(async canvasElement => {
                    const dataUrl = canvasElement.toDataURL();
                    const blob = await (await fetch(dataUrl)).blob();
                    const filesArray = [
                        new File(
                            [blob],
                            "betslip.png",
                            {
                                type: blob.type,
                                lastModified: new Date().getTime()
                            }
                        )
                    ];
                    const shareData = {
                        files: filesArray
                    };
                    navigator.share(shareData)
                        .then(canvasElement.remove)
                        .catch(e => {
                            appLogger.logger.warn(e.stackTrace);
                        });
                });
            }
        }

        return {
            isActive,
            isRendering,
            printContent,
            onClose,
            onShare
        };
    }
};
