import { ServerRegisteredItem } from "@sportaq/model/cashier/server-registered-item";
import { WagerContainer } from "@sportaq/model/cashier/wager-container";

export enum EOperationType {
    PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH="PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH",
    PAY_OPERATION_WITHDRAWAL_CASH="PAY_OPERATION_WITHDRAWAL_CASH",
    PAY_OPERATION_VOUCHER_GENERATION_CASH="PAY_OPERATION_VOUCHER_GENERATION_CASH",
    PAY_OPERATION_VOUCHER_REDEMPTION_CASH="PAY_OPERATION_VOUCHER_REDEMPTION_CASH",
    PAY_OPERATION_VOUCHER_REDEMPTION_TRM="PAY_OPERATION_VOUCHER_REDEMPTION_TRM",
    PAY_OPERATION_INCREASE_BALANCE_BP="PAY_OPERATION_INCREASE_BALANCE_BP",
    PAY_OPERATION_NULLIFICATION_POINT_BALANCE="PAY_OPERATION_NULLIFICATION_POINT_BALANCE",
    PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP="PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP",
    PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP="PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP",
    PAY_OPERATION_FOR_RACE_CARD="PAY_OPERATION_FOR_RACE_CARD",
    PAY_OPERATION_FOR_RACE_WINPAID="PAY_OPERATION_FOR_RACE_WINPAID",
    PAY_OPERATION_WINPAID="PAY_OPERATION_WINPAID",
    PAY_OPERATION_FOR_RACE_WINPAID_BP="PAY_OPERATION_FOR_RACE_WINPAID_BP",
    PAY_OPERATION_FOR_RACE_WINPAID_CASH="PAY_OPERATION_FOR_RACE_WINPAID_CASH",
    PAY_OPERATION_VOUCHER_GENERATION_BP="PAY_OPERATION_VOUCHER_GENERATION_BP",
    PAY_OPERATION_CANCEL_STAKE_BP="PAY_OPERATION_CANCEL_STAKE_BP"
}

export interface FinOperation extends ServerRegisteredItem{
    expanded: boolean;
    readonly operationType: EOperationType;
    readonly sum: number;
}

export interface CasinoDepositOperation extends FinOperation {
    wagerContainers: WagerContainer[]
}
