<template>
    <main class="container">
        <div class="popup active" id="popup12">
            <div class="popup-container">
                <div class="popup-title smaller">{{ $t("cashier.loginForm.caption") }}</div>
                <form class="popup-content">
                    <div class="form-line">
                        <input id="login-input" type="text" :placeholder="$t('webdesktop.loginDialog.emailPlaceholder')" v-model="login" autofocus >
                    </div>
                    <div class="form-line password">
                        <div class="icons">
                            <img :src="'/img/icon-eye-' + (isPasswordVisible?'off':'on') + '.svg'" alt=""
                                 @click.prevent="isPasswordVisible = !isPasswordVisible" >
                        </div>
                        <input id="password-input" :type="isPasswordVisible?'text':'password'" :placeholder="$t('webdesktop.loginDialog.passwordPlaceholder')" v-model="password" autocomplete="off">
                    </div>
                    <div class="form-line">
                        <label>{{ $t("common.language.language") }}</label>
                        <div class="select-wrapper">
                            <select v-model="selectedLanguage" v-on:change="onLanguageSelected">
                                <option :value="lang" v-for="lang in ESupportedLocale" v-bind:key="lang">
                                    {{ $t("common.language.languageName",{}, {locale:lang}) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-line">
                        <button id="login-button" type="submit" class="button button-general" :class="{disabled: !login || !password || requestPending}" @click.prevent="onLogin()">{{$t("webdesktop.loginDialog.loginButton")}}</button>
                    </div>
                    <div class="form-line">
                        <button id="registration-button" class="button button-secondary" href="#" @click.prevent="onRegistration">{{$t("cashier.loginForm.registration")}}</button>
                    </div>
                    <div class="form-line">
                        <button id="reset-password-button" class="button button-secondary" href="#" @click.prevent="onResetPassword">{{$t("webdesktop.loginDialog.forgetPassword")}}</button>
                    </div>
                </form>
            </div>
        </div>
        <settings-dialog v-if="isSettingsDialogActive" :common-settings="settings" @submit="onSettingsDialogSubmit" @close="onSettingsDialogClose" />
        <registration-dialog v-if="isRegistrationDialogActive" :registration-data="registrationData" @close="onRegistrationClose" @submit="onRegistrationSubmit" />
        <reset-password-dialog v-if="isResetPasswordDialogActive" @close="onResetPasswordClose"></reset-password-dialog>
    </main>
</template>

<script src="./login-view-component.ts" />
