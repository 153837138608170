import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatValue, getRequiredIntValue, getRequiredValue, getValue
} from "@sportaq/common/utils/xml-helper-functions";
import { getCurrencyName } from "@sportaq/model/common/point-settings";

export class TicketNotFoundError extends Error {

}
export class AVc14BuyVoucherMessageHandler extends AbstractMessageHandler<VoucherSellInfo> {
    protected readonly requestType: string = "A.VC.1.4";
    protected readonly mobileRequestType: string = "A.VC.1.7";
    constructor (readonly isMobile: boolean, readonly voucherCode: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.isMobile ? this.mobileRequestType : this.requestType
        });
        const voucherElement = request.addChild(actionElement, "Voucher");
        request.addChildWithValue(voucherElement, "VoucherCode", this.voucherCode.toString());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): VoucherSellInfo {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "2301") {
            throw new TicketNotFoundError();
        }
        if (serverCode !== "2300") {
            throw new LocalizedError(`errors.mtl.aVc14.code${serverCode}`);
        }
        const voucherElement = getRequiredChild(action, "Voucher");
        const currencyId = getRequiredIntValue(getRequiredChild(voucherElement, "VoucherСurrencyId"));
        return {
            amount: getRequiredFloatValue(getRequiredChild(voucherElement, "VoucherCost")),
            sellDate: new Date(Date.now()),
            voucherCode: getRequiredValue(getRequiredChild(voucherElement, "VoucherCode")),
            voucherNumber: getRequiredValue(getRequiredChild(voucherElement, "VoucherNumber")),
            name: getValue(getRequiredChild(voucherElement, "VoucherDescription")),
            currencyId: getRequiredIntValue(getRequiredChild(voucherElement, "VoucherСurrencyId")),
            currency: getCurrencyName(currencyId)
        };
    }
}
