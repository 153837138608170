import { ROUTES } from "@/router/router-consts";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { computed } from "vue";
import { RouteLocationNormalizedLoaded, useRoute, useRouter } from "vue-router";
import { MenuItem } from "web_mobile/src/components/layout/app-bottom-bar/app-bottom-bar-component";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { useRootStore } from "@sportaq/vuex/index";
import { isServiceEnabled } from "@sportaq/model/games/functions";
import { EGameService } from "@sportaq/model/common/point-settings";
import { useSettings } from "@sportaq/services/index";

export default {
    setup () {
        const router = useRouter();
        const route = useRoute();
        const betSlipStore = useBetSlipStore();
        const store = useRootStore();
        const isRaceEnabled = isServiceEnabled(useSettings(), EGameService.Race);
        const isBettingEnabled = isServiceEnabled(useSettings(), EGameService.Betting);
        const menuItems = computed(() => getMenuItems(isRaceEnabled, isBettingEnabled));
        const selectedTab = computed(() => recognizeSelectedMenuItem(menuItems.value, route));
        const quotationCount = computed(() => betSlipStore.simples.length);
        const imageThemePath = computed(() => store.theme);

        async function select (item: MenuItem) {
            if (item.id === EMenuItem.BetSlip) {
                if (betSlipStore.simples.length > 0) {
                    betSlipStore.isShowed = !betSlipStore.isShowed;
                }
                return;
            }
            if (item.clickHandler) {
                item.clickHandler();
                return;
            }
            if (item.path) {
                betSlipStore.isShowed = false;
                await router.push({ path: item.path });
            }
        }

        function showMenu () {
            document.body.classList.add("drop-menu-active");
        }

        return {
            selectedTab,
            menuItems,
            quotationCount,
            imageThemePath,
            COMMON_ROUTES,
            ROUTES,
            select,
            showMenu
        };
    }
};

export enum EMenuItem {
    Sport = 1, Live = 2, BetSlip = 3, Horses = 6,
}

function getMenuItems (isRaceEnabled: boolean, isBettingEnabled: boolean): MenuItem[] {
    const result: MenuItem[] = [];
    if (isBettingEnabled) {
        result.push(
            {
                id: EMenuItem.Sport,
                path: COMMON_ROUTES.SPORT.path,
                i18nKey: "mobile.routes.sport",
                iconName: "icon-deportes.svg",
                dataCy: "sport"
            },
            {
                id: EMenuItem.Live,
                path: COMMON_ROUTES.LIVE.path,
                i18nKey: "mobile.routes.live",
                iconName: "icon-envivo.svg",
                dataCy: "live"
            },
            {
                id: EMenuItem.BetSlip,
                path: "",
                i18nKey: "betting.betSlip.shortTitle",
                iconName: "icon-cupon.svg",
                dataCy: "betSlip"
            });
    }
    if (isRaceEnabled) {
        result.push({
            id: EMenuItem.Horses,
            path: COMMON_ROUTES.HORSE.path,
            i18nKey: "mobile.routes.racing",
            iconName: "icon-horse.svg",
            dataCy: "horse"
        });
    }
    return result;
}

function recognizeSelectedMenuItem (menu: MenuItem[], route: RouteLocationNormalizedLoaded): MenuItem | undefined {
    for (let i = 0; i < menu.length; i++) {
        const menuItem = menu[i];
        if (menuItem.path && route.path.indexOf(menuItem.path) === 0) {
            return menuItem;
        }
    }
    return undefined;
}
