import { SetupContext } from "vue";
import { useWinPaymentDialogCommon } from "@sportaq/vue/components/mobile-cashier/win-payment-dialog-common";
import MobileInnerStakeTable
    from "@/components/win-payment-dialog/common/inner-stake-table/MobileInnerStakeTable.vue";
import { HorseItemStatus } from "@sportaq/model/cashier/wager-container";

export default {
    name: "WinPaymentDialog",
    components: {
        MobileInnerStakeTable
    },
    setup (props: unknown, context: SetupContext) {
        const {
            paymentCode,
            card,
            raceCard,
            expressBonusMultiplier,
            requestPending,
            isPayButtonDisabled,

            onFindCard,
            onClose,
            onPay,
            getQuotationTitle,
            getParticipants
        } = useWinPaymentDialogCommon(context, false);

        return {
            paymentCode,
            card,
            raceCard,
            expressBonusMultiplier,
            requestPending,
            HorseItemStatus,
            isPayButtonDisabled,

            onFindCard,
            onClose,
            onPay,
            getQuotationTitle,
            getParticipants
        };
    }
};
