<template>
    <div class="popup popup-dni active" id="popup2-1">
        <div class="popup-container">
            <div class="popup-title">{{ $t("betting.dniInputForm.dniCaption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <input type="text" class="field width-100" v-model="valueRef">
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-general" @click.stop.prevent="onSubmit" :class="{disabled: !valueRef}">{{ $t("common.forms.ok") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./dni-input-dialog-component.ts">
</script>

<style scoped>

</style>
