import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { RegistrationData } from "@sportaq/model/cashier/registration-data";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";

export class AUs14UpdateProfileMessageHandler extends AbstractMessageHandler<boolean> {
    protected readonly requestType: string = "A.US.1.4";
    constructor (readonly registrationData: RegistrationData) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "FirstName", this.registrationData.firstName);
        request.addChildWithValue(actionElement, "LastName", this.registrationData.lastName);
        request.addChildWithValue(actionElement, "Address", this.registrationData.address);
        request.addChildWithValue(actionElement, "Phone", this.registrationData.phone);
        request.addChildWithValue(actionElement, "Email", this.registrationData.email);
        request.addChildWithValue(actionElement, "Login", this.registrationData.login);
        // request.addChildWithValue(actionElement, "Password", this.registrationData.password);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): boolean {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1150") {
            return true;
        }
        throw new LocalizedError(`errors.mtl.aUs11.code${serverCode}`);
    }
}
