<template v-if="stake.amount">
    <tr>
        <td class="text-big"><i><b>{{ $t("betting.betSlip.ordinar") }}</b></i></td>
        <td class="text-big"><b><i>{{ $n(stake.amount, "currency") }} {{ pointCurrency }}</i></b></td>
    </tr>
    <tr>
        <td class="text-big">{{ $t("betting.betSlip.maxWin") }}</td>
        <td class="text-big">{{ $n(maxWin, "currency") }} {{ pointCurrency }}</td>
    </tr>
    <tr>
        <td colspan="2" class="text-big">
            <template v-if="isLive"><b>LIVE.</b> </template>
            {{ $d(stake.event.startTime, "long") }} {{ stake.event.partitionName }}
            <br> {{ participantsString }}
        </td>
    </tr>
    <tr>
        <td colspan="2" class="text-big"><b>{{quotationTitle}} <span v-html="quotationLabel" /></b></td>
    </tr>
</template>

<script src="./print-simple-stake-row-component.ts"/>
