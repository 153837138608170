import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getAttribute,
    getChild,
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { EVerifyField, User, UserAccount, UserAccountsImpl } from "@sportaq/model/common/user-accounts";
import { toLocalTime } from "@sportaq/common/utils/time-utils";

export class QUs22FindUserMessageHandler extends AbstractMessageHandler<User | undefined> {
    protected readonly requestType: string = "Q.US.2.2";
    constructor (
        readonly phoneNumber: string | undefined,
        readonly userNumber: string | undefined,
        readonly document: string | undefined
    ) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const validateEmail = (email: string) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const userFilterElement = request.addChild(filterElement, "UserFilter", {
            logic: "AND"
        });
        if (this.phoneNumber) {
            request.addChildWithValue(userFilterElement, "Phone", this.phoneNumber);
        }
        if (this.document) {
            request.addChildWithValue(userFilterElement, "IdentificationCode", this.document);
        } else {
            if (this.userNumber) {
                if (validateEmail(this.userNumber)) {
                    request.addChildWithValue(userFilterElement, "Email", this.userNumber);
                } else if (!isNaN(parseFloat(this.userNumber))) {
                    request.addChildWithValue(userFilterElement, "UserNumber", this.userNumber);
                } else {
                    request.addChildWithValue(userFilterElement, "Login", this.userNumber);
                }
            }
        }
        const selectorElement = request.addChild(actionElement, "Selector", undefined);
        const userSelectorElement = request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.User", allFields: "True"
        });
        request.addChild(userSelectorElement, "SelectObject", {
            class: "ps.user.UserVerifyField", allFields: "True"
        });
        request.addChild(userSelectorElement, "SelectObject", {
            class: "ps.user.UserAccount", allFields: "True"
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): User | undefined {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "1190") {
            throw new LocalizedError(`errors.mtl.qUs22.code${serverCode}`);
        }
        return parseUser(action);
    }
}

export function parseUser (action: Element): User | undefined {
    const userListElement = getChild(action, "UserList");
    if (!userListElement) {
        return undefined;
    }
    const userElement = getRequiredChild(userListElement, "US");
    const userVerifyElement = getRequiredChild(userElement, "UserVerifyFieldList");
    const verifiedFields = new Set<EVerifyField>();
    for (let uf = userVerifyElement.firstElementChild; uf != null; uf = uf.nextElementSibling) {
        if (getRequiredAttribute(uf, "isVerify") === "true") {
            verifiedFields.add(EVerifyField[getRequiredAttribute(uf, "fieldName") as keyof typeof EVerifyField]);
        }
    }
    const userAccountListElement = getChild(action, "UserAccountList");
    const userAccounts: UserAccount[] = [];
    if (userAccountListElement !== undefined) {
        const userAccountElements = userAccountListElement.getElementsByTagName("UA");
        for (let i = 0; i < userAccountElements.length; i++) {
            const userAccountElement = userAccountElements.item(i);
            if (userAccountElement != null) {
                userAccounts.push({
                    accountId: getRequiredIntAttribute(userAccountElement, "Id"),
                    accountNumber: getRequiredAttribute(userAccountElement, "accountNumber"),
                    currencyId: getRequiredIntAttribute(userAccountElement, "currencyId"),
                    currencyMark: getRequiredAttribute(userAccountElement, "currencyMark"),
                    currencyValue: getRequiredFloatAttribute(userAccountElement, "currencyValue"),
                    isBlocked: getRequiredIntAttribute(userAccountElement, "isBlocked") === 1,
                    isBonus: getRequiredIntAttribute(userAccountElement, "isBonus") === 1,
                    isUsed: getRequiredIntAttribute(userAccountElement, "isUsed") === 1,
                    sumAccount: getRequiredFloatAttribute(userAccountElement, "sumAccount"),
                    sumCaptive: getRequiredFloatAttribute(userAccountElement, "sumCaptive"),
                    sumStake: getRequiredFloatAttribute(userAccountElement, "sumStake"),
                    accountMark: getRequiredAttribute(userAccountElement, "accountMark")
                });
            }
        }
    }
    const selfBlockedTime = getAttribute(userElement, "selfBlockedTime");
    return {
        userId: getRequiredIntAttribute(userElement, "Id"),
        login: getRequiredAttribute(userElement, "login"),
        userNumber: getAttribute(userElement, "userNumber"),
        firstName: getAttribute(userElement, "firstname"),
        lastName: getAttribute(userElement, "lastname"),
        birthday: getRequiredAttribute(userElement, "birthday"),
        address: getAttribute(userElement, "address"),
        email: getAttribute(userElement, "email"),
        phone: getAttribute(userElement, "phone"),
        middleName: getAttribute(userElement, "middlename"),
        partnerId: getRequiredIntAttribute(userElement, "partnerId"),
        IdentificationCode: getAttribute(userElement, "IdentificationCode"),
        registrationTime: new Date(getRequiredAttribute(userElement, "registrationTime")),
        countryId: getRequiredIntAttribute(userElement, "countryId"),
        userAccounts: new UserAccountsImpl(userAccounts),
        city: getAttribute(userElement, "cityText"),
        selfBlockedTime: selfBlockedTime ? toLocalTime(new Date(selfBlockedTime)) : undefined,
        isRegulatorBlocked: getAttribute(userElement, "isRegulatorBlocked") !== "",
        userRoles: [],
        paymentSystems: [],
        verified: verifiedFields.has(EVerifyField.identificationCode),
        verifiedFields
    };
}
