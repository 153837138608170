<template>
    <div id="reset-password-dialog" class="popup active" >
        <div class="overlay" @click.prevent="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.resetPasswordDialog.caption")}}</div>
                <div class="tabs">
                    <div id="reset-by-email-tab" class="tabs-item" :class="{active: activeTab === 0}" @click="switchTab(0)">
                        <i class="icon email">
                            <svg viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4635 15.8075L26.1139 5.52435C25.7998 4.91909 25.0465 4.68117 24.4409 4.99668L3.15671 16.0693C2.55109 16.3848 2.31488 17.1371 2.62904 17.7423L2.78334 18.0392L2.78091 18.0428L2.78531 18.0426L7.97897 28.0241C8.29313 28.6294 9.04637 28.8673 9.65199 28.5518L30.9362 17.4792C31.5415 17.165 31.7794 16.4118 31.4639 15.8062L31.4635 15.8075ZM29.7185 15.5377L20.3426 15.1256L25.3875 7.21195L29.7185 15.5377ZM19.4589 16.5113L28.9176 16.9268L10.3554 26.5838L15.4462 18.5958L16.7031 18.6511C18.0129 18.7134 18.019 18.6905 18.7013 17.7012L19.4589 16.5113ZM13.8081 18.5249L8.75774 26.4429L4.42438 18.1151L13.8068 18.5246L13.8081 18.5249ZM4.99376 16.7174L17.2957 17.2582L23.9149 6.87366L4.99241 16.7171L4.99278 16.7157L4.99376 16.7174Z" fill="#2E416B"></path>
                            </svg>
                        </i>
                        <div class="name">{{$t("webdesktop.resetPasswordDialog.emailTabCaption")}}</div>
                    </div>
                    <div id="reset-by-phone-tab" class="tabs-item" :class="{active: activeTab === 1}" @click="switchTab(1)">
                        <i class="icon mobile">
                            <svg viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.1184 6.39875L30.1744 16.1172C30.2252 16.2147 30.1855 16.3371 30.089 16.3874L10.2666 26.6994C10.1693 26.7494 10.0466 26.7105 9.9964 26.6141L4.94043 16.8956C4.8902 16.7992 4.9273 16.6769 5.02579 16.6254L24.8482 6.31339C24.9457 6.26257 25.0678 6.30047 25.1184 6.39875ZM27.1177 11.5325C27.2433 11.7737 27.1492 12.07 26.9088 12.1958C26.6677 12.3214 26.3714 12.2273 26.2456 11.987C26.12 11.7459 26.2141 11.4495 26.4544 11.3237C26.6955 11.1981 26.9919 11.2922 27.1177 11.5325ZM31.4503 15.4528L26.3943 5.73439C25.9783 4.93482 24.9837 4.62075 24.1841 5.0367L3.33996 15.8807C2.54039 16.2966 2.22632 17.2913 2.64227 18.0909L7.69824 27.8093C8.11419 28.6089 9.10889 28.923 9.90845 28.507L30.7526 17.663C31.5521 17.2471 31.8662 16.2524 31.4503 15.4528ZM5.09166 21.1867L6.56865 24.0272C6.70915 24.2965 7.0432 24.4016 7.31231 24.2619C7.58061 24.122 7.6867 23.7873 7.54702 23.5182L6.07002 20.6778C5.93033 20.4087 5.59547 20.3034 5.32636 20.4431C5.05806 20.583 4.95197 20.9176 5.09187 21.1859L5.09166 21.1867Z" fill="#2E416B"></path>
                            </svg>
                        </i>
                        <div class="name">{{$t("webdesktop.resetPasswordDialog.phoneTabCaption")}}</div>
                    </div>
                </div>
                <!-- Content Restore Via Email -->
                <div class="popup-content active" v-if="activeTab === 0">
                    <div class="form-line">
                        <input id="email-input" type="email" :placeholder="$t('webdesktop.resetPasswordDialog.emailPlaceholder')" v-model="data">
                    </div>
                    <div class="text">
                        {{$t("webdesktop.resetPasswordDialog.emailTabText1")}}
                        <br><br>
                        {{$t("webdesktop.resetPasswordDialog.emailTabText2")}}
                    </div>
                    <div class="form-line">
                        <a id="submit-email-button" class="button button-primary smaller" :class="{disabled: !data}" @click.prevent="resetPassword">{{$t("webdesktop.resetPasswordDialog.submitButton")}}</a>
                    </div>
                    <div class="form-line">
                        <a id="close-button-1" class="button button-secondary smaller" @click.prevent="onClose">{{$t("common.forms.close")}}</a>
                    </div>
                </div>
                <!-- Content Restore Via Mobile -->
                <div class="popup-content active" v-if="activeTab === 1">
                        <div class="form-line">
                            <input id="phone-input" type="text" :placeholder="$t('webdesktop.resetPasswordDialog.phonePlaceholder')" v-model="data">
                        </div>
                        <div class="text">
                            {{$t("webdesktop.resetPasswordDialog.phoneTabText1")}}
                            <br><br>
                            {{$t("webdesktop.resetPasswordDialog.phoneTabText2")}}
                        </div>
                    <div class="form-line">
                        <a id="submit-phone-button" class="button button-primary smaller" :class="{disabled: !data}" @click.prevent="resetPassword">{{$t("webdesktop.resetPasswordDialog.submitButton")}}</a>
                    </div>
                    <div class="form-line">
                        <a id="close-button" class="button button-secondary smaller" @click.prevent="onClose">{{$t("common.forms.close")}}</a>
                    </div>
                </div>
            </div>
    </div>
    <confirm-code-dialog v-if="confirmDialogVisible" @close="onConfirmDialogClose" @success="onConfirmSuccess" />
</template>

<script src="./reset-password-dialog-component.ts" />
