<template v-if="stake.amount">
    <tr v-if="stake.express">
        <td class="text-big">
            <i><b>{{ $t("betting.betSlip.expressWithCoef", { coef: stake.coef.toFixed(2) }) }}</b></i>
        </td>
        <td class="text-big">
            <i><b>{{ $n(stake.amount, "currency") }} {{ pointCurrency }}</b></i>
        </td>
    </tr>
    <tr v-else>
        <td class="text-big">
            <i><b>{{
                    $t("betting.betSlip.system", {
                        base: stake.base,
                        limit: stake.limit
                    })
                }}</b></i>
        </td>
        <td class="text-big">
            <i><b>{{ $n(stake.amount, "currency") }} {{ pointCurrency }}</b></i>
        </td>
    </tr>
    <tr>
        <td class="text-big">{{ $t("betting.betSlip.maxWin") }}</td>
        <td class="text-big">{{ $n(stake.maxWin, "currency") }} {{ pointCurrency }}</td>
    </tr>
    <tr v-for="stake of simpleStakes" :key="stake.templateKey">
        <td colspan="2" class="text-big">
            <template v-if="stake.event.eventType === 2"><b>LIVE.</b></template>
            {{ $d(stake.event.startTime, "long") }} {{ stake.event.partitionName }}
            <br> {{ getStakeParticipants(stake) }} <br>
            <b><span v-html="getQuotationTitle(stake)"/></b>
        </td>
    </tr>
</template>

<script src="./print-combined-stake-row-component.ts"/>
