import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { RegistrationData } from "@sportaq/model/cashier/registration-data";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";

export class AUs11RegistrationMessageHandler extends AbstractMessageHandler<string> {
    protected readonly requestType: string = "A.US.1.1";
    protected readonly mobileRequestType: string = "A.US.2.4";
    constructor (readonly registrationData: RegistrationData, readonly partnerGuid: string, readonly isMobile: boolean) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.isMobile ? this.mobileRequestType : this.requestType
        });
        request.addChildWithValue(actionElement, "FirstName", this.registrationData.firstName);
        request.addChildWithValue(actionElement, "LastName", this.registrationData.lastName);
        request.addChildWithValue(actionElement, "Address", this.registrationData.address);
        request.addChildWithValue(actionElement, "Phone", this.registrationData.phoneCode + this.registrationData.phone);
        request.addChildWithValue(actionElement, "Email", this.registrationData.email);
        request.addChildWithValue(actionElement, "Login", this.registrationData.login);
        request.addChildWithValue(actionElement, "Password", this.registrationData.password);
        request.addChildWithValue(actionElement, "CountryId", this.registrationData.country.toString());
        request.addChildWithValue(actionElement, "PartnerGUID", this.partnerGuid);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): string {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1100") {
            return getRequiredValue(getRequiredChild(getRequiredChild(action, "User"), "UserNumber"));
        }
        throw new LocalizedError(`errors.mtl.aUs11.code${serverCode}`);
    }
}
