<template>
    <div class="coupon coupon-content-active" data-cy="bet-slip">
        <mobile-dialog-bet-prematch-verification v-if="prematchVerificationShowed" :simpleStakes="simpleStakeDTOs"
                                            :combinedStakes="combinedStakeDTOs"
                                            @confirm="closePrematchVerificationForm(true)"
                                            @cancel="closePrematchVerificationForm(false)"/>

        <mobile-dialog-bet-accepted v-if="placeBetResponseSuccessInfo" :responseInfo="placeBetResponseSuccessInfo"
                                :simpleStakes="simpleStakeDTOs" :combinedStakes="combinedStakeDTOs"
                                 @close="onBetAcceptedFormClose"/>
        <div class="coupon-content">
            <div class="coupon-head">
                <div class="coupon-close" @click="couponClose" data-cy="btn-coupon-close"/>
                <div class="coupon-head-title">{{ $t("betting.betSlip.title") }}</div>
            </div>

            <div class="coupon-error" v-if="topMessage" data-cy="bet-slip-top-message">
                <img src="@assets/img/icon-error.png" alt="" class="coupon-error-icon"/>
                <div class="coupon-error-info">
                    <div class="message">{{ topMessage }}</div>
                </div>
            </div>
            <div class="preloader" v-if="requestSent">
                <div class="preloader-el"></div>
            </div>

            <div class="coupon-body" data-cy="bet-slip-body">
                <div class="coupon-block" data-cy="simple-bet-block">
                    <div class="coupon-block-title">{{ $t("betting.betSlip.simple") }}</div>
                    <div class="coupon-block-head">
                        <div class="name width-67">{{ $t("betting.betSlip.columns.event") }}</div>
                        <div class="name width-33">{{ $t("betting.betSlip.columns.sum") }}</div>
                    </div>
                    <transition-group name="coupon-block-animation" tag="div">
                        <mobile-bet-slip-stake-row v-for="stake of stakes" :stake="stake" :key="stake.templateKey"
                                                   @updateAmount="updateAmount"/>
                    </transition-group>
                </div>

                <div class="coupon-block" data-cy="combined-bet-block">
                    <div class="coupon-block-title" v-if="combined.length > 0">{{
                            $t("betting.betSlip.combined")
                        }}
                    </div>
                    <div class="coupon-block-head" v-if="combined.length > 0">
                        <div class="name width-67">{{ $t("betting.betSlip.columns.event") }}</div>
                        <div class="name width-33">{{ $t("betting.betSlip.columns.sum") }}</div>
                    </div>
                    <transition-group name="coupon-block-animation" tag="div">
                        <div v-if="combined.length > 0">
                            <mobile-combined-stake-row v-for="stake of combined" :stake="stake" :key="stake.templateKey"
                                                @updateAmount="updateAmount"/>
                        </div>
                        <div v-else>
                            <div key="empty_div"></div>
                        </div>
                    </transition-group>
                </div>
                <div class="coupon-options" v-if="containsLive">
                    <div class="form-line">
                        <div class="checkbox">
                            <input type="radio" id="nonFullCheckingRadio" class="checkbox-input" value="false" v-model="fullChecking" data-cy="non-full-checking-switcher">
                            <label for="nonFullCheckingRadio" class="checkbox-label" data-cy="non-full-checking-switcher-label">
                                {{ $t("betting.betSlip.acceptWithAllQuotations") }}
                            </label>
                        </div>
                    </div>
                    <div class="form-line">
                        <div class="checkbox">
                            <input type="radio" id="fullCheckingRadio" class="checkbox-input" value="true" v-model="fullChecking" data-cy="full-checking-switcher">
                            <label for="fullCheckingRadio" class="checkbox-label" data-cy="full-checking-switcher-label">
                                {{ $t("betting.betSlip.acceptOnlyWithQuotationsAreRaised") }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="coupon-buttons" v-if="isConfirmed">
                <button class="button button-secondary" @click.prevent="clearAllStakes" data-cy="clear-bet-slip-button">
                    {{ $t("betting.betSlip.buttons.clear") }}
                </button>
                <button class="button button-primary" @click.prevent="placeBet" data-cy="place-bet-button">
                    {{ $t("betting.betSlip.buttons.verify") }}
                </button>
            </div>
            <div class="coupon-error" data-cy="bet-slip-alert" v-else>
                <div class="coupon-error-info">
                <div class="message" data-cy="message">{{ $t("betting.betSlip.confirmCoefDiffs") }}</div>
                    <button class="button" data-cy="alert-accept-button" @click="confirmAllChanges">
                        {{ $t("betting.betSlip.acceptButton") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./mobile-bet-slip-component.ts"/>
