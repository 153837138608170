<template>
    <div class="popup wide compact active">
        <div class="overlay" @click="onClose"></div>
        <Form @submit="onSubmit" v-slot="{ errors, handleSubmit }">
            <div class="popup-container">
                <div class="popup-title">{{ $t("cashier.expressAccount.caption") }}</div>
                <div class="popup-body">
                    <div class="popup-content">
                            <div class="form-line">
                                <label class="label" :class="{'label-error': errors.firstName}">{{ $t("cashier.expressAccount.firstName") }}</label>
                                <div class="position-relative">
                                    <Field name="firstName" type="text" class="field" v-model="registrationData.firstName" rules="required"/>
                                    <div class="field-error" v-if="errors.firstName">{{errors.firstName}}</div>
                                </div>
                            </div>
                            <div class="form-line">
                                <label class="label" :class="{'label-error': errors.lastName}">{{ $t("cashier.expressAccount.lastName") }}</label>
                                <div class="position-relative">
                                    <Field name="lastName" type="text" class="field" v-model="registrationData.lastName" rules="required"/>
                                    <div class="field-error" v-if="errors.lastName">{{errors.lastName}}</div>
                                </div>
                            </div>
                            <div class="form-line">
                                <label class="label" :class="{'label-error': errors.orderSum}">{{ $t("cashier.expressAccount.orderSum") }}</label>
                                <div class="width-74 position-relative">
                                    <Field name="orderSum" type="number" class="field" rules="required" v-model.number="registrationData.orderSum"/>
                                    <div class="field-error" v-if="errors.orderSum">{{errors.orderSum}}</div>
                                </div>
                            </div>
                            <div class="form-line">
                                <label class="label" :class="{'label-error': errors.document}">{{ $t("cashier.expressAccount.document") }}</label>
                                <div class="position-relative">
                                    <Field name="document" type="text" class="field" v-model="registrationData.document" rules="required"/>
                                    <div class="field-error" v-if="errors.document">{{errors.document}}</div>
                                </div>
                            </div>
                            <div class="form-line">
                                <label class="label" :class="{'label-error': errors.phone}">{{ $t("cashier.expressAccount.phone") }}</label>
                                <div class="position-relative">
                                    <Field name="phone" type="text" class="field" v-model="registrationData.phone" rules="required"/>
                                    <div class="field-error" v-if="errors.phone">{{errors.phone}}</div>
                                </div>
                            </div>
                        <div class="form-line">
                            <a href="#" class="button button-primary" :class="{disabled: isSubmitButtonDisabled}" @click.stop.prevent="handleSubmit(onSubmit)">{{ $t("common.forms.ok") }}</a>
                        </div>
                        <div class="form-line">
                            <a href="#" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </div>
    <div class="popup active" v-if="success">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.expressAccount.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line" >{{ $t("cashier.expressAccount.print.number") }} </div>
                    <div class="form-line" ><strong>{{ formatVoucherNumber(expressAccountDataRef.userNumber) }}</strong> </div>
                    <div class="form-line" >{{ $t("cashier.expressAccount.print.balance") }} {{ parseFloat(registrationData.orderSum).toFixed(2) }} {{ currencyName }}</div>
                    <div class="form-line" >{{ $t("cashier.expressAccount.print.siteCaption") }} </div>
                    <div class="form-line" ><strong>{{ $t("cashier.expressAccount.print.login") }} {{ formatVoucherNumber(expressAccountDataRef.userNumber) }}</strong></div>
                    <div class="form-line" ><strong>{{ $t("cashier.expressAccount.print.password") }} {{ expressAccountDataRef.password }}</strong></div>
                    <div class="form-line" ><strong>{{ $t("cashier.expressAccount.print.warning") }} </strong></div>
                    <div class="form-line">
                        <a href="#" class="button button-primary" @click.stop.prevent="copy">{{ $t("common.forms.copy") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.ok") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./add-express-account-dialog-component.ts" />
