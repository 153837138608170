import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getAttribute,
    getChild,
    getRequiredAttribute,
    getRequiredChild,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { EUserRole, EVerifyField, UserAccountsImpl, UserInfo, UserSession } from "@sportaq/model/common/user-accounts";
import { stripDni } from "@sportaq/common/utils/string-utils";
import { PaymentSystemData } from "@sportaq/common/enums/payment-systems";
import { toLocalTime } from "@sportaq/common/utils/time-utils";
import { parsePaymentSystemList } from "@sportaq/services/rest/messages/system/point-settings-message-handler";

export class AUs111UserInfo extends AbstractMessageHandler<UserInfo> {
    protected readonly requestType: string = "Q.US.1.11";

    constructor () {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.User",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserAccount",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserRole",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserVerifyField",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.PaySystem",
            allFields: "true"
        });
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): UserInfo {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1190") {
            const userElement = getRequiredChild(action, "US");
            const userSessions: UserSession[] = [];
            const userSessionsElement = getChild(userElement, "UserSessions");
            if (userSessionsElement) {
                for (let sessionElement = userSessionsElement.firstElementChild; sessionElement != null; sessionElement = sessionElement.nextElementSibling) {
                    userSessions.push({
                        ipAddress: getRequiredAttribute(sessionElement, "ipAddress"),
                        registerTime: toLocalTime(new Date(getRequiredAttribute(sessionElement, "registerTime"))),
                        id: getRequiredIntAttribute(sessionElement, "sessionId")
                    });
                }
            }
            const birthday = getRequiredAttribute(userElement, "birthday");
            const paymentSystems: PaymentSystemData[] = parsePaymentSystemList(userElement);
            const roles: EUserRole[] = [];
            const rolesElement = getChild(userElement, "UserRoleList");
            if (rolesElement !== undefined) {
                for (let roleElement: Element | null = rolesElement.firstElementChild; roleElement !== null; roleElement = roleElement?.nextElementSibling) {
                    roles.push(getRequiredIntAttribute(roleElement, "Id") as EUserRole);
                }
            }
            const verifiedFields = new Set<EVerifyField>();
            const userVerifyElement = getChild(userElement, "UserVerifyFieldList");
            if (userVerifyElement) {
                for (let uf = userVerifyElement.firstElementChild; uf != null; uf = uf.nextElementSibling) {
                    if (getRequiredAttribute(uf, "isVerify") === "true") {
                        const key = getRequiredAttribute(uf, "fieldName") as keyof typeof EVerifyField;
                        verifiedFields.add(EVerifyField[key]);
                    }
                }
            }

            const accountList = getChild(userElement, "UserAccountList");
            const userAccount = accountList ? getChild(accountList, "UA") : undefined;
            const selfBlockedTime = getAttribute(userElement, "selfBlockedTime");
            return {
                user: {
                    userId: getRequiredIntAttribute(userElement, "Id"),
                    userNumber: getRequiredAttribute(userElement, "UserNumber"),
                    firstName: getRequiredAttribute(userElement, "firstname"),
                    lastName: getRequiredAttribute(userElement, "lastname"),
                    middleName: getRequiredAttribute(userElement, "middlename"),
                    address: getRequiredAttribute(userElement, "address"),
                    phone: getRequiredAttribute(userElement, "phone"),
                    email: getRequiredAttribute(userElement, "email"),
                    login: getRequiredAttribute(userElement, "login"),
                    partnerId: getRequiredIntAttribute(userElement, "partnerId"),
                    IdentificationCode: stripDni(getAttribute(userElement, "identificationCode")),
                    verified: verifiedFields.has(EVerifyField.identificationCode),
                    verifiedFields,
                    birthday: birthday !== "0000-00-00" ? birthday : "",
                    registrationTime: toLocalTime(new Date(getRequiredAttribute(userElement, "registrationTime"))),
                    countryId: getRequiredIntAttribute(userElement, "countryId"),
                    city: getAttribute(userElement, "cityText"),
                    userAccounts: new UserAccountsImpl([]),
                    selfBlockedTime: selfBlockedTime ? toLocalTime(new Date(selfBlockedTime)) : undefined,
                    isRegulatorBlocked: getAttribute(userElement, "isRegulatorBlocked") !== "",
                    sessions: userSessions,
                    userRoles: roles,
                    paymentSystems
                },
                currency: !userAccount
                    ? undefined
                    : {
                        rateId: 0,
                        typeId: getRequiredIntAttribute(userAccount, "currencyId"),
                        value: getRequiredIntAttribute(userAccount, "currencyValue")
                    }
            };
        }
        if (serverCode === "1191") {
            throw new Error("Session expired");
        }
        throw new LocalizedError(`errors.mtl.qUs111.code${serverCode}`);
    }
}
