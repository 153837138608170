import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";

export class APo67MakeWithdrawalMessageHandler extends AbstractMessageHandler<boolean> {
    protected readonly requestType: string = "A.PO.6.7";

    constructor (
        readonly payCode: string,
        readonly documentType: string,
        readonly documentNumber: string
    ) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "PayCode", this.payCode);
        request.addChildWithValue(actionElement, "PresentedDocumentType", this.documentType);
        request.addChildWithValue(actionElement, "PresentedDocumentNumber", this.documentNumber);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): boolean {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "4460") {
            throw new LocalizedError(`errors.mtl.aPo67.code${serverCode}`);
        }
        return true;
    }
}
