/* eslint quote-props: ["error", "always"] */
export default {
    "detailInfo": {
        "groups": {
            "americanFootball": {
                "evenOddMatch": "Пар / Непар матча",
                "evenOddMatchHalf1": "1 половина Пар / Непар",
                "evenOddMatchQuarter1": "1 чверть Пар / Непар",
                "evenOddMatchQuarter2": "2 чверть Пар / Непар",
                "evenOddMatchQuarter3": "3 чверть Пар / Непар",
                "evenOddMatchQuarter4": "4 чверть Пар / Непар",
                "handicap": "Фора",
                "handicapHalf1": "1 половина Фора",
                "handicapHalf2": "2 половина Фора",
                "handicapQuarter1": "1 чверть Фора",
                "handicapQuarter2": "2 чверть Фора",
                "handicapQuarter3": "3 чверть Фора",
                "handicapQuarter4": "4 чверть Фора",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam1Half1": "1 половина Індивідуальний тотал {team1}",
                "individualTotalTeam1Half2": "2 половина Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter1": "1 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter2": "2 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter3": "3 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter4": "4 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "individualTotalTeam2Half1": "1 половина Індивідуальний тотал {team2}",
                "individualTotalTeam2Half2": "2 половина Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter1": "1 чверть Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter2": "2 чверть Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter3": "3 чверть Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter4": "4 чверть Індивідуальний тотал {team2}",
                "total": "Тотал",
                "totalHalf1": "1 половина Тотал",
                "totalHalf2": "2 половина Тотал",
                "totalQuarter1": "1 чверть Тотал",
                "totalQuarter2": "2 чверть Тотал",
                "totalQuarter3": "3 чверть Тотал",
                "totalQuarter4": "4 чверть Тотал",
                "x1_2": "Результат матча",
                "x1x2Half1": "Результат 1 половина",
                "x1x2Half2": "Результат 2 половина",
                "x1x2Quarter1": "Результат 1 чверть",
                "x1x2Quarter2": "Результат 2 чверть",
                "x1x2Quarter3": "Результат 3 чверть",
                "x1x2Quarter4": "Результат 4 чверть"
            },
            "baseball": {
                "evenOddMatch": "Чет/Нечет матча",
                "handicap": "Фора",
                "handicapHalf1": "Фора 1 половина",
                "handicapInning1": "1 іннінг Фора",
                "handicapInning2": "2 іннінг Фора",
                "handicapInning3": "3 іннінг Фора",
                "handicapInning4": "4 іннінг Фора",
                "handicapInning5": "5 іннінг Фора",
                "handicapInning6": "6 іннінг Фора",
                "handicapInning7": "7 іннінг Фора",
                "handicapInning8": "8 іннінг Фора",
                "handicapInning9": "9 іннінг Фора",
                "handicaps": "Фори",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "total": "Тотал",
                "totalHalf1": "Тотал 1 половина",
                "totalInning1": "1 іннінг Тотал",
                "totalInning2": "2 іннінг Тотал",
                "totalInning3": "3 іннінг Тотал",
                "totalInning4": "4 іннінг Тотал",
                "totalInning5": "5 іннінг Тотал",
                "totalInning6": "6 іннінг Тотал",
                "totalInning7": "7 іннінг Тотал",
                "totalInning8": "8 іннінг Тотал",
                "totalInning9": "9 іннінг Тотал",
                "x1_2": "Результат матча",
                "x1x2Half1": "Результат 1 половина",
                "x1x2Inning1": "Результат 1 іннінг",
                "x1x2Inning2": "Результат 2 іннінг",
                "x1x2Inning3": "Результат 3 іннінг",
                "x1x2Inning4": "Результат 4 іннінг",
                "x1x2Inning5": "Результат 5 іннінг",
                "x1x2Inning6": "Результат 6 іннінг",
                "x1x2Inning7": "Результат 7 іннінг",
                "x1x2Inning8": "Результат 8 іннінг",
                "x1x2Inning9": "Результат 9 іннінг"
            },
            "basketball": {
                "evenOddMatch": "Пар / Непар матча",
                "evenOddMatchHalf1": "1 половина Пар / Непар",
                "evenOddMatchQuarter1": "1 чверть Пар / Непар",
                "evenOddMatchQuarter2": "2 чверть Пар / Непар",
                "evenOddMatchQuarter3": "3 чверть Пар / Непар",
                "evenOddMatchQuarter4": "4 чверть Пар / Непар",
                "handicap": "Фора",
                "handicapHalf1": "1 половина Фора",
                "handicapHalf2": "2 половина Фора",
                "handicapQuarter1": "1 чверть Фора",
                "handicapQuarter2": "2 чверть Фора",
                "handicapQuarter3": "3 чверть Фора",
                "handicapQuarter4": "4 чверть Фора",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam1Half1": "1 половина Індивідуальний тотал {team1}",
                "individualTotalTeam1Half2": "2 половина Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter1": "1 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter2": "2 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter3": "3 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam1Quarter4": "4 чверть Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "individualTotalTeam2Half1": "1 половина Індивідуальний тотал {team2}",
                "individualTotalTeam2Half2": "2 половина Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter1": "1 чверть Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter2": "2 чверть Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter3": "3 чверть Індивідуальний тотал {team2}",
                "individualTotalTeam2Quarter4": "4 чверть Індивідуальний тотал {team2}",
                "total": "Тотал",
                "totalHalf1": "1 половина Тотал",
                "totalHalf2": "2 половина Тотал",
                "totalQuarter1": "1 чверть Тотал",
                "totalQuarter2": "2 чверть Тотал",
                "totalQuarter3": "3 чверть Тотал",
                "totalQuarter4": "4 чверть Тотал",
                "x1x2": "Результат матча",
                "x1x2Half1": "Результат 1 половина",
                "x1x2Half2": "Результат 2 половина",
                "x1x2Quarter1": "Результат 1 чверть",
                "x1x2Quarter2": "Результат 2 чверть",
                "x1x2Quarter3": "Результат 3 чверть",
                "x1x2Quarter4": "Результат 4 чверть"
            },
            "football": {
                "12TotalUnder": "12 + тотал менше ({p1})",
                "DrawTotalOver": "Нічия + тотал більше ({p1})",
                "DrawTotalUnder": "Нічия + тотал менше ({p1})",
                "atLeastOneWontScore12": "Бодай одна не забʼє + 12",
                "atLeastOneWontScore1X": "Бодай одна не забʼє + 1X",
                "atLeastOneWontScoreDraw": "Бодай одна не забʼє + Нічия",
                "atLeastOneWontScoreTeam1": "Бодай одна не забʼє + {team1}",
                "atLeastOneWontScoreTeam2": "Бодай одна не забʼє + {team2}",
                "atLeastOneWontScoreTotalOver": "Бодай одна не забʼє + тотал біл. ({p1})",
                "atLeastOneWontScoreTotalUnder": "Бодай одна не забʼє + тотал мен. ({p1})",
                "atLeastOneWontScoreX2": "Бодай одна не забʼє + X2",
                "bothScoreTotalOver": "Обидві забʼють + тотал біл. ({p1})",
                "bothScoreTotalUnder": "Обидві забʼють + тотал мен. ({p1})",
                "dryWin": "Суха перемога",
                "evenOddHalf1": "Пар / Непар 1 тайм",
                "evenOddMatch": "Пар / Непар матча",
                "firstGoalTeam": "Перший гол забʼє",
                "goalBothHalves": "Гол в обох таймах",
                "goalHalf1": "Гол у першому таймі",
                "goalHalf2": "Гол у другому таймі",
                "goalIntervalTeam1x16x30": "Гол {team1} 15:01 - 30:00 хв",
                "goalIntervalTeam1x1x15": "Гол {team1} 00:01 - 15:00 хв",
                "goalIntervalTeam1x1x30": "Гол {team1} 00:01 - 30:00 хв",
                "goalIntervalTeam1x1x45": "Гол {team1} 00:01 - 45:00 хв",
                "goalIntervalTeam1x1x60": "Гол {team1} 00:01 - 60:00 хв",
                "goalIntervalTeam1x1x75": "Гол {team1} 00:01 - 75:00 хв",
                "goalIntervalTeam1x31x45": "Гол {team1} 30:01 - 45:00 хв",
                "goalIntervalTeam1x46x60": "Гол {team1} 45:01 - 60:00 хв",
                "goalIntervalTeam1x61x75": "Гол {team1} 60:01 - 75:00 хв",
                "goalIntervalTeam1x76x90": "Гол {team1} 75:01 - 90:00 хв",
                "goalIntervalTeam2x16x30": "Гол {team2} 15:01 - 30:00 хв",
                "goalIntervalTeam2x1x15": "Гол {team2} 00:01 - 15:00 хв",
                "goalIntervalTeam2x1x30": "Гол {team2} 00:01 - 30:00 хв",
                "goalIntervalTeam2x1x45": "Гол {team2} 00:01 - 45:00 хв",
                "goalIntervalTeam2x1x60": "Гол {team2} 00:01 - 60:00 хв",
                "goalIntervalTeam2x1x75": "Гол {team2} 00:01 - 75:00 хв",
                "goalIntervalTeam2x31x45": "Гол {team2} 30:01 - 45:00 хв",
                "goalIntervalTeam2x46x60": "Гол {team2} 45:01 - 60:00 хв",
                "goalIntervalTeam2x61x75": "Гол {team2} 60:01 - 75:00 хв",
                "goalIntervalTeam2x76x90": "Гол {team2} 75:01 - 90:00 хв",
                "goalIntervalx16x30": "Гол 15:01 - 30:00 хв",
                "goalIntervalx1x15": "Гол 00:01 - 15:00 хв",
                "goalIntervalx1x30": "Гол 00:01 - 30:00 хв",
                "goalIntervalx1x45": "Гол 00:01 - 45:00 хв",
                "goalIntervalx1x60": "Гол 00:01 - 60:00 хв",
                "goalIntervalx1x75": "Гол 00:01 - 75:00 хв",
                "goalIntervalx31x45": "Гол 30:01 - 45:00 хв",
                "goalIntervalx46x60": "Гол 45:01 - 60:00 хв",
                "goalIntervalx61x75": "Гол 60:01 - 75:00 хв",
                "goalIntervalx76x90": "Гол 75:01 - 90:00 хв",
                "goalTeam1BothHalves": "{team1} забʼє в обох таймах",
                "goalTeam1Half1": "{team1} забʼє гол в 1 таймі",
                "goalTeam1Half2": "{team1} забʼє гол в 2 таймі",
                "goalTeam2BothHalves": "{team2} забʼє в обох таймах",
                "goalTeam2Half1": "{team2} забʼє гол в 1 таймі",
                "goalTeam2Half2": "{team2} забʼє гол в 2 таймі",
                "goalWillScore1": "1 гол забʼє",
                "goalWillScore10": "10 гол забʼє",
                "goalWillScore2": "2 гол забʼє",
                "goalWillScore3": "3 гол забʼє",
                "goalWillScore4": "4 гол забʼє",
                "goalWillScore5": "5 гол забʼє",
                "goalWillScore6": "6 гол забʼє",
                "goalWillScore7": "7 гол забʼє",
                "goalWillScore8": "8 гол забʼє",
                "goalWillScore9": "9 гол забʼє",
                "halfMatch": "Тайм/Матч",
                "halvesComparison": "Порівняння таймів",
                "handicap": "Фора",
                "handicapHalf1": "1 Тайм Фора",
                "handicapHalf2": "2 Тайм Фора",
                "handicapMatch": "Фора матч",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam1Half1": "1 Тайм Індивідуальний тотал {team1}",
                "individualTotalTeam1Half2": "2 Тайм Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "individualTotalTeam2Half1": "1 Тайм Індивідуальний тотал {team2}",
                "individualTotalTeam2Half2": "2 Тайм Індивідуальний тотал {team2}",
                "lastGoalTeam": "Останній гол забʼє команда",
                "matchScore": "Точный рахунок матча",
                "passage": "Проход",
                "scoreBoth": "Обидві забʼють",
                "scoreBoth12": "Обидві забʼють + 12",
                "scoreBoth1X": "Обидві забʼють + 1X",
                "scoreBothDraw": "Обидві забʼють + Нічия",
                "scoreBothHalf1": "Обидві забʼють в 1-ом таймі",
                "scoreBothHalf2": "Обидві забʼють во 2-ом таймі",
                "scoreBothTeam1": "Обидві забʼють + {team1}",
                "scoreBothTeam2": "Обидві забʼють + {team2}",
                "scoreBothX2": "Обидві забʼють + X2",
                "scoreTeam1": "{team1} забʼє гол",
                "scoreTeam2": "{team2} забʼє гол",
                "strongWilledWin": "Вольова перемога",
                "team1Goal112": "{team1} забʼє 1-й гол + 12",
                "team1Goal11X": "{team1} забʼє 1-й гол + 1X",
                "team1Goal1Draw": "{team1} забʼє 1-й гол + Нічия",
                "team1Goal1Team1": "{team1} забʼє 1-й гол + {team1}",
                "team1Goal1Team2": "{team1} забʼє 1-й гол + {team2}",
                "team1Goal1X2": "{team1} забʼє 1-й гол + X2",
                "team1TotalOver": "{team1} + Тотал більше ({p1})",
                "team1TotalUnder": "{team1} + Тотал менше ({p1})",
                "team2Goal112": "{team2} забʼє 1-й гол + 12",
                "team2Goal11X": "{team2} забʼє 1-й гол + 1X",
                "team2Goal1Draw": "{team2} забʼє 1-й гол + Нічия",
                "team2Goal1Team1": "{team2} забʼє 1-й гол + {team1}",
                "team2Goal1Team2": "{team2} забʼє 1-й гол + {team2}",
                "team2Goal1X2": "{team2} забʼє 1-й гол + X2",
                "team2TotalOver": "{team2} + Тотал більше ({p1})",
                "team2TotalUnder": "{team2} + Тотал менше ({p1})",
                "total": "Тотал",
                "totalBalls": "Тотал мячей",
                "totalHalf1": "1 Тайм Тотал",
                "totalHalf2": "2 Тайм Тотал",
                "winBothHalves": "Выиграет оба тайма",
                "x12TotalOver": "12 + тотал більше ({p1})",
                "x1TotalOver": "1X + тотал більше ({p1})",
                "x1TotalUnder": "1X + тотал менше ({p1})",
                "x1x12x2": "Двойной исход",
                "x1x12x2Half1": "Двойной исход 1 Тайм",
                "x1x12x2Half2": "Двойной исход 2 Тайм",
                "x1x2": "Результат матча",
                "x1x2fHalf1": "Результат 1 Тайм",
                "x1x2fHalf2": "Результат 2 Тайм",
                "x2TotalOver": "X2 + тотал більше ({p1})",
                "x2TotalUnder": "X2 + тотал менше ({p1})"
            },
            "handball": {
                "evenOddHalf1": "1 тайм Пар / Непар",
                "evenOddMatch": "Пар / Непар матча",
                "handicap": "Фора",
                "handicapHalf1": "1 тайм Фора",
                "handicapHalf2": "2 тайм Фора",
                "handicapMatch": "Фора матч",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam1Half1": "1 тайм Індивідуальний тотал {team1}",
                "individualTotalTeam1Half2": "2 тайм Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "individualTotalTeam2Half1": "1 тайм Індивідуальний тотал {team2}",
                "individualTotalTeam2Half2": "2 тайм Індивідуальний тотал {team2}",
                "passage": "Проход",
                "total": "Тотал",
                "totalHalf1": "1 тайм Тотал",
                "totalHalf2": "2 тайм Тотал",
                "x1x12x2": "Двойной шанс",
                "x1x12x2Half1": "Двойной шанс 1 тайм",
                "x1x12x2Half2": "Двойной шанс 2 тайм",
                "x1x2": "Результат матча",
                "x1x2fHalf1": "Результат 1 тайм",
                "x1x2fHalf2": "Результат 2 тайм"
            },
            "hockey": {
                "handicap": "Фора",
                "handicapPeriod1": "1 период Фора",
                "handicapPeriod2": "2 период Фора",
                "handicapPeriod3": "3 период Фора",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalPeriod1": "1 период Тотал",
                "totalPeriod2": "2 период Тотал",
                "totalPeriod3": "3 период Тотал",
                "x1x12x2": "Двойной шанс",
                "x1x12x2Period1": "Двойной шанс 1 период",
                "x1x12x2Period2": "Двойной шанс 2 период",
                "x1x12x2Period3": "Двойной шанс 3 период",
                "x1x2": "Результат  матча",
                "x1x2Period1": "Результат 1 период",
                "x1x2Period2": "Результат 2 период",
                "x1x2Period3": "Результат 3 период"
            },
            "tableTennis": {
                "evenOddMatch": "Пар / Непар матча",
                "evenOddSet1": "1 сет Пар / Непар",
                "evenOddSet2": "2 сет Пар / Непар",
                "evenOddSet3": "3 сет Пар / Непар",
                "evenOddSet4": "4 сет Пар / Непар",
                "evenOddSet5": "5 сет Пар / Непар",
                "handicap": "Фора",
                "handicapSet1": "1 сет Фора",
                "handicapSet2": "2 сет Фора",
                "handicapSet3": "3 сет Фора",
                "handicapSet4": "4 сет Фора",
                "handicapSet5": "5 сет Фора",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "matchScore": "Точний рахунок матча",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalSet1": "1 сет Тотал",
                "totalSet2": "2 сет Тотал",
                "totalSet3": "3 сет Тотал",
                "totalSet4": "4 сет Тотал",
                "totalSet5": "5 сет Тотал",
                "x1_2": "Результат матча",
                "x1_2Set1": "Результат 1 сет",
                "x1_2Set2": "Результат 2 сет",
                "x1_2Set3": "Результат 3 сет",
                "x1_2Set4": "Результат 4 сет",
                "x1_2Set5": "Результат 5 сет"
            },
            "tennis": {
                "evenOddMatch": "Пар / Непар матча",
                "evenOddSet1": "1 сет Пар / Непар",
                "evenOddSet2": "2 сет Пар / Непар",
                "evenOddSet3": "3 сет Пар / Непар",
                "evenOddSet4": "4 сет Пар / Непар",
                "evenOddSet5": "5 сет Пар / Непар",
                "handicap": "Фора",
                "handicapSet1": "1 сет Фора",
                "handicapSet2": "2 сет Фора",
                "handicapSet3": "3 сет Фора",
                "handicapSet4": "4 сет Фора",
                "handicapSet5": "5 сет Фора",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "matchScore": "Точный рахунок матча",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalSet1": "1 сет Тотал",
                "totalSet2": "2 сет Тотал",
                "totalSet3": "3 сет Тотал",
                "totalSet4": "4 сет Тотал",
                "totalSet5": "5 сет Тотал",
                "x1_2": "Результат матча",
                "x1_2Set1": "Результат 1 сет",
                "x1_2Set2": "Результат 2 сет",
                "x1_2Set3": "Результат 3 сет",
                "x1_2Set4": "Результат 4 сет",
                "x1_2Set5": "Результат 5 сет"
            },
            "volleyball": {
                "evenOddMatch": "Пар / Непар матча",
                "evenOddSet1": "1 сет Пар / Непар",
                "evenOddSet2": "2 сет Пар / Непар",
                "evenOddSet3": "3 сет Пар / Непар",
                "evenOddSet4": "4 сет Пар / Непар",
                "evenOddSet5": "5 сет Пар / Непар",
                "handicap": "Фора",
                "handicapSet1": "1 сет Фора",
                "handicapSet2": "2 сет Фора",
                "handicapSet3": "3 сет Фора",
                "handicapSet4": "4 сет Фора",
                "handicapSet5": "5 сет Фора",
                "individualTotalTeam1": "Індивідуальний тотал {team1}",
                "individualTotalTeam2": "Індивідуальний тотал {team2}",
                "matchScore": "Точный рахунок матча",
                "scoreAfter2Games": "Рахунок після 2х партій",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalSet1": "1 сет Тотал",
                "totalSet2": "2 сет Тотал",
                "totalSet3": "3 сет Тотал",
                "totalSet4": "4 сет Тотал",
                "totalSet5": "5 сет Тотал",
                "x1_2": "Результат матча",
                "x1_2Set1": "Результат 1 сет",
                "x1_2Set2": "Результат 2 сет",
                "x1_2Set3": "Результат 3 сет",
                "x1_2Set4": "Результат 4 сет",
                "x1_2Set5": "Результат 5 сет"
            }
        },
        "tabs": {
            "americanFootball": {
                "all": "Всі",
                "halves": "Половини",
                "handicaps": "Фори",
                "popular": "Популярні",
                "quarters": "Чверті",
                "totals": "Тотали"
            },
            "baseball": {
                "all": "Всі",
                "halves": "Половини",
                "handicap": "Фора",
                "handicaps": "Фори",
                "innings": "Іннінги",
                "popular": "Популярні",
                "total": "Тотал",
                "totals": "Тотали"
            },
            "basketball": {
                "all": "Всі",
                "halves": "Половини",
                "handicaps": "Фори",
                "popular": "Популярні",
                "quarters": "Чверті",
                "totals": "Тотали"
            },
            "football": {
                "all": "Всі",
                "goals": "Голи",
                "goalsPlusResult": "Голи + Результат",
                "goalsPlusTotal": "Голи + Тотал",
                "halves": "Таймы",
                "handicaps": "Фори",
                "popular": "Популярні",
                "resultPlusTotal": "Результат + Тотал",
                "totals": "Тотали"
            },
            "handball": {
                "all": "Всі",
                "half1": "1 тайм",
                "half2": "2 тайм",
                "handicap": "Фора",
                "handicaps": "Фори",
                "popular": "Популярні",
                "total": "Тотал",
                "totals": "Тотали"
            },
            "hockey": {
                "all": "Всі",
                "handicaps": "Фори",
                "periods": "Періоди",
                "popular": "Популярні",
                "totals": "Тотали"
            },
            "tableTennis": {
                "all": "Всі",
                "handicaps": "Фори",
                "popular": "Популярні",
                "sets": "Сеты",
                "totals": "Тотали"
            },
            "tennis": {
                "all": "Всі",
                "handicaps": "Фори",
                "popular": "Популярні",
                "sets": "Сеты",
                "totals": "Тотали"
            },
            "volleyball": {
                "all": "Всі",
                "handicaps": "Фори",
                "popular": "Популярні",
                "sets": "Сеты",
                "totals": "Тотали"
            }
        }
    }
};