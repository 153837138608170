import { CashierRestService } from "@sportaq/services/cashier/rest/cashier-rest-service";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { ErrorHandler } from "@sportaq/services/rest/rest-service";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { provideRestService, services, useRestService } from "@sportaq/services/index";
import { CashierSettingsService } from "@sportaq/services/cashier/settings/settings-service";
import { use } from "@sportaq/common/utils/dependency-injection";

export function provideCashierRestService (clockProvider: ClockProvider, uuidProvider: UUIDProvider, settingsService: CashierSettingsService, errorHandler: ErrorHandler): CashierRestService {
    const result = new CashierRestService(uuidProvider, clockProvider, settingsService);
    result.errorHandler = errorHandler;

    return provideInjectedCashierRestService(result);
}

export function provideInjectedCashierRestService (restService: CashierRestService): CashierRestService {
    provideRestService(restService);
    return restService;
}

export function useCashierRestService (): CashierRestService {
    return useRestService() as CashierRestService;
}

export function useCashierSettings (): CashierSettingsService {
    return use(services.SETTINGS);
}
