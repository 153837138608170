import MobileSimpleStakeRow
    from "@sportaq/vue/components/mobile-betting/betslip/dialogs/common/inner-stake-table/simple-stake-row/MobileSimpleStakeRow.vue";
import { PropType } from "vue";
import { CombinedStakeDTO, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { useSettings } from "@sportaq/services/index";
import { formatQuotationCoef } from "@sportaq/vue/components/betting/utils/quotation-strings";

export default {
    props: {
        simpleStakes: { required: true, type: Object as PropType<SimpleStakeDTO[]> },
        combinedStakes: { required: true, type: Object as PropType<CombinedStakeDTO[]> }
    },
    components: {
        MobileSimpleStakeRow
    },
    setup () {
        const quotationFormat = useSettings().quotationFormat;
        function formatCoef (coef: number) {
            return formatQuotationCoef(quotationFormat, coef);
        }
        return {
            formatCoef
        };
    }
};
