import { ref, SetupContext } from "vue";
import { WithdrawalData } from "@sportaq/model/cashier/withdrawal-data";
import { useCashierRestService } from "@sportaq/services/cashier";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { showMessageBox } from "../common/message-box/message-box-component";
import { useI18n } from "vue-i18n";

export function useCashWithdrawalDialogCommon (context: SetupContext, successCallback: () => void) {
    const withdrawalDataRef = ref<WithdrawalData>();
    const payCodeRef = ref<string>();
    const restService = useCashierRestService();
    const docTypeRef = ref<string>();
    const docNumberRef = ref<string>();
    const i18n = useI18n();

    function onClose () {
        context.emit(CLOSE_EVENT);
    }
    function onFindData () {
        if (payCodeRef.value) {
            restService.findWithdrawal(payCodeRef.value).then((w) => {
                withdrawalDataRef.value = w;
                if (w === undefined) {
                    showMessageBox(i18n.t("cashier.cashWithdrawalForm.notFound"));
                }
            });
        }
    }

    function onMakeWithdrawal () {
        if (withdrawalDataRef.value && docTypeRef.value && docNumberRef.value && payCodeRef.value) {
            restService.makeWithdrawal(payCodeRef.value, docTypeRef.value, docNumberRef.value)
                .then((success) => {
                    if (success) {
                        successCallback();
                    }
                });
        }
    }

    function onConfirmUser () {
        if (withdrawalDataRef.value) {
            const userId = withdrawalDataRef.value?.user.userId;
            restService.confirmUser(userId)
                .then((success) => {
                    if (success && withdrawalDataRef.value && withdrawalDataRef.value.user.userId === userId) {
                        withdrawalDataRef.value.user.verified = true;
                    }
                });
        }
    }

    return {
        withdrawalDataRef,
        payCodeRef,
        docTypeRef,
        docNumberRef,

        onMakeWithdrawal,
        onConfirmUser,
        onFindData,
        onClose,
        formatVoucherNumber
    };
}
