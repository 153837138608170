import { ref } from "vue";

interface Props {
    title: string;
}

export default {
    props: {
        title: {
            type: String,
            required: true
        }
    },
    setup (props: Props) {
        const currentTitle = ref(props.title);
        const submenuShow = ref(false);
        return {
            currentTitle,
            submenuShow
        };
    }
};
