import {
    calculateCombinedMaxWin,
    calculateExpressCoef,
    calculateSystemCoef
} from "@sportaq/model/common/combined-stakes-calculator";
import { Participant, ParticipantsSupplier } from "@sportaq/model/betting/events/event";
import { ServerRegisteredItem } from "@sportaq/model/cashier/server-registered-item";
import { QuotationKey } from "@sportaq/model/betting/events/quotation";
import { getParticipantList } from "@sportaq/model/common/participants-functions";

export enum ContainerType {
    Simple = 1, Express = 2, System = 3
}

export interface Card extends ServerRegisteredItem {
    readonly acceptClientTime: Date;
    readonly clientCoefFormat: number;
    readonly sumStake: number;
    readonly sumPay: number;
    readonly innerCardId: number;
    readonly payCode: number;
    readonly currencyMark: string;
    readonly containers: CardContainer[];
    isCancel: boolean;
    readonly isWin: boolean;
    readonly isPaid: boolean;
    readonly isBonus: boolean;
    readonly userNumber: string;
    expanded: boolean;
    readonly maxSystemCoef: number;
}

export class CardImpl implements Card {
    private _containers: CardContainer[] = [];
    private _expanded: boolean;
    private _isCancel: boolean;

    constructor (
        readonly id: number,
        readonly pointName: string,
        readonly acceptServerTime: Date,
        readonly acceptClientTime: Date,
        readonly clientCoefFormat: number,
        isCancel: boolean,
        readonly sumStake: number,
        readonly sumPay: number,
        readonly innerCardId: number,
        readonly payCode: number,
        readonly currencyMark: string,
        readonly pointId: number,
        readonly maxSystemCoef: number,
        readonly userNumber: string
    ) {
        this._expanded = false;
        this._isCancel = isCancel;
        this.isBonus = false;
    }

    public isBonus: boolean;

    public get containers () {
        return this._containers;
    }

    public get expanded () {
        return this._expanded;
    }

    public set expanded (e: boolean) {
        this._expanded = e;
    }

    public get isCancel () {
        return this._isCancel;
    }

    public set isCancel (e: boolean) {
        this._isCancel = e;
    }

    public get isPaid (): boolean {
        for (const cc of this.containers) {
            if (!cc.isPaid) {
                return false;
            }
        }
        return true;
    }

    public get isWin (): boolean {
        let result = false;
        if (this._isCancel) {
            return false;
        }
        for (const cc of this.containers) {
            result = result || cc.sumPay > 0;
        }
        return result;
    }
}

export interface CardContainer {
    readonly id: number;
    readonly containerType: number;
    readonly expressEvents: number;
    readonly rateId: number;
    readonly sumStake: number;
    sumPay: number;
    readonly bonusSchemeCode: number;
    isPaid: boolean;
    readonly items: CardItem[];

    getMaxWin (): number;

    getCoefColumnValue (): number;
}

export class CardContainerImpl implements CardContainer {
    private readonly _containerCoef: number;
    private _isPaid: boolean;
    private _sumPay: number;

    constructor (
        readonly id: number,
        readonly containerType: number,
        readonly expressEvents: number,
        readonly rateId: number,
        readonly sumStake: number,
        readonly maxCoef: number,
        readonly bonusSchemeCode: number,
        isPaid: boolean,
        readonly items: CardItem[]
    ) {
        this._isPaid = isPaid;
        this._sumPay = 0;
        switch (this.containerType) {
            case 1: {
                this._containerCoef = this.items[0].coef;
                break;
            }
            case 2: {
                this._containerCoef = calculateExpressCoef(this.items.map(value => ({ coef: value.coef })), 1);
                break;
            }
            default: {
                this._containerCoef = calculateSystemCoef(this.items.map(value => ({ coef: value.coef })), this.expressEvents, 1);
            }
        }
    }

    get sumPay (): number {
        return this._sumPay;
    }

    set sumPay (value: number) {
        this._sumPay = value;
    }

    get isPaid (): boolean {
        return this._isPaid;
    }

    set isPaid (isPaid: boolean) {
        this._isPaid = isPaid;
    }

    getCoefColumnValue (): number {
        return this._containerCoef;
    }

    getMaxWin (): number {
        if (this.containerType === ContainerType.Simple) {
            return this.sumStake * this._containerCoef;
        }
        return calculateCombinedMaxWin(this.containerType === ContainerType.Express, this.getCoefColumnValue(), this.sumStake, this.maxCoef);
    }
}

export enum CardItemStatus {
    InGAME = 0, Win = 1, Lose = 2, Return = 3, Unknown = 4, Cancel = 5
}

export interface CardItem extends ParticipantsSupplier {
    readonly id: number;
    readonly position: Position;

    readonly quotationKey: QuotationKey,
    readonly coef: number;
    readonly isLive: boolean;
    readonly sumWin: number;
    draftStatusCode: number;
    readonly resultText: string | null;
    readonly participants: Participant[];
    readonly itemStatus: CardItemStatus;
}

export class CardItemImpl implements CardItem {
    private _draftStatusCode: number;

    constructor (readonly id: number,
        readonly position: Position,
        readonly quotationKey: QuotationKey,
        readonly coef: number,
        readonly isLive: boolean,
        readonly sumWin: number,
        draftStatusCode: number,
        readonly resultText: string | null) {
        this._draftStatusCode = draftStatusCode;
    }

    get participants (): Participant[] {
        return getParticipantList(this.position, this.quotationKey);
    }

    get draftStatusCode (): number {
        return this._draftStatusCode;
    }

    set draftStatusCode (value: number) {
        this._draftStatusCode = value;
    }

    get itemStatus (): CardItemStatus {
        switch (this.draftStatusCode) {
            case 0:
                return CardItemStatus.InGAME;
            case 2:
                return CardItemStatus.Lose;
            case 1:
                return CardItemStatus.Return;
            case 4:
                return CardItemStatus.Cancel;
            case 3:
                return CardItemStatus.Win;
            default:
                return CardItemStatus.Unknown;
        }
    }
}

export interface Position extends ParticipantsSupplier {
    id: number;
    partition: Partition;
    startTime: Date;
    participants: Participant[];
}

export interface Partition {
    id: number;
    name: string;
    sportTypeId: number;
    sportName: string;
}
