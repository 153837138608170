import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { parseDate } from "@sportaq/common/utils/time-utils";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { UnpaidCard } from "@sportaq/model/cashier/unpaid-card";

export class QSt21GetUnpaidMessageHandler extends AbstractMessageHandler<UnpaidCard[]> {
    protected readonly requestType: string = "Q.ST.2.1";

    buildRequest (request: XmlRequest) {
        request.addChild(request.body, "query", {
            type: this.requestType
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): UnpaidCard[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        const result: UnpaidCard[] = [];
        if (serverCode === "1001") {
            throw new LocalizedError(`errors.mtl.qSt21.code${serverCode}`);
        }
        const cardList = getChild(action, "PaymentCardList");
        if (cardList === undefined) {
            return [];
        }
        for (let cardElement = cardList.firstElementChild; cardElement != null; cardElement = cardElement.nextElementSibling) {
            result.push(new UnpaidCard(getRequiredIntAttribute(cardElement, "cardId"),
                getRequiredIntAttribute(cardElement, "innerCardId"),
                getRequiredIntAttribute(cardElement, "payCode"),
                parseDate(getRequiredAttribute(cardElement, "calculatedTime")),
                getRequiredFloatAttribute(cardElement, "sumPay")
            ));
        }
        return result;
    }
}
