<template>
    <div class="popup active">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.cashWithdrawalForm.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.cashWithdrawalForm.payCode") }}</label>
                        <input type="text" v-model="payCodeRef">
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-primary" @click.stop.prevent="onFindData">{{
                                $t("cashier.cashWithdrawalForm.checkWithdrawalButton")
                            }}</a>
                    </div>
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.cashWithdrawalForm.withdrawalNum") }}</label>
                        <input type="text" disabled class="field" :value="withdrawalDataRef?.id">
                        <label class="label">{{ $t("cashier.cashWithdrawalForm.amount") }}</label>
                        <input type="text" disabled class="field"
                               :value="withdrawalDataRef?(withdrawalDataRef.sumOrder+withdrawalDataRef.currencyMark):''">
                    </div>
                    <div class="form-line">
                            <label class="label">{{ $t("cashier.cashWithdrawalForm.crdate") }}</label>
                            <input type="text" disabled class="field"
                                   :value="withdrawalDataRef?$d(withdrawalDataRef.orderTime, 'long'):''">
                    </div>
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.cashWithdrawalForm.expdate") }}</label>
                        <input type="text" disabled class="field"
                               :value="withdrawalDataRef?$d(withdrawalDataRef.expirationTime, 'long'):''">
                    </div>
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.cashWithdrawalForm.cashbox") }}</label>
                        <input type="text" disabled class="field width-88" value="">
                    </div>
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.cashWithdrawalForm.status") }}</label>
                        <b class="label color-success">{{
                                withdrawalDataRef ? $t("cashier.cashWithdrawalForm.status" + withdrawalDataRef.withdrawalStatus) : ""
                            }}</b>
                    </div>
                    <div class="form-line">
                            <label class="label">{{
                                    $t("cashier.cashWithdrawalForm.documentType")
                                }}:</label>
                            <div class="select-wrapper select-wrapper-custom">
                                <select v-model="docTypeRef">
                                    <option value="USER_DOCUMENT_TYPE_ID_CARD">
                                        {{ $t("cashier.cashWithdrawalForm.documentTypes.idcard") }}
                                    </option>
                                    <option value="USER_DOCUMENT_TYPE_PASSPORT">
                                        {{ $t("cashier.cashWithdrawalForm.documentTypes.passport") }}
                                    </option>
                                    <option value="USER_DOCUMENT_TYPE_DRIVING_LICENSE">
                                        {{ $t("cashier.cashWithdrawalForm.documentTypes.driver") }}
                                    </option>
                                </select>
                            </div>
                    </div>
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.cashWithdrawalForm.docNumber") }}</label>
                        <input type="text" v-model="docNumberRef">
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-primary"
                           :class="{disabled: !withdrawalDataRef || !docNumberRef || withdrawalDataRef.withdrawalStatus !== 1}"
                           @click.stop.prevent="onMakeWithdrawal">{{
                                $t("cashier.cashWithdrawalForm.makeWithdrawalButton")
                            }}</a>
                    </div>
                    <div class="form-line">
                        <label class="label">{{
                                $t("cashier.cashWithdrawalForm.userNumber")
                            }}</label>
                        <input type="text" disabled :value="withdrawalDataRef?.user.IdentificationCode">
                    </div>
                    <div class="form-line">
                        <label class="label">{{
                                $t("cashier.cashWithdrawalForm.firstName")
                            }}</label>
                        <input type="text" disabled :value="withdrawalDataRef?.user.firstName">
                    </div>
                    <div class="form-line">
                        <label class="label">{{
                                $t("cashier.cashWithdrawalForm.lastName")
                            }}</label>
                        <input type="text" disabled :value="withdrawalDataRef?.user.lastName">
                    </div>
                    <div class="form-line">
                        <label class="label">{{
                                $t("cashier.cashWithdrawalForm.birthday")
                            }}</label>
                        <input type="text" disabled :value="withdrawalDataRef?withdrawalDataRef.user.birthday:''">
                    </div>
                    <div class="form-line" v-if="withdrawalDataRef">
                        <label class="label flex-end">{{ $t("cashier.cashWithdrawalForm.verify") }}
                            <b :class="{'color-success': withdrawalDataRef.user.verified, 'color-error': !withdrawalDataRef.user.verified}">
                                {{
                                    withdrawalDataRef ? $t(withdrawalDataRef.user.verified ? "cashier.userConfirmationForm.verifiedYes" : "cashier.userConfirmationForm.verifiedNo") : ""
                                }}
                            </b></label>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-primary"
                           :class="{disabled: !withdrawalDataRef || withdrawalDataRef.user.verified}"
                           @click.stop.prevent="onConfirmUser">{{
                                $t("cashier.cashWithdrawalForm.confirmUserButton")
                            }}</a>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./cash-withdrawal-dialog-component.ts" />
