export class MtlError extends Error {
    constructor (readonly code: string) {
        super();
    }
}

export class LocalizedError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor (message: string, public causeMsg?: string, public params?: any) {
        super(message);
    }
}

export class UnsuccessfulRestResponse extends LocalizedError {
    constructor (readonly code: string) {
        super(`errors.mtl.status.code${code}`);
    }
}

export class XmlParserError extends Error {
    constructor (message: string) {
        super(message);
    }
}

export class MtlLocalizedError extends LocalizedError {
    constructor (readonly errorCode: number, message: string, public causeMsg?: string) {
        super(message, causeMsg);
    }
}

export class NotAuthorizedError extends Error {
}
