<template>
    <div class="popup popup-share wide active" id="popup6" v-if="isActive">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("mcashier.share.caption")}}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <a href="" class="button button-general" @click.stop.prevent="onShare">{{$t("mcashier.share.caption")}}</a>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.close") }}</a>
                    </div>
                </div>
                <div class="popup-table" ref="printContent" >

                </div>
            </div>
        </div>
    </div>
</template>
<script src="./share-dialog-component.ts" />
<style>

</style>
