import {
    useAuthorizationService,
    useClockProvider,
    useMiscellaneousParamsProvider,
    useRestService,
    useSettings
} from "@sportaq/services/index";
import { BetSlipStore, useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { PlaceBetResponseSuccessInfo } from "@sportaq/model/types/place-bet-response";
import {
    CombinedStakeDTO,
    EStakeStatus,
    SimpleStakeDTO, Stake
} from "@sportaq/model/betting/bet-slip/stakes/stakes";
import {
    simpleStakeDTOComparator,
    transformQuotationCoefByBonus
} from "@sportaq/model/common/combined-stakes-calculator";
import EventType from "@sportaq/common/enums/event-type";
import { resolveLanguage } from "@sportaq/i18n/index";
import { convertCardToStakeArrays } from "@sportaq/model/utils/convertors/converter-card-to-stake-arrays";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { computed, ref, shallowRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { onPositiveNumberKeyFilter } from "@sportaq/vue/utils/form-validation";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useRootStore } from "@sportaq/vuex/index";

export namespace BetSlipComponent {

    export function useBetSlipComponent () {
        const i18n = useI18n();
        const clockProvider = useClockProvider();
        const miscellaneousParamsProvider = useMiscellaneousParamsProvider();
        const betSlipStore = useBetSlipStore();
        const restService = useRestService();
        const settings = useSettings();
        const authorizationService = useAuthorizationService();
        const prematchVerificationShowed = ref(false);
        const responseTopMessage = ref<string | undefined>();
        const requestSent = ref(false);

        // Stake accepted
        const placeBetResponseSuccessInfo = shallowRef<PlaceBetResponseSuccessInfo | undefined>();
        const simpleStakeDTOs = shallowRef<SimpleStakeDTO[]>([]);
        const combinedStakeDTOs = shallowRef<CombinedStakeDTO[]>([]);

        const store = useBalanceStore();
        const rootStore = useRootStore();
        const currency = computed(() => {
            return store.currencyId ? store.currencyId : settings.pointSettings.currency.typeId;
        });
        const stakes = computed(() => betSlipStore.simples);
        const combined = computed(() => betSlipStore.combined);
        const topMessage = computed(() => {
            if (responseTopMessage.value) {
                return responseTopMessage.value;
            }
            return betSlipStore.topMessage ? i18n.t(betSlipStore.topMessage) : undefined;
        });
        const isConfirmed = computed(() => betSlipStore.isConfirmed);

        function clearAllStakes () {
            betSlipStore.clear();
            responseTopMessage.value = undefined;
        }

        async function placeBet () {
            try {
                responseTopMessage.value = undefined;
                validateBetSlip(betSlipStore);
                const allSimpleDTOs = betSlipStore.simples.map(value => value.createDTO()).sort(simpleStakeDTOComparator);
                const simpleDTOs = betSlipStore.simples.filter(value => !!value.amount && value.amount >= value.minSumStake).map(value => value.createDTO()).sort(simpleStakeDTOComparator);
                const combinedDTOs = betSlipStore.combined.filter(value => !!value.amount && value.amount >= value.minSumStake).map(item => {
                    const simples = item.bonus
                        ? allSimpleDTOs.map(item => {
                            const coef = transformQuotationCoefByBonus(item, settings.pointSettings.expressBonusMultiplier).coef;
                            return item.cloneWithNewCoef(coef);
                        })
                        : allSimpleDTOs;
                    return item.createDTO(simples, settings.pointSettings.draftCoef);
                });
                if (!authorizationService.isUserAuthorize()) {
                    return;
                }
                const allStakesArePreMatch = allSimpleDTOs.every(value => value.event.eventType === EventType.PRE_MATCH);
                if (allStakesArePreMatch) {
                    showPrematchVerificationForm(simpleDTOs, combinedDTOs);
                } else {
                    await sendPlaceBetRequest(simpleDTOs, combinedDTOs, allStakesArePreMatch);
                }
            } catch (e) {
                responseTopMessage.value = i18n.t((e as Error).message);
            }
        }

        function showPrematchVerificationForm (simpleDTOs: SimpleStakeDTO[], combinedDTOs: CombinedStakeDTO[]) {
            simpleStakeDTOs.value = simpleDTOs;
            combinedStakeDTOs.value = combinedDTOs;
            prematchVerificationShowed.value = true;
        }

        async function closePrematchVerificationForm (confirm: boolean) {
            prematchVerificationShowed.value = false;
            if (confirm) {
                await sendPlaceBetRequest(simpleStakeDTOs.value, combinedStakeDTOs.value, true);
            } else {
                simpleStakeDTOs.value = [];
                combinedStakeDTOs.value = [];
            }
        }

        async function sendPlaceBetRequest (simpleDTOs: SimpleStakeDTO[], combinedDTOs: CombinedStakeDTO[], allStakesArePrematch: boolean) {
            if (requestSent.value) {
                return;
            }
            requestSent.value = true;
            try {
                try {
                    const fullChecking = betSlipStore.fullChecking || allStakesArePrematch;
                    const userNumber = settings.pointSettings.workWithDNIonly ? rootStore.userSharedInfo.userNumber : undefined;
                    const placeBetResult = await restService.placeBet(currency.value, simpleDTOs, combinedDTOs, fullChecking, settings.paymentMethod, userNumber);
                    switch (placeBetResult.type) {
                        case "success": {
                            try {
                                const cardInfo = await restService.findCardByPayCode(placeBetResult.info.payCode, resolveLanguage(), false);
                                const arr = convertCardToStakeArrays(clockProvider, miscellaneousParamsProvider.dateTimeFormatter,
                                    cardInfo);
                                simpleStakeDTOs.value = arr.simples;
                                combinedStakeDTOs.value = arr.combined;
                                placeBetResponseSuccessInfo.value = placeBetResult.info;
                            } catch (e) {
                                if (e instanceof LocalizedError) {
                                    responseTopMessage.value = i18n.t(e.message);
                                } else {
                                    responseTopMessage.value = (e as Error).message;
                                }
                            }
                            eventBus.emit(Events.REFRESH_BALANCE);
                            break;
                        }
                        case "registrationSystemError": {
                            responseTopMessage.value = i18n.t("errors.betSlip.response.registrationSystemError");
                            break;
                        }
                        case "notEnoughMoneyOnCenterBalance": {
                            responseTopMessage.value = i18n.t("errors.betSlip.response.notEnoughMoneyOnCenterBalance");
                            break;
                        }
                        case "notEnoughMoneyOnUserBalance": {
                            responseTopMessage.value = i18n.t("errors.betSlip.response.notEnoughMoneyOnUserBalance");
                            break;
                        }
                        case "coefIsNotAllowedForBonus": {
                            responseTopMessage.value = i18n.t("errors.betSlip.response.coefIsNotAllowedForBonus");
                            break;
                        }
                        case "userDontHaveOpenAccount":
                            responseTopMessage.value = i18n.t("errors.betSlip.response.userDontHaveOpenAccount");
                            break;
                        case "detailedError": {
                            let incorrectNumberOfPositions = false;
                            for (const item of placeBetResult.causes) {
                                if (item.type === "incorrectNumberOfPositions") {
                                    responseTopMessage.value = i18n.t("errors.betSlip.response.incorrectNumberOfPositions");
                                    incorrectNumberOfPositions = true;
                                    break;
                                }
                            }
                            if (!incorrectNumberOfPositions) {
                                betSlipStore.handlePlacesBetErrors(placeBetResult.causes, settings.pointSettings);
                            }
                            break;
                        }
                    }
                } catch (e) {
                    if (e instanceof LocalizedError) {
                        responseTopMessage.value = i18n.t(e.message);
                    } else {
                        responseTopMessage.value = (e as Error).message;
                    }
                }
            } finally {
                requestSent.value = false;
            }
        }

        function confirmAllChanges () {
            betSlipStore.confirmAllSimples();
        }

        function onBetAcceptedFormClose () {
            placeBetResponseSuccessInfo.value = undefined;
            simpleStakeDTOs.value = [];
            combinedStakeDTOs.value = [];
            betSlipStore.clear();
        }

        function updateAmount () {
            responseTopMessage.value = undefined;
        }

        watch(() => betSlipStore.simples.length, () => {
            responseTopMessage.value = undefined;
        });

        return {
            placeBetResponseSuccessInfo,
            simpleStakeDTOs,
            combinedStakeDTOs,
            prematchVerificationShowed,
            stakes,
            combined,
            isConfirmed,
            requestSent,
            topMessage,
            clearAllStakes,
            confirmAllChanges,
            placeBet,
            onBetAcceptedFormClose,

            closePrematchVerificationForm,
            updateAmount,
            onAmountKeyDown: onPositiveNumberKeyFilter
        };
    }

    function validateBetSlip (betSlipStore: BetSlipStore) {
        const simpleItems = betSlipStore.simples.filter(value => value.amount);
        const combinedItems = betSlipStore.combined.filter(value => value.amount);
        if (simpleItems.length === 0 && combinedItems.length === 0) {
            throw new Error("errors.betSlip.betSlipEmpty");
        }
        for (const stake of simpleItems) {
            if (!isStakeValid(stake)) {
                throw new Error("errors.betSlip.betSlipInvalidItems");
            }
        }
        for (const stake of combinedItems) {
            if (!isStakeValid(stake)) {
                throw new Error("errors.betSlip.betSlipInvalidItems");
            }
        }

        if (combinedItems.length > 0) {
            // If combined stakes are present, we have to check status for all simples
            if (betSlipStore.simples.some(value => value.status !== EStakeStatus.active)) {
                throw new Error("errors.betSlip.betSlipInvalidItems");
            }
        }
    }

    function isStakeValid (stake: Stake): boolean {
        return (stake.status === EStakeStatus.active) &&
            (stake.amount! >= stake.minSumStake) &&
            (stake.amount! <= stake.maxSumStake);
    }

}
