import { computed, onMounted, ref, SetupContext } from "vue";
import { DailyReport } from "@sportaq/model/cashier/daily-report";
import { useCashierRestService, useCashierSettings } from "@sportaq/services/cashier";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";

export const CLOSE_EVENT = "close";

export function useDailyReportCommon (isMobile: boolean, context: SetupContext) {
    const dailyReport = ref<DailyReport>();
    const restService = useCashierRestService();
    const isCloseDayConfirmatonDialogVisible = ref(false);
    const settings = useCashierSettings();
    const store = useBalanceStore();
    const pointName = settings.pointSettings.name;
    const currencyName = computed(() => {
        return isMobile ? store.currency : settings.pointSettings.currencyName;
    });
    const reportDate = ref<Date>();
    const closeDayButtonPressedRef = ref(false);
    const i18n = useI18n();
    onMounted(async () => {
        loadFinDay().then();
    });
    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function refresh () {
        loadFinDay().then();
    }

    async function loadFinDay () {
        const r = await restService.dailyReport();
        dailyReport.value = r;
        reportDate.value = new Date();
    }

    function calculateTotal () {
        const dr = dailyReport.value;
        if (dr) {
            const result = dr.totalStake + dr.totalStakeInternet -
                dr.totalPaid - dr.totalPaidInternet - dr.totalCancelStakeBP + dr.totalStakeRace - dr.totalPayRace +
                dr.totalBalanceDeposit + dr.totalBalanceDepositBetPlace - dr.totalBalanceWithdowal +
                dr.totalGenerationVoucher - dr.totalCashCheque -
                dr.totalBalanceNulling - dr.totalPaidTerminal;
            return result.toFixed(2);
        }
        return "";
    }

    function onCloseDay () {
        isCloseDayConfirmatonDialogVisible.value = true;
    }

    function onCloseDayCancel () {
        isCloseDayConfirmatonDialogVisible.value = false;
    }

    function onCloseDayConfirmed () {
        isCloseDayConfirmatonDialogVisible.value = false;
        closeDayButtonPressedRef.value = true;
        restService.closeDay()
            .then(() => {
                const localizedMessage = i18n.t("cashier.dailyReportForm.success");
                showMessageBox(localizedMessage);
                loadFinDay().then(() => {
                    closeDayButtonPressedRef.value = false;
                });
            });
    }

    return {
        dailyReport,
        isCloseDayConfirmatonDialogVisible,
        currencyName,
        reportDate,
        calculateTotal,
        closeDayButtonPressedRef,
        pointName,
        refresh,
        onClose,
        onCloseDay,
        onCloseDayCancel,
        onCloseDayConfirmed
    };
}
