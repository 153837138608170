<template>
    <div class="popup active" id="popup8-2">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.saleVoucherForm.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content" v-if="currentVoucher">
                    <div class="form-line">
                        <label class="label" >{{$t("cashier.saleVoucherForm.print.voucher")}} <strong>{{formatVoucherNumber(currentVoucher.voucherCode)}}</strong></label>
                    </div>
                    <div class="form-line">
                        <label class="label" >{{ $t("cashier.saleVoucherForm.print.amount") }} <strong>{{ currentVoucher.amount}} {{ currentVoucherCurrency }}</strong></label>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-general" @click.stop.prevent="onCopy">{{$t("mcashier.saleVoucherDialog.copyButton")}}</a>
                    </div>
                    <div class="form-line">
                      <a href="" class="button button-general" @click.stop.prevent="onPrint">{{$t("mcashier.saleVoucherDialog.printButton")}}</a>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
                <div class="popup-content" v-else>
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.saleVoucherForm.amount") }}</label>
                        <input type="number" min="1" step="0.1" v-model.number="amountRef" @keydown="onAmountKeyDown">
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-general" :class="{disabled: isSaleButtonDisabled}" @click.stop.prevent="onSell">{{ $t("cashier.saleVoucherForm.saleButton") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
                <div class="popup-table">
                    <table>
                        <thead>
                        <tr>
                            <th>{{ $t("cashier.saleVoucherForm.table.time") }}</th>
                            <th>{{ $t("cashier.saleVoucherForm.table.num") }}</th>
                            <th>{{ $t("cashier.saleVoucherForm.table.amount") }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="voucher in vouchers" :key="voucher.voucherCode">
                            <td>{{ $d(voucher.sellDate, "long") }}</td>
                            <td>{{ voucher.voucherCode }}</td>
                            <td>{{ voucher.amount }} {{ voucher.currency }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div style="display: none" v-if="currentVoucher" ref="printContent" class="popup-content-center">
      <logo-print /> <br>
      <div class="text-big">{{ $t("cashier.saleVoucherForm.print.time") }} {{ $d( new Date(), "long" ) }}</div>
      <div class="text-divider" align="center">---------------------------</div>
      <div class="text-big"><b>{{ $t("cashier.saleVoucherForm.print.voucher") }}</b> <b>{{ formatVoucherNumber(currentVoucher.voucherCode) }}</b></div>
      <div class="text-big"><b>{{ $t("cashier.saleVoucherForm.print.amount") }}</b> <b>{{ currentVoucher.amount}} {{ currentVoucherCurrency }}</b></div>
      <div class="text-divider" align="center">---------------------------</div>
      <div class="text-big">{{ $t("cashier.saleVoucherForm.print.until") }} {{ $d(validUntil,"long") }}</div>
      <div class="text-divider" align="center">---------------------------</div>
      <svg ref="barcode"></svg>
    </div>
</template>

<script src="./sale-voucher-dialog-component.ts" />
