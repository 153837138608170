import { SetupContext } from "vue";

const YES_EVENT = "submit";
const NO_EVENT = "close";

interface Props {
    message: string;
}

export default {
    name: "InputBox",
    props: {
        message: { required: true, type: String },
        caption: { required: true, type: String }
    },
    emits: [YES_EVENT, NO_EVENT],
    setup (props: Props, context: SetupContext) {
        function onYesButtonClick () {
            context.emit(YES_EVENT);
        }

        function onNoButtonClick () {
            context.emit(NO_EVENT);
        }
        return {
            onYesButtonClick,
            onNoButtonClick
        };
    }
};
