import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { DailyReport } from "@sportaq/model/cashier/daily-report";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatValue,
    getRequiredIntValue, getValue
} from "@sportaq/common/utils/xml-helper-functions";
import { parseDate } from "@sportaq/common/utils/time-utils";

export class QPi58DailyReportMessageHandler extends AbstractMessageHandler<DailyReport> {
    protected readonly requestType: string = "Q.PI.5.4";

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const sessionFilterElement = request.addChild(filterElement, "SessionFilter", {
            logic: "AND"
        });
        const userElement = request.addChild(sessionFilterElement, "User", {});
        userElement.textContent = "true";
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): DailyReport {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "2400") {
            throw new LocalizedError(`errors.mtl.aPi54.code${serverCode}`);
        }
        // return getRequiredIntValue(getRequiredChild(getRequiredChild(action, "User"), "UserNumber"));
        const dailyReportElement = getRequiredChild(action, "DailyReport");
        const lastActionTime = getValue(getRequiredChild(dailyReportElement, "LastActionTime"));
        return {
            actionId: getRequiredIntValue(getRequiredChild(dailyReportElement, "ActionId")),
            totalBalanceDeposit: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalBalanceDeposit")),
            totalBalanceDepositBetPlace: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalBalanceDepositBetPlace")),
            totalBalanceNulling: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalBalanceNulling")),
            totalBalanceWithdowal: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalBalanceWithdowal")),
            totalCashCheque: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalCashCheque")),
            totalGenerationVoucher: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalGenerationVoucher")),
            totalPaid: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalPaid")),
            totalPaidInternet: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalPaidInternet")),
            totalPaidTerminal: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalPaidTerminal")),
            totalStake: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalStake")),
            totalStakeInternet: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalStakeInternet")),
            totalStakeRace: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalStakeRace")),
            totalPayRace: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalPayRace")),
            totalCancelStakeBP: getRequiredFloatValue(getRequiredChild(dailyReportElement, "TotalCancelStakeBP")),
            lastActionTime: lastActionTime ? parseDate(lastActionTime) : undefined
        };
    }
}
