<template>
    <div class="coupon-block" :data-cy="'table-'+stake.id" v-for="(stake, index) of card.containers"
         :key="'table-'+stake.id">
        <div class="coupon-block-title" data-cy="name"><i>
          <template v-if="stake.containerType === 3">
            {{
              $t("cashier.monitoring.table.containerType" + stake.containerType,
                  {
                    itemCount: stake.items.length,
                    express: stake.expressEvents
                  })
            }}
          </template>
          <template v-else>
            {{$t("cashier.monitoring.table.containerType"+stake.containerType)
            }}
          </template>
        </i>
        </div>

        <div class="coupon-block-head">
            <div class="name width-33" data-cy="coef">
                {{ $t("betting.betSlip.dialogBetAccepted.columns.coef") }} :
                {{ formatCoef(stake.getCoefColumnValue()) }}
            </div>
            <div class="name width-33" data-cy="amount">
                {{ $t("betting.betSlip.dialogBetAccepted.columns.amount") }} :
                {{ $n(stake.sumStake, "currency") }}
            </div>
        </div>

        <mobile-simple-stake-row v-for="(entry) of stake.items" :key="'table-combined-'+index+'-'+entry.id"
                                 :stake="entry" />
    </div>
</template>

<script src="./mobile-inner-stake-table-component.ts"/>
