import PointPanel from "@/views/monitoring/point-panel/PointPanel.vue";
import InputBox from "@/components/input-box/InputBox.vue";
import { useMonitoringCommon } from "@sportaq/vue/components/mobile-cashier/monitoring/monitoring-common";
import CardPanel from "@sportaq/vue/components/web/mobile/card-panel/CardPanel.vue";
import MonitoringSearch from "@/views/monitoring/monitoring-search/MonitoringSearch.vue";
import RaceEntryRow from "@/views/monitoring/race-entry-row/RaceEntryRow.vue";
import SportCardPrint
    from "@sportaq/vue/components/mobile-cashier/monitoring/sport-card-print/SportCardPrint.vue";
import RaceCardPrint
    from "@sportaq/vue/components/mobile-cashier/monitoring/race-card-print/RaceCardPrint.vue";

export default {
    name: "Monitoring",
    components: {
        InputBox,
        PointPanel,
        CardPanel,
        MonitoringSearch,
        RaceEntryRow,
        SportCardPrint,
        RaceCardPrint
    },
    setup () {
        return useMonitoringCommon();
    }
};
