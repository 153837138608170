<template>
    <div :class="globalTheme" v-if="applicationStarted">
        <router-view/>
        <message-box v-if="isMessageBoxActive" :message="message" :caption="caption" :success="success" @close="messageBoxClose"/>
    </div>
    <div v-else>
        Loading...
    </div>
</template>

<script src="./app-component.ts">

</script>

<style lang="scss">
    @import "@scss/mobile/app.scss";
    @import "@scss/mobile_cashier/app.scss";
</style>
