import { useSettings } from "@sportaq/services/index";

export default {
    name: "LogoPrint",
    props: {
        logo: { required: false, type: String }
    },
    setup () {
        const settings = useSettings();
        const logoInternal = settings.pointSettings.logoPrintSrc ? settings.pointSettings.logoPrintSrc : settings.base64Logo;
        return {
            logoInternal
        };
    }
};
