import { AbstractRestService } from "@sportaq/services/rest/rest-service";
import { AUs13AuthorizationMessageHandler } from "@sportaq/services/cashier/rest/messages/a_us_1_3_authorization";
import { RegistrationData } from "@sportaq/model/cashier/registration-data";
import { AUs11RegistrationMessageHandler } from "@sportaq/services/cashier/rest/messages/a_us_1_1_registration";
import { DailyReport } from "@sportaq/model/cashier/daily-report";
import { QPi58DailyReportMessageHandler } from "@sportaq/services/cashier/rest/messages/q_pi_5_4_daily-report";
import { APi58CloseDayMessageHandler } from "@sportaq/services/cashier/rest/messages/a_pi_5_8_close-day";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { QSt100MonitoringMessageHandler } from "@sportaq/services/cashier/rest/messages/q_st_10_0_monitoring";
import { ClockProvider, dateToMtlStr } from "@sportaq/common/utils/time-utils";
import { AST13CancelCardMessageHandler } from "@sportaq/services/cashier/rest/messages/a_st_1_3_cancel-card";
import { UnpaidCard } from "@sportaq/model/cashier/unpaid-card";
import { QSt21GetUnpaidMessageHandler } from "@sportaq/services/cashier/rest/messages/q_st_2_1_unpaid";
import { QUs22FindUserMessageHandler } from "@sportaq/services/cashier/rest/messages/q_us_2_2_find-user";
import { AUs25ConfirmUserMessageHandler } from "@sportaq/services/cashier/rest/messages/a_us_2_5_confirm-user";
import { APo510FillAccountMessageHandler } from "@sportaq/services/cashier/rest/messages/a_po_5_10_fill-account";
import { AUs14UpdateProfileMessageHandler } from "@sportaq/services/cashier/rest/messages/a_us_1_4_update-profile";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import { AVc11SellVoucherMessageHandler } from "@sportaq/services/cashier/rest/messages/a_vc_1_1_sell-voucher";
import { AVc14BuyVoucherMessageHandler } from "@sportaq/services/cashier/rest/messages/a_vc_1_4_buy_voucher";
import { WithdrawalData } from "@sportaq/model/cashier/withdrawal-data";
import { QPo62FindWithdrawalMessageHandler } from "@sportaq/services/cashier/rest/messages/q_po_6_2_check-withdrawal";
import {
    APo67MakeWithdrawalMessageHandler
} from "@sportaq/services/cashier/rest/messages/a_po_6_7_confirm_withdrawal";
import { User } from "@sportaq/model/common/user-accounts";
import { CashierPoint } from "@sportaq/model/cashier/point";
import { CashierSettingsService } from "@sportaq/services/cashier/settings/settings-service";
import { QPi55PointListMessageHandler } from "@sportaq/services/cashier/rest/messages/q_pi_5_5_point_list";
import { APi55BalanceNullingMessageHandler } from "@sportaq/services/cashier/rest/messages/a_pi_5_5_balance_nulling";
import { APo513BalanceDepositMessageHandler } from "@sportaq/services/cashier/rest/messages/a_po_5_13_balance_deposit";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { ExpressAccountRegData, ExpressAccountUser } from "@sportaq/model/cashier/express-account-user";
import {
    APo512AddExpressAccountMessageHandler
} from "@sportaq/services/cashier/rest/messages/a_po_5_12_add-express-account";
import { AST23WinPaymentMessageHandler } from "@sportaq/services/cashier/rest/messages/a_st_2_3_win-payment";
import { Card } from "@sportaq/model/cashier/card";
import { QUs111BalanceMessageHandler } from "@sportaq/services/cashier/rest/messages/q_us_1_11_balance";
import { resolveLanguage } from "@sportaq/i18n/index";
import { QSt103CardRequest } from "@sportaq/services/rest/messages/bet-slip/q_st_103_card-request";
import { QPO11FinOperations } from "@sportaq/services/cashier/rest/messages/q_po_1_1-fin-operations";
import { ExtendedBalance } from "@sportaq/model/common/user-balance";
import { QCs35WagerStakesHandler } from "@sportaq/services/cashier/rest/messages/q_cs_3_5_wager-stakes";
import {
    AGe22ApplicationStartMessageHandler,
    ApplicationStartResult
} from "@sportaq/services/rest/messages/system/a_ge_2_2_application_start";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { QPi51PointListMessageHandler } from "@sportaq/services/cashier/rest/messages/q_pi_5_1_point_list";
import { QSt103MonitoringMessageHandler } from "@sportaq/services/cashier/rest/messages/q_st_10_3_monitoring";
import { EPointType } from "@sportaq/common/enums/EPointType";
import { HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { AST24RaceWinPaymentMessageHandler } from "@sportaq/services/cashier/rest/messages/a_st_2_4_race_win_payment";
import { QRs13GetRaceStakesHandler } from "@sportaq/services/cashier/rest/messages/q_rs_1_3_get-race-stakes";
import { appLogger } from "@sportaq/common/utils/logger";

export class CashierRestService extends AbstractRestService<CashierSettingsService> {
    constructor (uuidProvider: UUIDProvider, clockProvider: ClockProvider, settingService: CashierSettingsService) {
        super(uuidProvider, clockProvider, settingService);
        this.session = settingService.sessionCode;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async login (userNumber: string, password: string, partnerGUID: string, isMobile: boolean): Promise<CashierPoint> {
        const appCode = this.settingService.appCode;
        if (appCode == null) {
            throw new Error("appcode is not initialized");
        }
        const locale = resolveLanguage();
        appLogger.logger.info("Language is " + locale);
        const xmlRequest = new XmlRequest(appCode, this.clockProvider, "00000000-0000-0000-0000-000000000000", locale, this.appVersion);
        const appStartHandler = new AGe22ApplicationStartMessageHandler();
        appStartHandler.buildRequest(xmlRequest);
        appLogger.logger.info("Send A.GE.2.2");
        try {
            const response = await this.sendRequest(xmlRequest);
            appLogger.logger.info("Got result of A.GE.2.2");
            const sessionResult = await appStartHandler.parseResponse(response);
            appLogger.logger.info("Parsed A.GE.2.2");
            const handler = new AUs13AuthorizationMessageHandler(userNumber, password, partnerGUID);
            appLogger.logger.info("Send A.US.1.3");
            const authResult = await this.executeRequest(handler);
            appLogger.logger.info("Executed A.US.1.3");
            authResult.cashierPoint.points.push(new ShortPointInfo(sessionResult.pointSettings.id, EPointType.Cashier, "", sessionResult.pointSettings.name, 0));
            this.session = authResult.sessionCode;
            authResult.cashierPoint.pointSettings = sessionResult.pointSettings;
            if (authResult.currency) {
                authResult.cashierPoint.pointSettings.currency = authResult.currency;
            }
            appLogger.logger.info("Login done");
            return authResult.cashierPoint;
        } catch (error) {
            appLogger.logger.error((error as Error).toString());
            throw error;
        }
    }

    async loadSettings (): Promise<ApplicationStartResult> {
        const locale = resolveLanguage();
        const xmlRequest = new XmlRequest(this.settingService.appCode, this.clockProvider, "00000000-0000-0000-0000-000000000000", locale, this.appVersion);
        const appStartHandler = new AGe22ApplicationStartMessageHandler();
        appStartHandler.buildRequest(xmlRequest);
        const response = await this.sendRequest(xmlRequest);
        return appStartHandler.parseResponse(response);
    }

    async loginWithStoredSession (): Promise<CashierPoint> {
        const sessionResult = await this.loadSettings();
        const userInfo = await this.requestUserInfo();
        const cashierPoint = new CashierPoint(userInfo.user, [], undefined, undefined, undefined, undefined);
        cashierPoint.pointSettings = sessionResult.pointSettings;
        if (userInfo.currency) {
            cashierPoint.pointSettings.currency = userInfo.currency;
        }
        return cashierPoint;
    }

    async register (registrationData: RegistrationData, partnerGuid: string, isMobile: boolean): Promise<string> {
        const handler = new AUs11RegistrationMessageHandler(registrationData, partnerGuid, isMobile);
        return this.executeRequest(handler);
    }

    async dailyReport (): Promise<DailyReport> {
        const handler = new QPi58DailyReportMessageHandler();
        return this.executeRequest(handler);
    }

    async closeDay (): Promise<string> {
        const handler = new APi58CloseDayMessageHandler();
        return this.executeRequest(handler);
    }

    async getCardByPayCode (payCode: number, locale: string, isDesktop: boolean): Promise<Card[]> {
        const handler = new QSt103CardRequest(payCode, locale,
            this.settingService.pointSettings.draftCoef, isDesktop);
        return this.executeRequest(handler);
    }

    async getMobileCashierRaceStakes (startDate: Date, lastContainerId: number, pointFilter: number[]): Promise<HorseWagerContainer[]> {
        const handler = new QRs13GetRaceStakesHandler(startDate, lastContainerId, pointFilter);
        return this.executeRequest(handler);
    }

    async getCards (startTime: Date, limitCardCount: number, startCardId: number, locale: string): Promise<Card[]> {
        const handler = new QSt100MonitoringMessageHandler(dateToMtlStr(startTime), limitCardCount, startCardId, locale, this.settingService.pointSettings.draftCoef);
        return this.executeRequest(handler);
    }

    async getMobileCards (startTime: Date, limitCardCount: number, startCardId: number, locale: string, pointFilter: number[]): Promise<Card[]> {
        const handler = new QSt103MonitoringMessageHandler(dateToMtlStr(startTime), limitCardCount, startCardId, locale, pointFilter, this.settingService.pointSettings.draftCoef);
        return this.executeRequest(handler);
    }

    async getOperations (stationId: number, startTime: Date, lastOperationId: number, pointFilter: number[] | undefined) {
        const handler = new QPO11FinOperations(stationId, dateToMtlStr(startTime), lastOperationId, pointFilter);
        return this.executeRequest(handler);
    }

    async getWagerContainers (startTime: Date, lastOperationId: number, pointFilter: number[] | undefined) {
        const handler = new QCs35WagerStakesHandler(dateToMtlStr(startTime), lastOperationId, pointFilter);
        return this.executeRequest(handler);
    }

    async cancelCard (cardId: number): Promise<boolean> {
        const handler = new AST13CancelCardMessageHandler(cardId);
        return this.executeRequest(handler);
    }

    async getUnpaidCards (): Promise<UnpaidCard[]> {
        const handler = new QSt21GetUnpaidMessageHandler();
        return this.executeRequest(handler);
    }

    async findUser (phoneNumber: string | undefined, userNumber: string | undefined, document: string | undefined): Promise<User | undefined> {
        const handler = new QUs22FindUserMessageHandler(phoneNumber, userNumber, document);
        let result: User | undefined = await this.executeRequest(handler);
        if (result === undefined && document !== undefined) {
            const dniHandler = new QUs22FindUserMessageHandler(phoneNumber, userNumber, "DNI:" + document);
            result = await this.executeRequest(dniHandler);
        }
        return result;
    }

    async confirmUser (userId: number): Promise<boolean> {
        const handler = new AUs25ConfirmUserMessageHandler(userId);
        return this.executeRequest(handler);
    }

    async refillAccount (mobileCashier: boolean, userId: number, amount: number, currencyId: number, useBonus: boolean): Promise<number> {
        const handler = new APo510FillAccountMessageHandler(mobileCashier, userId, amount, currencyId, useBonus);
        return this.executeRequest(handler);
    }

    async updateProfile (registrationData: RegistrationData): Promise<boolean> {
        const handler = new AUs14UpdateProfileMessageHandler(registrationData);
        return this.executeRequest(handler);
    }

    async sellVoucher (amount: number, currencyId: number, name: string): Promise<VoucherSellInfo> {
        const handler = new AVc11SellVoucherMessageHandler(amount, currencyId, name);
        return this.executeRequest(handler);
    }

    async buyVoucher (isMobile: boolean, voucherCode: number): Promise<VoucherSellInfo> {
        const handler = new AVc14BuyVoucherMessageHandler(isMobile, voucherCode);
        return this.executeRequest(handler);
    }

    async findWithdrawal (payCode: string): Promise<WithdrawalData | undefined> {
        const handler = new QPo62FindWithdrawalMessageHandler(payCode);
        return this.executeRequest(handler);
    }

    async makeWithdrawal (payCode: string, documentType: string, documentNumber: string): Promise<boolean> {
        const handler = new APo67MakeWithdrawalMessageHandler(payCode, documentType, documentNumber);
        return this.executeRequest(handler);
    }

    async getPointList (pointIds: number[]): Promise<ShortPointInfo[]> {
        const handler = new QPi55PointListMessageHandler(pointIds);
        return this.executeRequest(handler);
    }

    async getDesktopPointList (): Promise<ShortPointInfo[]> {
        const handler = new QPi51PointListMessageHandler();
        return this.executeRequest(handler);
    }

    async pointBalanceNullify (pointGuid: string, sum: number): Promise<boolean> {
        const handler = new APi55BalanceNullingMessageHandler(pointGuid, sum);
        return this.executeRequest(handler);
    }

    async balanceDeposit (pointGuid: string, sum: number): Promise<boolean> {
        const handler = new APo513BalanceDepositMessageHandler(pointGuid, sum);
        return this.executeRequest(handler);
    }

    async addExpressAccount (regData: ExpressAccountRegData): Promise<ExpressAccountUser> {
        const handler = new APo512AddExpressAccountMessageHandler(regData);
        return this.executeRequest(handler);
    }

    async makeWinPayment (cardId: number): Promise<boolean> {
        const handler = new AST23WinPaymentMessageHandler(cardId);
        return this.executeRequest(handler);
    }

    async makeRaceWinPayment (cardId: number, serial: number): Promise<boolean> {
        const handler = new AST24RaceWinPaymentMessageHandler(cardId, serial);
        return this.executeRequest(handler);
    }

    async getExtendedBalance (): Promise<ExtendedBalance> {
        const handler = new QUs111BalanceMessageHandler();
        return this.executeRequest(handler);
    }
}
