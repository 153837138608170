import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import {
    getChild, getRequiredAttribute,
    getRequiredBooleanValue,
    getRequiredChild,
    getRequiredFloatAttribute,
    getRequiredIntAttribute,
    getRequiredIntValue,
    getRequiredValue,
    getValue
} from "@sportaq/common/utils/xml-helper-functions";
import { Currency, EGameService, PointSettings, PointSettingsImpl } from "@sportaq/model/common/point-settings";
import { Banner, BannerScreen } from "@sportaq/model/common/banner";
import { PaymentSystemData, PaymentSystems } from "@sportaq/common/enums/payment-systems";
import { EBrandType } from "@sportaq/common/enums/brand-type";

export abstract class AbstractPointSettingsMessageHandler<T> extends AbstractMessageHandler<T> {
    protected parsePoint (actionElement: Element): PointSettings {
        const pointElement = getRequiredChild(actionElement, "Point");
        const pointGuid = getRequiredValue(getRequiredChild(pointElement, "PointGUID"));
        const pointId = getRequiredIntValue(getRequiredChild(pointElement, "PointId"));
        const partnerId = getRequiredIntValue(getRequiredChild(pointElement, "PartnerId"));
        const pointName = getRequiredValue(getRequiredChild(pointElement, "Name"));
        const pointCityText = getRequiredValue(getRequiredChild(pointElement, "CityText"));
        const pointAddress = getValue(getRequiredChild(pointElement, "Address"));
        const pointPhone = getValue(getRequiredChild(pointElement, "Phone"));
        const pointEmail = getValue(getRequiredChild(pointElement, "Email"));
        const stationId = getRequiredIntValue(getRequiredChild(pointElement, "StationId"));
        const isRated = getRequiredBooleanValue(getRequiredChild(pointElement, "IsRated"));
        const isDemo = getRequiredBooleanValue(getRequiredChild(pointElement, "IsDemo"));
        const stationDescription = getValue(getChild(pointElement, "stationDescription"));
        const partnerElement = getChild(pointElement, "IsPartner");
        const isPartner = partnerElement === undefined ? false : getRequiredBooleanValue(partnerElement);
        const currencyElement = getRequiredChild(pointElement, "Currency");
        const currency: Currency = {
            rateId: getRequiredIntAttribute(currencyElement, "rateId"),
            typeId: getRequiredIntAttribute(currencyElement, "typeId"),
            value: getRequiredFloatAttribute(currencyElement, "value")
        };
        const pointConfigListElement = getRequiredChild(pointElement, "PointConfigList");
        let brand = EBrandType.betgol;
        const config = new Map<string, string>();
        const configElements = pointConfigListElement.getElementsByTagName("Item");
        for (let i = 0; i < configElements.length; i++) {
            const itemElement = configElements.item(i);
            if (itemElement != null) {
                const key = getRequiredAttribute(itemElement, "type");
                const value = itemElement.textContent;
                if (value) {
                    if (key === "ps.point.system.brandId") {
                        brand = parseInt(value);
                    } else {
                        config.set(key, value);
                    }
                }
            }
        }
        const paymentSystems: PaymentSystemData[] = parsePaymentSystemList(pointElement);
        const restrictionGameProviderList = AbstractPointSettingsMessageHandler.readRestrictedItems(pointElement, "RestrictionGameProviderList");
        const restrictionGameMarkList = AbstractPointSettingsMessageHandler.readRestrictedItems(pointElement, "RestrictionGameMarkList");

        const result = new PointSettingsImpl(pointId, pointGuid, pointName, pointCityText, pointAddress, pointPhone, pointEmail,
            isRated, isDemo, isPartner, currency, config, restrictionGameProviderList,
            restrictionGameMarkList, stationId, partnerId, brand, paymentSystems, stationDescription);
        result.disabledServices = readDisabledServices(pointElement);
        return result;
    }

    protected parseCountryId (action: Element): number {
        const pointElement = getRequiredChild(action, "Point");
        return parseInt(getValue(getChild(pointElement, "CountryId")) ?? "0");
    }

    private static readRestrictedItems (pointElement: Element, tag: string): string[] {
        const result: string[] = [];
        const restrictionElement = getChild(pointElement, tag);
        if (restrictionElement) {
            const elements = restrictionElement.getElementsByTagName("Item");
            for (let i = 0; i < elements.length; i++) {
                const itemElement = elements.item(i);
                if (itemElement != null) {
                    const value = itemElement.textContent;
                    if (value) {
                        result.push(value);
                    }
                }
            }
        }
        return result;
    }

    protected parseBanners (bannersElement: Element): Banner[] {
        const elements = bannersElement.getElementsByTagName("BN");
        const result: Banner[] = [];
        for (let i = 0; i < elements.length; i++) {
            const itemElement = elements.item(i);
            if (itemElement != null) {
                result.push({
                    id: getRequiredIntAttribute(itemElement, "id"),
                    name: getRequiredAttribute(itemElement, "name"),
                    image: getRequiredAttribute(itemElement, "srcUrl"),
                    orderNum: getRequiredIntAttribute(itemElement, "orderNum"),
                    onclickUrl: getRequiredAttribute(itemElement, "onclickUrl"),
                    screenId: getRequiredIntAttribute(itemElement, "screenId") === 0 ? BannerScreen.homeScreen : BannerScreen.secondMonitor
                });
            }
        }
        return result;
    }
}

export function parsePaymentSystemList (pointElement: Element): PaymentSystemData[] {
    const paymentSystems = [];
    const paymentSystemListElement = getChild(pointElement, "PaySystemList");
    if (paymentSystemListElement) {
        const paymentSystemElements = paymentSystemListElement.getElementsByTagName("Item");
        for (let i = 0; i < paymentSystemElements.length; i++) {
            const itemElement = paymentSystemElements.item(i);
            if (itemElement !== null) {
                const currencyList = JSON.parse(getRequiredAttribute(itemElement, "allowCurrency"));
                paymentSystems.push({
                    paymentSystemType: getRequiredAttribute(itemElement, "mark") as PaymentSystems,
                    availableCurrencies: currencyList
                });
            }
        }
    }
    return paymentSystems;
}

export function readDisabledServices (pointElement: Element): EGameService[] {
    const result: EGameService[] = [];
    const restrictionElement = getChild(pointElement, "RestrictionGameServiceList");
    if (restrictionElement) {
        const elements = restrictionElement.getElementsByTagName("Item");
        for (let i = 0; i < elements.length; i++) {
            const itemElement = elements.item(i);
            if (itemElement != null && getRequiredAttribute(itemElement, "type") === "disable") {
                const value = itemElement.textContent;
                switch (value) {
                    case "betting": {
                        result.push(EGameService.Betting);
                        break;
                    }
                    case "virtual": {
                        result.push(EGameService.Virtual);
                        break;
                    }
                    case "casino": {
                        result.push(EGameService.Casino);
                        break;
                    }
                    case "racing": {
                        result.push(EGameService.Race);
                        break;
                    }
                    case "aviator": {
                        result.push(EGameService.Aviator);
                        break;
                    }
                }
            }
        }
    }
    return result;
}
