export const TIMEOUTS = {
    MAX_CONNECTION_RETRY_WAIT: 30000,
    QUOTATION_BACKLIGHT_OFF: 5000,
    BALANCE_REFRESH_TIMEOUT: 5 * 1000,
    ELECTRON_IDLE_CHECK_INTERVAL: 1000,
    ELECTRON_MAX_IDLE_FOR_APP_RELOAD: 30 * 60 * 1000,
    ELECTRON_MAX_IDLE_FOR_APP_RELOAD_AUTHORIZED: 3 * 60 * 1000,
    ELECTRON_INACTIVE_TIMER_DELAY: 60 * 1000,
    CASHIER_MONITORING_RELOAD_TIMEOUT: 10 * 1000,
    CASHIER_MAX_LIVE_CANCEL_TIMEOUT: 10 * 1000,
    CASHIER_MAX_PREMATCH_CANCEL_TIMEOUT: 10 * 60 * 1000,
    BLINK_DELAY: 500,
    SEARCH_DIALOG_INPUT_TIMEOUT: 600,
    WAIT_FOR_COLD_HOT_DELIMITER: 100,
    LEAVE_CASINO_TIMEOUT: 500,
    BEFORE_APPLICATION_RELOAD_GAP: 100,
    START_SINGLE_GAME_VERSION_GAP: 2000,
    SHOW_COOKIE_PERMISSIONS_PANEL: 1000
};

export const BIG_DECIMAL_SCALES = {
    BETTING_COEF: 2,
    BETTING_KEY_P: 2,
    SYSTEM_CALCULATED_COEF: 2,
    MONEY: 2
};

export const MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW = 30;
export const ALWAYS_ADD_PREMATCH_TO_LIVE_COUNT = 10;

export const INTERNATIONAL_COUNTRY_ID = 9999;
export const INTERNATIONAL_COUNTRY_IMAGE = "icon-world";
export const HOT_PARTITIONS_COUNTRY_ID = 9998;
