<template>
    <div class="popup extrawide active" id="popup10">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.winPaymentForm.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.winPaymentForm.cardCode") }}</label>
                        <input type="text" class="" v-model="paymentCode">
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-primary"
                           @click.stop.prevent="onFindCard">{{ $t("cashier.winPaymentForm.find") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-general"
                           :class="{ disabled: isPayButtonDisabled }"
                           @click.stop.prevent="onPay">{{ $t("cashier.winPaymentForm.doPayment") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-secondary"
                           @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
                <template v-if="card">
                    <div class="coupon-block payout-check-block">
                        <div class="color-error" v-if="card.isBonus">{{ $t("webdesktop.cardsTable.bonus") }}</div>
                        <div class="deposit-totals">
                            <div class="head">
                                <div class="item">{{ $t("cashier.monitoring.table.stake") }}</div>
                                <div class="item">{{ $t("cashier.monitoring.table.pay") }}</div>
                            </div>
                            <div class="body">
                                <div class="item">{{ $n(card.sumStake, "currency") }} {{ card.currencyMark }}</div>
                                <div class="item">{{ $n(card.sumPay, "currency") }} {{ card.currencyMark }}</div>
                            </div>
                        </div>
                        <mobile-inner-stake-table :card="card"></mobile-inner-stake-table>
                    </div>
                </template>
                <template v-if="raceCard">
                    <div class="coupon-block payout-check-block">
                        <div class="coupon-block-title">
                            {{ $t("routes.horse") }} <br>
                            {{ raceCard.serial }} {{ $d(raceCard.acceptServerTime, "long") }} <br>
                            {{ $t(raceCard.isPaid ? 'cashier.monitoring.table.ispaid' : raceCard.isCalculated ? 'cashier.monitoring.table.iscalculated' : 'cashier.monitoring.table.isaccepted') }}
                        </div>
                        <div class="coupon-footer">
                            <div class="name" data-cy="dialog-bet-accepted-sum-amount">
                                {{ $t("cashier.monitoring.table.stake") }}<br/>
                                {{ $n(raceCard.sumBet, "currency") }}
                            </div>
                            <div class="name" data-cy="dialog-bet-accepted-sum-max-win">
                                {{ $t("cashier.monitoring.table.pay") }}<br/>
                                {{ $n(raceCard.sumWin, "currency") }}
                            </div>
                        </div>
                        <div class="deposit-totals">
                            <div class="head">
                                <div class="item">{{ $t("cashier.monitoring.table.stake") }}</div>
                                <div class="item">{{ $t("cashier.monitoring.table.pay") }}</div>
                            </div>
                            <div class="body">
                                <div class="item">{{ $n(raceCard.sumBet, "currency") }}</div>
                                <div class="item">{{ $n(raceCard.sumWin, "currency") }}</div>
                            </div>
                        </div>
                        <div class="coupon-block-item" v-for="(item, index) of raceCard.items" :key="index">
                            <div class="info-line">
                                <div class="info-line-name">{{ item.trackName }}; {{ item.horses }} {{ item.gameType }}
                                    <br>
                                    <span
                                        :class="{ 'color-success': item.status === HorseItemStatus.Winner, 'color-error': item.status === HorseItemStatus.Lost}">
                            {{ $t("cashier.monitoring.table.horse.status" + item.status) }}
                          </span>
                                </div>
                                <div class="info-line-rate">
                                    {{ $t("cashier.monitoring.table.stake") }} {{ $n(item.sumBet, "currency") }}
                                    {{ $t("cashier.monitoring.table.pay") }} {{ $n(item.sumWin, "currency") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script src="./win-payment-dialog-component.ts"/>
