import { useClockProvider, useSettings } from "@sportaq/services/index";
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted, ref } from "vue";
import { useCashierRestService } from "@sportaq/services/cashier";
import { useBalanceUpdate } from "@sportaq/vue/components/common/balance-component/balance-update";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import {
    ENTRY_TYPE_CARD,
    ENTRY_TYPE_CASINO_DEPOSIT_OPERATION,
    ENTRY_TYPE_HORSE,
    ENTRY_TYPE_OPERATION,
    MonitoringEntry,
    useCashierStore
} from "@sportaq/vuex/modules/cashier/cashier-module";
import { TIMEOUTS } from "@sportaq/common/consts/default-consts";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { CasinoDepositOperation, EOperationType, FinOperation } from "@sportaq/model/cashier/fin-operation";
import { Card } from "@sportaq/model/cashier/card";
import { HorseWagerContainer, WagerContainer } from "@sportaq/model/cashier/wager-container";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { EPointType } from "@sportaq/common/enums/EPointType";
import { appLogger } from "@sportaq/common/utils/logger";

const MAX_COUNT_FOR_BLINK = 5;

export function useMainViewCommon (mobileCashier: boolean) {
    const i18n = useI18n();
    const settingsService = useSettings();
    const currentDialog = ref<string>();
    const dialogParam = ref("");
    let cardsLastLoadingTime = 0;
    const stationId = settingsService.pointSettings.stationId;
    const store = useCashierStore();
    let isActive = true;
    let lastCardId = 0;
    let lastOperationId = 0;
    let lastWagerId = 0;
    let lastRaceId = 0;
    const pointIdsForBalanceRefresh: number[] = [];
    const allPointIds: number[] = [];

    if (mobileCashier) {
        for (const point of store.getPoints()) {
            if (point.pointTypeId === EPointType.BetPlace || point.pointTypeId === EPointType.NEW_TERMINAL) {
                pointIdsForBalanceRefresh.push(point.id);
            }
            allPointIds.push(point.id);
        }
    }

    function onComponentSelected (component: string, param: string = ""): void {
        currentDialog.value = component;
        dialogParam.value = param;
    }

    function onDialogClosed () {
        currentDialog.value = undefined;
        dialogParam.value = "";
    }

    const restService = useCashierRestService();
    const clockProvider = useClockProvider();
    useBalanceUpdate(restService, useBalanceStore(), settingsService,
        { canSwitchAccounts: false });

    function monitoringRefresh () {
        cardsLastLoadingTime = 0;
        lastWagerId = 0;
        lastOperationId = 0;
        lastCardId = 0;
        lastRaceId = 0;
        store.clearCards();
    }

    function loadCards () {
        const loadCallback = () => {
            setTimeout(async () => {
                if (clockProvider.getDate().getTime() - cardsLastLoadingTime > TIMEOUTS.CASHIER_MONITORING_RELOAD_TIMEOUT && store.isMonitoringActive) {
                    const startDate = new Date();
                    startDate.setHours(0, 0, 0, 0);
                    startDate.setDate(startDate.getDate());
                    let loadedCards: Card[] | undefined;
                    try {
                        const lc = mobileCashier
                            ? await restService.getMobileCards(startDate, 100, lastCardId, i18n.locale.value, allPointIds)
                            : await restService.getCards(startDate, 100, lastCardId, i18n.locale.value);
                        loadedCards = lc.sort((a, b) => { return b.id - a.id; });
                        if (loadedCards.length > 0) {
                            lastCardId = loadedCards[0].id + 1;
                        }
                    } catch (e) {
                        appLogger.logger.error("Error in loadCards loadCallback", e as Error);
                    }
                    let loadedOperations:FinOperation[] | undefined;
                    try {
                        loadedOperations = await restService.getOperations(stationId, startDate, lastOperationId, mobileCashier ? allPointIds : undefined);
                        loadedOperations = loadedOperations.sort((a, b) => {
                            return b.acceptServerTime.getTime() - a.acceptServerTime.getTime();
                        });
                        if (loadedOperations.length > 0) {
                            lastOperationId = loadedOperations[0].id;
                        }
                    } catch (e) {
                        appLogger.logger.error("Error in loadCards loadCallback (restService.getOperations)", e as Error);
                    }
                    let wagerContainers: WagerContainer[] | undefined;
                    try {
                        wagerContainers = (await restService.getWagerContainers(startDate, lastWagerId, mobileCashier ? allPointIds : undefined)).sort((a, b) => a.id - b.id);
                        if (wagerContainers.length > 0) {
                            lastWagerId = wagerContainers[wagerContainers.length - 1].id;
                        }
                    } catch (e) {
                        appLogger.logger.error("Error in loadCards loadCallback (restService.getWagerContainers)", e as Error);
                    }
                    const isUpdate = store.getCards().length !== 0;
                    const entries:MonitoringEntry[] = [];
                    let loadedRaces: HorseWagerContainer[] = [];
                    try {
                        loadedRaces = mobileCashier
                            ? await restService.getMobileCashierRaceStakes(startDate, lastRaceId, allPointIds)
                            : await restService.getRaceStakes(startDate, undefined, lastRaceId);
                    } catch (e) {
                        appLogger.logger.error("Error in loadCards loadCallback (races)", e as Error);
                    }
                    if (loadedRaces.length > 0) {
                        lastRaceId = loadedRaces[0].id;
                        for (const race of loadedRaces) {
                            entries.push({
                                entryType: ENTRY_TYPE_HORSE,
                                value: race,
                                isHighlighted: isUpdate && loadedRaces.length < MAX_COUNT_FOR_BLINK
                            });
                            if (isUpdate && loadedRaces.length < MAX_COUNT_FOR_BLINK) {
                                blink(race.id);
                            }
                        }
                    }
                    if (loadedCards) {
                        for (const c of loadedCards) {
                            entries.push({
                                entryType: ENTRY_TYPE_CARD,
                                value: c,
                                isHighlighted: isUpdate && loadedCards.length < MAX_COUNT_FOR_BLINK
                            });
                            if (isUpdate && loadedCards.length < MAX_COUNT_FOR_BLINK) {
                                blink(c.id);
                            }
                        }
                    }
                    if (loadedOperations) {
                        for (const c of loadedOperations) {
                            if (c.operationType === EOperationType.PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP) {
                                entries.push({
                                    entryType: ENTRY_TYPE_CASINO_DEPOSIT_OPERATION,
                                    value: c as CasinoDepositOperation,
                                    isHighlighted: isUpdate && loadedOperations.length < MAX_COUNT_FOR_BLINK
                                });
                            } else {
                                entries.push({
                                    entryType: ENTRY_TYPE_OPERATION,
                                    value: c,
                                    isHighlighted: isUpdate && loadedOperations.length < MAX_COUNT_FOR_BLINK
                                });
                            }
                            if (isUpdate && loadedOperations.length < MAX_COUNT_FOR_BLINK) {
                                blink(c.id);
                            }
                        }
                    }
                    const sortedCards = entries.sort((a, b) => {
                        return b.value.acceptServerTime.getTime() - a.value.acceptServerTime.getTime();
                    });
                    store.addCards(sortedCards);
                    if (wagerContainers) {
                        store.addWagerContainers(wagerContainers);
                    }
                    cardsLastLoadingTime = clockProvider.getDate().getTime();
                }
                if (isActive) {
                    loadCallback();
                }
            }, 2000);
        };
        loadCallback();
    }

    function blink (id: number): void {
        let blinkCount = 0;
        const t = () => {
            if (blinkCount < 9) {
                blinkCount++;
                store.blinkEntry(id);
                setTimeout(t, TIMEOUTS.BLINK_DELAY);
            }
        };
        setTimeout(t, TIMEOUTS.BLINK_DELAY);
    }

    function loadPoints () {
        const loadCallback = () => {
            const pointListPromise: Promise<ShortPointInfo[]> = mobileCashier ? restService.getPointList(pointIdsForBalanceRefresh) : restService.getDesktopPointList();
            pointListPromise.then((points) => {
                if (isActive) {
                    store.updatePoints(points);
                    setTimeout(() => {
                        loadCallback();
                    }, TIMEOUTS.CASHIER_MONITORING_RELOAD_TIMEOUT);
                }
            });
        };
        loadCallback();
    }

    onMounted(() => {
        eventBus.on<string>(Events.CANCEL_CARD, cancelCard);
        eventBus.on<string>(Events.MONITORING_REFRESH, monitoringRefresh);
        loadCards();
        loadPoints();
    });

    onUnmounted(() => {
        isActive = false;
        eventBus.off<string>(Events.MONITORING_REFRESH, monitoringRefresh);
        eventBus.off<string>(Events.CANCEL_CARD, cancelCard);
    });

    function cancelCard (cardId: unknown) {
        restService.cancelCard(cardId as number).then();
    }
    return {
        i18n,
        currentDialog,
        settingsService,
        dialogParam,
        onComponentSelected,
        onDialogClosed
    };
}
