import { ref, SetupContext } from "vue";
import { useSettings } from "@sportaq/services/index";
import { useRefillAccountDialogCommon } from "@sportaq/vue/components/mobile-cashier/refill-account-dialog-common";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { onPositiveNumberKeyFilter } from "@sportaq/vue/utils/form-validation";

export default {
    name: "RefillAccountDialog",

    emits: [CLOSE_EVENT],
    setup (props: unknown, context: SetupContext) {
        const printContent = ref<HTMLElement>();
        const settings = useSettings();
        const i18n = useI18n();
        const {
            userNumberRef,
            userDocumentRef,
            phoneNumber,
            userRef,
            amountRef,
            deposits,
            depositTime,
            userName,
            currentDepositRef,
            isFillButtonDisabled,
            requestSent,
            balance,
            amountErrorMessage,
            onClose,
            onFind,
            onRefill,
            formatVoucherNumber
            // eslint-disable-next-line @typescript-eslint/no-empty-function
        } = useRefillAccountDialogCommon(true, settings, context, () => {
            showMessageBox(i18n.t("mcashier.refillAccount.success"));
        });
        return {
            userNumberRef,
            userDocumentRef,
            phoneNumber,
            userRef,
            amountRef,
            deposits,
            depositTime,
            userName,
            currentDepositRef,
            isFillButtonDisabled,
            requestSent,
            printContent,
            balance,
            amountErrorMessage,
            onAmountKeyDown: onPositiveNumberKeyFilter,

            onClose,
            onFind,
            onRefill,
            formatVoucherNumber
        };
    }
};
