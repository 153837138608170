import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import { ClockProvider, DefaultClockProvider } from "@sportaq/common/utils/time-utils";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { StreamingService } from "@sportaq/services/streaming/streaming-service";
import { StreamingNetwork } from "@sportaq/services/streaming/streaming-network/streaming-network";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { DefaultUUIDProvider, UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { RestService } from "@sportaq/services/rest/rest-service";
import { PrintService } from "@sportaq/services/print/print-service";
import {
    DefaultMiscellaneousParamsProvider,
    MiscellaneousParamsProvider
} from "@sportaq/common/utils/miscellaneous-params";
import {
    StreamingRequestService,
    StreamingRequestServiceImpl
} from "@sportaq/services/streaming/streaming-request-service";
import { CasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import { AuthorizationService } from "@sportaq/services/authorization/AuthorizationService";
import { HttpService, HttpServiceImpl } from "@sportaq/services/http/http-service";
import { GoogleTagService, GoogleTagServiceImpl } from "@sportaq/services/google-tag/google-tag-service";
import { CasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";

export const services = {
    STREAMING_SERVICE: Symbol("Streaming service"),
    STREAMING_REQUEST_SERVICE: Symbol("Streaming request service"),
    PRINT: Symbol("Print service"),
    CLOCK: Symbol("Clock provider"),
    UUID: Symbol("UUID provider"),
    SETTINGS: Symbol("Settings Service"),
    REST: Symbol("Rest service"),
    MISCELLANEOUS: Symbol("Miscellaneous params provider"),
    AUTHORIZATION: Symbol("Authorization service"),
    HTTP: Symbol("Http service"),
    GOOGLE_TAG: Symbol("Google tag")
};

// region Streaming Service
export function provideStreamingService (
    streamingNetwork: StreamingNetwork,
    streamingRequestService: StreamingRequestService,
    settingsService: BaseSettingsService, clockProvider: ClockProvider,
    eventSupplier: EventSupplier,
    casinoGamesSupplier: CasinoGamesSupplier,
    casinoGamesStore: CasinoGamesStore
) {
    const result = new StreamingService(streamingNetwork, streamingRequestService,
        settingsService, clockProvider, eventSupplier, casinoGamesSupplier, casinoGamesStore);
    provide(services.STREAMING_SERVICE, result);
}

export function useStreamingService (): StreamingService {
    return use(services.STREAMING_SERVICE);
}

// endregion

// region Streaming Request Service
export function provideStreamingRequestService (streamingNetwork: StreamingNetwork): StreamingRequestService {
    const result = new StreamingRequestServiceImpl(streamingNetwork);
    provide(services.STREAMING_REQUEST_SERVICE, result);
    return result;
}

export function provideInjectedStreamingRequestService (streamingRequestService: StreamingRequestService): StreamingRequestService {
    provide(services.STREAMING_REQUEST_SERVICE, streamingRequestService);
    return streamingRequestService;
}

export function useStreamingRequestService (): StreamingRequestService {
    return use(services.STREAMING_REQUEST_SERVICE);
}

// endregion
// region Clock Provider
export function provideClockProvider (): ClockProvider {
    const result = new DefaultClockProvider();
    provide(services.CLOCK, result);
    return result;
}

export function provideInjectedClockProvider (clockProvider: ClockProvider) {
    provide(services.CLOCK, clockProvider);
}

export function useClockProvider (): ClockProvider {
    return use(services.CLOCK);
}

// endregion

// region UUID Provider
export function provideUUIDProvider (): UUIDProvider {
    const result = new DefaultUUIDProvider();
    provide(services.UUID, result);
    return result;
}

export function provideInjectedUUIDProvider (uuidProvider: UUIDProvider) {
    provide(services.UUID, uuidProvider);
}

export function useUUIDProvider (): ClockProvider {
    return use(services.UUID);
}

// endregion

// region Google tag
export function provideGoogleTagService () {
    const result = new GoogleTagServiceImpl();
    provide(services.GOOGLE_TAG, result);
    return result;
}

export function provideInjectedGoogleTagService (service: GoogleTagService) {
    provide(services.GOOGLE_TAG, service);
}

export function useGoogleTagService (): GoogleTagService {
    return use(services.GOOGLE_TAG);
}

// region Print Service
export function provideInjectedPrintService (printService: PrintService) {
    provide(services.PRINT, printService);
}

export function usePrintService (): PrintService {
    return use(services.PRINT);
}

// endregion

// region Settings Service
let globalSettingsForRouting: BaseSettingsService;

export function provideSettings (settings: BaseSettingsService) {
    provide(services.SETTINGS, settings);
    globalSettingsForRouting = settings;
    return settings;
}

export function useSettings (): BaseSettingsService {
    return use(services.SETTINGS);
}

export { globalSettingsForRouting };

// endregion

// region Rest Service
export function provideRestService (restService: RestService) {
    provide(services.REST, restService);
}

export function useRestService (): RestService {
    return use(services.REST);
}

// endregion

// region Miscellaneous Params Provider
export function provideMiscellaneousParamsProvider (): MiscellaneousParamsProvider {
    const result = new DefaultMiscellaneousParamsProvider();
    provide(services.MISCELLANEOUS, result);
    return result;
}

export function provideInjectedMiscellaneousParamsProvider (miscellaneousParamsProvider: MiscellaneousParamsProvider) {
    provide(services.MISCELLANEOUS, miscellaneousParamsProvider);
}

export function useMiscellaneousParamsProvider (): MiscellaneousParamsProvider {
    return use(services.MISCELLANEOUS);
}

// endregion

// region Rest Service
export function provideAuthorizationService (authorizationService: AuthorizationService) {
    provide(services.AUTHORIZATION, authorizationService);
}

export function useAuthorizationService (): AuthorizationService {
    return use(services.AUTHORIZATION);
}

// region Http Service
export function provideHttpService () {
    provide(services.HTTP, new HttpServiceImpl());
}

export function provideInjectedHttpService (service: HttpService) {
    provide(services.HTTP, service);
}

export function useHttpService (): HttpService {
    return use(services.HTTP);
}

// endregion
