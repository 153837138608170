<template>
    <div class="popup messagebox" :class="{active: true, error: !success}" id="messagebox" data-cy="message-box">
        <div class="popup-container">
            <div class="popup-close" @click.stop.prevent="close"></div>
            <div class="popup-title" v-if="caption" data-cy="message-box-caption" v-html="caption">
            </div>
            <div class="popup-text" v-if="message"><div data-cy="message-box-message" v-html="message"></div></div>
            <div class="confirm-buttons">
                <a href="#" class="button" @click.stop.prevent="close">OK</a>
            </div>
        </div>
    </div>
</template>

<script src="./message-box-component.ts"/>
