import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild, getValue
} from "@sportaq/common/utils/xml-helper-functions";
import { ExpressAccountRegData, ExpressAccountUser } from "@sportaq/model/cashier/express-account-user";

export class APo512AddExpressAccountMessageHandler extends AbstractMessageHandler<ExpressAccountUser> {
    protected readonly requestType: string = "A.PO.5.12";
    constructor (
        readonly regData: ExpressAccountRegData
    ) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        const orderSum: number = parseFloat(this.regData.orderSum);
        request.addChildWithValue(actionElement, "OrderedSum", orderSum.toFixed(2));
        request.addChildWithValue(actionElement, "CurrencyId", this.regData.currencyId);
        request.addChildWithValue(actionElement, "FirstName", this.regData.firstName);
        request.addChildWithValue(actionElement, "LastName", this.regData.lastName);
        request.addChildWithValue(actionElement, "IdentificationCode", this.regData.document);
        if (this.regData.phone) {
            request.addChildWithValue(actionElement, "Phone", this.regData.phone);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): ExpressAccountUser {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "4310") {
            throw new LocalizedError(`errors.mtl.aPo512.code${serverCode}`);
        }
        const userElement = getRequiredChild(action, "User");
        return {
            userNumber: getValue(getRequiredChild(userElement, "UserNumber")),
            password: getValue(getRequiredChild(userElement, "Password"))
        };
    }
}
