<template>
    <div class="popup active" id="popup12">
        <div class="popup-container">
            <div class="popup-close" @click="onClose"></div>
            <div class="popup-title">{{ $t("cashier.monitoring.search.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.monitoring.search.inputLabel") }}</label>
                        <input type="text"  v-model.number="valueRef">
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-general" @click.prevent="onSubmit">{{ $t("common.forms.ok") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-secondary" @click.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./monitoring-search-component.ts" />
