<template>
    <div class="deposit-tabs">
        <div class="deposit-tabs-body">
            <div class="deposit-block" v-for="(point) in pointsRef" :key="point.id">
                <div class="deposit-block-body">
                    <div class="info">
                        <div class="info-text">
                            <div class="title">
                                {{point.name}}
                            </div>
                            <div class="line">{{$t("cashier.activeBetplaces.sum")}}: <strong>{{point.balance?.toFixed(2)}} / {{point.casinoBalance?.toFixed(2)}}</strong></div>
                        </div>
                    </div>
                    <div class="deposit-totals">
                        <div class="body">
                            <div class="item">
                                <button class="button-primary smaller" @click.stop.prevent="nullify(point)">{{$t("cashier.activeBetplaces.nullify")}}</button>
                            </div>
                            <div class="item">
                                <button class="button-primary smaller" @click.stop.prevent="deposit(point)">{{$t("cashier.activeBetplaces.deposit")}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <input-box v-if="resetConfirmationVisible" :caption="$t('cashier.activeBetplaces.nullify')" :message="$t('cashier.activeBetplaces.nullifyConfirm', { betplace: depositPointRef?.name })"
             @close="resetConfirmationVisible = false"
             @submit="doReset"></input-box>

    <div class="popup active" id="popup8-1" v-if="depositDialogVisibleRef">
        <div class="popup-container">
            <div class="popup-title">{{$t("cashier.activeBetplaces.depositDialog.caption")}}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <input type="number" class="field width-50" v-model.number="depositAmountRef">
                    </div>
                    <div class="form-line">
                        <button class="button button-primary" @click.stop.prevent="depositSubmit">{{$t("common.forms.ok")}}</button>
                    </div>
                    <div class="form-line">
                        <button class="button button-secondary" @click.stop.prevent="depositCancel">{{$t("common.forms.cancel")}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="preloader" v-if="requestPending">
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./active-betplaces-component.ts">
</script>
