import { App, Plugin } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import { I18nSetup } from "@sportaq/i18n/settings/i18n-settings";
import i18nSettings = I18nSetup.i18nSettings;

export interface StreamServer {
    readonly locale: string;
    readonly address: string;
}

export interface WebEnvironmentSettings {
    readonly appCode: string,
    readonly partnerCode: string,
    readonly restServerAddress: string,
    readonly streamServers: StreamServer[],
    readonly useSSL: boolean,
    readonly logo: string | undefined,
    readonly disabledLanguages: string[] | undefined,
    readonly isAboutPagePresent: string | undefined,
    readonly isRegistrationEnabled: string | undefined,
    readonly gtag: string
    showChat: string | undefined
}

declare global {
    interface Window {
        dependencyProvider: {
            environment?: WebEnvironmentSettings;
        }
    }
}

export async function requestEnvironmentSettings (devHost?: string): Promise<WebEnvironmentSettings> {
    if (window.dependencyProvider?.environment) {
        return window.dependencyProvider?.environment;
    } else {
        const url = (process.env.__PRODUCTION__
            ? "/resource/app_properties.json"
            : `https://${devHost ?? "www.dev.tubetgol.com"}/resource/app_properties.json`) + "?" + Math.random();
        const fetchResult = await fetch(url);
        const result: WebEnvironmentSettings = await fetchResult.json();
        return result;
    }
}

export const webEnvironmentPlugin:Plugin = {
    install (app: App, settings: WebEnvironmentSettings) {
        app.provide(WEB_ENVIRONMENT, settings);
        if (settings.disabledLanguages) {
            i18nSettings.disabledLanguages = settings.disabledLanguages;
        }
    }
};

export function useWebEnvironment ():WebEnvironmentSettings {
    return use(WEB_ENVIRONMENT);
}

const WEB_ENVIRONMENT = Symbol("WEB_ENVIRONMENT");
