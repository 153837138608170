
export const WC_TYPE_COMMON = 1;

interface WCItem {
    gameMark: string;
    initTime: Date;
    sumBet: number;
    sumWin: number;
}

export interface BaseWagerContainer<T> {
    items: T
}

export type CommonWagerContainer = {
    id: number,
    wcType: 1,
    item: WCItem,
    pointId: number;
    acceptServerTime: Date
}

export type HorseWagerContainer = {
    id: number,
    items: HorseItem[],
    pointId: number;
    sumBet: number,
    sumWin: number,
    expanded: boolean,
    serial: number,
    userNumber: string | undefined,
    acceptServerTime: Date,
    isCalculated: boolean,
    isPaid: boolean;
}

export type WagerContainer = CommonWagerContainer;

export enum HorseItemStatus {
    Winner = "G", InPlay = "J", Lost = "P", Return = "D", Void = "A"
}

export interface HorseItem {
    trackName: string;
    trackId: number;
    gameType: HorseGameType;
    path: number;
    horses: string;
    sumBet: number;
    sumWin: number;
    status: HorseItemStatus
}

export enum HorseGameType {Win = "win", Place = "place", Show = "show", Extract = "exact", Trifecta = "trifecta",
       Superfect = "superfect", Pick2 = "Pick2", Pick3 = "Pick3"}
