import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild, getRequiredValue } from "@sportaq/common/utils/xml-helper-functions";
import { StartGameFrameResponse } from "@sportaq/model/types/responses";

export class AUr11StartRaceGameRequestHandler extends AbstractMessageHandler<StartGameFrameResponse> {
    protected readonly requestType: string = "A.UR.1.1";
    protected readonly requestTypeWeb: string = "A.UR.1.2";

    constructor (readonly userNumber: string | undefined, readonly isDesktop: boolean) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const action = request.addChild(request.body, "action", {
            type: this.isDesktop ? this.requestType : this.requestTypeWeb
        });
        if (this.userNumber) {
            request.addChildWithValue(action, "userNumber", this.userNumber);
        }
    }

    protected parseMessageBody (body: Element): StartGameFrameResponse {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "8100") {
            const gameUrl = getRequiredChild(action, "GameURL");
            return {
                type: "success",
                url: getRequiredValue(gameUrl)
            };
        } else {
            return {
                type: "error",
                code: serverCode
            };
        }
    }
}
