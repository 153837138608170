<template>
    <print-accepted-bet v-if="isPrintServiceRegistered" :responseInfo="responseInfo" :simpleStakes="simpleStakes" :combinedStakes="combinedStakes"
                        :printTrigger="printTrigger" :printAppCode="false" />
    <div class="coupon coupon-content-active  coupon-dialog">
        <div class="coupon-content" data-cy="dialog-bet-accepted">
            <div class="coupon-head">
                <div class="coupon-head-title">
                    {{ $t("betting.betSlip.dialogBetAccepted.caption") }}
                    <template v-if="responseInfo.isBonus">
                        <br><span class="color-error">{{$t("webdesktop.cardsTable.bonus")}}</span>
                    </template>
                </div>
            </div>

            <div class="coupon-body" data-cy="dialog-bet-verification-body">
                <mobile-inner-stake-table :simpleStakes="simpleStakes" :combinedStakes="combinedStakes"/>
            </div>
            <div class="coupon-footer">
                <div class="name" data-cy="dialog-bet-accepted-sum-amount">
                    {{ $t("betting.betSlip.common.total") }}<br/>
                    {{ $n(betTotal.total, "currency") }} {{ currency }}
                </div>
                <div class="name" data-cy="dialog-bet-accepted-sum-max-win">
                    {{ $t("betting.betSlip.common.maxWin") }}<br/>
                    {{ $n(betTotal.maxWin, "currency") }} {{ currency }}
                </div>
            </div>
            <div class="coupon-buttons">
                <button class="button button-primary" @click.prevent="onClose" data-cy="close-btn">
                    {{ $t("common.forms.ok") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script src="./mobile-dialog-bet-accepted-component.ts"/>
