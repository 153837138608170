import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild, getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";
import { toLocalTime } from "@sportaq/common/utils/time-utils";

export interface DniLoginResponse {
    readonly userNumber: string;
    readonly selfBlockedTime: Date | undefined;
    readonly isRegulatorBlocked: boolean;
}

export class QUs113LoginMessageHandler extends AbstractMessageHandler<DniLoginResponse> {
    protected readonly requestType: string = "Q.US.1.13";
    constructor (readonly dni: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", { type: this.requestType });
        request.addChildWithValue(actionElement, "DNI", this.dni);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): DniLoginResponse {
        const query = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(query, "servercode");
        if (serverCode === "1300" || serverCode === "1302") {
            const userNumber = getRequiredValue(getRequiredChild(getRequiredChild(query, "User"), "UserNumber"));
            const selfBlockedTime = serverCode !== "1302" ? undefined : toLocalTime(new Date(getRequiredValue(getRequiredChild(getRequiredChild(query, "User"), "SelfBlockedTime"))));
            const isRegulatorBlocked = getChild(getRequiredChild(query, "User"), "IsRegulatorBlocked") !== undefined;
            return { userNumber, selfBlockedTime, isRegulatorBlocked };
        }
        throw new LocalizedError(`errors.mtl.qUs113.code${serverCode}`);
    }
}
