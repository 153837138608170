<template>
    <div class="deposit-section" v-if="dailyReport">
        <div class="deposit-section-body">
          <div class="line-date">{{$t(isFinDay ? "cashier.finDayForm.caption" : "cashier.dailyReportForm.caption")}} <templace v-if="dailyReport.lastActionTime">{{$d(dailyReport.lastActionTime, "long" )}} - </templace>{{ $d( reportDate, "long" )}}</div>
          <div class="line">
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalStake") }}</div>
                    <div class="value">{{ (dailyReport.totalStake + dailyReport.totalStakeInternet).toFixed(2)}} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalPaid") }}</div>
                    <div class="value">{{ (dailyReport.totalPaid + dailyReport.totalPaidInternet).toFixed(2)}} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalCancelStakeBP") }}</div>
                    <div class="value">{{ (dailyReport.totalCancelStakeBP).toFixed(2)}} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalStakeRace") }}</div>
                    <div class="value">{{ (dailyReport.totalStakeRace).toFixed(2)}} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalPayRace") }}</div>
                    <div class="value">{{ (dailyReport.totalPayRace).toFixed(2)}} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalBalanceDeposit") }}</div>
                    <div class="value">{{ dailyReport.totalBalanceDeposit.toFixed(2) }} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalBalanceWithdowal") }}</div>
                    <div class="value">{{ dailyReport.totalBalanceWithdowal.toFixed(2) }} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalGenerationVoucher") }}</div>
                    <div class="value">{{ dailyReport.totalGenerationVoucher.toFixed(2) }} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalCashCheque") }}</div>
                    <div class="value">{{ dailyReport.totalCashCheque.toFixed(2) }} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalBalanceDepositBetPlace") }}</div>
                    <div class="value">{{ dailyReport.totalBalanceDepositBetPlace.toFixed(2) }} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalBalanceNulling") }}</div>
                    <div class="value">{{ dailyReport.totalBalanceNulling.toFixed(2) }} {{currencyName}}</div>
                </div>
                <div class="line-total">
                    <div class="name">{{ $t("cashier.dailyReportForm.totalPaidTerminal") }}</div>
                    <div class="value">{{ dailyReport.totalPaidTerminal.toFixed(2) }} {{currencyName}}</div>
                </div>
                <div class="line">
                    <div class="line-total">
                        <div class="name">{{ $t("cashier.dailyReportForm.total") }}</div>
                        <div class="value">= {{ calculateTotal() }} {{currencyName}}</div>
                    </div>
                </div>
                <div class="form-line" v-if="isFinDay">
                    <a href="#" class="button button-general" @click.stop.prevent="onCloseDay" :class="{disabled: closeDayButtonPressedRef}">{{ $t("cashier.dailyReportForm.closeDay") }}</a>
                </div>
                <!-- <div class="form-line">
                    <a href="#" class="button button-secondary" @click.stop.prevent="refresh" >{{ $t("cashier.dailyReportForm.refresh") }}</a>
                </div> -->
            </div>
        </div>
    </div>
    <div class="preloader" v-else>
        <div class="preloader-el"></div>
    </div>
    <input-box v-if="isCloseDayConfirmatonDialogVisible" :caption="$t('cashier.dailyReportForm.caption')" :message="$t('cashier.dailyReportForm.confirm')" @close="onCloseDayCancel" @submit="onCloseDayConfirmed" />
</template>

<script src="./fin-day-view.ts" />
