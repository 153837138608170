import { User } from "@sportaq/model/common/user-accounts";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { AccountDeposit } from "@sportaq/model/cashier/account-deposit";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { computed, nextTick, ref, SetupContext } from "vue";
import { useCashierRestService } from "@sportaq/services/cashier";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { useI18n } from "vue-i18n";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";

export function useRefillAccountDialogCommon (mobileCashier: boolean, settings: BaseSettingsService, context: SetupContext, successCallback: () => void) {
    const phoneNumber = ref<string>();
    const userNumberRef = ref<string>();
    const userDocumentRef = ref<string>();
    const userRef = ref<User>();
    const amountRef = ref<number>();
    const depositTime = ref<Date>();
    const store = useCashierStore();
    const deposits = computed(() => store.getAccountDeposits());
    const currentDepositRef = ref<AccountDeposit>();
    const balanceStore = useBalanceStore();
    const requestSent = ref<boolean>();
    const restService = useCashierRestService();
    const amountErrorMessage = ref("");
    const i18n = useI18n();
    const balance = computed(() => {
        return balanceStore.balance.numberValue;
    });

    const isFillButtonDisabled = computed(() => {
        if (!userRef.value) {
            return true;
        }
        if (requestSent.value) {
            return true;
        }
        if (currentDepositRef.value !== undefined) {
            return true;
        }
        if (amountRef.value === undefined) {
            return false;
        }
        return amountRef.value < 0;
    });

    const userName = computed(() => {
        if (!userRef.value) {
            return "";
        }
        let result = "";
        if (userRef.value.firstName) {
            result = result + userRef.value.firstName;
        }
        if (userRef.value.lastName) {
            result = result + " " + userRef.value.lastName;
        }
        if (userRef.value.login) {
            result = result + " (" + userRef.value.userNumber + ")";
        }
        return result;
    });

    function onRefill () {
        if (amountRef.value === undefined) {
            amountErrorMessage.value = i18n.t("cashier.refillAccountForm.empty");
            return;
        }
        if (amountRef.value > balance.value) {
            amountErrorMessage.value = i18n.t("cashier.refillAccountForm.insufficientFunds");
            return;
        }
        if (userRef.value) {
            if (userRef.value.isRegulatorBlocked) {
                showMessageBox(i18n.t("common.blocked.regulatorBlocked"));
                return;
            }
            if (userRef.value.selfBlockedTime) {
                showMessageBox(i18n.t("common.blocked.detailsMessage",
                    { date: i18n.d(userRef.value.selfBlockedTime, "long") }));
                return;
            }
            const amount = amountRef.value;
            const account = userRef.value?.userAccounts.getPrimaryAccount();
            const userLogin = userRef.value?.login;
            const userNumber = userRef.value?.userNumber;
            requestSent.value = true;
            const currencyId = account && account.currencyId !== 0 ? account.currencyId : mobileCashier ? store.user.userAccounts.getPrimaryAccount()?.currencyId : settings.pointSettings.currency.typeId;
            const currencyName = account && account.currencyId !== 0 ? account.currencyMark : mobileCashier ? store.user.userAccounts.getPrimaryAccount()?.currencyMark : settings.pointSettings.currencyName;
            if (!currencyId || !currencyName) {
                showMessageBox("Currency is undefined");
                return;
            }
            restService.refillAccount(mobileCashier, userRef.value?.userId, amount, currencyId, false)
                .then(id => {
                    const now = new Date(Date.now());
                    const deposit = {
                        id,
                        amount,
                        depositTime: now,
                        userLogin,
                        userNumber,
                        currencyMark: currencyName
                    };
                    store.addAccountDeposit(deposit);
                    currentDepositRef.value = deposit;
                    depositTime.value = now;
                    requestSent.value = false;
                    nextTick(successCallback).then();
                }).catch(() => {
                    requestSent.value = false;
                });
        }
    }

    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function onFind () {
        if (userNumberRef.value || userDocumentRef.value || phoneNumber.value) {
            currentDepositRef.value = undefined;
            restService.findUser(phoneNumber.value, userNumberRef.value, userDocumentRef.value).then(u => {
                if (u !== undefined) {
                    userRef.value = u;
                    userDocumentRef.value = u.IdentificationCode;
                    userNumberRef.value = u.userNumber;
                } else {
                    showMessageBox(i18n.t("errors.mtl.aUs14.code1152"));
                }
            });
        }
    }

    return {
        userNumberRef,
        userDocumentRef,
        phoneNumber,
        userRef,
        amountRef,
        deposits,
        depositTime,
        userName,
        currentDepositRef,
        isFillButtonDisabled,
        requestSent,
        balance,
        amountErrorMessage,

        onClose,
        onFind,
        onRefill,
        formatVoucherNumber
    };
}
