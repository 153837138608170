import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute, getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";

export class QPi51PointListMessageHandler extends AbstractMessageHandler<ShortPointInfo[]> {
    protected readonly requestType: string = "Q.PI.5.1";
    constructor (readonly pointIds?: number[]) {
        super();
    }

    buildRequest (request: XmlRequest) {
        request.addChild(request.body, "query", {
            type: this.requestType
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): ShortPointInfo[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "2600") {
            throw new LocalizedError(`errors.mtl.aPi51.code${serverCode}`);
        }
        const pointListElement = getRequiredChild(action, "BetPlacesList");
        const result = new Map<number, ShortPointInfo>();
        for (let pointElement = pointListElement.firstElementChild; pointElement != null; pointElement = pointElement.nextElementSibling) {
            if (pointElement.nodeName === "BetPlace") {
                const point = new ShortPointInfo(
                    getRequiredIntAttribute(pointElement, "id"),
                    0,
                    getRequiredAttribute(pointElement, "bpGUID"),
                    getRequiredAttribute(pointElement, "name"),
                    getRequiredFloatAttribute(pointElement, "balance")
                );
                result.set(point.id, point);
            } else if (pointElement.nodeName === "BetPlaceCasino") {
                const id = getRequiredIntAttribute(pointElement, "id");
                const point = result.get(id);
                if (point) {
                    point.casinoBalance = getRequiredFloatAttribute(pointElement, "balance");
                }
            }
        }
        return Array.from(result.values());
    }
}
