<template>
    <div v-if="printCardData" style="display: none" ref="printContent" width="100%">
        <table>
            <tr>
                <td colspan="2" align="center">
                    <svg ref="barcode"></svg>
                </td>
            </tr>
            <tr>
                <td colspan="2" align="center">
                    <logo-print></logo-print>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-big"><b>{{ pointName }}</b></td>
            </tr>
            <tr  v-if="printAppCode">
                <td colspan="2" class="text-big"><b>{{ appCode }}</b></td>
            </tr>
            <tr>
                <td colspan="2" class="text-big"><b>{{ pointAddress }}</b></td>
            </tr>
            <tr v-if="pointDescription">
                <td colspan="2" class="text-normal"><b>{{ pointDescription }}</b></td>
            </tr>
            <tr v-if="printCardData.card.userNumber">
                <td>{{$t("betting.betSlip.common.userNumber")}}</td><td><b>{{ printCardData.card.userNumber }}</b></td>
            </tr>
            <tr>
                <td colspan="2" class="text-divider">--------------------------------------------------</td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">{{ $t("betting.betSlip.print.card") }}
                    {{ printCardData.card.innerCardId }} {{ $t("betting.betSlip.print.code") }}
                    <b>{{ printCardData.formattedPayCode }}</b></td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">{{ $t("betting.betSlip.print.time") }}
                    {{ $d(printCardData.card.acceptServerTime, 'long') }}
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">{{ $t("betting.betSlip.print.endTime") }}
                    {{ $d(printCardData.validUntil, 'long') }}
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-divider">--------------------------------------------------</td>
            </tr>
            <template v-for="container of printCardData.card.containers" :key="container.id">
                <tr>
                    <td class="text-big" v-if="container.containerType === 1">
                        <i><b>{{ $t("betting.betSlip.ordinar") }}</b></i></td>
                    <td class="text-big" v-if="container.containerType === 2">
                        <i><b>{{ $t("betting.betSlip.expressWithCoef", {coef: container.getCoefColumnValue().toFixed(2)}) }}</b></i>
                    </td>
                    <td class="text-big" v-if="container.containerType === 3"><i><b>{{
                            $t("betting.betSlip.system", {
                                base: container.expressEvents,
                                limit: container.items.length
                            })
                        }}</b></i></td>
                    <td class="text-big"><b><i>{{ container.sumStake }} {{ printCardData.card.currencyMark }}</i></b>
                    </td>
                </tr>
                <tr>
                    <td class="text-big">{{ $t("betting.betSlip.maxWin") }}</td>
                    <td class="text-big">{{ container.getMaxWin().toFixed(2) }} {{ printCardData.card.currencyMark }}
                    </td>
                </tr>
                <template v-for="item of container.items" :key="item.id">
                    <tr>
                        <td colspan="2" class="text-big">{{ $d(item.position.startTime, "long") }}
                            {{ item.position.partition.name }} <br> {{ getParticipants(item) }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="text-big"><b><span v-html="getQuotationTitle(item)"/> @
                            {{ item.coef.toFixed(2) }}</b></td>
                    </tr>
                </template>
            </template>
            <tr>
                <td class="text-big"><b>{{ $t("betting.betSlip.common.total") }}</b></td>
                <td class="text-big"><b>{{ printCardData.card.sumStake.toFixed(2) }}
                    {{ printCardData.card.currencyMark }}</b></td>
            </tr>
            <tr>
                <td class="text-big"><b>{{ $t("betting.betSlip.common.maxWin") }}</b></td>
                <td class="text-big"><b>{{ printCardData.maxWin.toFixed(2) }} {{ printCardData.card.currencyMark }}</b>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-divider">--------------------------------------------------</td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">{{ $t("betting.betSlip.print.confirm") }}</td>
            </tr>
        </table>
    </div>
</template>

<script src="./sport-card-print-component.ts">
</script>

<style scoped>

</style>