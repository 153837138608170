<template>
    <div class="coupon-block-item" :data-cy="stake.id">
        <div class="info">
            <div class="info-subtitle">
                <div class="live" v-if="stake.isLive" data-cy="live">LIVE</div>
                <div class="date" data-cy="date">{{$d(stake.position.startTime, 'long')}}</div>
                <div class="name" data-cy="partition">{{
                    stake.position.partition.sportName + ". " + stake.position.partition.name + ". " +
                    getParticipants(stake)
                  }}</div>
            </div>
        </div>
        <div class="info-line">
            <div class="info-line-name" data-cy="quotation-title"><span v-html="getQuotationTitle(stake)"/></div>
            <div class="info-line-rate" v-html="stake.coef.toFixed(2)" data-cy="quotation-label"></div>
        </div>
        <div class="info-line" :class="{ 'color-success': stake.itemStatus === 1, 'color-error': stake.itemStatus === 2}">
            <div class="info-line-name" data-cy="amount">{{ $t("cashier.monitoring.table.status") }} :
              {{ $t("cashier.monitoring.table.itemStatus" + stake.itemStatus) }}
            </div>
        </div>
    </div>
</template>

<script src="./mobile-simple-stake-row-component.ts"/>
