import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { dateToMtlStr } from "@sportaq/common/utils/time-utils";
import { BaseRaceStakesHandler } from "@sportaq/services/rest/messages/games/race/base-race-stakes-handler";

export class QRs13GetRaceStakesHandler extends BaseRaceStakesHandler<HorseWagerContainer[]> {
    protected readonly requestType: string = "Q.RS.1.3";

    constructor (readonly startDate: Date, readonly lastOperationId: number, readonly pointFilter: number[] | undefined) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        request.addChildWithValue(filterElement, "startDate", dateToMtlStr(this.startDate));
        request.addChildWithValue(filterElement, "lasContainerId", this.lastOperationId.toString());
        if (this.pointFilter) {
            const pointFilterElement = request.addChild(filterElement, "PointFilter");
            const pointListElement = request.addChild(pointFilterElement, "PointList");
            for (const pointId of this.pointFilter) {
                request.addChildWithValue(pointListElement, "PointId", pointId.toString());
            }
        }
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): HorseWagerContainer[] {
        const query = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(query, "servercode");
        if (serverCode !== "8550") {
            throw new LocalizedError(`errors.mtl.qRs12.code${serverCode}`);
        }
        const result: HorseWagerContainer[] = [];
        const wagerContainerListElement = getRequiredChild(query, "RaceContainerList");
        for (let wcElement = wagerContainerListElement.firstElementChild; wcElement != null; wcElement = wcElement.nextElementSibling) {
            const rc = this.parseContainer(wcElement);
            result.push(rc);
        }
        return result;
    }
}
