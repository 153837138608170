import { CashierSettingsService } from "@sportaq/services/cashier/settings/settings-service";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import Cookies from "js-cookie";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { EGameService } from "@sportaq/model/common/point-settings";

const SESSION_CODE_KEY = "mCashierSessionCode";
const POINTS_KEY = "mCashierPoints";
const POINT_NAME_KEY = "mCashierPointName";
const POINT_ADDRESS_KEY = "mCashierPointAddress";
const STATION_DESCRIPTION_KEY = "mCashierStationDescription";
const DISABLED_SERVICES_KEY = "mCashierDisabledServices";

export class MobileCashierSettingsService extends CashierSettingsService {
    private _appCode: string;
    private readonly _sessionCode: string | undefined;
    private _points: ShortPointInfo[] | undefined;
    private _userPointName: string | undefined;
    private _userPointAddress: string | undefined;
    private _disabledServices: EGameService[];
    private _userStationDescription: string | undefined;

    constructor (uuidProvider: UUIDProvider) {
        super(uuidProvider);
        this._appCode = "0f2f4248-6003-4880-a6ee-3d79a0cfd22d";
        this._sessionCode = Cookies.get(SESSION_CODE_KEY);
        if (this._sessionCode) {
            this._userPointAddress = Cookies.get(POINT_ADDRESS_KEY);
            this._userPointName = Cookies.get(POINT_NAME_KEY);
            this._userStationDescription = Cookies.get(STATION_DESCRIPTION_KEY);
            const pointsJson = localStorage.getItem(POINTS_KEY);
            if (pointsJson) {
                this._points = JSON.parse(pointsJson);
            }
            const disabledServicesJson = localStorage.getItem(DISABLED_SERVICES_KEY);
            this._disabledServices = JSON.parse(disabledServicesJson || "[]");
        } else {
            this._disabledServices = [];
        }
    }

    get disabledServices (): EGameService[] {
        return this._disabledServices;
    }

    get usePrintPreview (): boolean {
        return false;
    }

    get appCode () {
        return this._appCode;
    }

    set appCode (value: string) {
        this._appCode = value;
    }

    get partnerCode () {
        return "";
    }

    get useSSL () {
        return true;
    }

    public storeSessionCode (sessionCode: string | undefined): void {
        if (sessionCode) {
            Cookies.set(SESSION_CODE_KEY, sessionCode, { expires: 1 });
        } else {
            Cookies.remove(SESSION_CODE_KEY);
        }
    }

    public storeCashierPoint (name: string | undefined, address: string | undefined, stationDescription: string | undefined) {
        this._userPointName = name;
        this._userPointAddress = address;
        this._userStationDescription = stationDescription;
        if (name) {
            Cookies.set(POINT_NAME_KEY, name, { expires: 1 });
        } else {
            Cookies.remove(POINT_NAME_KEY);
        }
        if (stationDescription) {
            Cookies.set(STATION_DESCRIPTION_KEY, stationDescription, { expires: 1 });
        } else {
            Cookies.remove(STATION_DESCRIPTION_KEY);
        }
        if (address) {
            Cookies.set(POINT_ADDRESS_KEY, address, { expires: 1 });
        } else {
            Cookies.remove(POINT_ADDRESS_KEY);
        }
    }

    get pointName (): string | undefined {
        return this._userPointName;
    }

    get pointAddress (): string | undefined {
        return this._userPointAddress;
    }

    get stationDescription (): string | undefined {
        return this._userStationDescription;
    }

    get sessionCode (): string | undefined {
        return this._sessionCode;
    }

    get points (): ShortPointInfo[] | undefined {
        return this._points;
    }

    public storePoints (points: ShortPointInfo[]) {
        this._points = points;
        const pointsJson = JSON.stringify(points);
        localStorage.setItem(POINTS_KEY, pointsJson);
    }

    storeDisabledServices (disabledServices: EGameService[]) {
        this._disabledServices = disabledServices;
        localStorage.setItem(DISABLED_SERVICES_KEY, JSON.stringify(disabledServices));
    }

    protected getDefaultRestAddress () {
        return "www.dev.tubetgol.com";
    }
}
