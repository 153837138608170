import { ModuleTree, Store, StoreOptions } from "vuex";
import { ThemeClass } from "@sportaq/common/enums/theme";
import AbstractStore from "@sportaq/vuex/abstract-store";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { COUNTRY_ID_PERU } from "@sportaq/model/consts/countries";

export interface UserSharedInfo {
    userNumber: string | undefined;
    countryId: number;
    primaryAccountCurrencyId?: number;
}

export const DEFAULT_USER_SHARED_INFO: UserSharedInfo = {
    userNumber: undefined,
    countryId: COUNTRY_ID_PERU
};

export interface RootState {
    theme: ThemeClass;
    additionalThemes: ThemeClass[];
    userSharedInfo: UserSharedInfo;
    userCountryIdByIP?: number;
}

enum Getters {
    GET_THEME = "getTheme",
    GET_ADDITIONAL_THEMES = "GET_ADDITIONAL_THEMES",
    GET_USER_SHARED_INFO = "getUserSharedInfo"
}

export const ROOT_STORE_SET_USER_SHARED_INFO_MUTATION = "setUserSharedInfo";

enum Mutations {
    SET_THEME = "setTheme",
    ADD_ADDITIONAL_THEME = "addAdditionalTheme",
    REMOVE_ADDITIONAL_THEME = "removeAdditionalTheme",
    SET_USER_SHARED_INFO = "setUserSharedInfo",
    SET_USER_COUNTRY_ID_BY_IP = "setUserCountryIdByIP"
}

export function createRootStoreOptions (modules: ModuleTree<RootState>): StoreOptions<RootState> {
    return {
        state: {
            theme: APP_TYPE.value === EApplicationType.betgol ? ThemeClass.Default : ThemeClass.Light,
            additionalThemes: [],
            userSharedInfo: DEFAULT_USER_SHARED_INFO
        },
        getters: {
            [Getters.GET_THEME]: state => state.theme,
            [Getters.GET_ADDITIONAL_THEMES]: state => state.additionalThemes,
            [Getters.GET_USER_SHARED_INFO]: state => {
                if (state.userSharedInfo.userNumber) {
                    return state.userSharedInfo;
                }
                if (state.userCountryIdByIP) {
                    const result: UserSharedInfo = {
                        userNumber: DEFAULT_USER_SHARED_INFO.userNumber,
                        countryId: state.userCountryIdByIP
                    };
                    return result;
                }
                return DEFAULT_USER_SHARED_INFO;
            }
        },
        mutations: {
            [Mutations.SET_THEME]: (state, theme) => {
                state.theme = theme;
            },
            [Mutations.ADD_ADDITIONAL_THEME]: (state, theme) => {
                state.additionalThemes.push(theme);
            },
            [Mutations.REMOVE_ADDITIONAL_THEME]: (state, theme) => {
                const index = state.additionalThemes.indexOf(theme);
                if (index >= 0) {
                    state.additionalThemes.splice(index, 1);
                }
            },
            [Mutations.SET_USER_SHARED_INFO]: (state, userSharedInfo) => {
                state.userSharedInfo = userSharedInfo;
            },
            [Mutations.SET_USER_COUNTRY_ID_BY_IP]: (state, userCountryIdByIP) => {
                state.userCountryIdByIP = userCountryIdByIP;
            }
        },
        actions: {},
        modules,
        strict: process.env.NODE_ENV !== "production"
    };
}

export interface RootStore {
    store: Store<RootState>;
    theme: ThemeClass;
    userSharedInfo: UserSharedInfo;

    getAdditionalThemes (): ThemeClass[];

    addAdditionalTheme (theme: ThemeClass): void;

    removeAdditionalTheme (theme: ThemeClass): void;

    setUserCountryIdByIP (countryId?: number): void;
}

export class RootStoreImpl extends AbstractStore<RootState, Getters, Mutations, undefined> implements RootStore {
    constructor (root: Store<RootState>) {
        super(root, "");
    }

    set theme (theme: ThemeClass) {
        this.mutate(Mutations.SET_THEME, theme);
    }

    get theme (): ThemeClass {
        return this.get(Getters.GET_THEME);
    }

    get userSharedInfo (): UserSharedInfo {
        return this.get(Getters.GET_USER_SHARED_INFO);
    }

    set userSharedInfo (userSharedInfo: UserSharedInfo) {
        this.mutate(Mutations.SET_USER_SHARED_INFO, userSharedInfo);
    }

    getAdditionalThemes (): ThemeClass[] {
        return [...this.get(Getters.GET_ADDITIONAL_THEMES)];
    }

    addAdditionalTheme (theme: ThemeClass) {
        this.mutate(Mutations.ADD_ADDITIONAL_THEME, theme);
    }

    removeAdditionalTheme (theme: ThemeClass) {
        this.mutate(Mutations.REMOVE_ADDITIONAL_THEME, theme);
    }

    setUserCountryIdByIP (countryId?: number) {
        this.mutate(Mutations.SET_USER_COUNTRY_ID_BY_IP, countryId);
    }
}

class RootStoreByPackageHolder {
    rootStoreByPackage!: RootStore;
}

export const rootStoreByPackageHolder = new RootStoreByPackageHolder();

export function useRootStore (): RootStore {
    return rootStoreByPackageHolder.rootStoreByPackage;
}
