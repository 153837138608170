import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { BaseCardRequest } from "@sportaq/services/rest/messages/bet-slip/base/base-card-request";

export class QSt103MonitoringMessageHandler extends BaseCardRequest {
    protected readonly requestType: string = "Q.ST.10.3";

    constructor (
        readonly startTime: string,
        readonly limitCardCount: number,
        readonly startCardId: number,
        readonly locale: string,
        readonly pointFilter: number[],
        maxCoef: number
    ) {
        super(locale, maxCoef);
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const stakeFilterElement = request.addChild(filterElement, "StakeFilter", {
            logic: "AND"
        });
        const pointFilterElement = request.addChild(filterElement, "PointFilter");
        const pointListElement = request.addChild(pointFilterElement, "PointList");
        for (const pointId of this.pointFilter) {
            request.addChildWithValue(pointListElement, "PointId", pointId.toString());
        }
        if (this.startTime) {
            const stakePeriodElement = request.addChild(stakeFilterElement, "StakePeriod");
            request.addChildWithValue(stakePeriodElement, "StartTime", this.startTime);
        }
        request.addChildWithValue(stakeFilterElement, "MinSum", "0");
        const stakeIntervalElement = request.addChild(stakeFilterElement, "StakeInterval");
        request.addChildWithValue(stakeIntervalElement, "LimitCardCount", this.limitCardCount.toString());
        request.addChildWithValue(stakeIntervalElement, "StartCardId", this.startCardId.toString());
        this.appendSelector(request, actionElement);
    }
}
