import { BalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { onMounted, onUnmounted } from "vue";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { RestService } from "@sportaq/services/rest/rest-service";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { UserBalance } from "@sportaq/model/common/user-balance";
import { useMiscellaneousParamsProvider } from "@sportaq/services/index";
import { BalanceUpdate } from "@sportaq/vue/components/common/balance-component/common/balance-update-functions";

export enum EBalanceType {
    regular, casino
}

export interface BalanceUpdateParams {
    canSwitchAccounts: boolean;
    balanceUpdateCallBack?: (balance: UserBalance) => void;
    isLoggedIn?: () => boolean;
    dontUpdateBalanceOnMount?: boolean;
}

export function useBalanceUpdate (restService: RestService, balanceStore: BalanceStore, settingsService: BaseSettingsService, params: BalanceUpdateParams) {
    // eslint-disable-next-line
    let timerId: any;

    const paramsProvider = useMiscellaneousParamsProvider();

    async function refreshBalance () {
        return BalanceUpdate.refreshBalance(restService, balanceStore, settingsService, params);
    }

    onMounted(async () => {
        if (!params.dontUpdateBalanceOnMount) {
            await refreshBalance();
        }
        eventBus.on(Events.REFRESH_BALANCE, refreshBalance);
        timerId = setInterval(() => {
            eventBus.emit(Events.REFRESH_BALANCE);
        }, paramsProvider.balanceRefreshTimeout);
    });

    onUnmounted(() => {
        eventBus.off(Events.REFRESH_BALANCE, refreshBalance);
        clearInterval(timerId);
    });
    return refreshBalance;
}
