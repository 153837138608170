import { computed, PropType, SetupContext } from "vue";
import { HorseEntry, useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { HorseItemStatus } from "@sportaq/model/cashier/wager-container";

const CLICK_EVENT = "click";
const DBL_CLICK_EVENT = "dblclick";

export default {
    props: {
        entry: {
            required: true,
            type: Object as PropType<HorseEntry>
        }
    },
    emits: [CLICK_EVENT, DBL_CLICK_EVENT],
    name: "RaceEntryRow",
    setup (props:{entry: HorseEntry}, context: SetupContext) {
        const store = useCashierStore();
        const pointName = computed(() => {
            const point = store.getPoints().find(p => p.id === props.entry.value.pointId);
            if (point) {
                return point.name;
            }
            return "";
        });

        function click () {
            context.emit(CLICK_EVENT);
        }

        function dblClick () {
            context.emit(DBL_CLICK_EVENT);
        }

        return {
            pointName,
            HorseItemStatus,
            click,
            dblClick
        };
    }
};
