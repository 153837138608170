import { useMainViewCommon } from "@sportaq/vue/views/mobile-cashier/main/main-view-common";
import AppMenuBar from "@/components/layout/app-menu-bar/AppMenuBar.vue";
import AppBottomBar from "@/components/layout/app-bottom-bar/AppBottomBar.vue";
import SaleVoucherDialog from "@/components/sale-voucher-dialog/SaleVoucherDialog.vue";
import BuyVoucherDialog from "@/components/buy-voucher-dialog/BuyVoucherDialog.vue";
import CardsState from "@/components/cards-state/CardsState.vue";
import WinPaymentDialog from "@/components/win-payment-dialog/WinPaymentDialog.vue";
import RefillAccountDialog from "@/components/refill-account-dialog/RefillAccountDialog.vue";
import AddExpressAccountDialog from "@/components/add-express-account-dialog/AddExpressAccountDialog.vue";
import CashWithdrawalDialog from "@/components/cash-withdrawal/CashWithdrawalDialog.vue";
import ChangePasswordDialog from "@/components/change-password/ChangePasswordDialog.vue";
import ShareDialog from "@/components/share-dialog/ShareDialog.vue";
import { computed, onMounted, ref } from "vue";
import { Ref } from "@sportaq/common/types/types";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { countries } from "@sportaq/model/consts/countries";
import { setTheme } from "@sportaq/vue/components/common/preferences-dialog/preferences-dialog-common";
import { resolveLanguage } from "@sportaq/i18n/index";
import { isSportRoute } from "@sportaq/vue/components/web/web-virtual-games-lifecycle";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { LocalizedError, UnsuccessfulRestResponse } from "@sportaq/common/exceptions/localized-errors";
import { appLogger } from "@sportaq/common/utils/logger";
import { showMessageBox, showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { FatalConnectionError } from "@sportaq/services/streaming/streaming-service";
import { SilentError } from "@sportaq/services/rest/rest-service";
import { useI18n } from "vue-i18n";
import { useHttpService, useStreamingService } from "@sportaq/services/index";
import { useRootStore } from "@sportaq/vuex/index";
import { useCashierRestService, useCashierSettings } from "@sportaq/services/cashier";
import { useRoute } from "vue-router";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import MobileBetSlip from "@sportaq/vue/components/mobile-betting/betslip/MobileBetSlip.vue";
import { requireDni } from "@sportaq/vue/components/mobile-cashier/require-dni";
import DniInputDialog from "@/components/dni-input/DniInputDialog.vue";

enum ApplicationStatus {
    CHECKING_UPDATES = 0,
    LOADING = 1,
    START = 2,
    ERROR_STARTING = 3,
}
export default {
    name: "MainView",
    components: {
        AppMenuBar,
        AppBottomBar,
        SaleVoucherDialog,
        BuyVoucherDialog,
        CardsState,
        WinPaymentDialog,
        RefillAccountDialog,
        AddExpressAccountDialog,
        CashWithdrawalDialog,
        ChangePasswordDialog,
        MobileBetSlip,
        ShareDialog,
        DniInputDialog
    },
    setup () {
        const {
            onComponentSelected,
            onDialogClosed,
            currentDialog
        } = useMainViewCommon(true);
        const i18n = useI18n();
        const httpService = useHttpService();
        const restService = useCashierRestService();
        const betSlipStore = useBetSlipStore();
        const settingsService = useCashierSettings();
        const streamingService = useStreamingService();
        const store = useCashierStore();
        const route = useRoute();
        const rootStore = useRootStore();
        const appStatus: Ref<ApplicationStatus> = ref(ApplicationStatus.LOADING);
        const appLoading = computed(() => appStatus.value === ApplicationStatus.LOADING);
        const appStarted = computed(() => appStatus.value === ApplicationStatus.START);
        const isBetSlipShowed = computed(() => betSlipStore.isShowed);
        const { needAuthorization } = requireDni();
        async function startApplication () {
            appStatus.value = ApplicationStatus.LOADING;
            try {
                const locationInfo = await httpService.getUserLocationInfo();
                rootStore.setUserCountryIdByIP(countries.getByCode(locationInfo.country)?.id);
                setTheme(settingsService, rootStore);
                if (isSportRoute(route.path) || route.name === COMMON_ROUTES.CASINO.name || route.name === COMMON_ROUTES.VIRTUAL.name) {
                    await connectToStreamService(rootStore.userSharedInfo.countryId);
                } else {
                    connectToStreamService(rootStore.userSharedInfo.countryId).then();
                }
                appStatus.value = ApplicationStatus.START;
            } catch (e) {
                appStatus.value = ApplicationStatus.ERROR_STARTING;
                if (e instanceof UnsuccessfulRestResponse) {
                    if (e.code.startsWith("4")) {
                        appLogger.logger.error(i18n.t(e.message));
                        showMessageBox(i18n.t(e.message));
                    }
                } else if (e instanceof LocalizedError) {
                    appLogger.logger.error(i18n.t(e.message));
                    showMessageBox(i18n.t(e.message), () => startApplication());
                } else if (e instanceof FatalConnectionError) {
                    appLogger.logger.error(`Unsupported client protocol version. Processing server: ${settingsService.restServerAddress}. Stream server: ${settingsService.getStreamServerAddress(resolveLanguage())}`, e);
                    showMessageBoxEx(false, FatalConnectionError.ERROR_MESSAGE, e.message, () => () => startApplication());
                } else if (!(e instanceof SilentError)) {
                    appLogger.logger.error(`Unexpected error of connect. Processing server: ${settingsService.restServerAddress}, stream server: ${settingsService.getStreamServerAddress(resolveLanguage())}`, e as Error);
                    showMessageBoxEx(false, "The unexpected error of starting application:", (e as Error).message, () => startApplication());
                }
            }
        }

        async function connectToStreamService (countryId: number) {
            await streamingService.connect();
            const country = store.user ? store.user.countryId : countryId;
            await restService.updateCountryFilterFavourites(country);
        }

        onMounted(() => startApplication());

        return {
            onComponentSelected,
            onDialogClosed,
            needAuthorization,
            currentDialog,
            isBetSlipShowed,
            appLoading,
            appStarted
        };
    }
};
