<template>
    <div class="popup wide active" id="popup2-1">
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.settingsForm.caption") }}</div>
            <div class="popup-content">
                <div class="form-line">
                    <label class="label ">{{ $t("common.settings.appCode") }}</label>
                    <input type="text" class="field" v-model="commonSettingsRef.appCode">
                </div>
                <div class="form-line">
                    <label class="label ">{{ $t("common.settings.partnerCode") }}</label>
                    <input type="text" class="field" v-model="commonSettingsRef.partnerCode">
                </div>
                <div class="form-line">
                    <label class="label ">{{ $t("cashier.settingsForm.cashboxName") }}</label>
                    <input type="text" class="field" value="" disabled>
                </div>
                <div class="form-line">
                    <label class="label ">{{ $t("common.settings.address") }}</label>
                    <input type="text" class="field" value="" disabled>
                </div>
                <div class="form-line">
                    <label class="label">{{ $t("cashier.settingsForm.currency") }}</label>
                    <input type="text" class="field" :value="financeParams?.currency" disabled>
                </div>
                <div class="form-line">
                    <label class="label">{{ $t("cashier.settingsForm.minStakeOrdinar") }}</label>
                    <input type="text" class="field" :value="financeParams?.minStakeOrdinar" disabled>
                </div>
                <div class="form-line">
                    <label class="label">{{ $t("cashier.settingsForm.minStakeExpress") }}</label>
                    <input type="text" class="field" :value="financeParams?.minStakeExpress" disabled>
                </div>
                <div class="form-line">
                    <label class="label">{{ $t("cashier.settingsForm.minStakeSystem") }}</label>
                    <input type="text" class="field" :value="financeParams?.minStakeSystem" disabled>
                </div>
                <div class="form-line">
                    <label class="label">{{ $t("common.settings.network.restServerAddress") }}</label>
                    <input type="text" class="field" readonly :value = "commonSettingsRef.restServerAddress">
                </div>
                <div class="form-line">
                    <button type="submit" id="submit-button" class="button button-primary smaller" @click.prevent="submit">{{$t("common.forms.ok")}}</button>
                </div>
                <div class="form-line">
                    <button type="submit" id="cancel-button" class="button button-secondary smaller" @click.prevent="cancel">{{$t("common.forms.cancel")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./settings-dialog-component.ts" />
