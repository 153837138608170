import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";
import { PointSettings } from "@sportaq/model/common/point-settings";
import {
    AbstractPointSettingsMessageHandler
} from "@sportaq/services/rest/messages/system/point-settings-message-handler";
import { Banner } from "@sportaq/model/common/banner";
import { countries } from "@sportaq/model/consts/countries";
import { ESupportedLocale } from "@sportaq/i18n/interfaces/interfaces";

export interface ApplicationStartResult {
    sessionCode: string;
    pointSettings: PointSettings;
    banners: Banner[];
    countryId: number;
    defaultLanguage: ESupportedLocale;
    supportedLanguages: ESupportedLocale[]
}

// @typescript-eslint/no-unused-vars
export class AGe22ApplicationStartMessageHandler extends AbstractPointSettingsMessageHandler<ApplicationStartResult> {
    protected readonly requestType: string = "A.GE.2.2";

    constructor (readonly countryId?: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const countryCode = countries.getById(this.countryId)?.alpha2 ?? "";
        request.addChildWithValue(request.head, "country", countryCode);
        request.addChild(request.body, "action", {
            type: this.requestType
        });
    }

    parseMessageBody (body: Element, head: Element): ApplicationStartResult {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1020") {
            const sessionCode = getRequiredChild(head, "sessioncode");
            return {
                sessionCode: getRequiredValue(sessionCode),
                pointSettings: this.parsePoint(action),
                banners: this.parseBanners(getRequiredChild(getRequiredChild(action, "Point"), "BannerList")),
                countryId: this.parseCountryId(action),
                defaultLanguage: getRequiredValue(getRequiredChild(action, "DefaultLang")) as ESupportedLocale,
                supportedLanguages: getRequiredValue(getRequiredChild(action, "AcceptedListLang")).split(",").map(v => v as ESupportedLocale)
            };
        } else {
            throw new LocalizedError(`errors.mtl.aGe22.code${serverCode}`);
        }
    }
}
