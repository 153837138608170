import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";

export class AST23WinPaymentMessageHandler extends AbstractMessageHandler<boolean> {
    protected readonly requestType: string = "A.ST.2.3";
    constructor (readonly cardId: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChild(actionElement, "PAIDCARD", { cardId: this.cardId.toString() });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): boolean {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "1280") {
            throw new LocalizedError(`errors.mtl.aSt22.code${serverCode}`);
        }
        return true;
    }
}
