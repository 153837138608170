import { SetupContext } from "vue";
import { useCashWithdrawalDialogCommon } from "@sportaq/vue/components/mobile-cashier/cash-withdrawal-dialog-common";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { useI18n } from "vue-i18n";

export default {
    name: "CashWithdrawalDialog",
    emits: [CLOSE_EVENT],
    setup (props: unknown, context: SetupContext) {
        const i18n = useI18n();
        const {
            withdrawalDataRef,
            payCodeRef,
            docTypeRef,
            docNumberRef,

            onMakeWithdrawal,
            onConfirmUser,
            onFindData,
            onClose,
            formatVoucherNumber
        } = useCashWithdrawalDialogCommon(context, onWithdrawalSuccess);

        function onWithdrawalSuccess () {
            showMessageBox(i18n.t("mcashier.cashWithdrawal.success"));
        }

        return {
            withdrawalDataRef,
            payCodeRef,
            docTypeRef,
            docNumberRef,

            onMakeWithdrawal,
            onConfirmUser,
            onFindData,
            onClose,
            formatVoucherNumber
        };
    }
};
