import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ROUTES } from "@/router/router-consts";
import LoginView from "@/views/login-view/LoginView.vue";
import { globalSettingsForRouting } from "@sportaq/services/index";
import { CashierSettingsService } from "@sportaq/services/src/cashier/settings/settings-service";
import MainView from "@/views/main-view/MainView.vue";
import ActiveBetplaces from "@/components/active-betplaces/ActiveBetplaces.vue";
import FinDayView from "@/views/fin-day-view/FinDayView.vue";
import MonitoringView from "@/views/monitoring/MonitoringView.vue";
import NotFound from "@sportaq/vue/views/not-found/NotFound.vue";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import MobileBettingViewLayout from "@/views/betting/mobile-betting-view-layout/MobileBettingViewLayout.vue";
import MobileBettingView from "@/views/betting/mobile-betting-view-layout/mobile-betting-view/MobileBettingView.vue";
import MobileEventDetailsPage from "@sportaq/vue/components/mobile-betting/event-details/MobileEventDetailsPage.vue";
import HorseGameView from "@/views/horse-game-view/HorseGameView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: ROUTES.LOGIN.path,
        name: ROUTES.LOGIN.name,
        component: LoginView
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFound,
        props: {
            code: 404
        }
    },
    {
        name: "home",
        path: "/home",
        redirect: () => {
            return ROUTES.LOGIN.path;
        }
    },
    {
        path: "/",
        component: MainView,
        children: [
            {
                path: ROUTES.BETPLACES.path,
                name: ROUTES.BETPLACES.name,
                component: ActiveBetplaces
            },
            {
                path: ROUTES.MONITORING.path,
                name: ROUTES.MONITORING.name,
                component: MonitoringView
            },
            {
                path: ROUTES.FINDAY.path,
                name: ROUTES.FINDAY.name,
                component: FinDayView
            },
            {
                path: ROUTES.DAILYREPORT.path,
                name: ROUTES.DAILYREPORT.name,
                component: FinDayView,
                props: {
                    isFinDay: false
                }
            },
            {
                path: `/:locale?/:eventType(${COMMON_ROUTES.SPORT.name}|${COMMON_ROUTES.LIVE.name})/:sportType?`,
                component: MobileBettingViewLayout,
                children: [
                    {
                        path: "",
                        component: MobileBettingView
                    },
                    {
                        path: ":positionId(\\d+)",
                        component: MobileEventDetailsPage
                    }
                ],
                meta: {
                    hasTitle: true
                }
            },
            {
                path: "/:locale?" + COMMON_ROUTES.FAVOURITES.path,
                component: MobileBettingViewLayout,
                children: [
                    {
                        path: "",
                        name: COMMON_ROUTES.FAVOURITES.name,
                        component: MobileBettingView
                    }
                ]
            },
            {
                path: COMMON_ROUTES.HORSE.path,
                name: COMMON_ROUTES.HORSE.name,
                component: HorseGameView
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to) => {
    if (to.name === ROUTES.LOGIN.name) {
        return true;
    }
    const cashierSettings = globalSettingsForRouting as CashierSettingsService;
    if (!cashierSettings.pointSettings) {
        return { name: ROUTES.LOGIN.name };
    }
    return true;
});

export default router;
