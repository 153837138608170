import { computed, onMounted, ref, SetupContext } from "vue";
import { useCashierRestService } from "@sportaq/services/cashier";
import { UnpaidCard } from "@sportaq/model/cashier/unpaid-card";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { useI18n } from "vue-i18n";

export function useCardsStateCommon (context: SetupContext) {
    const i18n = useI18n();
    const restService = useCashierRestService();
    const allCards = ref<UnpaidCard[]>();
    const selectedItemId = ref(0);
    const cardsFilter = ref<number>();
    const cards = computed(() => {
        const result: UnpaidCard[] = [];
        let total = 0;
        if (allCards.value) {
            for (const card of allCards.value) {
                if (cardsFilter.value) {
                    if (card.innerCardId !== cardsFilter.value) {
                        continue;
                    }
                }
                if (card.sumPay > 0) {
                    result.push(card);
                    total += card.sumPay;
                }
            }
        }
        return {
            items: result,
            total
        };
    });
    function onClose () {
        context.emit(CLOSE_EVENT);
    }
    onMounted(() => {
        restService.getUnpaidCards()
            .then(r => {
                if (r.length === 0) {
                    showMessageBox(i18n.t("cashier.cardsState.empty"));
                }
                allCards.value = r;
            });
    });
    return {
        cards,
        selectedItemId,
        cardsFilter,
        onClose
    };
}
