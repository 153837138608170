/* eslint quote-props: ["error", "always"] */
export default {
    "brands": {
        "betgol": "BetGol",
        "certabet": "Certabet",
        "support": {
            "betgol": "info{'@'}tubetgol.com",
            "certabet": "info{'@'}certa.bet"
        },
        "site": {
            "betgol": "tubetgol.com",
            "certabet": "certa.bet"
        }
    },
    "routes": {
        "home": "INCIO",
        "sport": "ESPORTE",
        "casino": "AO VIVO",
        "live": "AO VIVO",
        "registration": "REGISTRO",
        "results": "Resultados",
        "virtual": "VIRTUAL",
        "horse": "CORRIDAS",
        "games": "JOGOS"
    },
    "appMenuBar": {
        "deposit": "Depósito",
        "withdrawal": "Saque",
        "verifyBetSlip": "Verifique a sua aposta"
    },
    "common": {
        "tempPassword": "Alterar senha temporária",
        "language": {
            "language": "língua",
            "languageName": "Português"
        },
        "pluralization": {
            "hour": "{hour} h|{hour} h|{hour} h",
            "match": "{count} evento|{count} evento|{count} eventos"
        },
        "days": {
            "tomorrow": "Amanhã",
            "today": "Hoje"
        },
        "update": {
            "checkingForUpdates": "Verificando novas versões ...",
            "updatesAvailable": "Baixando uma nova versão ...\nApós o download, o aplicativo será reiniciado para instalar a nova versão",
            "updateError": "Ocorreu um erro durante a atualização:\n{error}\nReinicie o aplicativo e tente novamente mais tarde.\nSe o erro persistir, entre em contato com o administrador do sistema."
        },
        "settings": {
            "tabs": {
                "generalTab": "Em geral",
                "connectionTab": "Conexão"
            },
            "appCode": "Código do programa:",
            "partnerCode": "Código de parceiro:",
            "address": "Endereço:",
            "network": {
                "restServerAddress": "Processando IP do servidor:",
                "streamServerAddress": "IP do servidor de dados:",
                "useSSL": "Use uma conexão SSL segura"
            },
            "logo": "Logotipo (formato BASE64):"
        },
        "forms": {
            "ok": "Ок",
            "cancel": "Cancelar",
            "continue": "Continuar",
            "close": "Cerrar",
            "open": "Abrir",
            "yes": "Sím",
            "no": "Não",
            "print": "Imprimir",
            "clear": "Excluir",
            "copy": "Copiar",
            "loadMore": "Mais",
            "validation": {
                "required": "O campo deve ser preenchido",
                "email": "O e-mail especificado não está correto",
                "min": "O comprimento mínimo do campo e {param} de caracteres.",
                "confirmed": "Senha incorreta",
                "invalidFormat": "Formato incorreto",
                "birthday": "Você deve ter mais de 18 anos",
                "invalidCharacters": "Símbolos + - , ; & são inválidos"
            }
        },
        "blocked": {
            "btn": "Detalhes",
            "caption": "A conta é temporariamente bloqueada.",
            "detailsMessage": "A conta fica temporariamente bloqueada até a <span id='time-to'>{date}</span> que você solicitou. As apostas em qualquer esporte estão suspensas, assim como as recargas de conta.",
            "regulatorBlocked": "A conta é bloqueada conforme exigido pelo regulador."
        },
        "games": {
            "tabs": {
                "all": "Todos",
                "popular": "Populares",
                "new": "Novos",
                "favourites": "Favoritos",
                "football": "Futebol",
                "tennis": "Tênis ",
                "horse": "Corridas de cavalos",
                "dog": "Corridas de cães",
                "moto": "Motos",
                "fighting": "Combate",
                "providers": "Provedores",
                "casual": "Casuales",
                "slots": "Slots",
                "card": "Jogos de mesa",
                "lottery": "Loteria",
                "roulette": "Ruleta",
                "poker": "Poker",
                "jackpot": "Jackpot",
                "live": "Ao vivo",
                "blackjack": "Blackjack",
                "craps": "Craps",
                "buyfeature": "Buy feature",
                "megaways": "Megaways",
                "crash": "Crash",
                "fishing": "Fishing",
                "scratch": "Scratch",
                "basketball": "Basketball",
                "keno": "Keno",
                "bingo": "Bingo",
                "hockey": "Hóquei"
            },
            "buttons": {
                "play": "JUGAR",
                "demo": "DEMO"
            },
            "zeroBalance": "Fundos insuficientes na conta do cassino"
        }
    },
    "appTitle": {
        "brand": {
            "betgol": "Apostas Esportivas e Cassino Online | Betgol",
            "oncebet": "Apostas Esportivas e Cassino Online | Oncebet",
            "latino": "Apuestas Deportivas",
            "ligabet": "Apuestas Deportivas",
            "columbiaApuesta": "Apuestas",
            "certabet": "Apostas Esportivas e Cassino"
        },
        "cashier": "Cashier"
    },
    "homePage": {
        "widgets": {
            "coupon": {
                "coupons": "CUPONES",
                "today": "Hoje",
                "totalGoals": "Total de Gols",
                "bothToScore": "Ambos Marcarem",
                "weekend": "Fim de Semana",
                "mostPopular": "Populares"
            },
            "live": "AO VIVO",
            "starting-soon": "EM BREVE"
        }
    },
    "betting": {
        "depositAccount": {
            "success": "Depósito efetuado com sucesso!"
        },
        "countryMenu": {
            "all": "Todos",
            "hot": "Destaqados"
        },
        "dniInputForm": {
            "caption": "Insira o DNI",
            "ceCaption": "Insira o CE",
            "switchToCE": "Mudar para CE",
            "switchToDNI": "Mudar para DNI"
        },
        "codeInputForm": {
            "submit": "Verificar",
            "close": "Fechar",
            "caption": "Verificar o código "
        },
        "withdrawalForm": {
            "caption": "Insira o valor da retirada     ",
            "cancel": "Cancelar",
            "ticket": "Retirar",
            "footer": "Mantenha o recibo fora do alcance de pessoas não autorizadas.",
            "success": {
                "text": "A retirada no valor de <b>{amount} {currency}</b> foi bem-sucedida. <br> N código <b>{code}</b>",
                "caption": "Retirada"
            }
        },
        "horse": {
            "ticket": {
                "serial": "Serial",
                "date": "Date",
                "horse": "Horse",
                "game": "Game",
                "amount": "Amount",
                "status": "Status",
                "total": "Total:",
                "outcomes": {
                    "w": "WIN",
                    "p": "PLACE",
                    "s": "SHOW",
                    "f": "COTA FIXA",
                    "fp": "COTA FIXA PLACE",
                    "ex": "EXACTO",
                    "tr": "TRIFECTA",
                    "su": "SUPERFECTA",
                    "Pk2": "PICK 2",
                    "Pk3": "PICK 3"
                }
            }
        },
        "couponVerification": {
            "caption": "Verifique a sua aposta",
            "coupon": "Cupom de Aposta",
            "bet": "Aposta",
            "date": "Data",
            "event": "Evento",
            "coef": "Quota",
            "amount": "Aposta",
            "payment": "Pagamento",
            "info": "Info",
            "cardNotFound": "Cupom não encontrado",
            "paymentTotal": "Pagamento é{total} {curr}",
            "transferButton": "Transferir para saldo",
            "transfer": "Transferir para saldo?",
            "success": "Transferência bem sucedida"
        },
        "navigationSideBar": {
            "league": "Ligas",
            "countries": "A-Z",
            "search": "Búsqueda",
            "rules": "Reglas",
            "faq": "Preguntas frecuentes",
            "reload": "Refrescar",
            "time": "Tempo"
        },
        "searchDialog": {
            "placeholder": "Procurar",
            "emptyResults": "Nenhum evento relevante encontrado"
        },
        "mobile": {
            "favourites": "Favoritos",
            "popular": "Populares",
            "search": "Procurar",
            "virtual": "Virtual",
            "bonus": "Bônus"
        },
        "event": {
            "scoreboardTitle": {
                "events": "Eventos",
                "menu": {
                    "item1x2": "Resultado da partida",
                    "item12": "Resultado da partida",
                    "item1x12x2": "Chance dobrada",
                    "itemHandicap": "Ganha com handicap",
                    "itemTotalGoals": "Total golos",
                    "itemLocalTotalGoals": "Time da casa - Total golos",
                    "itemVisitTotalGoals": "Time visitante - Total golos",
                    "itemBothWillScore": "Ambos os times marcam",
                    "itemTotalPoints": "Total de pontos",
                    "itemLocalTotalPoints": "Time da casa - Total de pontos",
                    "itemVisitTotalPoints": "Time visitante - Total de pontos",
                    "itemTotalGames": "Total de jogos",
                    "itemLocalTotalGames": "Time da casa - Total de jogos",
                    "itemVisitTotalGames": "Time visitante - Total de jogos",
                    "itemTotalRuns": "Total de pontos na corrida",
                    "itemTotal": "Total",
                    "itemMatchTotal": "Total",
                    "item1x2xFavourites": "Resultado da partida",
                    "itemTotalFavourites": "Total",
                    "itemHandicapFavourites": "Ganha com handicap"
                }
            },
            "scoreboardLine": {
                "startDate": "Hoje"
            },
            "periods": {
                "sport1": {
                    "period1": "1º tempo",
                    "period2": "2º tempo"
                },
                "sport2": {
                    "period1": "1º Quarto",
                    "period2": "2º Quarto",
                    "period3": "3º Quarto",
                    "period4": "4º Quarto",
                    "period100": "1ra metade",
                    "period101": "2da metade"
                },
                "sport3": {
                    "period1": "1º periodo",
                    "period2": "2º periodo",
                    "period3": "3º periodo"
                },
                "sport4": {
                    "period1": "1 set",
                    "period2": "2 set",
                    "period3": "3 set",
                    "period4": "4 set",
                    "period5": "5 set"
                },
                "sport6": {
                    "period1": "1 set",
                    "period2": "2 set",
                    "period3": "3 set",
                    "period4": "4 set",
                    "period5": "5 set"
                },
                "sport7": {
                    "period1": "1ª entrada",
                    "period2": "2ª entrada",
                    "period3": "3ª entrada",
                    "period4": "4ª entrada",
                    "period5": "5ª entrada",
                    "period6": "6ª entrada",
                    "period7": "7ª entrada",
                    "period8": "8ª entrada",
                    "period9": "9ª entrada",
                    "period10": "10ª entrada",
                    "period11": "11ª entrada",
                    "period12": "12ª entrada",
                    "period13": "13ª entrada",
                    "period100": "5 primeiras entradas"
                },
                "sport9": {
                    "period1": "1º quarto",
                    "period2": "2º quarto",
                    "period3": "3º quarto",
                    "period4": "4º quarto",
                    "period100": "Meio tempo"
                },
                "sport28": {
                    "period1": "1º período",
                    "period2": "2º período"
                },
                "sport44": {
                    "period5": "Set 5",
                    "period4": "Set 4",
                    "period3": "Set 3",
                    "period2": "Set 2",
                    "period1": "Set 1"
                }
            },
            "groupEvents": {
                "participant": "Team",
                "leaveTheGroup": "Vai classificar",
                "stayTheGroup": "Não vai classificar",
                "winner": "Vencedor",
                "placeInGroup": "{p1} Lugar no grupo",
                "finishTheRaceYes": "Terminar a corrida - Sim",
                "finishTheRaceNo": "Terminar a corrida - Não",
                "fastestLap": "Volta mais rápida",
                "firstPitstop": "1\" Pitstop",
                "lastPlace": "Último Puesto",
                "willQualifyToFinal": "Classificado para Final",
                "willQualifyToFinal1_2": "Classificado para 1/2 da Final",
                "willQualifyToFinal1_4": "Classificado para 1/4 da Final"
            }
        },
        "betSlip": {
            "title": "Cupom de aposta",
            "shortTitle": "Cupom",
            "acceptWithAllQuotations": "Aceitar todas as mudanças de cotas",
            "acceptOnlyWithQuotationsAreRaised": "Aceitar quando as cotas aumentam",
            "oneClickBet": "Aposta com um clique",
            "simple": "Simples",
            "combined": "Combinação\\",
            "maxWin": "Pagamento potencial\\",
            "ordinar": "Simples",
            "express": "Múltipla",
            "expressWithCoef": "Múltipla ({coef})",
            "system": "Sistema ({base}/{limit})",
            "variants": "Apostas:",
            "columns": {
                "event": "Evento",
                "sum": "Valor"
            },
            "buttons": {
                "clear": "Remover",
                "continue": "Fazer aposta",
                "accept": "Aceitar",
                "verify": "Verificar"
            },
            "confirmCoefDiffs": "As probabilidades ou disponibilidade de seleções em seu Cupom de aposta foram alteradas.",
            "acceptButton": "Aceitar",
            "common": {
                "total": "Montante da aposta:",
                "maxWin": "Pagamento potencial",
                "userNumber": "Número de usuario:"
            },
            "print": {
                "card": "Cupom",
                "code": "Código",
                "time": "Tempo:",
                "endTime": "Válido até:",
                "confirm": "Ao fazer uma aposta, você confirma que está familiarizado com as regras."
            },
            "dialogBetAccepted": {
                "caption": "Aposta aceita",
                "code": "Código",
                "columns": {
                    "date": "Data, Hora",
                    "event": "Evento",
                    "count": "Quantidade",
                    "coef": "Quota",
                    "amount": "Valor",
                    "maxWin": "Pagamento potencial",
                    "variants": "{variants} apostas"
                },
                "timer": "Você tem {timer} segundos para anular a aposta.",
                "copy": "Copiar",
                "cancel": "Cancelar"
            },
            "dialogBetVerification": {
                "caption": "Verifique os dados do seu aposta"
            },
            "numberInputDialog": {
                "caption": "Insira o valor da",
                "confirmButton": "OK"
            }
        },
        "registration": {
            "title": "Regístra-se",
            "tabs": {
                "logoTab": "Logotipo"
            },
            "fields": {
                "pointName": "Nome do salão:",
                "city": "Cidade:",
                "countryLabel": "Pais:",
                "phone": "Número de telefone:"
            }
        }
    },
    "cashier": {
        "loginForm": {
            "caption": "Entrar",
            "user": "Nome do usuário",
            "password": "Senha",
            "parameters": "Opções",
            "settings": "Definições",
            "registration": "Regístra-se",
            "settingsNotFilled": "Configurações do aplicativo não preenchidas",
            "serverAddressNotFilled": "Servidor de la aplicación no completado",
            "noAccount": "A moeda da conta não existe"
        },
        "registrationForm": {
            "caption": "Nome do usuário",
            "number": "#",
            "nameGroup": "Nome do usuário",
            "lastName": "Sobrenome:*",
            "firstName": "Nome:*",
            "login": "Entrar:*",
            "requiredFields": "* - Os campos obrigatórios",
            "contactsGroup": "Contatos",
            "address": "Endereço:",
            "phone": "Número de telefone:*",
            "email": "E-mail:*",
            "passwordGroup": "Senha",
            "password": "Senha:*",
            "confirmPassword": "Confirmação da senha:*",
            "partnerGuid": "Сódigo de parceiro:*"
        },
        "settingsForm": {
            "caption": "Opções",
            "locationTab": "Localização",
            "optionsTab": "Opções",
            "requisites": "Requisitos",
            "remoteAccess": "Configurações de acesso remoto",
            "partnerCode": "Código de parceiro:",
            "cashboxName": "Nome do cashier:",
            "finance": "Finança",
            "currency": "Moeda:",
            "minStakeOrdinar": "Montante mínimo da aposta (simples):",
            "minStakeExpress": "Montante mínimo da aposta (Multiples):",
            "minStakeSystem": "Montante mínimo da aposta (Sistema):",
            "proxy": "Proxy server:",
            "useProxy": "Use um servidor proxy",
            "proxyAddress": "Direção IP:",
            "proxyPort": "Port:",
            "captionImage": "Arquivo de imagem para cabeçalhos",
            "multiscreen": "Multiscreen Live",
            "multiscreenFile": "Arquivo Exe:",
            "hotkey": "Tecla de atalho Ctrl+",
            "directOutput": "Impressão direta",
            "cashbox": "Cashier",
            "voucher": "Ticket",
            "express": "Contas Express",
            "terminal": "Conta de terminal",
            "checkFormat": "O formato de impressão:",
            "leftPad": "Margem esquerda:",
            "barcodeScanner": "Leitor de códigos de barra",
            "barcodeScannerPort": "Port:",
            "depositMenu": "Item de menu de depósito"
        },
        "finDayForm": {
            "caption": "Dia financeiro - reconciliação de caixa"
        },
        "dailyReportForm": {
            "caption": "Relatório diário",
            "totalGenerationVoucher": "Valor total de Tickets",
            "totalCashCheque": "Tickets pagos",
            "totalBalanceNulling": "Total de retiradas do saldo do BP",
            "totalBalanceDeposit": "Cuantidade de depósitos",
            "totalBalanceWithdowal": "Quantidade retiradas",
            "totalStake": "Apostas totais (caixa)",
            "totalPaid": "Pagamentos totais (caixa)",
            "totalStakeRace": "Apostas Corridas",
            "totalPayRace": "Prêmios Corridas",
            "totalCancelStakeBP": "Cancelado (BP)",
            "totalPaidTerminal": "Pagamentos totais (terminal)",
            "total": "Total",
            "closeDay": "Fechar o dia financeiro",
            "success": "Dia financeiro encerrado com sucesso",
            "totalBalanceDepositBetPlace": "Recarga direta BP",
            "refresh": "recarregar"
        },
        "userConfirmationForm": {
            "caption": "Pesquisa de usuário",
            "accountId": "Número de conta:",
            "document": "Documento:",
            "phone": "telefone:",
            "findButton": "Pesquisa de usuário",
            "personalData": "Informações pessoais",
            "personalCode": "Idintificador privado:",
            "name": "Nome:",
            "birthdate": "Data de nascimento:",
            "lastName": "Sobrenome:",
            "docs": "Documentos do usuário:",
            "verify": "Verificação do usuário:",
            "confirmButton": "Confirme os dados",
            "verifiedYes": "Sim",
            "verifiedNo": "No",
            "success": "Completado com sucesso"
        },
        "refillAccountForm": {
            "caption": "Recarga direta de conta",
            "user": "Usuário:",
            "findUser": "Pesquisa de usuário",
            "accountNumber": "Número da conta, login ou e-mail:",
            "phone": "Número de telefone: +",
            "document": "Documento:",
            "checkButton": "Verificar",
            "accountRefill": "Depositar",
            "amount": "Valor:",
            "getBonus": "Ganhe bônus",
            "refillButton": "Depositar",
            "insufficientFunds": "Fundos insuficientes",
            "empty": "O campo deve ser preenchido",
            "blocked": "O usuário é bloqueado até:",
            "table": {
                "time": "Encontro",
                "num": "Número",
                "name": "Nome",
                "amount": "Valor"
            },
            "print": {
                "accountNumber": "Número da conta",
                "amount": "Saldo"
            }
        },
        "winPaymentForm": {
            "caption": "Pagamento de ganhos",
            "cardCode": "Código de aposta:",
            "find": "Procurar",
            "doPayment": "Pagar",
            "success": "O pagamento foi feito com sucesso",
            "notfound": "Cupom não encontrado",
            "expired": "O período de pagamento já passou.",
            "alreadyPaid": "Cupom já pago"
        },
        "cashWithdrawalForm": {
            "status0": "Pendente",
            "status1": "Aprovado",
            "status2": "Pago",
            "status3": "Rejeitado",
            "status4": "Cancelado pelo usuário",
            "status5": "Desconhecido",
            "caption": "Retirada da conta",
            "payCode": "Código de pagamento:",
            "checkWithdrawalButton": "Verifique o código de pagamento",
            "withdrawalCaption": "Detalhes do pedido",
            "withdrawalNum": "Número do pedido:",
            "amount": "Valor",
            "crdate": "Criada:",
            "expdate": "Válido até:",
            "cashbox": "Cashier:",
            "status": "Status da solicitação:",
            "document": "Documento apresentado",
            "documentType": "Tipo de documento",
            "documentTypes": {
                "idcard": "ID / DNI",
                "passport": "Passaporte",
                "driver": "Carteira de motorista"
            },
            "docNumber": "Número do documento:",
            "makeWithdrawalButton": "Verificar retirada",
            "personalData": "Dados pessoais",
            "userNumber": "Código pessoal:",
            "firstName": "Nome:",
            "lastName": "Sobrenome:",
            "birthday": "Data de nascimento:",
            "userDocuments": "Documentos do usuário:",
            "verify": "Verificação do usuário:",
            "confirmUserButton": "Verifique os dados",
            "print": {
                "caption": "Retirada da conta",
                "sum": "Valor:",
                "account": "Número de conta"
            },
            "notFound": "Pedido no encontrado"
        },
        "saleVoucherForm": {
            "caption": "Ticket - venda",
            "amount": "Valor:",
            "saleButton": "Pagar",
            "table": {
                "time": "Encontro",
                "num": "Número",
                "amount": "Valor"
            },
            "print": {
                "time": "Tempo de operação:",
                "voucher": "Ticket",
                "amount": "Montante de pagamento:",
                "until": "Válido até:"
            }
        },
        "buyVoucherForm": {
            "caption": "Ticket - Compra",
            "voucher": "Ticket №:",
            "buyButton": "Compra",
            "success": "A operação foi bem-sucedida, o valor é {amount}",
            "notfound": "Bilhete não encontrado"
        },
        "menu": {
            "monitoring": "Monitoramento",
            "payments": "Pagamentos",
            "vouchers": "Ticket",
            "account": "Conta de jogo",
            "finance": "Finança",
            "service": "Serviço",
            "sport": "ESPORTE",
            "live": "AO VIVO",
            "state": "No pagados",
            "payment": "Pagamento de ganhos",
            "withdrawal": "Retiradas",
            "refill": "Depósito",
            "userFind": "Pesquisar usuário",
            "profile": "Parâmetros de registro",
            "settings": "Opções",
            "salevoucher": "Venda",
            "buyvoucher": "Compra",
            "dailyreport": "Relatório diário",
            "finday": "Dia financeiro",
            "betplaces": "Pontos",
            "express": "Conta expressa"
        },
        "monitoring": {
            "copyError": "Erro de cópia",
            "table": {
                "id": "Id",
                "time": "Tempo",
                "position": "Objeto / Posição",
                "quotation": "Fonte / quota",
                "coef": "Quota",
                "stake": "Quantia",
                "status": "Status",
                "pay": "Pagamentos",
                "income": "Renda",
                "print": "Imprimir",
                "number": "#",
                "iscancel": "Cancelado",
                "isaccepted": "Aceitaram",
                "iscalculated": "Calculada",
                "ispaid": "Pago",
                "object": "Número interno {InnerCardId} Código de pagamento: {payCode}",
                "containerType1": "Simple",
                "containerType3": "Sistema ({express} / {itemCount})",
                "containerType2": "Múltipla",
                "itemStatus0": "Pendente",
                "itemStatus1": "Ganhos",
                "itemStatus2": "Nenhum prêmio",
                "itemStatus3": "Reembolso",
                "itemStatus4": "Desconhecido",
                "itemStatus5": "Anulada",
                "horse": {
                    "statusG": "Ganhos",
                    "statusJ": "Pendente",
                    "statusP": "Nenhum prêmio",
                    "statusD": "Reembolso",
                    "statusA": "Void"
                }
            },
            "operations": {
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH": "Recarga de conta",
                "PAY_OPERATION_WITHDRAWAL_CASH": "Retirada de conta",
                "PAY_OPERATION_VOUCHER_GENERATION_CASH": "Venda de ticket",
                "PAY_OPERATION_VOUCHER_REDEMPTION_CASH": "Compra de ticket",
                "PAY_OPERATION_VOUCHER_REDEMPTION_TRM": "Compra de ticket",
                "PAY_OPERATION_VOUCHER_GENERATION_BP": "Venda de ticket de Betplace",
                "PAY_OPERATION_FOR_RACE_WINPAID_BP": "Pagamento do prémio Hípicas BP",
                "PAY_OPERATION_FOR_RACE_WINPAID_CASH": "Pagamento do prémio Hípicas",
                "PAY_OPERATION_WINPAID": "Pagamento de ganhos",
                "PAY_OPERATION_INCREASE_BALANCE_BP": "Recarga de saldo Betplace",
                "PAY_OPERATION_NULLIFICATION_POINT_BALANCE": "Zerando de saldo Betplace",
                "PAY_OPERATION_FOR_RACE_WINPAID": "Ganho na Corridas",
                "PAY_OPERATION_FOR_RACE_CARD": "Aposta na Corridas",
                "PAY_OPERATION_INCREASE_BONUS_ACCOUNT": "Depósito na conta de bônus (Esporte)",
                "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT": "Depósito na conta de bônus (Cassino)",
                "PAY_OPERATION_CANCEL_BONUS_ACCOUNT": "Cancelamento de bônus (Esporte)",
                "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT": "Cancelamento do bônus (Cassino)"
            },
            "search": {
                "caption": "Procurar",
                "inputLabel": "Digite o número"
            },
            "filter": {
                "cashier": "Cashier",
                "allbp": "Todos bp"
            },
            "deleteSuccess": "Aposta eliminada com sucesso",
            "deleteComfirm": "Eliminar aposta?",
            "payCode": "Código de pagamento:"
        },
        "cardsState": {
            "innerCardId": "Número",
            "cardFilter": "Aposta:",
            "sumPay": "Valor",
            "status": "Status",
            "caption": "No pagados",
            "unpaidSum": "Montante não pago: {sumPay}",
            "unpaidRadio": "Ganhos não pagos",
            "paidRadio": "Ganhos pagos",
            "loseRadio": "Apostas perdidas",
            "allRadio": "Apostas calculadas",
            "footer": "Aposta:",
            "payCode": "Código de pagamento",
            "empty": "Não há cartões não pagos."
        },
        "updateProfile": {
            "success": "Edição de perfil feita corretamente"
        },
        "activeBetplaces": {
            "deposit": "Depositar",
            "depositDialog": {
                "caption": "Recarregue a conta"
            },
            "depositsuccess": "Conta recarregada com sucesso",
            "depositsuccesscaption": "Recarregue a conta",
            "nullify": "Restabeleça o saldo",
            "nullifyConfirm": "Você realmente deseja redefinir o saldo no {betplace}?",
            "nullifysuccess": "Saldo é zero",
            "nullifysuccesscaption": "Restabeleça o saldo",
            "sum": "Saldo"
        },
        "expressAccount": {
            "caption": "Criação de conta expressa",
            "firstName": "Nome: *",
            "lastName": "Sobrenome: *",
            "orderSum": "Valor: *",
            "document": "Documento: *",
            "phone": "Número de telefone:",
            "print": {
                "number": "Número da conta expressa:",
                "balance": "Saldo da conta:",
                "siteCaption": "Dados de login:",
                "login": "Nome do usuário:",
                "password": "Senha:",
                "warning": "Atenção! Você deve alterar sua senha imediatamente após o login!"
            }
        }
    },
    "errors": {
        "mtl": {
            "serverError": "Erro de servidor inesperado",
            "status": {
                "code201": "Uma solicitação incorreta foi enviada ao servidor",
                "code202": "Erro de definição de solicitação de servidor",
                "code203": "Erro de definição de solicitação de servidor: a solicitação não corresponde ao protocolo",
                "code101": "A solicitação não pode ser processada neste momento. Tente um pouco mais tarde",
                "code102": "Erro ao processar a solicitação no servidor",
                "code401": "Código de ponto não especificado na solicitação",
                "code402": "Ponto não ativado",
                "code403": "O ponto foi bloqueado"
            },
            "aUs11": {
                "code1103": "Número mínimo de caracteres de la contraseña es 8",
                "code1104": "Número mínimo de caracteres de la login es 8",
                "code1106": "Já existe usuário com este endereço de e-mail",
                "code1107": "Já existe um usuário com este login",
                "code1112": "Data de nascimento incorrecta",
                "code1113": "O código de usuário pessoal especificado já foi usado",
                "code1114": "O código de referência não está correto",
                "code1115": "Já existe usuário com este endereço de telefone",
                "code1119": "Parceiro não encontrado"
            },
            "aUs16": {
                "code1172": "A senha atual está incorreta"
            },
            "aUs12": {
                "code1133": "A conta foi bloqueada após 3 tentativas incorretas de senha. Para desbloquear, use a função de recuperação de senha.",
                "code1122": "Número de usuário e senha incorretos",
                "code1123": "O usuário está bloqueado",
                "code1124": "O usuário não pode fazer login na estação especificada",
                "code1125": "A moeda da conta não existe",
                "code1126": "O usuário tem várias contas",
                "code1127": "Incompatibilidade de moeda",
                "code1161": "E-mail ou número de telefone incorreto",
                "code1162": "O usuário não está ativado"
            },
            "aUs22": {
                "code1703": "Telefone já cadastrado"
            },
            "qRs12": {
                "code1411": "Cupom não foi encontrado"
            },
            "aCs11": {
                "code8101": "Sessão não auth",
                "code8132": "O jogo não está disponível",
                "code8136": "Recarregue o saldo para ativar o aplicativo"
            },
            "aCs12": {
                "code8101": "Sessão não auth",
                "code8121": "Sessão não auth"
            },
            "aPi55": {
                "code2601": "Saldos não combinam",
                "code2604": "Incompatibilidade de moeda"
            },
            "aUs25": {
                "code1121": "O usuário atual não tem direitos para editar informações"
            },
            "aPi54": {
                "code1001": "O usuário não está logado"
            },
            "qSt100": {
                "code1311": "o objeto especificado não existe",
                "code1312": "não há apostas que correspondam ao parâmetro especificado",
                "code1313": "o usuário não está definido na sessão",
                "code1314": "o usuário não tem permissão para solicitar uma lista de apostas"
            },
            "aSt13": {
                "code1221": "A aposta não foi encontrada no banco de dados",
                "code1222": "O tempo estimado para cancelar a aposta foi excedido."
            },
            "aSt22": {
                "code1281": "Aposta não foi encontrada",
                "code1282": "A aposta já foi paga",
                "code1283": "Boletim de apostas está pendente",
                "code1284": "Erro de pagamento"
            },
            "aSt25": {
                "code1381": "Aposta não foi encontrada",
                "code1382": "A aposta já foi paga",
                "code1383": "A aposta já foi paga",
                "code1384": "Boletim de apostas está pendente"
            },
            "qSt106": {
                "code1411": "Cupom não foi encontrado"
            },
            "qSt107": {
                "code1421": "Cupom não foi encontrado"
            },
            "qUs22": {
                "code1191": "Você não está autorizado a solicitar essas informações.",
                "code1192": "A solicitação do servidor não retornou resultados"
            },
            "qUs113": {
                "code1301": "Usuário não encontrado, entre em contato com a equipe de suporte",
                "code1303": "A conta é bloqueada conforme exigido pelo regulador."
            },
            "aPo51": {
                "code5108": "Erro de depósito. Insira um valor válido",
                "code5109": "O sistema de pagamento não está disponível. Para depositar a sua conta, escolha outro método ou entre em contato conosco."
            },
            "aPo510": {
                "code4301": "Erro geral de depósito",
                "code4303": "O aplicativo não está autorizado a realizar a operação",
                "code4304": "O usuário não tem permissão para realizar a operação",
                "code4305": "O usuário com os dados especificados não existe",
                "code4306": "O usuário não tem uma conta com a moeda especificada",
                "code4307": "O usuário está bloqueado",
                "code4309": "O bônus não está disponível para este usuário",
                "code4310": "Parceiros não combinam",
                "code4308": "Unauthorized operation, contact support",
                "code4314": "O telefone do utilizador já existe",
                "code4404": "Incompatibilidade de moeda"
            },
            "aPo513": {
                "code4403": "Saldo insuficiente para realizar a operação",
                "code4404": "Incompatibilidade de moeda",
                "code4406": "Fundos insuficientes na conta"
            },
            "aUs14": {
                "code1151": "Falha ao alterar os dados do usuário",
                "code1152": "Usuário não encontrado",
                "code1153": "O nome de usuário deve ter 5 caracteres ou mais",
                "code1154": "Já existe um usuário com este login",
                "code1155": "Sobrenome não especificado",
                "code1156": "Nome não especificado",
                "code1157": "A data de nascimento está incorreta",
                "code1158": "ID / DNI de usuário especificado já existe"
            },
            "aVc14": {
                "code4811": "Sem direito de acesso",
                "code2302": "Voucher é usado",
                "code2303": "Incompatibilidade de lojas",
                "code2305": "Incompatibilidade de lojas",
                "code2308": "Incompatibilidade de moeda"
            },
            "aVc11": {
                "code1": "Sessão não autorizada",
                "code4801": "Sem direito de acesso",
                "code2202": "O valor está incorreto",
                "code2201": "O valor da transação excede o saldo",
                "code2203": "Moeda incorreta"
            },
            "aPo67": {
                "code4441": "Sem direito de acesso",
                "code4442": "Sem direito de acesso",
                "code4443": "Solicitação especificada não encontrada",
                "code4444": "O status da solicitação não permite a operação",
                "code4445": "O ponto de liquidação declarado não corresponde",
                "code4446": "Erro geral de retirada",
                "code4447": "O número de retiradas por dia foi excedido",
                "code4463": "Nenhuma solicitação especificada encontrada",
                "code4464": "Retirada paga",
                "code4465": "O pedido não pode ser pago através do caixa.",
                "code4466": "OPERAÇÃO DE RETIRADA NÃO PERMITIDA"
            },
            "aPo617": {
                "code4443": "A RETIRADA NÃO É AUTORIZADA PARA O USUÁRIO",
                "code4445": "O valor solicitado é superior ao disponível"
            },
            "aGe21": {
                "code1010": "Registro realizado com sucesso, o programa será encerrado. Inicie o programa após a ativação",
                "code1011": "Código de ponto não encontrado",
                "code1012": "O nome do ponto está incorreto",
                "code1013": "E-mail está incorreto",
                "code1014": "País não especificado",
                "code1015": "Nenhum endereço especificado",
                "code1016": "Parceiro não definido",
                "code1017": "Código do ponto já definido"
            },
            "aGe22": {
                "code1021": "O ponto está bloqueado",
                "code1131": "Erro de registro de sessão",
                "code1142": "A sessão expirou"
            },
            "aVc18": {
                "code1001": "O usuário não está logado",
                "code2301": "Ticket não encontrado",
                "code2302": "Ticket usado",
                "code2305": "Sem direitos de operação",
                "code2307": "Incompatibilidade de sócios",
                "code4316": "As moedas não coincidem",
                "code4301": "Erro geral de depósito",
                "code4307": "O usuário está bloqueado",
                "code4310": "Parceiros não combinam"
            },
            "aVc22": {
                "code1211": "Não há dinheiro suficiente no saldo",
                "code1214": "OPERAÇÃO NÃO PERMITIDA PARA ESTE TIPO DE PONTO"
            },
            "aPo512": {
                "code4301": "O aplicativo não está autorizado a realizar a operação.",
                "code4302": "O usuário não tem permissão para realizar a operação.",
                "code4303": "O código de identificação já está em uso.",
                "code4304": "O número do telefone já está cadastrado no sistema.",
                "code4305": "Valor de depósito inválido",
                "code4313": "Número do documento já em uso",
                "code4306": "Código de moeda inválido",
                "code4311": "Operação não autorizada, entre em contato com o suporte",
                "code4314": "Já existe usuário com este endereço de telefone",
                "code4315": "Operação não autorizada, entre em contato com o suporte"
            },
            "aCn11": {
                "code1051": "Confirmação não encontrada",
                "code1052": "A confirmação é usada!",
                "code1053": "Tempo possível para confirmar que a operação expirou"
            },
            "aBn11": {
                "code4231": "Operação não permitida para sessão não ativa",
                "code4232": "Operação não permitida para usuários expressos",
                "code4233": "Bônus não permitido sem depósito",
                "code4236": "Bônus não sai para depósito",
                "code4234": "Ordem da soma muito pequena",
                "code4240": "Bônus não permitido após a aposta",
                "code4238": "O bônus deve vir primeiro",
                "code4235": "Bônus já iniciado",
                "code4237": "Apenas um bônus pode estar ativo por vez",
                "code4241": "O valor do depósito não está em conformidade com as Regras de Bônus."
            }
        },
        "betSlip": {
            "duplicateEventWarning": "Não poderá combinar várias seleções do mesmo mercado.",
            "exceedsMaxStakeAmount": "O valor da aposta é maior do que o máximo permitido",
            "lessMinStakeAmount": "O valor da aposta é inferior ao mínimo permitido",
            "coefWasChanged": "As chances mudaram",
            "quotationBlocked": "A aceitação das apostas está suspensa",
            "positionBlocked": "A aceitação das apostas está temporariamente suspensa",
            "eventFullTime": "As apostas neste evento estão encerradas - o jogo acabou",
            "eventStarted": "As apostas neste evento estão encerradas",
            "betSlipEmpty": "Cupom está vazio",
            "betSlipInvalidItems": "O cupom contém erros",
            "response": {
                "registrationSystemError": "Erro de registro do sistema",
                "incorrectNumberOfPositions": "Erro de sistema ao registrar uma aposta combinada",
                "notEnoughMoneyOnCenterBalance": "Fundos insuficientes na conta",
                "notEnoughMoneyOnUserBalance": "Fundos insuficientes na conta",
                "userDontHaveOpenAccount": "Para fazer apostas, você deve primeiro entrar em sua conta ou registrar-se",
                "positionLiveServiceNotAvailable": "A aceitação de apostas ao vivo neste evento foi suspensa",
                "coefIsNotAllowedForBonus": "A cota da aposta é inferior ao permitido"
            }
        },
        "games": {
            "errorStartingGame": "O jogo não está disponível"
        }
    },
    "webdesktop": {
        "pageTitle": {
            "default": {
                "title": "{brand}: Seu Destino Definitivo para Cassino Online, Apostas Esportivas e Jogos ao Vivo!",
                "description": "Bem-vindo ao {brand}, o site oficial do seu principal centro de apostas! ☝Explore nossos serviços de primeira linha como casa de apostas ☝☝Experimente a emoção dos jogos de cassino online ☝ e aproveite generosos bônus de até $300"
            },
            "sport": {
                "title": "{sport} - {brand} Casa de Apostas: Aposte em {sport} com Confiança!",
                "description": "Aposte em {sport} com confiança na BetGol Casa de Apostas! ☝Cadastro rápido e acesso fácil ☝☝Escolha entre uma ampla variedade de apostas esportivas ☝ Ganhe apostas gratuitas, códigos promocionais e bônus exclusivos ☝"
            },
            "live": {
                "title": "Apostas Ao Vivo na {brand} Casa de Apostas: Liberte a Emoção com Altas Odds!",
                "description": "Participe da ação com apostas esportivas ao Vivo na {brand} Casa de Apostas! ✅Cadastre-se hoje e aproveite apostas gratuitas, códigos promocionais e bônus fantásticos 🎁 Faça apostas em uma ampla seleção de esportes ☝️"
            },
            "casino": {
                "title": "Cassino Online {brand}: Jogue e Ganhe Dinheiro Real!",
                "description": "Entre no mundo da diversão do cassino online no {brand}! ✅Cadastre-se agora para um registro fácil e acesso a uma ampla variedade de caça-níqueis 🎰 Aproveite emocionantes bônus e giros grátis de até $300 🎁"
            },
            "virtual": {
                "title": "Cassino Online {brand}: Jogue e Ganhe Dinheiro Real!",
                "description": "Entre no mundo da diversão do cassino online no {brand}! ✅Cadastre-se agora para um registro fácil e acesso a uma ampla variedade de caça-níqueis 🎰 Aproveite emocionantes bônus e giros grátis de até $300 🎁"
            }
        },
        "race": {
            "bonusActive": "As apostas de corrida não estão disponíveis de uma conta de bônus"
        },
        "idle": {
            "message": "La sesión ha terminado por  inactividad."
        },
        "verification": {
            "emailCaption": "Verificação de e-mail",
            "phoneCaption": "Verificação do número de telefone",
            "caption": "Verificação de segurança",
            "text": "Para proteger sua conta, conclua a seguinte verificação",
            "phone": "Código de Verificação do Número de Telefone",
            "email": "Código de verificação de e-mail",
            "placeholder": "Digite o código de 8 dígitos enviado a ",
            "phoneVerified": "Seu número de telefone é verificado com sucesso",
            "emailVerified": "Seu e-mail é verificado com sucesso",
            "getCode": "Obter o código",
            "sent": "O código de verificação será enviado para "
        },
        "resetPasswordDialog": {
            "caption": "Recuperar senha",
            "emailTabCaption": "Por e-mail",
            "phoneTabCaption": "Por telefone",
            "submitButton": "Criar uma senha temporária",
            "emailPlaceholder": "Digite seu e-mail",
            "emailTabText1": "Para recuperar sua senha, digite o endereço de e-mail que você forneceu durante o registro.",
            "emailTabText2": "Enviaremos um e-mail com mais instruções.",
            "phoneTabText1": "Para recuperar sua senha, digite o número de telefone que você forneceu durante o registro.",
            "phoneTabText2": "Enviaremos um SMS com mais instruções.",
            "success": "Você recebeu uma senha temporária",
            "phonePlaceholder": "Digite seu número de telefone"
        },
        "userMenu": {
            "stakes": "Minhas apostas",
            "bonuses": "Bonos"
        },
        "accountSwitch": {
            "sport": {
                "primary": "Você foi transferido para a conta principal",
                "bonus": "Você foi transferido para a conta do Bônus Esportivo"
            },
            "casino": {
                "primary": "Você foi transferido para a conta do Casino",
                "bonus": "Você foi transferido para a conta de bônus do cassino"
            }
        },
        "footer": {
            "rights": "Este sitio web tubetgol.com es propiedad y está operado por OG OVERSEAS SERVICES LTD, número de registro: 14942707, dirección registrada: 128 City Road, Londres, EC1V 2NX. Contáctenos en info&#64;tubetgol.com. tubetgol.com está autorizado y regulado por el Gobierno de la Isla Autónoma de Anjouan, Unión de las Comoras, y opera bajo la Licencia No. ALSI-022307004-FI2. tubetgol.com ha superado todas las normativas y está legalmente autorizado para llevar a cabo operaciones de juegos de azar y apuestas en cualquier tipo de juego.",
            "rightsCerta": "Este sitio web www.certa.bet es propiedad y está operado por OG OVERSEAS SERVICES LTD, número de registro: 14942707, dirección registrada: 128 City Road, Londres, EC1V 2NX. Contáctenos en info&#64;www.certa.bet. www.certa.bet está autorizado y regulado por el Gobierno de la Isla Autónoma de Anjouan, Unión de las Comoras, y opera bajo la Licencia No. ALSI-022307004-FI2. www.certa.bet ha superado todas las normativas y está legalmente autorizado para llevar a cabo operaciones de juegos de azar y apuestas en cualquier tipo de juego.",
            "about": {
                "caption": "SOBRE",
                "terms": "Termos e Condições",
                "privacy": "Declaração de Privacidade",
                "aml": "AML/KYC Políticas",
                "resp": "Jogo Responsável",
                "cookies": "Política de Сookies"
            },
            "bets": "Apostas esportivas",
            "affiliates": "Afiliados",
            "warning": "O excesso de jogos remotos e apostas esportivas pode causar dependência de jogos de azar"
        },
        "preferences": {
            "languages": "línguas",
            "quotations": {
                "caption": "Formato de probabilidades",
                "decimal": "Decimal",
                "american": "Americano",
                "english": "Fraccional"
            },
            "theme": {
                "caption": "Tema",
                "light": "Claro",
                "dark": "Noite"
            },
            "view": "Visão"
        },
        "withdrawal": {
            "ipay": {
                "description": "Sistema de pagamento IPAY. Durante o dia bancário. Taxa 0%. Você pode retirar fundos da sua conta de jogo através do sistema de pagamento iPay. Crie um pedido de pagamento pelo sistema de pagamento iPay e confirme-o. Após confirmar o pedido, você receberá um código secreto de pagamento, e o valor do pagamento será deduzido da sua conta de jogo. Apresente o Código Secreto a um representante do sistema IPay e, após verificar o código, você receberá o valor do pagamento em dinheiro. Não há taxa para pagar através do sistema de pagamento IPay."
            }
        }
    },
    "mcashier": {
        "saleVoucherDialog": {
            "copyButton": "Copiar número",
            "printButton": "Imprimir",
            "copySuccess": "Número copiado com sucesso"
        },
        "cashWithdrawal": {
            "success": "Retirada concluída com sucesso"
        },
        "menu": {
            "saleVoucher": "Ticket venta",
            "buyVoucher": "Ticket compra"
        },
        "refillAccount": {
            "success": "Conta recarregada com sucesso"
        },
        "expressAccount": {
            "success": "Conta criada com sucesso"
        },
        "share": {
            "caption": "Compartilhar"
        }
    },
    "landing": {
        "agreement": "Tenho mais de 18 anos e confirmo que li e aceito os termos e condições"
    },
    "bonusNotification": {
        "body": "Recarregue sua conta para ativar o Bônus"
    },
    "bonusRegistration": {
        "title": "Cadastre-se",
        "subtitle": "Escolha o seu Bónus de Boas-Vindas",
        "sportBetting": {
            "title": "APOSTAS ESPORTIVAS"
        },
        "casino": {
            "title": "CASINO"
        },
        "noBonus": {
            "title": "SEM BÔNUS",
            "description": "JOGUE NA<br/> {site}",
            "list": `
                <li>Os bônus podem ser ativados após o registro.</li>
                <li>Esta opção não afetará seu registro.</li>
            `
        }
    },
    "cookies": {
        "cookieDialog": {
            "message": "Utilizamos cookies para garantizar el correcto funcionamiento de nuestro sitio web, personalizar anuncios y otros contenidos, habilitar funciones de redes sociales y analizar el tráfico en línea. También compartimos información sobre su uso de nuestro sitio web con nuestros socios de redes sociales, publicidad y análisis.",
            "policeLink": "Política de cookies",
            "buttons": {
                "settings": "Personalizar la configuración",
                "accept": "Aceptar"
            }
        },
        "privacySettingsCenterDialog": {
            "caption": "Centro de configuración de privacidad",
            "yourPrivacy": {
                "head": "Tu privacidad",
                "description": "Cuando visita cualquier sitio web, este puede almacenar o recuperar información en su navegador, principalmente en forma de cookies. Esta información puede ser sobre usted, sus preferencias o su dispositivo y se utiliza principalmente para que el sitio funcione como usted espera. La información generalmente no lo identifica directamente, pero puede brindarle una experiencia web más personalizada. Debido a que respetamos su derecho a la privacidad, puede optar por no permitir algunos tipos de cookies. Haga clic en los encabezados de las diferentes categorías para obtener más información y cambiar nuestra configuración predeterminada. Sin embargo, bloquear algunos tipos de cookies puede afectar su experiencia en el sitio y los servicios que podemos ofrecer.",
                "approveAllButton": "Aprobar todo"
            },
            "strictlyNecessaryCookies": {
                "head": "Cookies estrictamente necesarias",
                "description": "Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Por lo general, solo se configuran en respuesta a acciones realizadas por usted que equivalen a una solicitud de servicios, como configurar sus preferencias de privacidad, iniciar sesión o completar formularios. Puede configurar su navegador para que bloquee o le avise sobre estas cookies, pero algunas partes del sitio no funcionarán. Estas cookies no almacenan ninguna información de identificación personal.",
                "alwaysActive": "Siempre activo"
            },
            "functionalCookies": {
                "head": "Cookies funcionales",
                "description": "Estas cookies permiten que el sitio web proporcione una funcionalidad y personalización mejoradas. Pueden ser establecidos por nosotros o por proveedores externos cuyos servicios hayamos agregado a nuestras páginas. Si no permite estas cookies, es posible que algunos o todos estos servicios no funcionen correctamente."
            },
            "performanceCookies": {
                "head": "Cookies de rendimiento",
                "description": "Estas cookies nos permiten contar visitas y fuentes de tráfico para que podamos medir y mejorar el rendimiento de nuestro sitio. Nos ayudan a saber qué páginas son las más y menos populares y ver cómo se mueven los visitantes por el sitio. Toda la información que recopilan estas cookies es agregada y, por lo tanto, anónima. Si no permite estas cookies, no sabremos cuándo visitó nuestro sitio y no podremos monitorear su rendimiento."
            },
            "targetingCookies": {
                "head": "Cookies de orientación",
                "description": "Estas cookies pueden ser configuradas a través de nuestro sitio por nuestros socios publicitarios. Estas empresas pueden utilizarlos para crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios. No almacenan información personal directamente, sino que se basan en la identificación única de su navegador y dispositivo de Internet. Si no permite estas cookies, experimentará publicidad menos dirigida."
            },
            "buttons": {
                "rejectAll": "Rechazar todo",
                "saveSettings": "Confirmar selección"
            }
        }
    }
};
