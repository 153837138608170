/* eslint quote-props: ["error", "always"] */
export default {
    "brands": {
        "betgol": "BetGol",
        "certabet": "Certabet",
        "support": {
            "betgol": "info{'@'}tubetgol.com",
            "certabet": "info{'@'}certa.bet"
        },
        "site": {
            "betgol": "tubetgol.com",
            "certabet": "certa.bet"
        }
    },
    "sports": {
        "1": "Football",
        "2": "Basketball",
        "3": "Hockey",
        "4": "Tennis",
        "5": "Formula1",
        "6": "Volleyball",
        "7": "Baseball",
        "8": "Rugby",
        "9": "AmericanFootball",
        "10": "Boxing",
        "11": "Keno",
        "12": "SkiJumping",
        "13": "Skeleton",
        "14": "Luge",
        "15": "Snowboard",
        "16": "FreestyleSkiing",
        "17": "FigureSkating",
        "18": "ShortTrack",
        "19": "Biathlon",
        "20": "Bobsleigh",
        "21": "AlpineSkiing",
        "22": "Curling",
        "23": "SpeedSkating",
        "24": "NordicCombined",
        "25": "CrossCountry",
        "26": "SuperLotto",
        "27": "WaterPolo",
        "28": "Handball",
        "29": "Bandy",
        "30": "Swimming",
        "31": "Chess",
        "32": "Futsal",
        "33": "Contest",
        "35": "Policy",
        "36": "OlympicGames",
        "37": "FieldHockey",
        "38": "AutoSport",
        "39": "Motorsport",
        "40": "Billiards",
        "41": "Athletic",
        "42": "Lotto3",
        "43": "Badminton",
        "44": "TableTennis",
        "45": "BeachSoccer",
        "46": "BeachVolleyball",
        "47": "ESports"
    },
    "routes": {
        "home": "MAIN",
        "sport": "SPORT",
        "live": "LIVE",
        "casino": "CASINO",
        "registration": "REGISTRATION",
        "results": "RESULTS",
        "virtual": "VIRTUAL",
        "horse": "RACING",
        "games": "GAMES"
    },
    "appMenuBar": {
        "deposit": "Deposit",
        "withdrawal": "Withdrawal",
        "verifyBetSlip": "Check your bet"
    },
    "common": {
        "tempPassword": "Change temporary password",
        "notFound": {
            "networkError": {
                "title": "Network error",
                "description": "Check your internet connection and press the button below"
            },
            "responseError": {
                "title": "Response Error"
            },
            "code500": {
                "title": "Internal Server Error",
                "description": "Sorry, a server error has occurred"
            },
            "code502": {
                "title": "Bad gateway",
                "description": "Sorry, a server error has occurred"
            },
            "code503": {
                "title": "Service temporarily unavailable",
                "description": "Sorry, a server error has occurred"
            },
            "code504": {
                "title": "Gateway Timeout",
                "description": "Sorry, a server error has occurred"
            },
            "code404": {
                "title": "Page not found",
                "description": "Sorry, we couldn't find the page you are looking for"
            },
            "backButton": "Go back",
            "homeButton": "Go Home",
            "refreshButton": "Refresh the page"
        },
        "language": {
            "language": "language",
            "languageName": "English"
        },
        "pluralization": {
            "hour": "{hour} h|{hour} h|{hour} h",
            "match": "{count} matches|{count} match|{count} matches"
        },
        "days": {
            "tomorrow": "Tomorrow",
            "today": "Today"
        },
        "update": {
            "checkingForUpdates": "Checking for new updates ...",
            "updatesAvailable": "Downloading a new updates ...\nAfter downloading, the application will be restarted to install the new updates",
            "updateError": "An error occurred while updating:\n{error}\nPlease restart the application and try again later.\nIf the error persists, contact your system administrator."
        },
        "settings": {
            "tabs": {
                "generalTab": "General",
                "connectionTab": "Connection"
            },
            "appCode": "Program code:",
            "partnerCode": "Partner code:",
            "address": "Address:",
            "network": {
                "restServerAddress": "Processing server IP:",
                "streamServerAddress": "Data Server IP:",
                "useSSL": "Use a secure SSL connection"
            },
            "logo": "Logo (BASE64 format):"
        },
        "forms": {
            "ok": "Оk",
            "cancel": "Cancel",
            "continue": "Continue",
            "close": "Close",
            "open": "Open",
            "yes": "Yes",
            "no": "No",
            "print": "Print",
            "clear": "Clear",
            "copy": "Copy",
            "loadMore": "More",
            "validation": {
                "required": "The field must be filled",
                "email": "The specified email is not correct",
                "min": "The minimum length of the field is {param} characters.",
                "confirmed": "Password mismatch",
                "invalidFormat": "Invalid format",
                "birthday": "You must be 18+ years old",
                "invalidCharacters": "Symbols + - , ; & are invalid"
            }
        },
        "blocked": {
            "btn": "Details",
            "caption": "The account is temporarily blocked.",
            "detailsMessage": "The account is temporarily locked until the <span id='time-to'>{date}</span> you have requested. Bets on any sport are suspended, as are account deposits.",
            "regulatorBlocked": "The account is blocked at the request of the regulator."
        },
        "games": {
            "tabs": {
                "all": "All",
                "popular": "Popular",
                "new": "New",
                "favourites": "Favourites",
                "football": "Football virtual",
                "tennis": "Tennis virtual",
                "horse": "Horse races",
                "dog": "Dog racing",
                "moto": "Motos",
                "fighting": "Fighting",
                "providers": "Providers",
                "casual": "Casual",
                "slots": "Slots",
                "card": "Table games",
                "lottery": "Lottery",
                "roulette": "Roulette",
                "poker": "Poker",
                "jackpot": "Jackpot",
                "live": "Live",
                "blackjack": "Blackjack",
                "craps": "Craps",
                "buyfeature": "Buy feature",
                "megaways": "Megaways",
                "crash": "Crash",
                "fishing": "Fishing",
                "scratch": "Scratch",
                "basketball": "Basketball",
                "keno": "Keno",
                "bingo": "Bingo",
                "hockey": "Hockey"
            },
            "buttons": {
                "play": "PLAY",
                "demo": "DEMO"
            },
            "zeroBalance": "Insufficient funds in the casino account"
        }
    },
    "appTitle": {
        "brand": {
            "betgol": "Betgol: Online Casino, Live Casino &amp; Sportsbetting",
            "BETGOL": "BETGOL: Online Casino, Live Casino &amp; Sportsbetting",
            "latino": "Apuestas Deportivas",
            "ligabet": "Apuestas Deportivas",
            "columbiaApuesta": "Apuestas",
            "certabet": "Apostas Esportivas e Cassino"
        },
        "cashier": "Cashier"
    },
    "homePage": {
        "widgets": {
            "coupon": {
                "coupons": "COUPONS",
                "today": "Today",
                "totalGoals": "Total Goals",
                "bothToScore": "Both To Score",
                "weekend": "Weekend",
                "mostPopular": "Most Popular"
            },
            "live": "LIVE",
            "starting-soon": "STARTING SOON"
        }
    },
    "betting": {
        "countryMenu": {
            "all": "All",
            "hot": "Favorites"
        },
        "codeInputForm": {
            "submit": "Verify",
            "close": "Close",
            "caption": "Check code"
        },
        "dniInputForm": {
            "dniCaption": "Enter DNI",
            "ceCaption": "Enter CE",
            "switchToCE": "Switch to CE",
            "switchToDNI": "Switch to DNI"
        },
        "withdrawalForm": {
            "caption": "Enter withdrawal amount",
            "cancel": "Cancel",
            "ticket": "Withdraw",
            "footer": "Keep the receipt out of reach of unauthorised persons.",
            "success": {
                "text": "Withdrawal in the amount of <b>{amount} {currency}</b> was successful. <br> N code <b>{code}</b>",
                "caption": "Withdrawal"
            }
        },
        "horse": {
            "ticket": {
                "serial": "Serial",
                "date": "Date",
                "horse": "Horse",
                "game": "Game",
                "amount": "Amount",
                "status": "Status",
                "total": "Total:",
                "outcomes": {
                    "w": "WIN",
                    "p": "PLACE",
                    "s": "SHOW",
                    "f": "FIXED ODDS",
                    "fp": "FIXED ODDS PLACE",
                    "ex": "EXACT",
                    "tr": "TRIFECTA",
                    "su": "FIRST FOUR",
                    "Pk2": "PICK 2",
                    "Pk3": "PICK 3"
                }
            }
        },
        "couponVerification": {
            "caption": "Check your stake",
            "coupon": "BetSlip",
            "bet": "Stake",
            "date": "Date",
            "event": "Event",
            "coef": "Price",
            "amount": "Bet",
            "payment": "Winnings",
            "info": "Info",
            "cardNotFound": "Stake was not found",
            "paymentTotal": "The Payout is {total} {curr}",
            "transferButton": "Transfer to balance",
            "transfer": "Transfer to balance?",
            "success": "Transfer was successful"
        },
        "navigationSideBar": {
            "league": "Leagues",
            "countries": "A-Z",
            "search": "Search",
            "rules": "Rules",
            "faq": "FAQ",
            "reload": "Refresh",
            "time": "Time"
        },
        "searchDialog": {
            "placeholder": "Search",
            "emptyResults": "No relevant events found"
        },
        "mobile": {
            "favourites": "Favourites",
            "popular": "Popular"
        },
        "event": {
            "scoreboardTitle": {
                "events": "Events",
                "menu": {
                    "item1x2": "Match Result",
                    "item12": "Match Result",
                    "item1x12x2": "Double chance",
                    "itemHandicap": "To Win With Handicap",
                    "itemTotalGoals": "Total Goals",
                    "itemLocalTotalGoals": "Home team - Total Goals",
                    "itemVisitTotalGoals": "Away team - Total Goals",
                    "itemBothWillScore": "Both teams to score",
                    "itemTotalPoints": "Total points",
                    "itemLocalTotalPoints": "Home team - Total points",
                    "itemVisitTotalPoints": "Away team - Total points",
                    "itemTotalGames": "Total Games",
                    "itemLocalTotalGames": "Home team - Total Games",
                    "itemVisitTotalGames": "Away team - Total Games",
                    "itemTotalRuns": "Total Runs",
                    "itemTotal": "Total",
                    "itemMatchTotal": "Total",
                    "item1x2xFavourites": "Match Result",
                    "itemTotalFavourites": "Total",
                    "itemHandicapFavourites": "To Win With Handicap"
                }
            },
            "scoreboardLine": {
                "startDate": "Today"
            },
            "periods": {
                "sport1": {
                    "period1": "1st Half",
                    "period2": "2nd Half"
                },
                "sport2": {
                    "period1": "1st Quarter",
                    "period2": "2nd Quarter",
                    "period3": "3d Quarter",
                    "period4": "4th Quarter",
                    "period100": "1st Half",
                    "period101": "2d Half"
                },
                "sport3": {
                    "period1": "1st period",
                    "period2": "2nd period",
                    "period3": "3d period"
                },
                "sport4": {
                    "period1": "1 set",
                    "period2": "2 set",
                    "period3": "3 set",
                    "period4": "4 set",
                    "period5": "5 set"
                },
                "sport6": {
                    "period1": "1 set",
                    "period2": "2 set",
                    "period3": "3 set",
                    "period4": "4 set",
                    "period5": "5 set"
                },
                "sport7": {
                    "period1": "1st inning",
                    "period2": "2d inning",
                    "period3": "3d inning",
                    "period4": "4th inning",
                    "period5": "5th inning",
                    "period6": "6th inning",
                    "period7": "7th inning",
                    "period8": "8th inning",
                    "period9": "9th inning",
                    "period10": "10th inning",
                    "period11": "11th inning",
                    "period12": "12th inning",
                    "period13": "13th inning",
                    "period100": "First 5 Innings"
                },
                "sport9": {
                    "period1": "1st Quarter",
                    "period2": "2nd Quarter",
                    "period3": "3rd Quarter",
                    "period4": "4th Quarter",
                    "period100": "1st Half"
                },
                "sport28": {
                    "period1": "1st Half",
                    "period2": "2nd Half"
                },
                "sport44": {
                    "period5": "Set 5",
                    "period4": "Set 4",
                    "period3": "Set 3",
                    "period2": "Set 2",
                    "period1": "Set 1"
                }
            },
            "groupEvents": {
                "participant": "Event",
                "leaveTheGroup": "Will qualify",
                "stayTheGroup": "Won't qualify",
                "winner": "Winner",
                "placeInGroup": "{p1} place in the group",
                "finishTheRaceYes": "Finish the race - Yes",
                "finishTheRaceNo": "Finish the race - No",
                "fastestLap": "Fastest lap",
                "firstPitstop": "1-st Pitstop",
                "lastPlace": "Last Place",
                "willQualifyToFinal": "Will qualify to Final",
                "willQualifyToFinal1_2": "Will qualify to 1/2 of the Final",
                "willQualifyToFinal1_4": "Will qualify to 1/4 of the Final"
            }
        },
        "betSlip": {
            "title": "BetSlip",
            "shortTitle": "BetSlip",
            "acceptWithAllQuotations": "Accept all odds changes",
            "acceptOnlyWithQuotationsAreRaised": "Accept when odds increase",
            "oneClickBet": "One-Click Bet",
            "simple": "Singles",
            "combined": "Combination",
            "maxWin": "Potential returns",
            "ordinar": "Single",
            "express": "Parley",
            "expressWithCoef": "Parley ({coef})",
            "system": "System ({base} / {limit})",
            "variants": "Options:",
            "columns": {
                "event": "Event",
                "sum": "Stake"
            },
            "buttons": {
                "clear": "Remove",
                "continue": "Place bet",
                "accept": "Accept",
                "verify": "Verify"
            },
            "confirmCoefDiffs": "The odds or availability of selections on your bet slip have changed.",
            "acceptButton": "Accept",
            "common": {
                "total": "Bet amount:",
                "maxWin": "Potential Payout",
                "userNumber": "User number:"
            },
            "print": {
                "card": "BetSlip",
                "code": "Code",
                "time": "Time:",
                "endTime": "Valid until:",
                "confirm": "Placing a bet, you confirm that you have read the rules."
            },
            "dialogBetAccepted": {
                "caption": "Bet accepted",
                "code": "Code",
                "columns": {
                    "date": "Date, Time",
                    "event": "Event",
                    "count": "Quantity",
                    "variants": "{variants} opt.",
                    "coef": "Price",
                    "amount": "Amount",
                    "maxWin": "Potential Payout"
                },
                "timer": "You have {timer} seconds to cancel a bet.",
                "copy": "Copy",
                "cancel": "Cancel"
            },
            "dialogBetVerification": {
                "caption": "Check your bet details"
            },
            "numberInputDialog": {
                "caption": "Enter a bet amount",
                "confirmButton": "OK"
            }
        },
        "registration": {
            "title": "Sign up",
            "tabs": {
                "logoTab": "Logo"
            },
            "fields": {
                "pointName": "Hall name:",
                "city": "City:",
                "countryLabel": "Country:",
                "phone": "Phone number:"
            }
        },
        "depositAccount": {
            "success": "Balance deposit completed successfully!"
        }
    },
    "cashier": {
        "loginForm": {
            "caption": "Login",
            "user": "User",
            "password": "Password",
            "parameters": "Options",
            "settings": "Settings",
            "registration": "Sign up",
            "settingsNotFilled": "Application settings is not filled",
            "serverAddressNotFilled": "Server address is not filled",
            "noAccount": "Account currency doesn’t exist"
        },
        "registrationForm": {
            "caption": "Registration",
            "number": "#",
            "nameGroup": "Username",
            "lastName": "Surname:*",
            "firstName": "Name:*",
            "login": "Login:*",
            "requiredFields": "* - required fields",
            "contactsGroup": "Contacts",
            "address": "Address:",
            "phone": "Phone number:*",
            "email": "E-mail:*",
            "passwordGroup": "Password",
            "password": "Password:*",
            "confirmPassword": "Password confirmation:*",
            "partnerGuid": "Partner code:*"
        },
        "settingsForm": {
            "caption": "Options",
            "locationTab": "Location",
            "optionsTab": "Options",
            "requisites": "Requisites",
            "remoteAccess": "Remote access settings",
            "partnerCode": "Partner code:",
            "cashboxName": "Cashier name:",
            "finance": "Finance",
            "currency": "Currency:",
            "minStakeOrdinar": "Minimum bet amount (Single):",
            "minStakeExpress": "Minimum bet amount (Parley):",
            "minStakeSystem": "Minimum bet amount (Sistem):",
            "proxy": "Proxy server:",
            "useProxy": "use proxy server",
            "proxyAddress": "IP direction:",
            "proxyPort": "Port:",
            "captionImage": "Image file for headers",
            "multiscreen": "Multiscreen Live",
            "multiscreenFile": "Exe. file:",
            "hotkey": "Ctrl+ hotkey",
            "directOutput": "Direct printing",
            "cashbox": "Cashier",
            "voucher": "Ticket",
            "express": "Express account",
            "terminal": "Terminal account",
            "checkFormat": "Check print format:",
            "leftPad": "Left margin:",
            "barcodeScanner": "Barcode Scanner",
            "barcodeScannerPort": "Port:",
            "depositMenu": "Deposit menu item"
        },
        "finDayForm": {
            "caption": "Financial day - Cash check"
        },
        "dailyReportForm": {
            "caption": "Daily report",
            "totalGenerationVoucher": "Total Tickets worth",
            "totalCashCheque": "Total Tickets paid",
            "totalBalanceNulling": "Total balance reset of BP",
            "totalBalanceDeposit": "The amount of the deposits",
            "totalBalanceWithdowal": "Withdrawal amount",
            "totalStake": "Total bets (cash desk)",
            "totalPaid": "Total payments (cash desk)",
            "totalPaidTerminal": "Total payments (terminal)",
            "totalStakeRace": "Total bets Race",
            "totalPayRace": "Total payments Race",
            "totalCancelStakeBP": "Total canceled BP",
            "total": "Total",
            "closeDay": "Close financial day",
            "success": "Financial day closed successfully",
            "confirm": "Close the financial day?",
            "totalBalanceDepositBetPlace": "Direct refill BP",
            "refresh": "Reload"
        },
        "userConfirmationForm": {
            "caption": "User Search",
            "accountId": "Account number:",
            "document": "Document:",
            "phone": "Phone:",
            "findButton": "User Search",
            "personalData": "Personal Information",
            "personalCode": "Private idintifier:",
            "name": "Name:",
            "birthdate": "Date of Birth:",
            "lastName": "Surname:",
            "docs": "User documents:",
            "verify": "User verification:",
            "confirmButton": "Confirm data",
            "verifiedYes": "Yes",
            "verifiedNo": "No",
            "success": "Successfully completed"
        },
        "refillAccountForm": {
            "caption": "Direct account funding",
            "user": "User:",
            "findUser": "User Search",
            "accountNumber": "ID, LOGIN or E-MAIL:",
            "phone": "Phone number: +",
            "document": "Document:",
            "checkButton": "Verify",
            "accountRefill": "Deposit",
            "amount": "Amount:",
            "getBonus": "Get bonus",
            "refillButton": "Deposit",
            "insufficientFunds": "Amount greater than balance",
            "empty": "The field must be filled",
            "blocked": "User is blocked until:",
            "table": {
                "time": "Time",
                "num": "Number",
                "name": "Name",
                "amount": "Amount"
            },
            "print": {
                "accountNumber": "Account number",
                "amount": "Balance"
            }
        },
        "winPaymentForm": {
            "caption": "Payout of winnings",
            "cardCode": "Bet code:",
            "find": "Search",
            "doPayment": "Pay",
            "success": "Winning bet payout successful",
            "notfound": "Bet not found",
            "expired": "The payment period has already passed.",
            "alreadyPaid": "Card already paid"
        },
        "cashWithdrawalForm": {
            "status0": "Pending",
            "status1": "Approved",
            "status2": "Paid",
            "status3": "Rejected",
            "status4": "Canceled by user",
            "status5": "Unknown",
            "caption": "Withdrawal",
            "payCode": "Payout code:",
            "checkWithdrawalButton": "Check payout code",
            "withdrawalCaption": "Order details",
            "withdrawalNum": "Order number:",
            "amount": "Amount",
            "crdate": "Created:",
            "expdate": "Valid until:",
            "cashbox": "Cashier:",
            "status": "Order status:",
            "document": "Document presented",
            "documentType": "Document type",
            "documentTypes": {
                "idcard": "ID / DNI",
                "passport": "Passport",
                "driver": "Driver's license"
            },
            "docNumber": "Document Number:",
            "makeWithdrawalButton": "Check payment",
            "personalData": "Personal Information",
            "userNumber": "Personal Code:",
            "firstName": "Name:",
            "lastName": "Surname:",
            "birthday": "Date of Birth:",
            "userDocuments": "User documents:",
            "verify": "User verification:",
            "confirmUserButton": "Check data",
            "notFound": "Order not found",
            "print": {
                "caption": "Withdrawal",
                "sum": "Amount:",
                "account": "Account number"
            }
        },
        "saleVoucherForm": {
            "caption": "Ticket - sale",
            "amount": "Amount:",
            "saleButton": "Pay",
            "table": {
                "time": "Time",
                "num": "Number",
                "amount": "Amount"
            },
            "print": {
                "time": "Operation time:",
                "voucher": "Ticket №:",
                "amount": "Payout amount:",
                "until": "Valid until:"
            }
        },
        "buyVoucherForm": {
            "caption": "Ticket - Purchase",
            "voucher": "Ticket №:",
            "buyButton": "Purchase",
            "success": "The operation was successful, \n the amount is {amount}",
            "notfound": "Ticket not found"
        },
        "menu": {
            "monitoring": "Monitoring",
            "payments": "Payouts",
            "vouchers": "Ticket",
            "account": "Game account",
            "finance": "Finance",
            "service": "Service",
            "sport": "SPORT",
            "live": "LIVE",
            "state": "Unpaid",
            "payment": "Payout of winnings",
            "withdrawal": "Withdrawal",
            "refill": "Deposit",
            "userFind": "User search ",
            "profile": "Registration parameters",
            "settings": "Options",
            "salevoucher": "Sale",
            "buyvoucher": "Refund",
            "dailyreport": "Daily report",
            "finday": "Financial day",
            "betplaces": "Points",
            "express": "Express account"
        },
        "monitoring": {
            "copyError": "Copy error",
            "table": {
                "id": "Id",
                "time": "Time",
                "position": "Object / Position",
                "quotation": "Source / price",
                "coef": "Price",
                "stake": "Amount",
                "status": "Status",
                "pay": "Payout",
                "income": "Income",
                "print": "Print",
                "number": "#",
                "iscancel": "Canceled",
                "isaccepted": "Accepted",
                "iscalculated": "Calculated",
                "ispaid": "Paid out",
                "object": "Int no. {InnerCardId} Payment code: {payCode}",
                "containerType1": "Single",
                "containerType3": "Sistema ({express} / {itemCount})",
                "containerType2": "Parley",
                "itemStatus0": "Pending",
                "itemStatus1": "Winnings",
                "itemStatus2": "Lost",
                "itemStatus3": "Void",
                "itemStatus4": "Unknown",
                "itemStatus5": "Canceled",
                "horse": {
                    "statusG": "Winnings",
                    "statusJ": "Pending",
                    "statusP": "Lost",
                    "statusD": "Void",
                    "statusA": "Void"
                }
            },
            "operations": {
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL": "Transfer to main account",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT": "Transfer to games account",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP": "Transfer to games account",
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH": "Account deposit",
                "PAY_OPERATION_WITHDRAWAL_CASH": "Account withdrawal",
                "PAY_OPERATION_VOUCHER_GENERATION_CASH": "Voucher selling",
                "PAY_OPERATION_VOUCHER_REDEMPTION_CASH": "Voucher purchase",
                "PAY_OPERATION_VOUCHER_REDEMPTION_TRM": "Voucher purchase",
                "PAY_OPERATION_VOUCHER_GENERATION_BP": "Voucher selling on betplace",
                "PAY_OPERATION_WINPAID": "Win payment",
                "PAY_OPERATION_FOR_RACE_WINPAID_BP": "Pay out Racing BP",
                "PAY_OPERATION_FOR_RACE_WINPAID_CASH": "Pay out Racing",
                "PAY_OPERATION_INCREASE_BALANCE_BP": "Betplace balance deposit BP",
                "PAY_OPERATION_NULLIFICATION_POINT_BALANCE": "Discharge Betplace balance",
                "PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT": "Affiliate Account Recovery",
                "PAY_OPERATION_REFERRAL_ACCOUNT_WITHDRAWA": "Withdraw from affiliate account",
                "PAY_OPERATION_INCREASE_ACCOUNT": "Deposit ",
                "PAY_OPERATION_WITHDRAWAL": "Withdrawal",
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT": "Direct deposit",
                "PAY_OPERATION_DEPOSIT_BONUS_REFILL": "Bonus",
                "PAY_OPERATION_INCREASE_BONUS_ACCOUNT": "Deposit to the bonus account (Sports)",
                "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT": "Deposit to the bonus account (Casino)",
                "PAY_OPERATION_CANCEL_BONUS_ACCOUNT": "Cancellation of the bonus (Sport)",
                "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT": "Cancellation of the bonus (Casino)",
                "PAY_OPERATION_CANCEL_STAKE_BP": "Cancellation of stake BP",
                "PAY_OPERATION_WITHDRAWAL_BONUS_ACCOUNT": "Refund from the bonus account (Sports)",
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP": "Transfer to main account",
                "PAY_OPERATION_WITHDRAWAL_CASINO_BONUS_ACCOUNT": "Refund from the bonus account (Casino)",
                "PAY_OPERATION_FOR_RACE_WINPAID": "Wining on Racing",
                "PAY_OPERATION_FOR_RACE_CARD": "Bet on Racing"
            },
            "filter": {
                "cashier": "Cashier",
                "allbp": "All bp"
            },
            "search": {
                "caption": "Search",
                "inputLabel": "Enter the number"
            },
            "deleteSuccess": "Bet deleted successfully",
            "deleteComfirm": "Delete the bet?",
            "payCode": "Pay code:"
        },
        "cardsState": {
            "innerCardId": "Number",
            "cardFilter": "Stake:",
            "sumPay": "Amount",
            "status": "Status",
            "caption": "Unpaid",
            "unpaidSum": "Unpaid amount: {sumPay}",
            "unpaidRadio": "Unpaid winnings",
            "paidRadio": "Paid winnings",
            "loseRadio": "Lost bets",
            "allRadio": "Calculated bets",
            "footer": "Stake:",
            "unpaid": "unpaid",
            "payCode": "Payment code",
            "empty": "There are no unpaid cards."
        },
        "updateProfile": {
            "success": "Profile editing done correctly"
        },
        "activeBetplaces": {
            "deposit": "Deposit",
            "depositDialog": {
                "caption": "Deposit"
            },
            "depositsuccess": "Account successfully funded",
            "depositsuccesscaption": "Deposit",
            "nullify": "Reset balance",
            "nullifyConfirm": "Do you really want to zero the balance of {betplace}?",
            "nullifysuccess": "Balance is zero",
            "nullifysuccesscaption": "Reset balance",
            "sum": "Balance"
        },
        "expressAccount": {
            "caption": "Express account creation",
            "firstName": "Name: *",
            "lastName": "Last name: *",
            "orderSum": "Amount: *",
            "document": "Document: *",
            "phone": "Phone number:",
            "print": {
                "number": "Express account number:",
                "balance": "Account balance:",
                "siteCaption": "Login data:",
                "login": "Login:",
                "password": "Password:",
                "warning": "Attention! You must change your password immediately after logging in!"
            }
        },
        "forms": {
            "print": "Print"
        }
    },
    "errors": {
        "mtl": {
            "serverError": "Unexpected Server Error",
            "status": {
                "code201": "An incorrect request was sent to the server",
                "code202": "Server query definition error",
                "code203": "Server query definition error: the request does not match the protocol",
                "code101": "The request cannot be processed at this time. Try a little later",
                "code102": "Error processing request on the server",
                "code401": "Point code not specified in request",
                "code402": "Point not activated",
                "code403": "The point was blocked"
            },
            "aUs11": {
                "code1103": "Min password length is 8 symbols",
                "code1104": "Min login length is 8 symbols",
                "code1106": "User with this email already exists",
                "code1107": "A user with this login already exists",
                "code1112": "Incorrect birth date",
                "code1113": "The document number has already been used",
                "code1114": "The referral code is not correct",
                "code1115": "User with this phone already exists",
                "code1118": "Invalid phone number",
                "code1119": "Partner was not found"
            },
            "aUs12": {
                "code1133": "Account locked after 3 incorrect password attempts. To unlock, use the password recovery feature.",
                "code1122": "Incorrect user number and password",
                "code1123": "User is blocked",
                "code1124": "User cannot login from the specified workstation",
                "code1125": "Account currency doesn’t exist",
                "code1127": "Currency mismatch",
                "code1161": "Incorrect mail or phone number",
                "code1126": "User has multiple accounts",
                "code1162": "User is not activated"
            },
            "aUs22": {
                "code1703": "Phone already registered",
                "code1106": "User with this email already exists",
                "code1107": "A user with this login already exists",
                "code1113": "The specified personal user code has already been used",
                "code1114": "The referral code is not correct",
                "code1115": "User with this phone already exists"
            },
            "aPi55": {
                "code2601": "Balances don't match",
                "code2604": "Currency Mismatch"
            },
            "aUs25": {
                "code1121": "The current user does not have rights to edit information"
            },
            "aPi54": {
                "code1001": "User is not logged in"
            },
            "qSt100": {
                "code1311": "The object specified does not exist",
                "code1312": "There are no bets matching the specified parameter",
                "code1313": "The user is not set in the session",
                "code1314": "The user does not have permission to request a list of bets"
            },
            "aSt13": {
                "code1221": "Bet is not found in database",
                "code1222": "The estimated time to cancel the bet has been exceeded."
            },
            "aSt22": {
                "code1281": "Bet not found",
                "code1282": "The bet has already been paid",
                "code1283": "There is no winning to pay",
                "code1284": "Payment transaction error"
            },
            "aSt25": {
                "code1381": "Bet not found",
                "code1382": "The bet has already been paid",
                "code1383": "The bet has already been paid",
                "code1384": "There is no winning to pay"
            },
            "qSt106": {
                "code1411": "Card not found"
            },
            "qSt107": {
                "code1421": "Card not found"
            },
            "qUs22": {
                "code1191": "You are not authorized to request this information.",
                "code1192": "Server request returned no results"
            },
            "qUs113": {
                "code1301": "User not found, please contact support",
                "code1303": "The account is blocked at the request of the regulator."
            },
            "qRs12": {
                "code1411": "Card not found"
            },
            "aPo51": {
                "code5103": "Deposit limit exceeded",
                "code5108": "Order error. Enter valid amount",
                "code5109": "The payment system is not available. To top up your account, choose another method or contact us."
            },
            "aPo510": {
                "code4301": "General deposit error",
                "code4303": "The application is not authorized to carry out the operation",
                "code4304": "The user does not have permission to carry out the operation",
                "code4305": "The user with the specified details does not exist",
                "code4306": "The user does not have an account with the specified currency",
                "code4307": "The user is blocked",
                "code4308": "Unauthorized operation, contact support",
                "code4309": "Bonus is not available for this user",
                "code4310": "Partners Mismatch",
                "code4314": "User phone already exist",
                "code4404": "Currency mismatch"
            },
            "aPo513": {
                "code4403": "Insufficient balance to carry out the operation",
                "code4404": "Currency mismatch",
                "code4406": "Amount greater than balance"
            },
            "aPo68": {
                "code4451": "The application does not have permission to perform the operation",
                "code4452": "Insufficient rights to perform the operation",
                "code4453": "No account opened with specified currency",
                "code4454": "The requested amount is more than available",
                "code4455": "No contact set to send confirmation",
                "code4456": "No rights to use the specified operation confirmation method"
            },
            "aVc21": {
                "code5103": "Incorrect amount",
                "code2302": "VOUCHER IS USED",
                "code4316": "Incorrect currency"
            },
            "aPo617": {
                "code4443": "WITHDRAWAL IS NOT AUTHORIZED FOR THE USER",
                "code4445": "The requested amount is more than available",
                "code4448": "The daily withdrawal limit has been exceeded."
            },
            "aUs14": {
                "code1151": "Failed to change user data",
                "code1152": "User not found",
                "code1153": "Login must have 5 characters or more",
                "code1154": "A user with this login already exists",
                "code1155": "Last name not specified",
                "code1156": "Name not specified",
                "code1157": "Date of birth is incorrect",
                "code1158": "The specified user ID already exists"
            },
            "aVc14": {
                "code4811": "No access right",
                "code2302": "VOUCHER IS USED",
                "code2303": "Partners mismatch",
                "code2305": "Partners mismatch",
                "code2308": "Currency mismatch"
            },
            "aVc11": {
                "code1": "Non-authorised session",
                "code4801": "No access right",
                "code2202": "Amount is incorrect",
                "code2201": "Transaction amount  more than user balance",
                "code2203": "Incorrect currency"
            },
            "aPo67": {
                "code4441": "No access right",
                "code4442": "No access right",
                "code4443": "Specified request not found",
                "code4444": "The status of the request does not allow the operation",
                "code4445": "The declared settlement point does not match",
                "code4446": "General withdrawal error",
                "code4447": "Exceeded the number of withdrawals per day",
                "code4463": "No specified request found",
                "code4464": "Withdrawal paid",
                "code4465": "The order cannot be paid through the cashier",
                "code4466": "WITHDRAWAL OPERTATION NOT ALLOWED"
            },
            "aGe21": {
                "code1010": "Registration was successful, the program will be closed. Please run the program after activation",
                "code1011": "Point code not found",
                "code1012": "Incorrect point’s name",
                "code1013": "E-mail is incorrect",
                "code1014": "Country not specified",
                "code1015": "No address specified",
                "code1016": "Partner not defined",
                "code1017": "Point’s code already defined"
            },
            "aGe22": {
                "code1021": "Point is blocked",
                "code1131": "Session registration error",
                "code1142": "Session timed out"
            },
            "aVc18": {
                "code1001": "User is not logged in",
                "code2301": "Ticket not found",
                "code2302": "Ticket is used",
                "code2305": "No right for operation",
                "code2307": "Partners mismatch",
                "code4316": "Currency mismatch",
                "code4301": "General deposit error",
                "code4307": "User is blocked",
                "code4310": "Partners don't match"
            },
            "aVc22": {
                "code1211": "Not enough money on balance",
                "code1214": "OPERATION NOT ALLOWED FOR POINT TYPE"
            },
            "aUs111": {
                "code1191": "User of session not found"
            },
            "aUs16": {
                "code1172": "The current password is incorrect",
                "code1173": "Minimum lenghth of password is 8 symbols"
            },
            "aUs17": {
                "code1181": "The current user is not set in the session",
                "code1182": "Invalid email address",
                "code1183": "Email address does not exist",
                "code1184": "Phone number is incorrect"
            },
            "aPo512": {
                "code4301": "The application is not authorized to carry out the operation.",
                "code4302": "The user does not have permission to carry out the operation.",
                "code4303": "ID code is already in use.",
                "code4304": "The phone number is already registered in the system.",
                "code4305": "Invalid deposit amount",
                "code4313": "Document number already in use",
                "code4306": "Invalid currency code",
                "code4311": "Unauthorized operation, contact support",
                "code4314": "User with this phone already exists",
                "code4315": "Unauthorized operation, contact support"
            },
            "aCs11": {
                "code8101": "Session not auth",
                "code8132": "Game is not available",
                "code8136": "Top-up balance to start"
            },
            "aCs12": {
                "code8101": "Session not auth",
                "code8121": "Session not auth"
            },
            "aCs27": {
                "code8125": "There are not enough funds in the main account to make the transfer!"
            },
            "aCs28": {
                "code8135": "There are not enough funds in the casino account to make the transfer!"
            },
            "aCn11": {
                "code1051": "Confirmation not found",
                "code1052": "Confirmation is used!",
                "code1053": "Possible time to confirm the operation has expired"
            },
            "aBn11": {
                "code4231": "Operation not permited for not active session",
                "code4232": "Operation not allowed for express users",
                "code4233": "Bonus not allow without deposit",
                "code4236": "Bonus not exits for deposit",
                "code4234": "The deposit amount does not comply with the Bonus Claim Rules.",
                "code4240": "Bonus not allow after bet",
                "code4238": "Hello bonus must come first",
                "code4235": "Bonus already init",
                "code4237": "Only one bonus may be active at one time",
                "code4241": "The deposit amount does not comply with the Bonus Claim Rules."
            }
        },
        "betSlip": {
            "duplicateEventWarning": "You cannot combine multiple selections from the same market!",
            "exceedsMaxStakeAmount": "The amount of the bet is more than the allowed maximum",
            "lessMinStakeAmount": "The amount of the bet is less than the minimum allowed",
            "coefWasChanged": "The odds have changed",
            "quotationBlocked": "Bets acceptance is suspended",
            "positionBlocked": "Bets acceptance is temporarily suspended",
            "eventFullTime": "Bets on this event are closed - match is over",
            "eventStarted": "Bets on this event are closed",
            "betSlipEmpty": "Betslip is empty",
            "betSlipInvalidItems": "Betslip contains errors",
            "response": {
                "registrationSystemError": "System registration error",
                "incorrectNumberOfPositions": "System error registering a Combination bet\\",
                "notEnoughMoneyOnCenterBalance": "Insufficient funds in the account",
                "positionLiveServiceNotAvailable": "Accepting live bets on this event has been suspended",
                "userDontHaveOpenAccount": "Insufficient funds in the account",
                "notEnoughMoneyOnUserBalance": "Insufficient funds in the account",
                "coefIsNotAllowedForBonus": "The betting odd is less than allowed"
            }
        },
        "games": {
            "errorStartingGame": "Game is not available"
        }
    },
    "webdesktop": {
        "pageTitle": {
            "default": {
                "title": "{brand}: Your Ultimate Destination for Online Casino, Sports Betting, and Live Games!",
                "description": "Welcome to {brand}, the official website of your premier betting hub! ☝Explore our top-notch bookmaker services ☝☝Experience the thrill of online casino games ☝ and claim generous bonuses up to $300 ☝"
            },
            "sport": {
                "title": "{sport} - {brand} Bookmaker: Bet on {sport} with Confidence!",
                "description": "Bet on {sport} with confidence at {brand} Bookmaker! ☝Quick sign-up and easy access ☝☝Choose from a wide range of sports bets ☝ Get free bets, promo codes, and exclusive bonuses"
            },
            "live": {
                "title": "{sport} en {brand} Casa de Apuestas: ¡Apuesta en Vivo en {sport} y Gana a lo Grande!",
                "description": "¿Listo para las apuestas en Vivo en {sport}? ¡{brand} Casa de Apuestas te respalda! ✅Registro rápido y acceso sencillo 🥇 Obtén apuestas gratuitas, códigos promocionales y bonos especiales 🎁 Ofrecemos diversas opciones deportivas"
            },
            "casino": {
                "title": "{brand} Online Casino: Play and Win Real Money!",
                "description": "Step into the world of online casino fun at {brand}! ☝Join now for easy registration and access to a wide array of slots ☝ Grab exciting bonuses and free spins up to $300 ☝"
            },
            "virtual": {
                "title": "{brand} Online Casino: Play and Win Real Money!",
                "description": "Step into the world of online casino fun at {brand}! ☝Join now for easy registration and access to a wide array of slots ☝ Grab exciting bonuses and free spins up to $300 ☝"
            },
            "registration": {
                "title": "{brand} Sign Up and Choose Your Welcome Bonus! ",
                "description": "Welcome to {brand}, the official website of your premier betting hub! ☝Explore our top-notch bookmaker services ☝☝Experience the thrill of online casino games ☝ and claim generous bonuses up to $300 ☝"
            }
        },
        "race": {
            "bonusActive": "Bets on Racing are not available  from a bonus account"
        },
        "idle": {
            "message": "The session is over because of inactivity."
        },
        "verification": {
            "emailCaption": "E-MAIL Verification",
            "phoneCaption": "Phone number verification",
            "caption": "Security check",
            "text": "To secure your account, please complete the following verification",
            "phone": "Phone Number Verification Code",
            "email": "Email Verification Code",
            "placeholder": "Enter the 8 digit code sent to ",
            "phoneVerified": "Your phone number is successfully verified",
            "emailVerified": "Your email is successfully verified",
            "getCode": "Get code",
            "sent": "Verification code is sent to "
        },
        "accountSwitch": {
            "sport": {
                "primary": "You have been transferred to the main account",
                "bonus": "You have been transferred to the Sports Bonus account"
            },
            "casino": {
                "primary": "You have been transferred to the Casino account",
                "bonus": "You have been transferred to the Casino Bonus account"
            }
        },
        "casino": {
            "balance": "Casino Account",
            "transferFromCasino": "Transfer from casino account:",
            "transferToCasino": "Transfer to casino account:",
            "depositSuccess": "Main account transfer successful!",
            "withdrawalSuccess": "Casino account transfer successful!"
        },
        "preferences": {
            "languages": "Languages",
            "quotations": {
                "caption": "Quotation format",
                "decimal": "Decimal",
                "american": "American",
                "english": "Fractional"
            },
            "theme": {
                "caption": "Theme",
                "light": "Light",
                "dark": "Dark"
            },
            "view": "View"
        },
        "registration": {
            "success": "Thank you for registration",
            "oneclick": {
                "tabName": "One click",
                "confirm": {
                    "title": "Thank you for registration",
                    "subtitle1": "Please, ",
                    "subtitle2": "!write down your login and password!",
                    "login": "Login",
                    "password": "Password",
                    "copy": "Copy",
                    "savetofile": "Save to file",
                    "savetoimage": "Save as image",
                    "emailplaceholder": "E-MAIL",
                    "emailbutton": "Send to e-mail",
                    "loginCopied": "Login copied",
                    "passwordCopied": "Password copied"
                }
            },
            "phone": {
                "tabName": "With phone",
                "sendSmsButton": "Send SMS",
                "confirmCode": "Confirmation code",
                "confirmButton": "Confirm"
            },
            "email": {
                "tabName": "Via E-mail",
                "emailLabel": "E-mail",
                "countryLabel": "Country",
                "phoneLabel": "Phone number",
                "firstNameLabel": "First name",
                "lastNameLabel": "Last name",
                "birthdayLabel": "Birth date",
                "passwordLabel": "Password",
                "passwordConfirmLabel": "Confirm password",
                "promoLabel": "Bonus",
                "promoPlaceholder": "Enter code",
                "registerLabel": "Registration",
                "documentLabel": "Document",
                "loginLabel": "Login",
                "cityLabel": "City",
                "policy1": "This site is protected by reCAPTCHA and by the ",
                "policy2": " Privacy Policy ",
                "policy3": " from Google and apply the ",
                "policy4": "Terms and Conditions",
                "policy5": ".",
                "policy6": "You confirm that you have read and accept the ",
                "policy7": "Terms and Conditions",
                "policy8": "and",
                "policy9": " Privacy Policy ",
                "policy10": "of the company, and confirm that you are of legal age."
            }
        },
        "emailConfirm": {
            "title": "!Your account is activated!",
            "userNumber": "Your account number:",
            "userLogin": "Your user name:",
            "ref1": "In order to place bets you you have to",
            "ref2": "top up your account"
        },
        "withdrawalConfirm": {
            "title": "Payment request #",
            "total": "Total to pay: ",
            "withdrawalType": "Pay mode: ",
            "payCode": "Pay code: ",
            "expiration": "The payment of this request can be made effective until: ",
            "WITHDRAWAL_METHOD_VOUCHER": "Agents {brandName}",
            "WITHDRAWAL_METHOD_VISA": "Visa/MasterCard",
            "WITHDRAWAL_METHOD_SETTLEMENTACCOUNT": "Settlement account"
        },
        "loginDialog": {
            "menuButton": "Login",
            "emailPlaceholder": "ID, LOGIN or E-MAIL",
            "passwordPlaceholder": "Password",
            "save": "Save",
            "forgetPassword": "Forgot your password?",
            "loginButton": "Login",
            "social": "You can access site with:",
            "no-register": "Not registered yet?"
        },
        "confirmCodeDialog": {
            "success": "A new code has been sent to your contact address provided at the time of registration!",
            "caption": "It is necessary to confirm the operation!",
            "text": "Enter the code sent to your contact address provided at the time of your registration in the following field:",
            "inputLabel": "Confirmation code",
            "submitButton": "Verify"
        },
        "resetPasswordDialog": {
            "caption": "Recover password",
            "emailTabCaption": "By e-mail",
            "phoneTabCaption": "By phone",
            "submitButton": "Сreate temporary password",
            "emailPlaceholder": "Enter your e-mail",
            "phonePlaceholder": "Enter your phone number",
            "emailTabText1": "To recover the password enter the e-mail entered by you when registering.",
            "emailTabText2": "We will send you an e-mail with further instructions.",
            "phoneTabText1": "To retrieve your password, enter the phone number you provided when registering.",
            "phoneTabText2": "We will send you an SMS with further instructions.",
            "success": "You have been sent a temporary password"
        },
        "changePasswordDialog": {
            "caption": "Change password",
            "currentPasswordLabel": "Current password",
            "newPasswordLabel": "New password",
            "newPasswordConfirmLabel": "Confirm new password",
            "submitButton": "Submit",
            "successMessage": "Password successfully changed"
        },
        "expressChangePasswordDialog": {
            "caption": "Welcome",
            "text1": "For the security of your account, we recommend changing your password now!",
            "submitButton": "Change your password now",
            "closeButton": "Change later"
        },
        "changeEmailDialog": {
            "caption": "New email",
            "email": "Enter new email:",
            "success": "Your email has been changed successfully"
        },
        "userMenu": {
            "personalData": "Personal data",
            "settings": "Account settings",
            "bids": "Bets in play",
            "allbids": "All bets",
            "finance": "Finance",
            "stakes": "My bets",
            "bonuses": "Bonus",
            "logout": "Close session"
        },
        "changePhoneDialog": {
            "caption": "Change phone number",
            "phone": "Phone number",
            "submitButton": "Ок",
            "success": "Your phone number has been changed successfully"
        },
        "blockDialog": {
            "week": "Week",
            "month": "Month",
            "year": "Year",
            "caption": "Block account"
        },
        "limitDialog": {
            "caption": "Limit deposit",
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "amount": "Amount",
            "success": "Deposit limit successfully changed"
        },
        "profileView": {
            "title": "PERSONAL INFORMATION",
            "subtitle": "Fill in the empty fields to use the functionalities of the website!",
            "titleLine": "ACCOUNT DATA",
            "accountNumber": "Account number",
            "passwordLabel": "Your password",
            "registrationDate": "Registration date",
            "information": "CONTACT INFORMATION",
            "submitButton": "Save",
            "success": "Data saved successfully",
            "block": "Block",
            "limit": "Limit",
            "blocked": "Account is blocked until "
        },
        "routes": {
            "inPlay": "Bets in play",
            "account": "My account",
            "allStakes": "All bets",
            "winstakes": "Results",
            "finances": "Finances",
            "raceStakes": "Races",
            "withdrawalOrders": "Withdrawals",
            "depositOperations": "Deposits",
            "bonusOperations": "Bonuses"
        },
        "withdrawalOrders": {
            "dateColumn": "Date",
            "amount": "Amount",
            "method": "Withdrawal type",
            "status": "Status",
            "cancel": "Cancel",
            "wt": {
                "WITHDRAWAL_METHOD_VOUCHER": "Voucher",
                "WITHDRAWAL_METHOD_VOUCHER_INSTANT": "Voucher Express",
                "WITHDRAWAL_METHOD_VISA": "Visa",
                "WITHDRAWAL_METHOD_LUKITA": "Lukita",
                "WITHDRAWAL_METHOD_YAPE": "Yape",
                "WITHDRAWAL_METHOD_TUNKI": "Tunki"
            },
            "statusText": {
                "ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION": "It is required to confirm the application using a secret code within 24 hours.",
                "ORDER_WITHDRAWAL_STATUS_CONFIRMATION_EXPIRED": "The withdrawal was not confirmed by the user within 24 hours.",
                "ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE": "The application of withdrawal is awaiting consideration.",
                "ORDER_WITHDRAWAL_STATUS_APPROVED": "The application has been approved and is being prepared for payment.",
                "ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED": "The application has been approved and will be submitted for payment after identity confirmation. An identification document is required. Contact the support for detailed information.",
                "ORDER_WITHDRAWAL_STATUS_PAID": "The application has been submitted for payment.",
                "ORDER_WITHDRAWAL_STATUS_CASH_WAITING": "The order is pending client payment at the cash desk for 3 days.",
                "ORDER_WITHDRAWAL_STATUS_CASH_PAID": "The order has been paid out at the cash desk.",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_EXPIRED": "The payment was not claimed by the client for 3 days and was canceled.",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_ERROR": "Payment is not possible. Please contact support for more information.",
                "ORDER_WITHDRAWAL_STATUS_DENIED": "Payment has been denied. The request has been cancelled. For more information, please contact support.",
                "ORDER_WITHDRAWAL_STATUS_USER_CANCELLED": "The payment request was cancelled by the client.",
                "ORDER_WITHDRAWAL_STATUS_PAYSYSTEM_PAID": "The order has been paid."
            },
            "confirm": {
                "caption": "Confirmation of cancellation of the payment transaction",
                "text": "Do you want to cancel the payment transaction?"
            },
            "cancelSuccess": "Payment transaction canceled successfully"
        },
        "cardsTable": {
            "dateColumn": "Date, time",
            "eventColumn": "Event/result",
            "quotationColumn": "Quotation",
            "amountColumn": "Amount",
            "winColumn": "Payments",
            "infoColumn": "Info",
            "bonus": "bonus account"
        },
        "inPlay": {
            "cardsTotal": "Bets in play:",
            "amount": "Total amount:"
        },
        "allStakes": {
            "beginDate": "Period from:",
            "endDate": "to:",
            "itemsPerPage": "Items per page:",
            "dateFilter": "Filter by date"
        },
        "operations": {
            "operationColumn": "Operation",
            "amountColumn": "Amount"
        },
        "deposit": {
            "amountInput": {
                "caption": "Deposit account {type}",
                "amount": "Amount: ",
                "currency": "Currency: ",
                "dialogText": "Check the data and confirm the operation: you have requested a payment for an amount of {amount}{currency}",
                "min": "The minimum deposit amount is {amount}{currency}."
            },
            "monnetCard": {
                "description": "Deposits using Visa or Mastercard cards are managed through the Monnet platform. Depending on the circumstances, this entity has the authority to approve or reject transactions in accordance with its internal policies. To carry out deposits with Visa or Mastercard, it is necessary to be the cardholder."
            },
            "tupay": {
                "description": "With Tupay, you have the control! Shop online and choose how to pay. Whether it’s with Cash, Bank Transfer, Credit or Debit Cards, or QR Digital Wallets, the choice is yours. We make your online purchases easy and flexible. Discover the freedom of payment with Tupay."
            },
            "DEPOSIT_MONNET_CARD": "MONNET CARD",
            "DEPOSIT_MONNET": "SAFETYPAY ONLINE",
            "DEPOSIT_MONNET_CASH": "SAFETYPAY",
            "DEPOSIT_TUPAY": "TUPAY",
            "PAGOEFECTIVO_INVOICE": "Pago Efectivo",
            "caption": "Recharge methods",
            "method": "Refill method",
            "time": "Transfer time to the account",
            "comment": "Comments",
            "yape": "Yape",
            "lukita": "Lukita",
            "tunki": "izipayYA",
            "yapeText": "Make your life easier with Yape and forget about cash! Recharge quickly, easily and securely through Yape.",
            "tunkiText": "With izipayYA you can send money to your contacts via cell phone number or QR, pay at thousands of bodegas and affiliated stores nationwide, recharge your cell phone and pay for services for FREE, FAST AND SAFE. <br>" +
                "Recharge quickly, easily and securely through izipayYA.",
            "zeroCommission": "Commission, 0%",
            "refillRef": "Add funds",
            "safetyPayInternet": "Internet banking SAFETYPAY",
            "pagoSeguro": "PAGO SEGURO",
            "pagoSeguroEfectivo": "Efectivo",
            "safetyPayDescription": "It is a safe and fast payment method that allows you to deposit funds in your {brandName} account through your Internet Banking. <br> Deposit is instantaneous and {brandName} will not apply any commission for using this method.",
            "pagoEfectivo": "PagoEfectivo",
            "pagoEfectivoDescription": "Deposit en BBVA, BCP, Interbank, Scotiabank, BanBif, Caja Arequipa and Banco Pichincha through mobile banking, QR or at Red Digital, Kasnet, Agente Niubiz, Tambo+, Western Union and Disashop agents and warehouses.",
            "immediate": "Immediate",
            "agents": "Agents of {brandName}",
            "agentsDescription": "You can easily, quickly and safely top up your account through the IPAY network; contact the nearest agent using your account number, user name (LOGIN) or DNI. The deposit to the account is automatic and without commissions.\n",
            "iPay": "Ticket iPay",
            "iPayDescription": "Payment system IPAY. During banking day. Fee 0%. You can withdraw funds from your gaming account through the iPay payment system. Create an order for payment by the iPay payment system and confirm it. After confirming the order, you will receive a Secret payout code, and the payout amount will be deducted from your gaming account. Present the Secret Code to an IPay system representative and after checking the code, you will receive the payout amount in cash. There is no fee for payment out through the IPay payment system.",
            "voucher": {
                "success": "Deposit for the amount {amount} was made successfully.",
                "found": "Ticket №{code} found, amount is {amount}",
                "numberLabel": "Enter the ticket code:",
                "caption": "Ticket iPay"
            }
        },
        "withdrawal": {
            "minAmountError": "Minimum amount to withdraw is {minAmount}",
            "maxAmount": "The maximum withdrawal amount is {maxAmount} per day",
            "general": {
                "WITHDRAWAL_METHOD_YAPE": "YAPE WITHDRAWALS",
                "WITHDRAWAL_METHOD_LUKITA": "LUKITA WITHDRAWALS",
                "WITHDRAWAL_METHOD_TUNKI": "TUNKI WITHDRAWALS",
                "phoneNumber": "Phone number {phoneNumber} will be used for withdrawal"
            },
            "partner": {
                "caption": "Account withdrawal",
                "confirmationMethod": "Select confirmation method:",
                "confirmationEmail": "Email",
                "confirmationPhone": "Phone"
            },
            "visaForm": {
                "caption": "Bank transfer",
                "document": "Document",
                "email": "E-mail",
                "bank": "Bank Name:",
                "cardName": "Full Name:",
                "accountType": "Account Type:",
                "fullNumber": "Account number:",
                "orderedSum": "Quantity (minimum {minAmount}):",
                "dni": "ID:"
            },
            "caption": "Withdrawal methods",
            "paymentMethod": "Payment method",
            "commission": "Commission",
            "agents": "AGENTS {brandName}",
            "agentsDescription": "You can make withdrawals from your gaming account through the IPAY payment system. For this, a request for payment must be sent through the IPAY payment system and confirmed. After confirming the request, You receive a Secret payment code, and the payment amount will be withdrawn from your game account. You must indicate your Secret code to the representative of the IPAY system and after verification of the code, you will obtain the payment in cash. No commission is charged for payment through the IPAY system.",
            "agentsLink": "Create a payment request through AGENTS {brandName} >>",
            "visaLink": "New request",
            "minAmount": "Min amount",
            "ipay": {
                "description": "Payment system IPAY. During banking day. Fee 0%. You can withdraw funds from your gaming account through the iPay payment system. Create an order for payment by the iPay payment system and confirm it. After confirming the order, you will receive a Secret payout code, and the payout amount will be deducted from your gaming account. Present the Secret Code to an IPay system representative and after checking the code, you will receive the payout amount in cash. There is no fee for paying out through the IPay payment system."
            }
        },
        "emailDone": {
            "caption": "ACCOUNT ACTIVATION",
            "text": "To protect your account, verify your registered email address.\n" +
                "                    <br><br>\n" +
                "                    You will receive a confirmation email within the next 10 minutes.\n" +
                "                    If you didn't receive it, please check your spam folder.",
            "codeButton": "Enter code"
        },
        "homeView": {
            "articles": {
                "sport": "Sport",
                "live": "Live",
                "casino": "Casino",
                "virtual": "Virtual <br> games",
                "bonuses": "Bonuses"
            },
            "footer": {
                "sport": "Sport",
                "live": "Live",
                "casino": "Casino",
                "virtual": "Virtual games",
                "bonuses": "Bonuses",
                "privacyPolicy": "Privacy policy",
                "rules": "Rules",
                "popular": "Popular Bets"
            }
        },
        "footer": {
            "sport": "Sport",
            "live": "Live",
            "casino": "Casino",
            "virtual": "Virtual games",
            "bonuses": "Bonuses",
            "privacyPolicy": "Privacy policy",
            "rules": "Rules",
            "popular": "Popular Bets",
            "bets": "Sports bets",
            "affiliates": "affiliates",
            "complaintsBook": "COMPLAINTS BOOK",
            "about": {
                "caption": "About",
                "terms": "Terms & Conditions",
                "privacy": "Privacy Policy",
                "aml": "AML/KYC Policy",
                "resp": "Responsible gambling",
                "cookies": "Cookie policy"
            },
            "rights": "This website tubetgol.com is owned and operated by OG OVERSEAS SERVICES LTD. registration number: 14942707, registered address: 128 City Road, London, EC1V 2NX.. Contact us info&#64;tubetgol.com. tubetgol.com is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. ALSI-022307004-FI2. tubetgol.com has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.",
            "rightsCerta": "This website www.certa.bet is owned and operated by OG OVERSEAS SERVICES LTD. registration number: 14942707, registered address: 128 City Road, London, EC1V 2NX.. Contact us info&#64;www.certa.bet. www.certa.bet is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. ALSI-022307004-FI2. www.certa.bet has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.",
            "warning": "Excessive gambling can be addictive",
            "warningDialog": {
                "title": "Responsible Gaming",
                "description": "Gambling can be addictive and could be difficult to avoid even when you are aware that it is causing problems. Gambling is not a solution to financial problems, you should only play when you can afford not to win! You should keep track of how much time and money you spend online and take action if necessary. Play responsibly and contact us or use the online tools here if you have any difficulties."
            }
        },
        "privacyPolicy": "<b><u>Privacy Policy</u></b><br>" +
            "This privacy policy is published to inform about the principles of the personal data of the users of the website www.tubetgol.com<br>" +
            "<br>" +
            "<b>General features</b><br>" +
            "Website www.tubetgol.com (hereinafter “website”) is concerned with giving the client and user the confidentiality of their personal data (hereinafter “Users”, “Personal Data”). Personal data means any information about the user of the website, including name, address, date of birth, contact details, payment requirements and any other information, that the user could provide to the Company. The Company will try to guarantee the use of the user's personal information in accordance with international standards of data protection and confidentiality.<br>" +
            "When the user provides the Company with his personal data When the user on the website requests information about the Company's products and services, he voluntarily provides the Company with his personal data. When the user registers a new gaming account, he is required to provide the Company with his personal information. If the user already has a registered account (he is a client of the Company), he must use his name and password to access the website. Cookies can be used to identify repeat logins of the website user. The Company's server records the pages visited by the user on the website.<br>" +
            "<b>Use of personal data</b><br>" +
            "The Company collects, stores and uses the personal data of users in accordance with current data protection legislation for the following uses: management of the user's gaming account, provision of services to the user, analysis and control of use by of the user of the website and of the services provided by the Company, quality control of the services to the users, periodic information on the products and services, which could be of interest to the users (hereinafter \"goals\"). The information about the actions of the users is kept by the Company, is its property and can be used for the indicated Goals.<br>" +
            "<b>Opening of personal data</b><br>" +
            "The user's personal information may be transferred or discovered by the Company by its business partners, who may together with the Company use this information for the established Goals. The use of the user's personal data in these cases will be regulated by the agreement in the format established by law. In cases where it is a legal requirement or by provisions of judicial bodies, the user's personal data may be provided to the corresponding government, administrative and executive bodies.<br>" +
            "<b>Personal data use agreement</b><br>" +
            "By providing personal data on the website and/or registering on the website and/or logging into your web gaming account, the user voluntarily authorizes the Company to collect, handle and access their personal information for the purposes indicated above. If you as a user of the website want to specify, change or restrict your authorization in any way, please inform the Company about this, using the contact information indicated on the website.",
        "rules": {
            "generalTabName": "General",
            "specialTabName": "Special cases",
            "stakeTypesTabName": "Types of bets",
            "financeTabName": "Finance operations",
            "resultsTabName": "Results"
        },
        "bonus": {
            "playGame": "Play",
            "saldo": "Bonus balance",
            "rest": "You have left to bet",
            "amount": "Amount to bet",
            "useBonusButton": "Use Bonus",
            "cancelBonusButton": "Cancel Bonus",
            "terms": "Terms and conditions",
            "init": "Activation of the bonus",
            "endTime": "Valid until",
            "cancelled": "Cancellation of the bonus",
            "expired": "The bonus expired",
            "beaten": "Bonus beaten",
            "winning": "Refund from the bonus account",
            "cancelBonusConfirmCaption": "Attention!",
            "cancelBonusConfirmMessage": "All bonus funds will be reset to zero",
            "freeSpins": "The number of free spins",
            "freeSpinsWinnings": "Winnings  received"
        },
        "results": {
            "goals": {
                "goal1": "First goal: {participantName} at {time} min.",
                "goal2": "Goal 2: {participantName} at {time} min.",
                "goal3": "Goal 3: {participantName} at {time} min.",
                "goal4": "Goal 4: {participantName} at {time} min.",
                "goal5": "Goal 5: {participantName} at {time} min.",
                "goal6": "Goal 6: {participantName} at {time} min.",
                "goal7": "Goal 7: {participantName} at {time} min.",
                "goal8": "Goal 8: {participantName} at {time} min.",
                "goal9": "Goal 9: {participantName} at {time} min.",
                "goal10": "Goal 10: {participantName} at {time} min.",
                "goal11": "Goal 11: {participantName} for {time} min.",
                "goal12": "Goal 12: {participantName} for {time} min."
            },
            "set": "set: {set} game: {game} winner {participant}",
            "caption": "RESULTS",
            "dateFilter": "Date",
            "all": "All",
            "today": "Today",
            "yesterday": "Yesterday",
            "select": "Select",
            "calendar": "Calendar",
            "invalidDate": "Invalid date"
        },
        "copyright": "© 2019-2024 Компания"
    },
    "mobile": {
        "profileView": {
            "profileTab": "Your data",
            "settingsTab": "Settings",
            "loginLabel": "Login"
        },
        "navigationView": {
            "depositButton": "Deposits",
            "withdrawalButton": "Withdrawals",
            "stakesButton": "Sport bets",
            "casinoButton": "Casino bets",
            "historyButton": "History",
            "bonusButton": "Bonus",
            "raceButton": "Racing bets",
            "financesButton": "Finances",
            "sportBalance": "Balance:",
            "sportBalanceAvailable": "Available for withdrawal:"
        },
        "depositView": {
            "min": "Min. amount:",
            "max": "Max. amount:",
            "depositButton": "Deposit"
        },
        "bottomBar": {
            "menuButton": "Menu"
        },
        "menuBar": {
            "installApplication": "Install application",
            "bets": "Bets history"
        },
        "withdrawalView": {
            "withdrawalButton": "Withdraw"
        },
        "financesView": {
            "caption": "My finances"
        },
        "stakesView": {
            "caption": "My bets",
            "cardCaption": "N.° int. {innerCardId}",
            "cardStatus": {
                "accepted": "Pending",
                "win": "Reward",
                "lost": "No reward"
            },
            "containerStatusCaption": "Status"
        },
        "historyView": {
            "caption": "History"
        },
        "homeView": {
            "sport": {
                "caption": "Sports Betting",
                "sportButton": "Visit Sports >>",
                "prematch": "Pre Match",
                "live": "Live",
                "soccer": "Football",
                "basketball": "Basketball",
                "baseball": "Baseball",
                "boxing": "Boxing",
                "rugby": "rugby",
                "tennis": "Tennis",
                "volleyball": "Volleyball",
                "allMatches": "All Matches"
            },
            "casino": {
                "caption": "Casino",
                "casinoButton": "Visit Casino >>"
            },
            "virtual": {
                "caption": "Virtual games",
                "virtualButton": "Visit virtual games"
            },
            "horse": {
                "caption": "Races",
                "horseButton": "Visit Races",
                "game1": "Horses",
                "game2": "Grayhounds",
                "game3": "Wagons"
            },
            "bonus": "Welcome Bonus",
            "allGames": "All Games"
        },
        "routes": {
            "sport": "Sport",
            "live": "Live",
            "casino": "Casino",
            "coupon": "Coupon",
            "operationsHistory": "Operations",
            "sessionsHistory": "Sessions",
            "balance": "Balance",
            "casinoStakes": "Casino",
            "search": "Search",
            "virtual": "Virtual",
            "bonus": "Bonus",
            "racing": "Racing"
        }
    },
    "mcashier": {
        "saleVoucherDialog": {
            "copyButton": "Copy number",
            "printButton": "Print",
            "copySuccess": "Number copied successfully"
        },
        "cashWithdrawal": {
            "success": "Withdrawal completed successfully"
        },
        "menu": {
            "saleVoucher": "Sale voucher",
            "buyVoucher": "Buy Voucher"
        },
        "refillAccount": {
            "success": "Account refilled successfully"
        },
        "expressAccount": {
            "success": "Account created successfuly"
        },
        "share": {
            "caption": "Share"
        }
    },
    "landing": {
        "agreement": "I am over 18 years old and I confirm that I have read and accepted the terms and conditions"
    },
    "bonusNotification": {
        "body": "Top up your account to activate the Bonus"
    },
    "bonusRegistration": {
        "title": "Sign up",
        "subtitle": "Choose your Welcome Bonus",
        "sportBetting": {
            "title": "SPORTS BETTING"
        },
        "casino": {
            "title": "CASINO"
        },
        "freeSpins": {
            "title": "Free spins"
        },
        "noBonus": {
            "title": "NO BONUS",
            "description": "PLAY AT<br/> {site}",
            "list": `
                <li>Bonuses can be activated after registration.</li>
                <li>This option will not affect your registration.</li>
            `
        }
    },
    "cookies": {
        "cookieDialog": {
            "message": "We use cookies to ensure that our website works properly, to personalize advertisements and other content, to enable social media features, and to analyze online traffic. We also share information about your use of our website with our social media, advertising and analytics partners.",
            "policeLink": "Cookie Policy",
            "buttons": {
                "settings": "Cookies settings",
                "accept": "Accept"
            }
        },
        "privacySettingsCenterDialog": {
            "caption": "Privacy Settings Center",
            "yourPrivacy": {
                "head": "Your privacy",
                "description": "When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.",
                "approveAllButton": "Approve all"
            },
            "strictlyNecessaryCookies": {
                "head": "Strictly Necessary Cookies",
                "description": "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
                "alwaysActive": "Always Active"
            },
            "functionalCookies": {
                "head": "Functional Cookies",
                "description": "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages.    If you do not allow these cookies then some or all of these services may not function properly."
            },
            "performanceCookies": {
                "head": "Performance Cookies",
                "description": "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.    All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance."
            },
            "targetingCookies": {
                "head": "Targeting Cookies",
                "description": "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.    They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising."
            },
            "buttons": {
                "rejectAll": "Reject all",
                "saveSettings": "Save my settings"
            }
        }
    }
};
