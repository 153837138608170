import { useActiveBetplacesCommon } from "@sportaq/vue/components/mobile-cashier/active-betplaces-common";
import InputBox from "@/components/input-box/InputBox.vue";

export default {
    name: "ActiveBetplaces",
    components: {
        InputBox
    },
    setup: function () {
        return useActiveBetplacesCommon();
    }
};
