import { createApp } from "vue";
import router from "./router";
import { i18n } from "@sportaq/i18n/index";
import Root from "./dependency-container-component";
import clickOutside from "@sportaq/vue/directives/click-outside";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import store from "@/store";
import { requestEnvironmentSettings, webEnvironmentPlugin } from "@sportaq/services/web/environment/web-environment";

async function run () {
    APP_TYPE.value = EApplicationType.mobileCashier;
    const application = createApp(Root);
    const environment = await requestEnvironmentSettings("cash.dev.tubetgol.com");
    application.directive("click-outside", clickOutside)
        .use(store)
        .use(router)
        .use(i18n)
        .use(webEnvironmentPlugin, environment)
        .mount("#app");
    application.component("RecycleScroller", RecycleScroller);
}

run().then();
