<template>
    <div class="popup wide active" id="popup6">
        <div class="overlay" @click="onClose"></div>
        <div class="preloader" v-if="requestSent">
            <div class="preloader-el"></div>
        </div>
        <div class="popup-container">
            <div class="popup-title">{{ $t("cashier.refillAccountForm.caption") }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="form-line">
                        <label class="label">{{ $t("cashier.userConfirmationForm.phone") }}</label>
                        <input type="text" class="field" v-model="phoneNumber">
                    </div>
                    <div class="form-line">
                      <label class="label">{{ $t("cashier.refillAccountForm.accountNumber") }}</label>
                      <input type="text" class="field" v-model="userNumberRef">
                    </div>
                    <div class="form-line">
                      <label class="label">{{ $t("cashier.refillAccountForm.document") }}</label>
                      <input type="text" class="field" v-model="userDocumentRef">
                    </div>
                    <div class="form-line" v-if="userRef">
                        <label class="label">{{ $t("cashier.refillAccountForm.user") }}</label>
                        <input type="text" class="field" disabled="true" :value="userName">
                    </div>
                    <div class="form-line" v-if="userRef && (userRef.selfBlockedTime || userRef.isRegulatorBlocked)">
                        <label class="label">{{ $t(userRef.isRegulatorBlocked ? "common.blocked.regulatorBlocked" : "cashier.refillAccountForm.blocked") }}</label>
                        <input type="text" class="field" disabled :value="$d(userRef.selfBlockedTime, 'long')">
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-primary no-margin" @click.stop.prevent="onFind">{{ $t("cashier.refillAccountForm.checkButton") }}</a>
                    </div>
                    <div class="form-line">
                        <label class="label width-20">{{ $t("cashier.refillAccountForm.amount") }}</label>
                        <input type="text" class="field width-80" :readonly="!userRef" v-model.number="amountRef" @keydown="onAmountKeyDown">
                        <div class="field-error" v-if="amountErrorMessage">{{ amountErrorMessage }}</div>
                    </div>
                    <div class="form-line">
                        <label class="label width-20">MAX</label>
                        <input type="text" class="field width-80" disabled :value="balance">
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-general" @click.stop.prevent="onRefill" :class="{disabled: isFillButtonDisabled}">{{ $t("cashier.refillAccountForm.refillButton") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="" class="button button-secondary" @click.stop.prevent="onClose">{{ $t("common.forms.cancel") }}</a>
                    </div>
                </div>
                <div class="popup-table">
                    <table>
                        <thead>
                        <tr>
                            <th>{{ $t("cashier.refillAccountForm.table.time") }}</th>
                            <th>{{ $t("cashier.refillAccountForm.table.num") }}</th>
                            <th>{{ $t("cashier.refillAccountForm.table.name") }}</th>
                            <th>{{ $t("cashier.refillAccountForm.table.amount") }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="deposit in deposits" :key="deposit.id">
                            <td>{{ $d(deposit.depositTime, "long") }}</td>
                            <td>{{ deposit.userNumber }}</td>
                            <td>{{ deposit.userLogin }}</td>
                            <td>{{ deposit.amount }}{{deposit.currencyMark}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./refill-account-dialog-component.ts" />
