import { ref, SetupContext } from "vue";
import { Field, Form } from "vee-validate";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import {
    useAddExpressAccountDialogCommon
} from "@sportaq/vue/components/mobile-cashier/add-express-account-dialog-common";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";

export default {
    name: "AddExpressAccountDialog",
    emits: [CLOSE_EVENT],
    components: {
        Form, Field
    },
    setup (props: unknown, context: SetupContext) {
        const {
            registrationData,
            expressAccountDataRef,
            currencyName,
            isSubmitButtonDisabled,

            onClose,
            onSubmit,
            formatVoucherNumber
        } = useAddExpressAccountDialogCommon(true, context, onAddExpressAccountSuccess);
        const success = ref(false);
        const i18n = useI18n();
        function onAddExpressAccountSuccess () {
            success.value = true;
        }

        function copy () {
            if (expressAccountDataRef.value) {
                navigator.clipboard.writeText(i18n.t("cashier.expressAccount.print.login") + expressAccountDataRef.value.userNumber +
                    " " + i18n.t("cashier.expressAccount.print.password") + expressAccountDataRef.value.password)
                    .then(() => showMessageBoxEx(true, "", i18n.t("webdesktop.registration.oneclick.confirm.loginCopied")));
                onClose();
            }
        }

        return {
            registrationData,
            expressAccountDataRef,
            currencyName,
            isSubmitButtonDisabled,
            success,
            onClose,
            onSubmit,
            copy,
            formatVoucherNumber
        };
    }
};
