import { CasinoGamesVuex } from "@sportaq/vuex/modules/games/internal/casino-games-vuex";
import { insertIntoSortedArray } from "@sportaq/common/utils/arrays";
import { CasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import { CasinoGameDiffsResponse } from "@sportaq/model/games/casino-game-diffs-response";
import { DiffType } from "@sportaq/model/betting/events/event";
import { appLogger } from "@sportaq/common/utils/logger";
import { PointSettings } from "@sportaq/model/common/point-settings";
import { GameListUpdater } from "@sportaq/vuex/modules/games/internal/game-list-resolvers/game-list-resolver";
import { GameFilterChanger } from "@sportaq/vuex/modules/games/game-view-filter/GameViewFilter";
import { CasinoGamesVuexGetters } from "@sportaq/vuex/modules/games/internal/casino-games-vuex-getters";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";
import { CountGameProvider, GameProvider, gameProviders } from "@sportaq/model/consts/game-providers";

export namespace CasinoGamesVuexMutations {

    import getFavourites = CasinoGamesVuexGetters.getFavourites;

    export function mutationSetProviders (state: CasinoGamesVuex.CasinoGamesStoreState, payload: {
        gameProviders: GameProvider[]
    }) {
        const providers: CountGameProvider[] = [];
        for (const item of payload.gameProviders) {
            insertIntoSortedArray(providers, {
                id: item.id,
                name: item.name,
                title: item.title,
                image: item.image,
                order: item.order,
                excludedCountries: item.excludedCountries,
                excludedCurrencies: item.excludedCurrencies,
                count: 0
            }, (a, b) => {
                const result = b.order - a.order;
                if (result === 0) {
                    return a.title.localeCompare(b.title);
                }
                return result;
            });
        }
        state.providers = providers;
    }

    export function mutationUpdateGameFilter (state: CasinoGamesVuex.CasinoGamesStoreState, payload: {
        casinoGamesSupplier: CasinoGamesSupplier;
        changers: GameFilterChanger[],
        pointSettings: PointSettings
    }) {
        let needRebuild = false;
        for (const changer of payload.changers) {
            if (state.gameViewFilter.change(changer)) {
                const changeGameType = changer.changeGameType;
                if (changeGameType) {
                    refreshProviderCounters(state, payload.casinoGamesSupplier);
                }
                needRebuild = true;
            }
        }
        if (needRebuild) {
            state.favouriteMode = false;
            rebuildGameView(state, payload.casinoGamesSupplier, payload.pointSettings);
        }
    }

    export function mutationClearGameFilter (state: CasinoGamesVuex.CasinoGamesStoreState) {
        state.gameViewFilter.clear();
    }

    function rebuildGameView (state: CasinoGamesVuex.CasinoGamesStoreState, casinoGamesSupplier: CasinoGamesSupplier, pointSettings: PointSettings) {
        const filter = state.gameViewFilter;
        const restrictedProviderIds = getProviderIdsByNames(pointSettings.restrictionGameProviderList);
        const restrictedGameIds = getRestrictedGameIds(pointSettings, state);
        const periodListResolver = state.gameListResolver;
        const rebuilder = periodListResolver.batchRebuilder;
        rebuilder.begin();
        if (state.favouriteMode) {
            getFavourites(state).items
                .map(value => casinoGamesSupplier.getOptionalGame(value))
                .forEach(game => {
                    if (game) {
                        rebuilder.addEvent(filter, game, state.favouriteMode, restrictedProviderIds, restrictedGameIds);
                    }
                });
        } else {
            const games = filter.gameType
                ? casinoGamesSupplier.getItems(filter.gameType)
                : [
                    ...casinoGamesSupplier.getItems(ECasinoGameType.Virtual),
                    ...casinoGamesSupplier.getItems(ECasinoGameType.Casino)
                ];
            for (const game of games) {
                rebuilder.addEvent(filter, game, state.favouriteMode, restrictedProviderIds, restrictedGameIds);
            }
        }
        state.gamesView = rebuilder.end();
    }

    export function mutationSyncGamesViewWithStore (state: CasinoGamesVuex.CasinoGamesStoreState, payload: {
        casinoGamesSupplier: CasinoGamesSupplier,
        list: CasinoGameDiffsResponse[],
        nonConfirmedPositionIds: string[],
        pointSettings: PointSettings
    }) {
        internalSyncGamesWithStorage(state, payload.list, payload.nonConfirmedPositionIds, payload.casinoGamesSupplier, payload.pointSettings);
        refreshProviderCounters(state, payload.casinoGamesSupplier);
    }

    function internalSyncGamesWithStorage (state: CasinoGamesVuex.CasinoGamesStoreState, list: CasinoGameDiffsResponse[], nonConfirmedPositionIds: string[], casinoGamesSupplier: CasinoGamesSupplier, pointSettings: PointSettings) {
        const restrictedProviderIds = getProviderIdsByNames(pointSettings.restrictionGameProviderList);
        const restrictedGameIds = getRestrictedGameIds(pointSettings, state);
        const periodListResolver = state.gameListResolver;
        const batchUpdater = periodListResolver.batchUpdater;
        batchUpdater.begin(state.gamesView);
        for (const diff of list) {
            const currentGame = casinoGamesSupplier.getOptionalGame(diff.id);
            switch (diff.diffType) {
                case DiffType.NEW:
                case DiffType.UPDATE: {
                    if (currentGame && currentGame.version === diff.version) {
                        if (!state.favouriteMode || getFavourites(state).contains(diff.id)) {
                            batchUpdater.addOrUpdateEvent(state.gameViewFilter, currentGame,
                                state.favouriteMode, restrictedProviderIds, restrictedGameIds);
                        }
                    } else {
                        const diffJson = JSON.stringify(diff);
                        if (!currentGame) {
                            appLogger.logger.warn(`SyncGamesWithStorage: Game with id=${diff.id} for ${diff.diffType === DiffType.NEW ? "NEW" : "UPDATE"} diff wasn't founded in event storage. Event diff has been omitted\nDiff:\n${diffJson}`);
                        } else {
                            appLogger.logger.warn(`SyncGamesWithStorage: Game with id=${diff.id} for ${diff.diffType === DiffType.NEW ? "NEW" : "UPDATE"} has version ${currentGame.version} and diff has different version ${diff.version}, but the diff should have been applied. Event diff has been omitted\nDiff:\n${diffJson}\nCurrent event:\n${JSON.stringify(currentGame)}`);
                        }
                    }
                    break;
                }
                case DiffType.DELETE: {
                    if (!currentGame) {
                        deleteGame(state, batchUpdater, diff.id);
                    } else {
                        appLogger.logger.warn(`SyncGamesWithStorage: Game with id=${diff.id} for DELETE diff was founded in event storage. Event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}\nCurrent event:\n${JSON.stringify(currentGame)}`, undefined);
                    }
                    break;
                }
            }
        }
        for (const nonConfirmedPosition of nonConfirmedPositionIds) {
            deleteGame(state, batchUpdater, nonConfirmedPosition);
        }
        state.gamesView = batchUpdater.end();
    }

    export function mutationToggleFavourites (state: CasinoGamesVuex.CasinoGamesStoreState, payload: {
        value: GameSortInfo,
        casinoGamesSupplier: CasinoGamesSupplier,
        pointSettings: PointSettings
    }) {
        const game = payload.value;
        const favourites = game.gameType === ECasinoGameType.Virtual ? state.virtualFavourites : state.casinoFavourites;
        if (!favourites.contains(game.id)) {
            favourites.push(game.id);
        } else {
            favourites.remove(game.id);
            if (state.favouriteMode) {
                rebuildGameView(state, payload.casinoGamesSupplier, payload.pointSettings);
            }
        }
    }

    export function mutationSetFavouriteMode (state: CasinoGamesVuex.CasinoGamesStoreState, payload: {
        value: boolean,
        casinoGamesSupplier: CasinoGamesSupplier,
        pointSettings: PointSettings
    }) {
        state.favouriteMode = payload.value;
        state.gameViewFilter.change({ changeProvider: { type: "unset" } });
        state.gameViewFilter.change({ changeClassifierMask: { type: "unset" } });
        rebuildGameView(state, payload.casinoGamesSupplier, payload.pointSettings);
    }

    export function addRestrictedGameId (state: CasinoGamesVuex.CasinoGamesStoreState, payload: {
        value: string,
        casinoGamesSupplier: CasinoGamesSupplier,
        pointSettings: PointSettings
    }) {
        state.restrictedGameIds.push(payload.value);
        const newGamesView = state.gamesView.clone();
        if (newGamesView.remove(payload.value)) {
            state.gamesView = newGamesView;
        }
    }

    function refreshProviderCounters (state: CasinoGamesVuex.CasinoGamesStoreState, casinoGamesSupplier: CasinoGamesSupplier) {
        const gameType = state.gameViewFilter.gameType;
        if (gameType) {
            for (const provider of state.providers) {
                provider.count = casinoGamesSupplier.getProviderCounter(gameType, provider.id);
            }
        }
    }

    function getProviderIdsByNames (providerNames: string[]) {
        const result: number[] = [];
        for (const name of providerNames) {
            const provider = gameProviders.getByName(name);
            if (provider) {
                insertIntoSortedArray(result, provider.id, (a, b) => a - b);
            }
        }
        return result;
    }

    function getRestrictedGameIds (pointSettings: PointSettings, state: CasinoGamesVuex.CasinoGamesStoreState) {
        const restrictedGameIds = [...pointSettings.restrictionGameMarkList, ...state.restrictedGameIds];
        restrictedGameIds.sort((a, b) => a.localeCompare(b));
        return restrictedGameIds;
    }

    function deleteGame (state: CasinoGamesVuex.CasinoGamesStoreState, batchUpdater: GameListUpdater, gameId: string) {
        batchUpdater.delete(gameId);
        state.virtualFavourites.remove(gameId);
        state.casinoFavourites.remove(gameId);
    }
}
