import { ref, SetupContext } from "vue";
const CLOSE_EVENT = "close";
const SUBMIT_EVENT = "submit";

export default {
    name: "MonitoringSearch",
    emits: [CLOSE_EVENT, SUBMIT_EVENT],

    setup (props: unknown, context: SetupContext) {
        const valueRef = ref<string>("");

        function onClose () {
            context.emit(CLOSE_EVENT);
        }

        function onSubmit () {
            context.emit(SUBMIT_EVENT, valueRef.value ? valueRef.value : 0);
        }
        return {
            valueRef,

            onClose,
            onSubmit
        };
    }
};
