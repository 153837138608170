import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { ExtendedBalance } from "@sportaq/model/common/user-balance";
import { BaseAccountMessageHandler } from "@sportaq/services/rest/messages/account/base-account-message-handler";

export class QUs111BalanceMessageHandler extends BaseAccountMessageHandler<ExtendedBalance> {
    protected readonly requestType: string = "Q.US.1.11";

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const selectorElement = request.addChild(actionElement, "Selector", undefined);
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.UserAccount",
            allFields: "True"
        });
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): ExtendedBalance {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "1190") {
            throw new LocalizedError(`errors.mtl.qUs22.code${serverCode}`);
        }
        return {
            mainBalance: this.parseAccounts(getRequiredChild(getRequiredChild(action, "US"), "UserAccountList"))
        };
    }
}
