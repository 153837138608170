import { useRootStore } from "@sportaq/vuex/index";
import { useSettings } from "@sportaq/services/index";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";

export function requireDni () {
    const rootStore = useRootStore();
    const settingsService = useSettings();
    const route = useRoute();

    const needAuthorization = computed(() => {
        if (!settingsService.pointSettings.workWithDNIonly) {
            return false;
        }
        if (rootStore.userSharedInfo.userNumber !== undefined) {
            console.log("User number:", rootStore.userSharedInfo.userNumber);
            return false;
        }
        return route.name === COMMON_ROUTES.HORSE.name || route.path.indexOf(COMMON_ROUTES.SPORT.path) >= 0 ||
            route.path.indexOf(COMMON_ROUTES.LIVE.path) >= 0;
    });

    return {
        needAuthorization
    };
}