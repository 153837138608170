
import MobileSimpleStakeRow
    from "@/components/win-payment-dialog/common/inner-stake-table/simple-stake-row/MobileSimpleStakeRow.vue";
import { PropType } from "vue";
import { useSettings } from "@sportaq/services/index";
import { formatQuotationCoef } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { Card } from "@sportaq/model/cashier/card";

export default {
    props: {
        card: { required: true, type: Object as PropType<Card> }
    },
    components: {
        MobileSimpleStakeRow
    },
    setup () {
        const quotationFormat = useSettings().quotationFormat;
        function formatCoef (coef: number) {
            return formatQuotationCoef(quotationFormat, coef);
        }
        return {
            formatCoef
        };
    }
};
