import { computed, SetupContext, watch } from "vue";
import { ROUTES } from "@/router/router-consts";
import { useRoute, useRouter } from "vue-router";
import BalanceComponent from "@sportaq/vue/components/common/balance-component/BalanceComponent.vue";
import { EVENT_OPEN_DIALOG } from "@sportaq/vue/components/web/dialogs/events";
import { useSettings } from "@sportaq/services/index";
import { MobileCashierSettingsService } from "@/services/settings/mobile-cashier-settings-service";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { DEFAULT_USER_SHARED_INFO, useRootStore } from "@sportaq/vuex/index";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import ActionsSubmenu from "@/components/submenu/ActionsSubmenu.vue";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";

export default {
    components: {
        BalanceComponent,
        ActionsSubmenu
    },
    emits: [EVENT_OPEN_DIALOG],
    setup (props:[], context: SetupContext) {
        const route = useRoute();
        const settings = useSettings() as MobileCashierSettingsService;
        const router = useRouter();
        const rootStore = useRootStore();
        const store = useCashierStore();
        const balanceStore = useBalanceStore();
        const betSlipStore = useBetSlipStore();
        const isInstallationAvailable = computed(() => store.isAppInstallationAvailable);

        const quotationCount = computed(() => betSlipStore.simples.length);
        const user = computed(() => store.user);
        const userNumber = computed(() => {
            if (!settings.pointSettings.workWithDNIonly) {
                return undefined;
            }
            return rootStore.userSharedInfo.userNumber;
        });

        function onDniLogout () {
            rootStore.userSharedInfo.userNumber = undefined;
        }

        watch(() => route.path, () => {
            hideMenu();
        });

        function toggleBetSlip () {
            betSlipStore.isShowed = betSlipStore.simples.length > 0 && !betSlipStore.isShowed;
            hideMenu();
        }

        function showMenu () {
            document.body.classList.add("drop-menu-active");
        }

        function hideMenu () {
            document.body.classList.remove("drop-menu-active");
        }

        function logout () {
            settings.storeSessionCode(undefined);
            settings.storePoints([]);
            store.clearStore();
            balanceStore.clearBalance();
            rootStore.userSharedInfo = DEFAULT_USER_SHARED_INFO;
            router.push({ path: ROUTES.LOGIN.path }).then();
        }

        function openDialog (componentName: string) {
            context.emit(EVENT_OPEN_DIALOG, componentName);
            hideMenu();
        }

        function installApplication () {
            hideMenu();
            eventBus.emit(Events.INSTALL_MOBILE_APPLICATION);
        }

        return {
            user,
            quotationCount,
            ROUTES,
            COMMON_ROUTES,
            isInstallationAvailable,
            userNumber,
            onDniLogout,
            toggleBetSlip,
            showMenu,
            hideMenu,
            openDialog,
            logout,
            installApplication
        };
    }
};
