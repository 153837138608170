export class RegistrationData {
    userNumber: string = "";
    firstName: string = "";
    lastName: string = "";
    address: string = "";
    phone: string = "";
    phoneCode: string = "";
    email: string = "";
    login: string = "";
    password: string = "";
    confirmPassword: string = "";
    partnerGuid: string = "";
    country: number = 0;
}
