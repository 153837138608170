import { useLoginViewCommon } from "@sportaq/vue/views/mobile-cashier/login/login-view-common";
import { ref } from "vue";
import SettingsDialog from "@/components/settings-dialog/SettingsDialog.vue";
import RegistrationDialog from "@/components/registration-dialog/RegistrationDialog.vue";
import ResetPasswordDialog from "@/components/reset-password-dialog/ResetPasswordDialog.vue";
import { useRouter } from "vue-router";
import { ROUTES } from "@/router/router-consts";
import { useSettings } from "@sportaq/services/index";
import { MobileCashierSettingsService } from "@/services/settings/mobile-cashier-settings-service";
import { useCashierRestService } from "@sportaq/services/cashier";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { EGameService } from "@sportaq/model/common/point-settings";

export default {
    name: "LoginView",
    components: {
        SettingsDialog,
        RegistrationDialog,
        ResetPasswordDialog
    },
    setup () {
        const settingsService = useSettings() as MobileCashierSettingsService;
        const restService = useCashierRestService();
        const store = useCashierStore();
        const {
            ESupportedLocale,
            selectedLanguage,
            login,
            password,
            isSettingsDialogActive,
            isRegistrationDialogActive,
            isResetPasswordDialogActive,
            registrationData,
            settings,
            usePrintPreview,
            requestPending,
            onLanguageSelected,
            onLogin,
            onSettings,
            onSettingsDialogClose,
            onSettingsDialogSubmit,
            onRegistration,
            onRegistrationSubmit,
            onRegistrationClose,
            onResetPassword,
            onResetPasswordClose
        } = useLoginViewCommon(onLoginSuccess, true);
        const router = useRouter();
        const isPasswordVisible = ref(false);
        function onLoginSuccess (userPointName: string | undefined, userPointAddress: string | undefined, userStationDescription: string | undefined, disabledServices: EGameService[] | undefined) {
            settingsService.storeSessionCode(restService.session);
            settingsService.storePoints(store.getPoints());
            settingsService.storeCashierPoint(userPointName, userPointAddress, userStationDescription);
            if (disabledServices) {
                settingsService.pointSettings.disabledServices = disabledServices;
                settingsService.storeDisabledServices(disabledServices);
            }
            if (userPointName) {
                settingsService.pointSettings.name = userPointName;
            }
            if (userPointAddress) {
                settingsService.pointSettings.address = userPointAddress;
            }
            settingsService.pointSettings.stationDescription = userStationDescription;
            router.push({ path: ROUTES.BETPLACES.path }).then();
        }
        return {
            ESupportedLocale,
            selectedLanguage,
            login,
            password,
            isSettingsDialogActive,
            isRegistrationDialogActive,
            isResetPasswordDialogActive,
            registrationData,
            settings,
            usePrintPreview,
            isPasswordVisible,
            requestPending,
            onLanguageSelected,
            onLogin,
            onSettings,
            onSettingsDialogClose,
            onSettingsDialogSubmit,
            onRegistration,
            onRegistrationSubmit,
            onRegistrationClose,
            onResetPassword,
            onResetPasswordClose
        };
    }
};
