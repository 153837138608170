import MessageBox from "@sportaq/vue/components/common/message-box/MessageBox.vue";
import { useI18n } from "vue-i18n";
import { useMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { configureFormValidation } from "@sportaq/vue/utils/form-validation";
import { changeLanguage, resolveLanguage } from "@sportaq/i18n/index";
import { appLogger } from "@sportaq/common/utils/logger";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { computed, onMounted, ref } from "vue";
import { useCashierRestService } from "@sportaq/services/cashier";
import { useSettings } from "@sportaq/services/index";
import { MobileCashierSettingsService } from "@/services/settings/mobile-cashier-settings-service";
import { defaultPointSettings } from "@sportaq/model/common/point-settings";
import Axios, { AxiosRequestConfig } from "axios";
import { useBrand } from "@sportaq/vue/components/common/brand-component/brand-component";
import { WebEnvironmentSettings } from "@sportaq/services/web/environment/web-environment";
import { I18nSetup } from "@sportaq/i18n/settings/i18n-settings";
import i18nSettings = I18nSetup.i18nSettings;
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { useRootStore } from "@sportaq/vuex/index";
import { ThemeClass } from "@sportaq/common/enums/theme";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;

    prompt (): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

export default {
    components: {
        MessageBox
    },
    setup () {
        i18nSettings.disabledLanguages = [];
        appLogger.logger.info(`Application channel: ${process.env.__CHANNEL__}`);
        const i18n = useI18n();
        configureFormValidation(i18n);
        const restService = useCashierRestService();
        const store = useCashierStore();
        const rootStore = useRootStore();
        const settingsService = useSettings() as MobileCashierSettingsService;
        if (settingsService.sessionCode) {
            settingsService.pointSettings = defaultPointSettings;
        }
        const cashierStore = useCashierStore();
        const applicationStarted = ref(false);
        const globalTheme = computed(() => rootStore.theme);
        useBrand();

        onMounted(async () => {
            if (process.env.__PRODUCTION__) {
                try {
                    const url = "/resource/app_properties.json?" + Math.random();
                    const config: AxiosRequestConfig = {
                        baseURL: "/",
                        headers: { "Content-Type": "application/json" }
                    };
                    const value = await Axios.get(url, config);
                    const settings: WebEnvironmentSettings = value.data;
                    if (settings.appCode) {
                        settingsService.appCode = settings.appCode;
                    }
                } catch (ex) {
                    appLogger.logger.warn("Error getting application properties", ex as Error);
                }
            }
            if (!store.point && restService.session) {
                loginWithStoredSession().then(() => {
                    const themes = store.point?.pointSettings?.themes;
                    if (themes) {
                        rootStore.theme = themes[0];
                    }
                    changeLanguage(i18n, resolveLanguage());
                }).finally(() => {
                    applicationStarted.value = true;
                });
            } else {
                restService.loadSettings().then(s => {
                    if (s.pointSettings.themes.length > 0) {
                        rootStore.theme = s.pointSettings.themes[0];
                    } else {
                        rootStore.theme = ThemeClass.Light;
                    }
                }).finally(() => {
                    applicationStarted.value = true;
                });
            }

            let installApplicationEvent: BeforeInstallPromptEvent | undefined;

            window.addEventListener("beforeinstallprompt", e => {
                e.preventDefault();
                // Stash the event so it can be triggered later.
                installApplicationEvent = e;
                store.isAppInstallationAvailable = true;
            });

            window.addEventListener("appinstalled", () => {
                store.isAppInstallationAvailable = false;
                installApplicationEvent = undefined;
            });

            eventBus.on(Events.INSTALL_MOBILE_APPLICATION, () => {
                if (installApplicationEvent) {
                    installApplicationEvent.prompt().then(() => {
                        installApplicationEvent = undefined;
                        store.isAppInstallationAvailable = false;
                    });
                }
            });
        });

        async function loginWithStoredSession () {
            const p = await restService.loginWithStoredSession();
            cashierStore.point = p;
            if (p.pointSettings) {
                settingsService.pointSettings = p.pointSettings;
                if (settingsService.pointName) {
                    settingsService.pointSettings.name = settingsService.pointName;
                }
                if (settingsService.pointAddress) {
                    settingsService.pointSettings.address = settingsService.pointAddress;
                }
                if (settingsService.stationDescription) {
                    settingsService.pointSettings.stationDescription = settingsService.stationDescription;
                }
            }
            cashierStore.user = p.user;
            settingsService.setLogin(p.user.login, p.user.userNumber);
            if (settingsService.points) {
                cashierStore.setPoints(settingsService.points);
            }
        }

        const {
            isMessageBoxActive,
            message,
            success,
            caption,
            messageBoxClose
        } = useMessageBox();

        return {
            isMessageBoxActive,
            message,
            success,
            caption,
            applicationStarted,
            globalTheme,

            messageBoxClose
        };
    }
};
