import App from "@/App.vue";
import { h } from "vue";
import router from "@/router";
import { ROUTES } from "@/router/router-consts";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import {
    injectMiscellaneousParamsProvider,
    injectUUIDProvider, provideSharedServices
} from "@sportaq/vue/components/common/dependency-container/shared-dependency-container";
import { useI18n } from "vue-i18n";
import {
    provideAuthorizationService,
    provideClockProvider,
    provideHttpService,
    provideSettings
} from "@sportaq/services/index";
import { provideCashierRestService } from "@sportaq/services/cashier";
import { provideCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { MobileCashierSettingsService } from "@/services/settings/mobile-cashier-settings-service";
import { NopAuthorizationService } from "@sportaq/services/authorization/AuthorizationService";
import { provideBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useRootStore } from "@sportaq/vuex/index";
import { provideMobilePrintService } from "@/services/print/mobile-print-service";
import { useWebEnvironment } from "@sportaq/services/web/environment/web-environment";

export default {
    name: "dependency-container",
    components: { App },
    setup () {
        APP_TYPE.value = EApplicationType.mobileCashier;
        const i18n = useI18n();
        const rootStore = useRootStore();
        const environment = useWebEnvironment();
        const uuidProvider = injectUUIDProvider({});
        const settingsService = new MobileCashierSettingsService(uuidProvider);
        settingsService.appCode = environment.appCode;
        if (process.env.__PRODUCTION__) {
            settingsService.restServerAddress = location.host;
        } else {
            settingsService.restServerAddress = "www.dev.tubetgol.com";
        }
        provideSettings(settingsService);
        const clockProvider = provideClockProvider();
        injectMiscellaneousParamsProvider(undefined);
        provideBalanceStore(rootStore);
        provideSharedServices(undefined, rootStore, settingsService);
        provideHttpService();
        provideMobilePrintService();
        provideCashierRestService(clockProvider, uuidProvider, settingsService, {
            onNotAuthorized () {
                router.push(ROUTES.LOGIN).then();
            },
            onLocalizedError (e: LocalizedError) {
                const localizedMessage = i18n.t(e.message);
                showMessageBox(localizedMessage);
            }
        });
        provideCashierStore(rootStore);
        provideAuthorizationService(new NopAuthorizationService());

        return () => h(App);
    }
};
