import { useSettings } from "@sportaq/services/index";
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted } from "vue";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { brandTypeToString } from "@sportaq/common/enums/brand-type";

export function useBrand () {
    const settings = useSettings();
    const i18n = useI18n();

    onMounted(() => {
        refreshBrandType();
        eventBus.on(Events.APPLICATION_POINT_SETTINGS_CHANGED, refreshBrandType);
    });

    onUnmounted(() => {
        eventBus.off(Events.APPLICATION_POINT_SETTINGS_CHANGED, refreshBrandType);
    });

    function refreshBrandType () {
        setTitleByBrand();
    }

    function setTitleByBrand () {
        if (settings.pointSettings?.caption) {
            document.title = i18n.t(settings.pointSettings.caption);
        } else {
            const suffix = brandTypeToString(settings.brand);
            document.title = i18n.t(`appTitle.brand.${suffix}`);
        }
    }
}
