import MobileSportTypeFilter
    from "@sportaq/vue/components/mobile-betting/betting-top-panel/mobile-sport-type-filter/MobileSportTypeFilter.vue";
import { useBettingView } from "@sportaq/vue/views/betting/betting-layout/betting-view-layout-common";
import MobileBettingNavigationBar from "@sportaq/vue/components/mobile-betting/betting-navigation-bar/MobileBettingNavigationBar.vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useBettingPageTitle } from "@sportaq/vue/components/web/betting-page-title";

export default {
    components: {
        MobileSportTypeFilter,
        MobileBettingNavigationBar
    },
    setup () {
        const route = useRoute();
        const { updateTitle } = useBettingPageTitle();
        useBettingView();
        onMounted(() => {
            updateTitle(route.params.eventType.toString(), route.params.sportType.toString());
        });
    }
};
