<template>
    <div class="popup wide active" id="popup11">
        <div class="popup-container">
            <Form @submit="onSubmit" v-slot="{ errors, handleSubmit }">
                <div class="popup-title">{{ $t("cashier.registrationForm.caption") }}</div>
                    <div class="popup-content">
                        <div class="form-line">
                            <label class="label" :class="{'label-error': errors.partnerGuid}">{{ $t("cashier.registrationForm.partnerGuid") }}</label>
                            <Field type="text" class="field" name="partnerGuid" :value = "registrationData.partnerGuid" rules="required" />
                            <div class="field-error" v-if="errors.partnerGuid">{{errors.partnerGuid}}</div>
                        </div>
                        <div class="form-line">
                            <label class="label" :class="{'label-error': errors.lastName}">{{ $t("cashier.registrationForm.lastName") }}</label>
                            <div class="position-relative">
                                <Field name="lastName" type="text" class="field" :value = "registrationData.lastName" rules="required"/>
                                <div class="field-error" v-if="errors.lastName">{{errors.lastName}}</div>
                            </div>
                        </div>
                        <div class="form-line">
                            <label class="label" :class="{'label-error': errors.firstName}">{{ $t("cashier.registrationForm.firstName") }}</label>
                            <div class="position-relative">
                                <Field name="firstName" type="text" class="field" :value = "registrationData.firstName" rules="required" />
                                <div class="field-error" v-if="errors.firstName">{{errors.firstName}}</div>
                            </div>
                        </div>
                        <div class="form-line">
                            <label class="label" :class="{'label-error': errors.login}">{{ $t("cashier.registrationForm.login") }}</label>
                            <div class="position-relative">
                                <Field name="login" type="text" class="field" autocomplete="username" :value = "registrationData.login" rules="required|min:5" />
                                <div class="field-error" v-if="errors.login">{{errors.login}}</div>
                            </div>
                        </div>
                        <div class="form-line">
                            <label >{{$t("webdesktop.registration.email.countryLabel")}}:*</label>
                            <country-select v-model="countryId"/>
                        </div>
                        <div class="form-line mobile">
                            <label for="phone" :class="{'label-error': errors.phone}">{{$t("webdesktop.registration.email.phoneLabel")}}</label>
                            <div class="icon">
                                <div class="num">{{phoneCode}}</div>
                            </div>
                            <Field type="tel" id="phone" name="phone" rules="required" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"/>
                            <div class="field-error" v-if="errors.phone">{{errors.phone}}</div>
                        </div>
                        <div class="form-line">
                            <label class="label" :class="{'label-error': errors.email}">{{ $t("cashier.registrationForm.email") }}</label>
                            <div class="position-relative">
                                <Field name="email" type="text" class="field" :value = "registrationData.email" rules="required|email"/>
                                <div class="field-error" v-if="errors.email">{{errors.email}}</div>
                            </div>
                        </div>
                        <div class="form-line password">
                            <password-field :isConfirm="false" :errors="errors" ></password-field>
                        </div>
                        <div class="form-line password-repeat">
                            <password-field :isConfirm="true" :errors="errors"></password-field>
                        </div>
                        <div class="form-line">
                            <div class="text-notice label-error">{{ $t("cashier.registrationForm.requiredFields") }}</div>
                        </div>
                        <div class="form-line">
                            <button type="submit" id="submit-button" class="button button-primary smaller" @click.prevent="handleSubmit(onSubmit)">{{$t("common.forms.ok")}}</button>
                        </div>
                        <div class="form-line">
                            <button type="submit" id="cancel-button" class="button button-secondary smaller" @click.prevent="onClose">{{$t("common.forms.cancel")}}</button>
                        </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script src="./registration-dialog-component.ts" />
