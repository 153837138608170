import MobileScoreboardHead from "@sportaq/vue/components/mobile-betting/betting-scoreboard/scoreboard-head/MobileScoreboardHead.vue";
import MobileScoreboardBody from "@sportaq/vue/components/mobile-betting/betting-scoreboard/scoreboard-body/MobileScoreboardBody.vue";
import MobileScoreboardDetails
    from "@sportaq/vue/components/mobile-betting/betting-scoreboard/scoreboard-details/MobileScoreboardDetails.vue";
import { computed, ref, watch } from "vue";
import QuotationView from "@sportaq/common/enums/quotation-views";
import { ScoreboardHeadMenuItem } from "@sportaq/model/betting/view/scoreboard-head-quotation-types-selector";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { UNSELECTED } from "@sportaq/model/betting/view/scoreboard-selection";
import { useRoute } from "vue-router";

export default {
    components: {
        MobileScoreboardHead,
        MobileScoreboardBody,
        MobileScoreboardDetails
    },
    setup () {
        const route = useRoute();
        const scoreboardStore = useScoreboardStore();
        const quotationViews = ref<QuotationView[]>([]);
        const viewTitle = ref("");
        const showList = ref(true);

        const selection = computed(() => scoreboardStore.getScoreboardSelection());
        const showDetails = computed(() => scoreboardStore.getScoreboardSelection().positionId > 0);

        function quotationViewChanged (item: ScoreboardHeadMenuItem) {
            if (item) { // can be null if no sport type is selected
                quotationViews.value = [item.view];
                viewTitle.value = item.localizationKey;
            } else {
                quotationViews.value = [];
                viewTitle.value = "";
            }
        }

        function hideList () {
            showList.value = false;
        }

        function closeLine () {
            scoreboardStore.setScoreboardSelection(route, UNSELECTED);
            showList.value = true;
        }

        watch(() => scoreboardStore.getScoreboardSelection(), value => {
            if (value.positionId < 0) {
                showList.value = true;
            }
        });

        return {
            quotationViews,
            viewTitle,
            selection,
            showList,
            showDetails,

            hideList,
            closeLine,
            quotationViewChanged
        };
    }
};
