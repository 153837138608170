import { useStreamingRequestService } from "@sportaq/services/index";
import {
    EventSupplier,
    useEventSupplier
} from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { useRouter } from "vue-router";
import {
    SearchResultFilter
} from "@sportaq/vue/components/betting/event-search-dialog/event-search-dialog-sport-category/event-search-dialog-sport-filter-item-component";
import { computed, onMounted, ref, SetupContext, shallowRef } from "vue";
import { TIMEOUTS } from "@sportaq/common/consts/default-consts";
import { SearchEventsResponse } from "@sportaq/model/types/responses";
import { insertIntoSortedArray } from "@sportaq/common/utils/arrays";
import { ESportType, getNameBySportType } from "@sportaq/common/enums/sport-type";
import { Participant, Partition } from "@sportaq/model/betting/events/event";
import EventType, { getEventTypeName } from "@sportaq/common/enums/event-type";
import { getParticipantsString } from "@sportaq/vue/components/betting/utils/participant-strings";
import { getParticipantList } from "@sportaq/model/common/participants-functions";
import { countries } from "@sportaq/model/consts/countries";
import { sportTypes } from "@sportaq/model/consts/sport-types";

export namespace EventSearchDialogComponent {
    export const CLOSE_EVENT = "close";

    export function useEventSearchDialogComponent (setupContext: SetupContext) {
        const streamingRequestService = useStreamingRequestService();
        const eventSupplier = useEventSupplier();
        const router = useRouter();

        const inputField = ref<HTMLElement>();
        const searchText = ref("");
        const searchCompleted = ref(true);
        const searchResultFilter = ref<SearchResultFilter>({ type: "all" });
        const searchResult = shallowRef<SearchResultItem[]>([]);

        let currentMessageId = 0;

        const filteredSearchResult = computed(() => {
            const filter = searchResultFilter.value;
            switch (filter.type) {
                case "all": {
                    return searchResult.value;
                }
                case "sportType": {
                    return [searchResult.value.find(value => value.sportType === filter.sportType)];
                }
                case "partition": {
                    const bySportType = searchResult.value.find(value => value.sportType === filter.sportType);
                    if (bySportType) {
                        const result = new SearchResultItem(filter.sportType);
                        bySportType.partitions
                            .filter(value => value.partition.id === filter.partitionId)
                            .forEach(value => result.partitions.push(value));
                        return [result];
                    } else {
                        return [];
                    }
                }
            }
            return [];
        });

        onMounted(() => {
            inputField.value?.focus();
        });

        function close () {
            setupContext.emit(CLOSE_EVENT);
        }

        let timeoutHandle: ReturnType<typeof setTimeout>;

        function change () {
            searchResult.value = [];
            searchResultFilter.value = { type: "all" };
            clearTimeout(timeoutHandle);
            if (searchText.value) {
                searchCompleted.value = false;
                timeoutHandle = setTimeout(async () => {
                    if (searchText.value) {
                        currentMessageId = currentMessageId + 1;
                        const searchEventsResponse = await streamingRequestService.searchEvents(currentMessageId, searchText.value);
                        if (searchEventsResponse.messageId === currentMessageId) {
                            searchResult.value = handleSearchResults(eventSupplier, searchEventsResponse);
                            searchCompleted.value = true;
                        }
                    }
                }, TIMEOUTS.SEARCH_DIALOG_INPUT_TIMEOUT);
            } else {
                searchCompleted.value = true;
            }
        }

        async function openEventDetails (item: DataPanelItem) {
            setupContext.emit(CLOSE_EVENT);
            await router.push({ path: item.path });
        }

        return {
            searchText,
            inputField,
            searchCompleted,
            searchResultFilter,
            searchResult,
            filteredSearchResult,
            close,
            change,
            openEventDetails
        };
    }

    function handleSearchResults (eventSupplier: EventSupplier, response: SearchEventsResponse): SearchResultItem[] {
        const result: SearchResultItem[] = [];
        const events = response.positions
            .map(value => eventSupplier.getOptionalEvent(value.eventType, value.positionId))
            .filter(value => value !== undefined);
        for (const item of events) {
            const event = item!;
            let sport = result.find(value => value.sportType === event.sportTypeId);
            if (!sport) {
                sport = new SearchResultItem(event.sportTypeId);
                insertIntoSortedArray(result, sport, (a, b) => a.index - b.index);
            }
            let partition = sport.partitions.find(value => value.partition.id === event.partition.id);
            if (!partition) {
                partition = new SearchResultPartitionItem(event.partition);
                insertIntoSortedArray(sport.partitions, partition,
                    (a, b) => a.partition.name.localeCompare(b.partition.name));
            }
            const panelItem = new DataPanelItem(event.eventType, event.sportTypeId, event.positionId, getParticipantList(event), event.startTime, event.startTimeStr);
            insertIntoSortedArray(partition.items, panelItem, (a, b) => a.startTime.getTime() - b.startTime.getTime());
        }
        return result;
    }

    export class SearchResultItem {
        readonly partitions: SearchResultPartitionItem[];
        readonly index: number;
        readonly name: string;
        readonly image: string;

        constructor (readonly sportType: ESportType) {
            this.partitions = [];
            const st = sportTypes.getById(sportType);
            if (st) {
                this.name = st.name;
                this.image = st.image;
                this.index = st.index;
            } else {
                this.name = "";
                this.image = "";
                this.index = -1;
            }
        }
    }

    class SearchResultPartitionItem {
        readonly items: DataPanelItem[];
        readonly flag: string;

        constructor (readonly partition: Partition) {
            this.items = [];
            this.flag = countries.getFlag(partition.countryId);
        }
    }

    class DataPanelItem {
        constructor (
            readonly eventType: EventType,
            readonly sportType: ESportType,
            readonly positionId: number,
            private _participants: Participant[],
            readonly startTime: Date,
            readonly startTimeStr: string
        ) {
        }

        get liveBetting (): boolean {
            return this.eventType === EventType.LIVE;
        }

        get participants (): string {
            return getParticipantsString(this._participants);
        }

        get path (): string {
            return `/${getEventTypeName(this.eventType)}/${getNameBySportType(this.sportType)}/${this.positionId}`;
        }
    }
}
