import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";

export class APi55BalanceNullingMessageHandler extends AbstractMessageHandler<boolean> {
    protected readonly requestType: string = "A.PI.5.5";
    constructor (
        readonly pointGuid: string,
        readonly sum: number
    ) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        const nullingElement = request.addChild(actionElement, "BalanceNulling");
        request.addChildWithValue(nullingElement, "SumNulling", this.sum.toFixed(2));
        request.addChildWithValue(nullingElement, "PointGUID", this.pointGuid);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): boolean {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "2600") {
            throw new LocalizedError(`errors.mtl.aPi55.code${serverCode}`);
        }
        return true;
    }
}
