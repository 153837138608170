<template>
    <div class="header">
        <div class="header-left">
            <router-link :to="{name: ROUTES.BETPLACES.name}" class="header-logo"></router-link>
        </div>
        <div class="header-account">
            <span class="button button-primary button-bigger"><balance-component :showCurrency="true"></balance-component></span>
        </div>
        <div class="header-account" v-if="userNumber">
            <span class="button button-primary button-bigger">{{userNumber}}</span>
        </div>
        <a class="button log-out" @click.prevent="onDniLogout" v-if="userNumber">
            <img :src="require(`@assets/img/mobile/theme-light/icon-exit.svg`)" alt="">
        </a>
        <div class="drop-menu drop-menu-mob-cashier">
            <div class="drop-menu-overlay" @click="hideMenu"></div>
            <div class="drop-menu-content">
                <div class="drop-menu-head drop-menu-head-user">
                    <div class="drop-menu-user">
                        <span>
                            <img class="mr-5" :src="require(`@assets/img/mobile/theme-light/icon-person.svg`)" alt="">
                        </span>
                        {{user?.login}}
                    </div>
                    <a class="drop-menu-exit" @click.prevent="logout">
                        <img :src="require(`@assets/img/mobile/theme-light/icon-exit.svg`)" alt="">
                    </a>
                    <div class="drop-menu-close-wrap">
                        <div class="drop-menu-close" @click="hideMenu"></div>
                    </div>
                    <div class="drop-menu-dots"><img src="/img/icon-dots.svg"></div>
                </div>
                <div class="drop-menu-actions">
                    <router-link :to="{path: ROUTES.MONITORING.path}" class="item">
                        {{ $t("cashier.menu.monitoring") }}
                    </router-link>
                    <actions-submenu :title="$t('cashier.menu.payments')">
                        <a href="" class="item" @click.prevent="openDialog('CardsState')">{{$t("cashier.menu.state")}}</a>
                        <a href="" class="item" @click.prevent="openDialog('WinPaymentDialog')">{{$t("cashier.menu.payment")}}</a>
                    </actions-submenu>
                    <router-link :to="{path: ROUTES.BETPLACES.path}" class="item">
                        {{ $t("cashier.menu.betplaces") }}
                    </router-link>
                    <actions-submenu :title="$t('cashier.menu.vouchers')">
                        <a href="" class="item" @click.prevent="openDialog('SaleVoucherDialog')">
                            {{$t("mcashier.menu.saleVoucher")}}
                        </a>
                        <a href="" class="item" @click.prevent="openDialog('BuyVoucherDialog')">
                            {{$t("mcashier.menu.buyVoucher")}}
                        </a>
                    </actions-submenu>
                    <actions-submenu :title="$t('cashier.menu.finance')">
                        <router-link :to="{path: ROUTES.DAILYREPORT.path}" class="item">
                            {{ $t("cashier.menu.dailyreport") }}
                        </router-link>
                        <router-link :to="{path: ROUTES.FINDAY.path}" class="item">
                            {{ $t("cashier.menu.finday") }}
                        </router-link>
                    </actions-submenu>
                    <actions-submenu :title="$t('cashier.menu.service')">
                        <a href="" class="item" @click.prevent="openDialog('ChangePasswordDialog')">
                            {{$t("webdesktop.changePasswordDialog.caption")}}
                        </a>
                    </actions-submenu>
                    <actions-submenu :title="$t('cashier.menu.account')">
                        <a href="" class="item" @click.prevent="openDialog('RefillAccountDialog')">{{$t("cashier.menu.refill")}}</a>
                        <a href="" class="item" @click.prevent="openDialog('CashWithdrawalDialog')">{{$t("cashier.menu.withdrawal")}}</a>
                        <a href="" class="item" @click.prevent="openDialog('AddExpressAccountDialog')">{{$t("cashier.menu.express")}}</a>
                    </actions-submenu>
                    <a href="" class="item" v-if="isInstallationAvailable" @click.prevent="installApplication">
                        {{ $t("mobile.menuBar.installApplication") }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./app-menu-bar-component.ts" />
