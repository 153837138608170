export enum EBrandType {
    betgol = 1,
    oncebet = 2,
    latinoApuesta = 3,
    ligabet = 4,
    columbiaApuesta = 5,
    certabet = 6,
    aviator = 7,
    four4 = 8
}

const brandTypeToNameMap: Map<EBrandType, string> = new Map<EBrandType, string>([
    [EBrandType.betgol, "betgol"],
    [EBrandType.oncebet, "oncebet"],
    [EBrandType.latinoApuesta, "latinoapuesta"],
    [EBrandType.ligabet, "ligabet"],
    [EBrandType.columbiaApuesta, "apuestas"],
    [EBrandType.certabet, "certabet"],
    [EBrandType.aviator, "aviator"],
    [EBrandType.four4, "4444"]
]);
const nameToBrandTypeMap = new Map<string, EBrandType>();
brandTypeToNameMap.forEach((value, key) => nameToBrandTypeMap.set(value, key));

export function stringToBrandType (str: string): EBrandType {
    const result = nameToBrandTypeMap.get(str);
    if (!result) {
        throw new Error(`Brand type with name '${str}' not registered`);
    }
    return result;
}

export function brandTypeToString (brandType: EBrandType): string {
    const result = brandTypeToNameMap.get(brandType);
    if (!result) {
        throw new Error(`Brand type '${brandType}' not registered`);
    }
    return result;
}
