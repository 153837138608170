import { ESportType } from "@sportaq/common/enums/sport-type";
import { resolveLanguage } from "@sportaq/i18n/index";
import { IndexedImageDictionaryItem } from "@sportaq/common/types/types";

class SportTypeHolder {
    private readonly indexById = new Map<ESportType, IndexedImageDictionaryItem>();
    private locale = "_Undefined_";

    getById (sportType: ESportType): IndexedImageDictionaryItem | undefined {
        this.refreshByLocale();
        return this.indexById.get(sportType);
    }

    private refreshByLocale () {
        const newLocale = resolveLanguage();
        if (newLocale !== this.locale) {
            this.rebuild(newLocale);
        }
    }

    private rebuild (locale: string) {
        this.indexById.clear();
        for (const item of rawSportTypeArray) {
            const value: IndexedImageDictionaryItem = {
                id: item.id,
                index: item.index,
                name: this.getName(item, locale),
                image: item.icon
            };
            this.indexById.set(item.id, value);
        }
        this.locale = locale;
    }

    private getName (raw: RawSportType, locale: string): string {
        switch (locale) {
            case "ru":
                return raw.names.ru;
            case "en":
                return raw.names.en;
            case "es":
                return raw.names.es;
            case "pt":
                return raw.names.pt;
            case "ua":
                return raw.names.ua;
            default: {
                throw new Error("Unsupported language: " + locale);
            }
        }
    }
}

interface RawSportType {
    id: ESportType,
    index: number,
    icon: string,
    names: {
        en: string;
        ru: string;
        es: string;
        pt: string;
        ua: string;
    };
}

const rawSportTypeArray: RawSportType[] = [
    {
        id: ESportType.Football,
        index: 0,
        icon: "Soccer",
        names: {
            en: "Football",
            ru: "Футбол",
            ua: "Футбол",
            es: "Fútbol",
            pt: "Fútbol"
        }
    },
    {
        id: ESportType.Basketball,
        index: 1,
        icon: "Basketball",
        names: {
            en: "Basketball",
            ru: "Баскетбол",
            ua: "Баскетбол",
            es: "Baloncesto",
            pt: "Baloncesto"
        }
    },
    {
        id: ESportType.Hockey,
        index: 2,
        icon: "Ice-Hockey",
        names: {
            en: "Hockey",
            ru: "Хоккей",
            ua: "Хокей",
            es: "Hockey",
            pt: "Hockey"
        }
    },
    {
        id: ESportType.Tennis,
        index: 3,
        icon: "Tennis",
        names: {
            en: "Tennis",
            ru: "Теннис",
            ua: "Теніс",
            es: "Tenis",
            pt: "Tenis"
        }
    },
    {
        id: ESportType.Volleyball,
        index: 4,
        icon: "Volleyball",
        names: {
            en: "Volleyball",
            ru: "Волейбол",
            ua: "Волейбол",
            es: "Voleibol",
            pt: "Voleibol"
        }
    },
    {
        id: ESportType.Baseball,
        index: 5,
        icon: "Baseball",
        names: {
            en: "Baseball",
            ru: "Бейсбол",
            ua: "Бейсбол",
            es: "Béisbol",
            pt: "Béisbol"
        }
    },
    {
        id: ESportType.Rugby,
        index: 6,
        icon: "Rugby",
        names: {
            en: "Rugby",
            ru: "Регби",
            ua: "Регбі",
            es: "Rugby",
            pt: "Rugby"
        }
    },
    {
        id: ESportType.AmericanFootball,
        index: 7,
        icon: "Football",
        names: {
            en: "Am. football",
            ru: "Ам. футбол",
            ua: "Ам. футбол",
            es: "Fútbol Am.",
            pt: "Fútbol Am."
        }
    },
    {
        id: ESportType.Boxing,
        index: 8,
        icon: "Boxing",
        names: {
            en: "UFC/MMA",
            ru: "UFC/MMA",
            ua: "UFC/MMA",
            es: "UFC/MMA",
            pt: "UFC/MMA"
        }
    },
    {
        id: ESportType.Handball,
        index: 9,
        icon: "Handball",
        names: {
            en: "Handball",
            ru: "Гандбол",
            ua: "Гандбол",
            es: "Balonmano",
            pt: "Balonmano"
        }
    },
    {
        id: ESportType.TableTennis,
        index: 10,
        icon: "TableTennis",
        names: {
            en: "Table tennis",
            ru: "Наст. теннис",
            ua: "Наст. теніс",
            es: "Tenis de mesa",
            pt: "Tenis de mesa"
        }
    }
];

export const sportTypes = new SportTypeHolder();
export const supportedSportTypeIds = rawSportTypeArray.map(r => r.id);
