<template>
    <div style="display: none; width: 100%" ref="printContent">
        <table>
            <tr><td colspan="2" align="center" class="padding-right"><logo-print></logo-print></td></tr>
            <tr>
                <td colspan="2" class="text-big"><b>{{ pointName }}</b></td>
            </tr>
            <tr v-if="printAppCode">
                <td colspan="2" class="text-big padding-right"><b>{{ appCode }}</b></td>
            </tr>
            <tr>
                <td colspan="2" class="text-big"><b>{{ pointAddress }}</b></td>
            </tr>
            <tr v-if="pointDescription">
                <td colspan="2" class="text-normal"><b>{{ pointDescription }}</b></td>
            </tr>
            <tr v-if="userNumber">
                <td>{{$t("betting.betSlip.common.userNumber")}}</td><td><b>{{ userNumber }}</b></td>
            </tr>
            <tr>
                <td colspan="2" class="text-divider">---------------------------</td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">
                    {{ $t("betting.betSlip.print.card") }} {{ responseInfo.innerCardId }}
                    {{ $t("betting.betSlip.print.code") }} <b>{{ formattedPayCode }}</b>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">
                    {{ $t("betting.betSlip.print.time") }} {{ $d(betDate, "long") }}
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">
                    {{ $t("betting.betSlip.print.endTime") }}
                    {{ $d(validUntil, "long") }}
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-divider">---------------------------</td>
            </tr>
            <template v-for="stake of simpleStakes" :key="stake.templateKey">
                <print-simple-stake-row :point-currency="pointCurrency" :stake="stake"/>
                <tr>
                    <td colspan="2" class="text-divider">---------------------------</td>
                </tr>
            </template>
            <template v-for="stake of combinedStakes" :key="stake.templateKey">
                <print-combined-stake-row :stake="stake" :simple-stakes="stake.simples"
                                          :point-currency="pointCurrency"/>
                <tr>
                    <td colspan="2" class="text-divider">---------------------------</td>
                </tr>
            </template>
            <tr>
                <td class="text-big"><b>{{ $t("betting.betSlip.common.total") }}</b></td>
                <td class="text-big"><b>{{ $n(betTotal.total, "currency") }} {{ pointCurrency }}</b></td>
            </tr>
            <tr>
                <td class="text-big"><b>{{ $t("betting.betSlip.common.maxWin") }}</b></td>
                <td class="text-big"><b>{{ $n(betTotal.maxWin, "currency") }} {{ pointCurrency }}</b></td>
            </tr>
            <tr><td colspan="2" align="center"><svg ref="barcode" ></svg></td></tr>
            <tr>
                <td colspan="2" class="text-divider">---------------------------</td>
            </tr>
            <tr>
                <td colspan="2" class="text-big padding-right">{{ $t("betting.betSlip.print.confirm") }}</td>
            </tr>
        </table>
    </div>
</template>

<script src="./print-accepted-bet-component.ts"/>
