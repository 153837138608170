import { Module, Store } from "vuex";
import { RootState, RootStore } from "@sportaq/vuex/index";
import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import { Card } from "@sportaq/model/cashier/card";
import AbstractStore from "@sportaq/vuex/abstract-store";
import { AccountDeposit } from "@sportaq/model/cashier/account-deposit";
import { CashierPoint } from "@sportaq/model/cashier/point";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { CasinoDepositOperation, FinOperation } from "@sportaq/model/cashier/fin-operation";
import {
    HorseWagerContainer,
    WagerContainer,
    WC_TYPE_COMMON
} from "@sportaq/model/cashier/wager-container";
import { appLogger } from "@sportaq/common/utils/logger";
import { User } from "@sportaq/model/common/user-accounts";

export const cashierStoreModule = "cashierStore";

export const ENTRY_TYPE_CARD = 1;
export const ENTRY_TYPE_OPERATION = 2;
export const ENTRY_TYPE_CASINO_DEPOSIT_OPERATION = 3;
export const ENTRY_TYPE_HORSE = 4;

export type CardEntry = {
    entryType: 1;
    value: Card;
    isHighlighted: boolean;
}

export type OperationEntry = {
    entryType: 2;
    value: FinOperation;
    isHighlighted: boolean;
}

export type CasinoDepositEntry = {
    entryType: 3;
    value: CasinoDepositOperation;
    isHighlighted: boolean;
}

export type HorseEntry = {
    entryType: 4;
    value: HorseWagerContainer;
    isHighlighted: boolean;
}
export type MonitoringEntry = CardEntry | OperationEntry | CasinoDepositEntry | HorseEntry;

interface CashierStoreState {
    user: User | undefined,
    accountDeposits: AccountDeposit[];
    voucherSellHistory: VoucherSellInfo[];
    voucherBuyHistory: VoucherSellInfo[];
    points: ShortPointInfo[];
    cards: MonitoringEntry[];
    casitoDepositOperations: Map<number, CasinoDepositOperation[]>,
    isAppInstallationAvailable: boolean
}

enum Getters {
    GET_ACCOUNT_DEPOSITS = "getAccountDeposits",
    GET_VOUCHER_SELL_HISTORY = "getVoucherSellHistory",
    GET_VOUCHER_BUY_HISTORY = "getVoucherBuyHistory",
    GET_CARDS = "getCards",
    GET_POINTS = "getPoints",
    GET_USER = "getUser",
    GET_APP_INSTALLATION_AVAILABLE = "getAppInstallationAvailable"
}

enum Mutations {
    ADD_ACCOUNT_DEPOSIT = "addAccountDeposit",
    ADD_VOUCHER_SELL = "addVoucherSell",
    ADD_VOUCHER_BUY = "addVoucherBuy",
    ADD_CARDS = "addCards",
    MARK_AS_CANCELED = "markAsCanceled",
    BLINK_ENTRY = "blink",
    EXPAND_COLLAPSE_CARD = "expandCollapseCard",
    UPDATE_CARD = "updateCard",
    CLEAR_CARDS = "clearCards",
    CLEAR_STORE = "slearStore",
    SET_POINTS = "setPoints",
    SET_POINT_DEPOSIT = "setPointDeposit",
    UPDATE_POINTS_BALANCE = "updatePointsBalance",
    ADD_WAGER_CONTAINERS = "addWagerContainers",
    SET_USER = "setUser",
    SET_APP_INSTALLATION_AVAILABLE = "setAppInstallationAvailable"
}

enum Actions {
    DUMMY = "dummyAction"
}

export const CashierStoreModule: Module<CashierStoreState, RootState> = {
    namespaced: true,
    state: {
        user: undefined,
        accountDeposits: [],
        voucherSellHistory: [],
        voucherBuyHistory: [],
        cards: [],
        points: [],
        isAppInstallationAvailable: false,
        casitoDepositOperations: new Map<number, CasinoDepositOperation[]>()
    },
    getters: {
        [Getters.GET_ACCOUNT_DEPOSITS]: state => state.accountDeposits,
        [Getters.GET_VOUCHER_SELL_HISTORY]: state => state.voucherSellHistory,
        [Getters.GET_VOUCHER_BUY_HISTORY]: state => state.voucherBuyHistory,
        [Getters.GET_CARDS]: state => state.cards,
        [Getters.GET_POINTS]: state => state.points,
        [Getters.GET_USER]: state => state.user,
        [Getters.GET_APP_INSTALLATION_AVAILABLE]: state => state.isAppInstallationAvailable
    },
    mutations: {
        [Mutations.ADD_ACCOUNT_DEPOSIT]: mutationAddAccountDeposit,
        [Mutations.ADD_VOUCHER_SELL]: mutationAddVoucherSell,
        [Mutations.ADD_VOUCHER_BUY]: mutationAddVoucherBuy,
        [Mutations.ADD_CARDS]: addCards,
        [Mutations.MARK_AS_CANCELED]: markAsCanceled,
        [Mutations.EXPAND_COLLAPSE_CARD]: expandCollapseCard,
        [Mutations.UPDATE_CARD]: updateCard,
        [Mutations.CLEAR_CARDS]: clearCards,
        [Mutations.CLEAR_STORE]: clearStore,
        [Mutations.SET_POINTS]: setPoints,
        [Mutations.SET_POINT_DEPOSIT]: setPointDeposit,
        [Mutations.UPDATE_POINTS_BALANCE]: updatePointsBalance,
        [Mutations.BLINK_ENTRY]: blinkEntry,
        [Mutations.ADD_WAGER_CONTAINERS]: addWagerContainers,
        [Mutations.SET_USER]: (state, user) => { state.user = user; },
        [Mutations.SET_APP_INSTALLATION_AVAILABLE]: (state, value) => {
            state.isAppInstallationAvailable = value;
        }
    }
};

function findCasinoDepositOperation (entries: MonitoringEntry[], containerDate: Date, pointId: number): CasinoDepositOperation | undefined {
    for (const entry of entries) {
        if (entry.entryType === ENTRY_TYPE_CASINO_DEPOSIT_OPERATION && entry.value.pointId === pointId) {
            if (entry.value.acceptServerTime && entry.value.acceptServerTime < containerDate) {
                return entry.value;
            }
        }
    }
}

function addWagerContainers (state: CashierStoreState, payload: WagerContainer[]) {
    for (const container of payload) {
        if (container.wcType === WC_TYPE_COMMON) {
            const depositOperation = findCasinoDepositOperation(state.cards, container.item.initTime, container.pointId);
            if (depositOperation) {
                depositOperation.wagerContainers.unshift(container);
            } else {
                appLogger.logger.warn("Deposit not found");
            }
        }
    }
}

function blinkEntry (state: CashierStoreState, id: number) {
    const e = state.cards.find(obj => obj.value.id === id);
    if (e) {
        e.isHighlighted = !e.isHighlighted;
    }
}

function clearStore (state: CashierStoreState) {
    state.user = undefined;
    state.accountDeposits = [];
    state.voucherSellHistory = [];
    state.voucherBuyHistory = [];
    state.points = [];
    state.cards = [];
    state.casitoDepositOperations = new Map<number, CasinoDepositOperation[]>();
}

function clearCards (state: CashierStoreState) {
    state.cards = [];
}

function expandCollapseCard (state: CashierStoreState, id: number) {
    const card = state.cards.find(obj => {
        return obj.value.id === id;
    });
    if (card) {
        card.value.expanded = !card.value.expanded;
    }
}

function updatePointsBalance (state: CashierStoreState, payload: ShortPointInfo[]) {
    for (const balance of payload) {
        let point: ShortPointInfo | undefined;
        for (const p of state.points) {
            if (p.id === balance.id) {
                point = p;
                break;
            }
        }
        if (point) {
            point.balance = balance.balance;
            point.casinoBalance = balance.casinoBalance;
        } else {
            state.points.push(balance);
        }
    }
}

function setPointDeposit (state: CashierStoreState, payload: {bpGuid: string, value: number}) {
    for (const point of state.points) {
        if (point.bpGuid === payload.bpGuid) {
            point.balance = payload.value;
            break;
        }
    }
}

function markAsCanceled (state: CashierStoreState, cardId: number) {
    const card = state.cards.find(obj => {
        if (obj.entryType !== ENTRY_TYPE_CARD) {
            return false;
        }
        return obj.value.id === cardId;
    });
    if (card && card.entryType === ENTRY_TYPE_CARD) {
        card.value.isCancel = true;
        for (const cc of card.value.containers) {
            for (const ci of cc.items) {
                ci.draftStatusCode = 4;
            }
        }
    }
}

function updateCard (state: CashierStoreState, card: Card) {
    const cardsTotal = state.cards.length;
    for (let i = 0; i < cardsTotal; i++) {
        const c = state.cards[i];
        if (c.entryType === ENTRY_TYPE_CARD) {
            if (c.value.id === card.id) {
                c.value = card;
                break;
            }
        }
    }
}

function setPoints (state: CashierStoreState, payLoad: ShortPointInfo[]) {
    state.points = payLoad;
}

function addCards (state: CashierStoreState, payLoad: MonitoringEntry[]) {
    state.cards = payLoad.concat(state.cards);
}

function mutationAddAccountDeposit (state: CashierStoreState, payload: { item: AccountDeposit }) {
    state.accountDeposits.push(payload.item);
}

function mutationAddVoucherSell (state: CashierStoreState, payload: { item: VoucherSellInfo }) {
    state.voucherSellHistory.push(payload.item);
}

function mutationAddVoucherBuy (state: CashierStoreState, payload: { item: VoucherSellInfo }) {
    state.voucherBuyHistory.push(payload.item);
}

export interface CashierStore {
    getAccountDeposits (): AccountDeposit[];

    addAccountDeposit (deposit: AccountDeposit): void;

    getVoucherSellHistory (): VoucherSellInfo[];

    addVoucherSell (voucher: VoucherSellInfo): void;

    getVoucherBuyHistory (): VoucherSellInfo[];

    addVoucherBuy (voucher: VoucherSellInfo): void;

    getCards (): MonitoringEntry [];

    markAsCanceled (innerCardId: number): void;

    addCards (cards: MonitoringEntry[]): void;

    blinkEntry (id: number): void;

    expandCollapseCard (id: number): void;

    isAppInstallationAvailable: boolean;
    isMonitoringActive: boolean;
    point: CashierPoint | undefined;
    currentPassword: string;
    user: User;
    updateCard (card: Card): void;
    clearCards (): void;
    getPoints(): ShortPointInfo[];
    setPoints(points: ShortPointInfo[]): void;

    setPointDeposit (bpGuid: string, value: number): void;

    addWagerContainers (containers: WagerContainer[]): void;

    updatePoints (points: ShortPointInfo[]): void;

    clearStore (): void;
}

class CashierStoreImpl extends AbstractStore<RootState, Getters, Mutations, Actions> implements CashierStore {
    private _point?: CashierPoint;
    private _currentPassword: string;

    isMonitoringActive = false;

    set isAppInstallationAvailable (value: boolean) {
        this.mutate(Mutations.SET_APP_INSTALLATION_AVAILABLE, value);
    }

    get isAppInstallationAvailable (): boolean {
        return this.get(Getters.GET_APP_INSTALLATION_AVAILABLE);
    }

    get user (): User {
        return this.get(Getters.GET_USER);
    }

    set user (value: User) {
        this.mutate(Mutations.SET_USER, value);
    }

    get currentPassword (): string {
        return this._currentPassword;
    }

    set currentPassword (value: string) {
        this._currentPassword = value;
    }

    constructor (store: Store<RootState>) {
        super(store, cashierStoreModule);
        this._currentPassword = "";
    }

    clearCards (): void {
        this.mutate(Mutations.CLEAR_CARDS);
    }

    addCards (cards: MonitoringEntry[]): void {
        if (cards.length === 0) {
            return;
        }
        this.mutate(Mutations.ADD_CARDS, cards);
    }

    setPoints (points: ShortPointInfo[]): void {
        const sorted = points.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.mutate(Mutations.SET_POINTS, sorted);
    }

    updatePoints (points: ShortPointInfo[]) {
        const sorted = points.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.mutate(Mutations.UPDATE_POINTS_BALANCE, sorted);
    }

    getPoints (): ShortPointInfo[] {
        return this.get(Getters.GET_POINTS);
    }

    getCards (): MonitoringEntry [] {
        return this.get(Getters.GET_CARDS);
    }

    markAsCanceled (innerCardId: number): void {
        this.mutate(Mutations.MARK_AS_CANCELED, innerCardId);
    }

    expandCollapseCard (id: number): void {
        this.mutate(Mutations.EXPAND_COLLAPSE_CARD, id);
    }

    addAccountDeposit (deposit: AccountDeposit): void {
        this.mutate(Mutations.ADD_ACCOUNT_DEPOSIT, { item: deposit });
    }

    getAccountDeposits (): AccountDeposit[] {
        return this.get(Getters.GET_ACCOUNT_DEPOSITS);
    }

    addVoucherSell (voucher: VoucherSellInfo) {
        this.mutate(Mutations.ADD_VOUCHER_SELL, { item: voucher });
    }

    getVoucherSellHistory (): VoucherSellInfo[] {
        return this.get(Getters.GET_VOUCHER_SELL_HISTORY);
    }

    addVoucherBuy (voucher: VoucherSellInfo) {
        this.mutate(Mutations.ADD_VOUCHER_BUY, { item: voucher });
    }

    getVoucherBuyHistory (): VoucherSellInfo[] {
        return this.get(Getters.GET_VOUCHER_BUY_HISTORY);
    }

    get point (): CashierPoint | undefined {
        return this._point;
    }

    set point (point: CashierPoint | undefined) {
        this._point = point;
    }

    updateCard (card: Card): void {
        this.mutate(Mutations.UPDATE_CARD, card);
    }

    setPointDeposit (bpGuid: string, value: number): void {
        this.mutate(Mutations.SET_POINT_DEPOSIT, { bpGuid, value });
    }

    blinkEntry (id: number): void {
        this.mutate(Mutations.BLINK_ENTRY, id);
    }

    addWagerContainers (containers: WagerContainer[]): void {
        if (containers.length === 0) {
            return;
        }
        this.mutate(Mutations.ADD_WAGER_CONTAINERS, containers);
    }

    clearStore (): void {
        this.mutate(Mutations.CLEAR_STORE);
    }
}

export const cashierStoreSymbol = Symbol("Cashier Store");

export function provideCashierStore (rootStore: RootStore) {
    const cashierStore = new CashierStoreImpl(rootStore.store);
    provide(cashierStoreSymbol, cashierStore);
}

export function useCashierStore (): CashierStore {
    return use(cashierStoreSymbol);
}
