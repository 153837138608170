import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import {
    forEach,
    getAttribute, getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { parseDate } from "@sportaq/common/utils/time-utils";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    Card,
    CardContainer,
    CardContainerImpl,
    CardImpl,
    CardItem,
    CardItemImpl,
    Partition,
    Position
} from "@sportaq/model/cashier/card";
import { getParticipantList } from "@sportaq/model/common/participants-functions";
import { Participant } from "@sportaq/model/betting/events/event";
import { parseQuotationKey } from "@sportaq/services/rest/messages/bet-slip/common/card-functions";

export abstract class BaseCardRequest extends AbstractMessageHandler<Card[]> {
    protected constructor (readonly locale: string, readonly maxCoef: number) {
        super();
    }

    private getLocalizedName (el: Element) {
        const localizedName = getAttribute(el, "name" + this.locale.toUpperCase());
        if (localizedName) {
            return localizedName;
        }
        return getRequiredAttribute(el, "nameEN");
    }

    parseCards (action: Element): Card[] {
        const cards: Card[] = [];
        const points: { [key: number]: string } = {};
        const sportTypes: { [key: number]: string } = {};
        const positions: { [key: number]: Position } = {};
        const partitions: { [key: number]: Partition } = {};
        const pointListElement = getRequiredChild(action, "PointList");
        for (let pointElement = pointListElement.firstElementChild; pointElement != null; pointElement = pointElement.nextElementSibling) {
            const id = getRequiredIntAttribute(pointElement, "Id");
            points[id] = getRequiredAttribute(pointElement, "name");
        }
        const sportTypeListElement = getRequiredChild(action, "SportTypeList");
        for (let sportElement = sportTypeListElement.firstElementChild; sportElement != null; sportElement = sportElement.nextElementSibling) {
            const id = getRequiredIntAttribute(sportElement, "Id");
            sportTypes[id] = this.getLocalizedName(sportElement);
        }

        const partitionListElement = getRequiredChild(action, "PartitionList");
        for (let partitionElement = partitionListElement.firstElementChild; partitionElement != null; partitionElement = partitionElement.nextElementSibling) {
            const id = getRequiredIntAttribute(partitionElement, "Id");
            const name = this.getLocalizedName(partitionElement);
            const sportTypeId = getRequiredIntAttribute(partitionElement, "SportTypeId");
            partitions[id] = {
                id,
                name,
                sportTypeId,
                sportName: sportTypes[sportTypeId]
            };
        }

        const positionListElement = getRequiredChild(action, "PositionList");
        for (let positionElement = positionListElement.firstElementChild; positionElement != null; positionElement = positionElement.nextElementSibling) {
            const id = getRequiredIntAttribute(positionElement, "Id");
            const eventElement = positionElement.firstElementChild;
            if (eventElement !== null) {
                positions[id] = {
                    id,
                    startTime: parseDate(getRequiredAttribute(eventElement, "startTime")),
                    partition: partitions[getRequiredIntAttribute(eventElement, "partitionId")],
                    participants: this.parseParticipants(eventElement)
                };
            }
        }

        const cardListElement = getRequiredChild(action, "CardList");

        for (let cardElement = cardListElement.firstElementChild; cardElement != null; cardElement = cardElement.nextElementSibling) {
            const id = getRequiredIntAttribute(cardElement, "Id");
            const pointId = getRequiredIntAttribute(cardElement, "pointId");
            const clientCoefFormat = getRequiredIntAttribute(cardElement, "clientCoefFormat");
            const acceptServerTime = getRequiredAttribute(cardElement, "acceptServerTime");
            const acceptClientTime = getRequiredAttribute(cardElement, "acceptClientTime");
            const isCancel = getRequiredAttribute(cardElement, "isCancel") === "1";
            const isBonus = getRequiredAttribute(cardElement, "isBonus") === "1";
            const sumStake = getRequiredFloatAttribute(cardElement, "sumStake");
            const sumPay = getRequiredFloatAttribute(cardElement, "sumPay");
            const innerCardId = getRequiredFloatAttribute(cardElement, "innerCardId");
            const payCode = getRequiredFloatAttribute(cardElement, "payCode");
            const currencyMark = getRequiredAttribute(cardElement, "currencyMark");
            const userNumber = getAttribute(cardElement, "userNumber");
            const card = new CardImpl(id, points[pointId], parseDate(acceptServerTime), parseDate(acceptClientTime),
                clientCoefFormat, isCancel, sumStake, sumPay, innerCardId, payCode, currencyMark, pointId, this.maxCoef, userNumber);
            cards.push(card);
            card.isBonus = isBonus;
            for (let cardContainerElement = cardElement.firstElementChild; cardContainerElement != null; cardContainerElement = cardContainerElement.nextElementSibling) {
                const id = getRequiredIntAttribute(cardContainerElement, "Id");
                const containerType = getRequiredIntAttribute(cardContainerElement, "containerType");
                const expressEvents = getRequiredIntAttribute(cardContainerElement, "expressEvents");
                const rateId = getRequiredIntAttribute(cardContainerElement, "rateId");
                const sumStake = getRequiredFloatAttribute(cardContainerElement, "sumStake");
                const bonusSchemeCode = getRequiredIntAttribute(cardContainerElement, "bonusSchemeCode");
                const isPaid = getRequiredIntAttribute(cardContainerElement, "isPaid") === 1;
                const items: CardItem[] = [];
                for (let cardItemElement = cardContainerElement.firstElementChild; cardItemElement != null; cardItemElement = cardItemElement.nextElementSibling) {
                    const item = new CardItemImpl(
                        getRequiredIntAttribute(cardItemElement, "Id"),
                        positions[getRequiredIntAttribute(cardItemElement, "positionId")],
                        parseQuotationKey(cardItemElement),
                        getRequiredFloatAttribute(cardItemElement, "coef"),
                        getRequiredAttribute(cardItemElement, "isLive") === "1",
                        getRequiredFloatAttribute(cardItemElement, "sumWin"),
                        getRequiredIntAttribute(cardItemElement, "draftStatusCode"),
                        cardItemElement.getAttribute("resultText"));
                    items.push(item);
                }
                items.sort((a: CardItem, b: CardItem) => {
                    let result = a.position.startTime.getTime() - b.position.startTime.getTime();
                    if (result === 0) {
                        result = a.position.partition.name.localeCompare(b.position.partition.name);
                    }
                    if (result === 0) {
                        const aParticipantList = getParticipantList(a, a.quotationKey);
                        const bParticipantList = getParticipantList(b, b.quotationKey);
                        let counter = 0;
                        while (result === 0 && counter < Math.min(aParticipantList.length, bParticipantList.length)) {
                            result = aParticipantList[counter].name.localeCompare(bParticipantList[counter].name);
                            counter++;
                        }
                    }
                    return result;
                });
                const cardContainer = new CardContainerImpl(id, containerType, expressEvents, rateId, sumStake, this.maxCoef, bonusSchemeCode, isPaid, items);
                card.containers.push(cardContainer);
            }
        }
        const draftContainerListElement = getRequiredChild(action, "DraftContainerList");

        function findContainer (cards: Card[], cardContainerId: number): CardContainer | undefined {
            for (const card of cards) {
                for (const cardElement of card.containers) {
                    if (cardElement.id === cardContainerId) {
                        return cardElement;
                    }
                }
            }
            return undefined;
        }

        for (let dc = draftContainerListElement.firstElementChild; dc != null; dc = dc.nextElementSibling) {
            const cardContainerId = getRequiredIntAttribute(dc, "cardcontainerId");
            const cardContainer = findContainer(cards, cardContainerId);
            if (cardContainer) {
                cardContainer.sumPay = getRequiredFloatAttribute(dc, "sumPay");
            }
        }
        return cards.sort((a, b) => b.innerCardId - a.innerCardId);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): Card[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "1310") {
            throw new LocalizedError(`errors.mtl.qSt100.code${serverCode}`);
        }
        return this.parseCards(action);
    }

    protected appendSelector (request: XmlRequest, actionElement: Element) {
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.point.Point"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.betting.DraftContainer"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.event.Partition",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.finance.CurrencyValue",
            allFields: "true"
        });
        const cardElement = request.addChild(selectorElement, "SelectObject", {
            class: "ps.betting.Card",
            allFields: "true"
        });
        const cardContainerElement = request.addChild(cardElement, "SelectObject", {
            class: "ps.betting.CardContainer",
            allFields: "true"
        });
        request.addChild(cardContainerElement, "SelectObject", {
            class: "ps.betting.CardItem",
            allFields: "true"
        });
        request.addChild(cardContainerElement, "SelectField", {
            name: "isPaid"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.event.SportType"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.event.Participant",
            allFields: "true"
        });
        const positionElement = request.addChild(selectorElement, "SelectObject", {
            class: "ps.line.Position"
        });
        request.addChild(positionElement, "SelectField", {
            name: "PositionId"
        });
        const eventElement = request.addChild(positionElement, "SelectObject", {
            class: "ps.event.Event"
        });
        request.addChild(eventElement, "SelectField", {
            name: "eventId"
        });
        request.addChild(eventElement, "SelectField", {
            name: "startTime"
        });
        request.addChild(eventElement, "SelectField", {
            name: "partitionId"
        });
    }

    private parseParticipants (eventElement: Element): Participant[] {
        const participants: Participant[] = [];
        const participantList = getRequiredChild(eventElement, "ParticipantList");
        forEach(participantList, "PC", el => (participants.push(
            new Participant(getRequiredIntAttribute(el, "Id"), getRequiredAttribute(el, "name"))
        )));
        return participants;
    }
}
