import { SetupContext } from "vue";
import { useCardsStateCommon } from "@sportaq/vue/components/mobile-cashier/cards-state-common";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";

export default {
    name: "CardsState",
    emits: [CLOSE_EVENT],
    setup (props: unknown, context: SetupContext) {
        return useCardsStateCommon(context);
    }
};
