import { useCashierRestService } from "@sportaq/services/cashier";
import { useSettings } from "@sportaq/services/index";
import { CashierSettingsService } from "@sportaq/services/cashier/settings/settings-service";
import { CommonSettings } from "@sportaq/services/base-setting-service/common-settings";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { RegistrationData } from "@sportaq/model/cashier/registration-data";
import { ESupportedLocale } from "@sportaq/i18n/interfaces/interfaces";
import { changeLanguage, getAvailableLocales, resolveLanguage } from "@sportaq/i18n/index";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { setTheme } from "@sportaq/vue/components/common/preferences-dialog/preferences-dialog-common";
import { appLogger } from "@sportaq/common/utils/logger";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { useRootStore } from "@sportaq/vuex/index";
import { EGameService } from "@sportaq/model/common/point-settings";

export function useLoginViewCommon (loginSuccessCallback: (userPointName: string | undefined,
                                                           userPointAddress: string | undefined,
                                                           stationDescription: string | undefined,
                                                           restrictedServices: EGameService[] | undefined) => void,
isMobile: boolean) {
    const i18n = useI18n();
    const loc = resolveLanguage();
    changeLanguage(i18n, loc);
    const selectedLanguage = ref(loc);
    const restService = useCashierRestService();
    const settingsService = useSettings() as CashierSettingsService;
    const settings = ref<CommonSettings>(new CommonSettings(settingsService.appCode, settingsService.partnerCode, settingsService.restServerAddress,
        settingsService.base64Logo));
    const usePrintPreview = ref(settingsService.usePrintPreview);
    const rootStore = useRootStore();
    const cashierStore = useCashierStore();
    const balanceStore = useBalanceStore();
    const login = ref("");
    const password = ref("");
    const registrationData = new RegistrationData();
    const isSettingsDialogActive = ref(false);
    const isRegistrationDialogActive = ref(false);
    const isResetPasswordDialogActive = ref(false);
    const isPasswordVisible = ref(false);
    const supportedLanguages = getAvailableLocales();
    const requestPending = ref(false);

    onMounted(updateLogin);

    function updateLogin () {
        let l = settingsService.userNumber;
        if (l) {
            if (settingsService.login) {
                l = l + " (" + settingsService.login + ")";
            }
            login.value = l;
        }
    }

    function onLanguageSelected () {
        changeLanguage(i18n, selectedLanguage.value);
    }

    function onLogin () {
        if (requestPending.value) {
            return;
        }
        if (!isMobile) {
            if (!settingsService.appCode || !settingsService.partnerCode) {
                showMessageBox(i18n.t("cashier.loginForm.settingsNotFilled"));
                return;
            }
            if (!settingsService.restServerAddress) {
                showMessageBox(i18n.t("cashier.loginForm.serverAddressNotFilled"));
                return;
            }
        }
        if (login.value) {
            let l = login.value;
            const spaceIndex = l.indexOf(" ");
            if (spaceIndex > 0) {
                l = l.substr(0, spaceIndex);
            }
            appLogger.logger.info("Trying to login as " + l);
            requestPending.value = true;
            restService.login(l, password.value, settingsService.partnerCode, isMobile).then(p => {
                if (!p.pointSettings) {
                    showMessageBox("Can't load point settings");
                    return;
                }
                if (password.value.length < 6) {
                    showMessageBox(i18n.t("common.tempPassword"));
                }
                cashierStore.point = p;
                cashierStore.user = p.user;
                cashierStore.currentPassword = password.value;
                settingsService.pointSettings = p.pointSettings;
                settingsService.setLogin(p.user.login, p.user.userNumber);
                settingsService.userNumber = p.user.userNumber;
                rootStore.userSharedInfo = {
                    userNumber: settingsService.pointSettings.workWithDNIonly ? undefined : p.user.userNumber,
                    countryId: p.user.countryId,
                    primaryAccountCurrencyId: p.user.userAccounts.getPrimaryAccount()?.currencyId ?? -1
                };
                const currency = p.user.userAccounts.getPrimaryAccount()?.currencyMark;
                if (currency) {
                    balanceStore.currency = currency;
                }
                if (isMobile) {
                    cashierStore.setPoints(p.points);
                }
                setTheme(settingsService, rootStore);
                appLogger.logger.info(`Logged in as ${l}`);
                return p;
            }).then(p => loginSuccessCallback(p?.userPointName, p?.userPointAddress, p?.userStationDescription, p?.restrictedServices)).catch(reason => {
                requestPending.value = false;
                if (reason instanceof LocalizedError) {
                    throw reason;
                }
                appLogger.logger.error(reason.toString() + " " + reason.stack);
                showMessageBox("Login error " + reason.toString() + " " + reason.stack);
            });
        }
    }

    function onSettingsDialogClose () {
        isSettingsDialogActive.value = false;
    }

    function onSettingsDialogSubmit (s: CommonSettings, pp: boolean) {
        settings.value = s;
        usePrintPreview.value = pp;
        settingsService.usePrintPreview = pp;
        settingsService.setCommonSettings(s);
        isSettingsDialogActive.value = false;
    }

    function onSettings () {
        isSettingsDialogActive.value = true;
    }

    function onRegistration () {
        isRegistrationDialogActive.value = true;
    }

    function onRegistrationClose () {
        isRegistrationDialogActive.value = false;
    }

    function onRegistrationSubmit (registrationData: RegistrationData) {
        restService.register(registrationData,
            registrationData.partnerGuid
                ? registrationData.partnerGuid
                : settings.value.partnerCode, isMobile).then((userNumber: string) => {
            isRegistrationDialogActive.value = false;
            settingsService.setLogin(registrationData.login, settingsService.userNumber);
            settingsService.userNumber = userNumber.toString();
            updateLogin();
        });
    }

    function onResetPassword () {
        isResetPasswordDialogActive.value = true;
    }

    function onResetPasswordClose () {
        isResetPasswordDialogActive.value = false;
    }

    return {
        ESupportedLocale,
        selectedLanguage,
        login,
        password,
        isSettingsDialogActive,
        isRegistrationDialogActive,
        isResetPasswordDialogActive,
        registrationData,
        settings,
        usePrintPreview,
        logins: settingsService.logins,
        supportedLanguages,
        requestPending,
        isPasswordVisible,
        onLanguageSelected,
        onLogin,
        onSettings,
        onSettingsDialogClose,
        onSettingsDialogSubmit,
        onRegistration,
        onRegistrationSubmit,
        onRegistrationClose,
        onResetPassword,
        onResetPasswordClose
    };
}
