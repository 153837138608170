<template>
    <div class="deposit-block">
        <div class="deposit-block-head">
            <label class="label">{{cardStatus}}</label>
            <div class="info">
                <div class="date">{{i18n.d(card.acceptServerTime, "long")}} <span class="color-error" v-if="card.isBonus">{{$t("webdesktop.cardsTable.bonus")}}</span></div>
                <div class="num" v-if="showPayCode">{{ $t("cashier.monitoring.table.object", {innerCardId: card.innerCardId, payCode: card.payCode}) }} </div>
                <div class="num" v-else>{{ $t("mobile.stakesView.cardCaption", {innerCardId: card.innerCardId}) }} </div>
            </div>
        </div>
        <div class="deposit-block-body" v-for="(container) in card.containers" :key="container.id">
            <div class="info">
                <div class="info-text">
                    <div class="title">{{
                            container.containerType === 3 ? $t("cashier.monitoring.table.containerType" + container.containerType,
                                    {
                                        itemCount: container.items.length,
                                        express: container.expressEvents
                                    }) :
                                $t("cashier.monitoring.table.containerType" + container.containerType)
                        }}</div>
                </div>
            </div>
            <div class="info" v-for="(item) in container.items" :key="item.id">
                <div class="info-text">
                    <div class="date">{{i18n.d(item.position.startTime, "long")}}</div>
                    <div class="name"><span class="live" v-if="item.isLive">LIVE</span> {{item.position.partition.sportName}}. {{item.position.partition.name}}</div>
                    <div class="title">{{ getParticipants(item) }} </div>
                    <div class="line">{{$t("webdesktop.cardsTable.quotationColumn")}}: <span v-html="getQuotationTitle(item)" /> {{ item.coef.toFixed(2) }}<strong></strong></div>
                    <div class="line" :class="{ 'color-success': item.itemStatus === 1, 'color-error': item.itemStatus === 2}">
                        {{ $t("cashier.monitoring.table.itemStatus"+item.itemStatus) }}
                    </div>
                </div>
            </div>
            <div class="deposit-totals">
                <div class="head">
                    <div class="item">{{$t("webdesktop.cardsTable.amountColumn")}}</div>
                    <div class="item">{{$t(container.isPaid ? "mobile.stakesView.containerStatusCaption" : "betting.betSlip.maxWin")}}</div>
                </div>
                <div class="body">
                    <div class="item">{{container.sumStake.toFixed(2)}}</div>
                    <div class="item">{{container.isPaid ? container.sumPay : container.getMaxWin().toFixed(2)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./card-panel-component.ts" />
