<template>
    <div>
        <div class="monitoring-betplaces">
            <point-panel @select="pointSelected"></point-panel>
        </div>
        <div class="deposit-tabs-body">
            <div class="deposit-section">
                <div class="deposit-section-head">
                    <div class="monitoring-actions">
                        <a href="" class="button button-secondary button-remove" :class="{'button-disabled': !canBeCancelled}"  @click.prevent="cancelCard">
                            <img src="@assets/img/icon-remove.png" alt="">
                        </a>
                        <a href="" class="button button-secondary" @click.prevent="search">
                            <img src="@assets/img/icon-search.png" alt="">
                        </a>
                        <a href="" class="button button-secondary"
                           :class="{'button-disabled': selectedCardRef === undefined || (selectedCardRef.entryType !== 1 && selectedCardRef.entryType !== 4)}"><img
                            src="@assets/img/icon-share.svg" alt="" @click.prevent="printCard"></a>
                        <a href="" class="button button-secondary" @click.prevent="reloadCards">
                            <img src="@assets/img/icon-sync.png" alt="">
                        </a>
                    </div>
                </div>
                <div class="deposit-section-body" v-if="cardsRef">
                    <template v-for="(entry) in cardsRef" :key="entry.value.id">
                        <race-entry-row v-if="entry.entryType === 4" :entry="entry" @dblclick="expandCard(entry.value.id)" @click="selectItem(entry)"></race-entry-row>
                        <div v-if="entry.entryType === 2 || entry.entryType === 3" class="line line-primary"
                             :class="{active: selectedCardRef && selectedCardRef.value.id === entry.value.id, 'has-children': entry.value.wagerContainers && entry.value.wagerContainers.length > 0}"
                             @dblclick="expandCard(entry.value.id)"
                             @click="selectItem(entry)">
                            <div class="line-date"><span>{{ entry.value.id }}</span> <span>{{ $d(entry.value.acceptServerTime, 'long') }}</span></div>
                            <div class="line-total smaller">
                                <div class="name">
                                    <span>{{ entry.value.pointName }}</span>&nbsp;
                                    <span>{{ $t("cashier.monitoring.operations." + entry.value.operationType) }}</span>
                                </div>
                                <div class="value">{{ entry.value.sum.toFixed(2) }}</div>
                            </div>
                        </div>
                        <div v-if="entry.entryType === 1" class="line line-primary has-children" @dblclick="expandCard(entry.value.id)" :class="{active: selectedCardRef && selectedCardRef.value.id === entry.value.id}" @click="selectItem(entry)">
                            <div class="line-date"><span>{{ entry.value.id }}</span> <span>{{ $d(entry.value.acceptServerTime, 'long') }}</span><br> <span>{{
                                    $t("cashier.monitoring.table.object", {
                                        innerCardId: entry.value.innerCardId,
                                        payCode: formatVoucherNumber(entry.value.payCode.toString())
                                    })
                                }}</span></div>
                            <div class="line-total smaller">
                                <div class="name" :class="{ 'color-success': !entry.value.isCancel}">
                                    <span>{{ entry.value.pointName }}</span>&nbsp;
                                    <span>{{$t(entry.value.isCancel ? "cashier.monitoring.table.iscancel" : entry.value.isPaid ? "cashier.monitoring.table.ispaid" : "cashier.monitoring.table.isaccepted")}}</span>
                                </div>
                                <div class="value">{{  entry.value.sumStake.toFixed(2) }}</div>
                            </div>
                        </div>
                        <card-panel v-if="entry.entryType === 1 && entry.value.expanded" :card="entry.value"></card-panel>
                        <div v-if="entry.entryType === 3 && entry.value.expanded" class="line-child">
                            <div class="line line-secondary" v-for="wc of entry.value.wagerContainers" :key="wc.id">
                                <div class="line-date"><span>{{wc.id}}</span> <span>{{ $d(wc.acceptServerTime, 'long') }}</span></div>
                                <div class="line-total smaller">
                                    <div class="name">{{wc.item.gameMark}}</div>
                                    <div class="value">{{wc.item.sumBet > 0 ? '-' + wc.item.sumBet.toFixed(2) : wc.item.sumWin.toFixed(2)}}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <sport-card-print v-if="printCardData" :printCardData="printCardData" ref="sportCardPrinter"></sport-card-print>
    <race-card-print v-if="raceCardData" :printCardData="raceCardData" ref="raceCardPrinter"></race-card-print>
    <input-box v-if="isCancelConfirmVisible" @close="onCancelConfirmationClose" @submit="doCancel"
               :caption="$t('cashier.menu.monitoring')" :message="$t('cashier.monitoring.deleteComfirm', {innerCardId: selectedCardRef.innerCardId})"/>
    <monitoring-search v-if="isSearchDialogVisible" @close="onSearchDialogClose"
                       @submit="onSearchDialogSubmit"></monitoring-search>
</template>

<script src="./monitoring-view-component.ts">
</script>
