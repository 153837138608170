import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { CasinoDepositOperation, EOperationType, FinOperation } from "@sportaq/model/cashier/fin-operation";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { parseDate } from "@sportaq/common/utils/time-utils";

export class QPO11FinOperations extends AbstractMessageHandler<FinOperation[]> {
    protected readonly requestType: string = "Q.PO.1.1";
    constructor (readonly stationId: number, readonly startTime: string, readonly lastOperationId: number, readonly pointFilter: number[] | undefined) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const finOperationFilterElement = request.addChild(filterElement, "FinOperationFilter", {
            logic: "AND"
        });
        const finOperationPeriodElement = request.addChild(finOperationFilterElement, "FinOperationPeriod", {
            logic: "AND"
        });
        const pointFilterElement = request.addChild(filterElement, "PointFilter", {
            logic: "AND"
        });
        if (this.pointFilter) {
            const pointListElement = request.addChild(pointFilterElement, "PointList");
            for (const pointId of this.pointFilter) {
                request.addChildWithValue(pointListElement, "PointId", pointId.toString());
            }
        } else {
            const stationFilterElement = request.addChild(pointFilterElement, "StationList", {});
            request.addChildWithValue(stationFilterElement, "StationId", this.stationId.toString());
        }
        const sessionFilterElement = request.addChild(filterElement, "SessionFilter", {
            logic: "AND"
        });
        request.addChildWithValue(sessionFilterElement, "User", "false");
        request.addChildWithValue(finOperationPeriodElement, "StartTime", this.startTime);
        request.addChildWithValue(finOperationFilterElement, "lastOperationId", this.lastOperationId.toString());
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.fin.FinOperation"
        });
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, head: Element): FinOperation[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "4110") {
            throw new LocalizedError(`errors.mtl.qSt100.code${serverCode}`);
        }
        const result: FinOperation[] = [];
        const operationListElement = getRequiredChild(action, "FinOperationList");
        const pointListElement = getRequiredChild(action, "PointList");
        const points: { [key: number]: string } = {};
        for (let pointElement = pointListElement.firstElementChild; pointElement != null; pointElement = pointElement.nextElementSibling) {
            const id = getRequiredIntAttribute(pointElement, "Id");
            points[id] = getRequiredAttribute(pointElement, "name");
        }
        for (let operationElement = operationListElement.firstElementChild; operationElement != null; operationElement = operationElement.nextElementSibling) {
            const operationType: EOperationType | undefined = this.parseOperationType(getRequiredAttribute(operationElement, "finOperationType"));
            if (!operationType || operationType === EOperationType.PAY_OPERATION_FOR_RACE_CARD) {
                continue;
            }
            const pointId = getRequiredIntAttribute(operationElement, "pointId");
            if (operationType === EOperationType.PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP) {
                const operation: CasinoDepositOperation = {
                    id: getRequiredIntAttribute(operationElement, "Id"),
                    acceptServerTime: parseDate(getRequiredAttribute(operationElement, "acceptServerTime")),
                    acceptClientTime: parseDate(getRequiredAttribute(operationElement, "acceptClientTime")),
                    operationType,
                    sum: getRequiredFloatAttribute(operationElement, "sumOper"),
                    pointId,
                    pointName: points[pointId],
                    expanded: false,
                    wagerContainers: []
                };
                result.push(operation);
            } else {
                result.push({
                    id: getRequiredIntAttribute(operationElement, "Id"),
                    acceptServerTime: parseDate(getRequiredAttribute(operationElement, "acceptServerTime")),
                    acceptClientTime: parseDate(getRequiredAttribute(operationElement, "acceptClientTime")),
                    operationType,
                    sum: getRequiredFloatAttribute(operationElement, "sumOper"),
                    pointId,
                    pointName: points[pointId],
                    expanded: false
                });
            }
        }
        return result;
    }

    parseOperationType (ot: string): EOperationType | undefined {
        switch (ot) {
            case "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH": return EOperationType.PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH;
            case "PAY_OPERATION_WITHDRAWAL_CASH": return EOperationType.PAY_OPERATION_WITHDRAWAL_CASH;
            case "PAY_OPERATION_VOUCHER_GENERATION_CASH": return EOperationType.PAY_OPERATION_VOUCHER_GENERATION_CASH;
            case "PAY_OPERATION_VOUCHER_REDEMPTION_CASH": return EOperationType.PAY_OPERATION_VOUCHER_REDEMPTION_CASH;
            case "PAY_OPERATION_VOUCHER_REDEMPTION_TRM": return EOperationType.PAY_OPERATION_VOUCHER_REDEMPTION_TRM;
            case "PAY_OPERATION_INCREASE_BALANCE_BP": return EOperationType.PAY_OPERATION_INCREASE_BALANCE_BP;
            case "PAY_OPERATION_NULLIFICATION_POINT_BALANCE": return EOperationType.PAY_OPERATION_NULLIFICATION_POINT_BALANCE;
            case "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP": return EOperationType.PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP;
            case "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP": return EOperationType.PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP;
            case "PAY_OPERATION_FOR_RACE_CARD": return EOperationType.PAY_OPERATION_FOR_RACE_CARD;
            case "PAY_OPERATION_FOR_RACE_WINPAID": return EOperationType.PAY_OPERATION_FOR_RACE_WINPAID;
            case "PAY_OPERATION_VOUCHER_GENERATION_BP": return EOperationType.PAY_OPERATION_VOUCHER_GENERATION_BP;
            case "PAY_OPERATION_WINPAID": return EOperationType.PAY_OPERATION_WINPAID;
            case "PAY_OPERATION_FOR_RACE_WINPAID_BP": return EOperationType.PAY_OPERATION_FOR_RACE_WINPAID_BP;
            case "PAY_OPERATION_FOR_RACE_WINPAID_CASH": return EOperationType.PAY_OPERATION_FOR_RACE_WINPAID_CASH;
            case "PAY_OPERATION_CANCEL_STAKE_BP": return EOperationType.PAY_OPERATION_CANCEL_STAKE_BP;
        }
        return undefined;
    }
}
