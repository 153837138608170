import { User } from "@sportaq/model/common/user-accounts";
import { EGameService, PointSettings } from "@sportaq/model/common/point-settings";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";

export class CashierPoint {
    private _point?: PointSettings;

    constructor (readonly user: User, readonly points: ShortPointInfo[],
                 readonly userPointName: string | undefined,
                 readonly userPointAddress: string | undefined,
                 readonly userStationDescription: string | undefined,
                 readonly restrictedServices: EGameService[] | undefined) {
    }

    get pointSettings (): PointSettings | undefined {
        return this._point;
    }

    set pointSettings (value: PointSettings | undefined) {
        this._point = value;
    }
}
