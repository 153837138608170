import { useRestService, useSettings } from "@sportaq/services/index";
import { useRootStore } from "@sportaq/vuex/index";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export function useDniInputCommon () {
    const valueRef = ref<string>("");
    const restService = useRestService();
    const rootStore = useRootStore();
    const i18n = useI18n();
    const settingsService = useSettings();

    function onSubmit () {
        restService.dniLogin(valueRef.value).then(result => {
            if (result.selfBlockedTime || result.isRegulatorBlocked) {
                valueRef.value = "";
                showMessageBoxEx(false, "",
                    result.selfBlockedTime
                        ? i18n.t("common.blocked.detailsMessage",
                            { date: i18n.d(result.selfBlockedTime, "long") })
                        : i18n.t("common.blocked.regulatorBlocked")
                );
                return;
            }
            rootStore.userSharedInfo = {
                userNumber: result.userNumber,
                countryId: rootStore.userSharedInfo.countryId,
                primaryAccountCurrencyId: settingsService.pointSettings.currency.typeId
            };
        });
    }

    return {
        valueRef,
        onSubmit
    };
}