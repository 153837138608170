import { computed, nextTick, PropType, ref, watch } from "vue";
import { CombinedStakeDTO, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import PrintSimpleStakeRow
    from "@sportaq/vue/components/betting/bet-slip/dialogs/dialog-bet-accepted/print/rows/simple/PrintSimpleStakeRow.vue";
import PrintCombinedStakeRow
    from "@sportaq/vue/components/betting/bet-slip/dialogs/dialog-bet-accepted/print/rows/combined/PrintCombinedStakeRow.vue";
import LogoPrint from "@sportaq/vue/components/common/logo-print/LogoPrint.vue";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import JsBarcode from "jsbarcode";
import {
    calculateBetTotal
} from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import { usePrintService, useSettings } from "@sportaq/services/index";
import { PlaceBetResponseSuccessInfo } from "@sportaq/model/types/place-bet-response";
import { useRootStore } from "@sportaq/vuex/index";

interface Props {
    responseInfo: PlaceBetResponseSuccessInfo;
    simpleStakes: SimpleStakeDTO[];
    combinedStakes: CombinedStakeDTO[];
    printTrigger: boolean;
}

export default {
    props: {
        responseInfo: {
            required: true,
            type: Object as PropType<PlaceBetResponseSuccessInfo>
        },
        simpleStakes: {
            required: true,
            type: Object as PropType<SimpleStakeDTO[]>
        },
        combinedStakes: {
            required: true,
            type: Object as PropType<CombinedStakeDTO[]>
        },
        printTrigger: {
            required: true,
            type: Boolean
        },
        printAppCode: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    components: {
        PrintSimpleStakeRow,
        PrintCombinedStakeRow,
        LogoPrint
    },
    setup (props: Props) {
        const settingsService = useSettings();
        const printService = usePrintService();
        const rootStore = useRootStore();

        const pointName = settingsService.pointSettings.name;
        const appCode = settingsService.appCode;
        const pointAddress = settingsService.pointSettings.address;
        const pointDescription = settingsService.pointSettings.stationDescription;
        const pointCurrency = settingsService.pointSettings.currencyName;
        const betDate = props.responseInfo.acceptClientTime;
        const validUntil = new Date(betDate).setMonth(betDate.getMonth() + 1);

        const barcode = ref<HTMLElement>();
        const printContent = ref<HTMLElement>();

        const formattedPayCode = computed(() => formatVoucherNumber(props.responseInfo.payCode));
        const betTotal = computed(() => calculateBetTotal(props.simpleStakes, props.combinedStakes,
            settingsService.pointSettings.draftCoef));
        const workWithDNIonly = settingsService.pointSettings.workWithDNIonly;
        const userNumber = computed(() => workWithDNIonly ? rootStore.userSharedInfo.userNumber : undefined);

        async function print () {
            JsBarcode(barcode.value).CODE128(props.responseInfo.payCode, {
                displayValue: false,
                textMargin: 0,
                width: 2,
                height: 50
            }).render();
            await nextTick();
            settingsService.usePrintPreview ? printService.printPreview(printContent.value?.innerHTML) : printService.print(printContent.value?.innerHTML);
        }

        watch(() => props.printTrigger, async () => (print()));

        return {
            userNumber,
            barcode,
            printContent,
            pointName,
            pointAddress,
            pointDescription,
            formattedPayCode,
            betDate,
            validUntil,
            pointCurrency,
            betTotal,
            appCode
        };
    }
};
