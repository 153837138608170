import { useCashierRestService } from "@sportaq/services/cashier";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import { getCurrencyName } from "@sportaq/model/common/point-settings";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { computed, ref, SetupContext } from "vue";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { TicketNotFoundError } from "@sportaq/services/cashier/rest/messages/a_vc_1_4_buy_voucher";

export function useBuyVoucherDialogCommon (isMobile: boolean, context: SetupContext) {
    const i18n = useI18n();
    const restService = useCashierRestService();
    const voucherCodeRef = ref<number>();
    const store = useCashierStore();
    const vouchers = computed<VoucherSellInfo[]>(() => store.getVoucherBuyHistory());
    const isRequestPending = ref(false);
    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function onBuy () {
        if (voucherCodeRef.value) {
            isRequestPending.value = true;
            restService.buyVoucher(isMobile, voucherCodeRef.value)
                .then(v => {
                    voucherCodeRef.value = undefined;
                    const localizedMessage = i18n.t("cashier.buyVoucherForm.success", { amount: v.amount + (v.currencyId ? getCurrencyName(v.currencyId) : "") });
                    store.addVoucherBuy(v);
                    isRequestPending.value = false;
                    showMessageBox(localizedMessage);
                }).catch((e) => {
                    isRequestPending.value = false;
                    if (e instanceof TicketNotFoundError) {
                        showMessageBox(i18n.t("cashier.buyVoucherForm.notfound"));
                    }
                });
        }
    }
    return {
        voucherCodeRef,
        vouchers,
        isRequestPending,

        onClose,
        onBuy
    };
}
