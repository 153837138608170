<template>
    <div class="popup active" id="change-password-dialog">
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.changePasswordDialog.caption")}}</div>
            <div class="popup-content">
                <Form @submit="changePassword" v-slot="{ errors, handleSubmit }">
                    <div class="form-line">
                        <div class="icons">
                          <img :src="require(`@assets/img/icon-eye-${oldPasswordVisible?'off':'on'}.png`)" @click.prevent="oldPasswordVisible = !oldPasswordVisible">
                        </div>
                        <label for="current-password" :class="{'label-error': errors.currentPassword}">{{$t("webdesktop.changePasswordDialog.currentPasswordLabel")}}</label>
                        <Field id="current-password" name="currentPassword" :type="oldPasswordVisible?'text':'password'" autocomplete="current-password" rules="required"/>
                        <div class="field-error" v-if="errors.currentPassword">{{errors.currentPassword}}</div>
                    </div>
                    <div class="form-line">
                        <div class="icons">
                            <img :src="require(`@assets/img/icon-eye-${newPasswordVisible?'off':'on'}.png`)" alt=""
                                 @click.prevent="newPasswordVisible = !newPasswordVisible" >
                        </div>
                        <label for="password" :class="{'label-error': errors.password }">{{$t("webdesktop.registration.email.passwordLabel")}}</label>
                        <Field id="password" name="password" :type="newPasswordVisible?'text':'password'" autocomplete="new-password" rules="min:6|required|invalidCharacters"/>
                        <div class="field-error" v-if="errors.password">{{errors.password}}</div>
                    </div>
                    <div class="form-line">
                        <div class="icons">
                            <img :src="require(`@assets/img/icon-eye-${confirmPasswordVisible?'off':'on'}.png`)" alt=""
                                 @click.prevent="confirmPasswordVisible = !confirmPasswordVisible">
                        </div>
                        <label for="confirmPassword" :class="{'label-error': errors.confirmPassword}">{{$t("webdesktop.registration.email.passwordConfirmLabel")}}</label>
                        <Field id="confirmPassword" name="confirmPassword" :type="confirmPasswordVisible?'text':'password'" autocomplete="new-password" rules="min:6|confirmed:@password|invalidCharacters"/>
                        <div class="field-error" v-if="errors.confirmPassword">{{errors.confirmPassword}}</div>
                    </div>
                    <div class="form-line">
                        <button id="submit-change-password" class="button button-primary" @click.stop.prevent="handleSubmit(changePassword)">{{$t("webdesktop.changePasswordDialog.submitButton")}}</button>
                    </div>
                    <div class="form-line">
                        <button class="button button-secondary" @click.prevent="onClose">{{$t("common.forms.cancel")}}</button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="preloader" v-if="isRequestPending">
            <div class="preloader-el"></div>
        </div>
    </div>
</template>

<script src="./change-password-dialog-component.ts" />
