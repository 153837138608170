import { CombinedStakeDTO, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { PropType } from "vue";
import { useI18n } from "vue-i18n";
import { createQuotationTitleByQuotationId } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { BetSlipRowCommon } from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import { useSettings } from "@sportaq/services/index";
import { getParticipantsAsString } from "@sportaq/model/common/participants-functions";

export default {
    name: "PrintCombinedStakeRow",
    props: {
        stake: {
            type: Object as PropType<CombinedStakeDTO>,
            required: true
        },
        simpleStakes: {
            type: Object as PropType<SimpleStakeDTO[]>,
            required: true
        },
        pointCurrency: {
            type: String,
            required: true
        }
    },
    setup () {
        const i18n = useI18n();
        const quotationFormat = useSettings().quotationFormat;

        function getQuotationTitle (stake: SimpleStakeDTO): string {
            return createQuotationTitleByQuotationId(i18n, stake.event.sportType, stake, stake.key.quotationKey) + " " +
                BetSlipRowCommon.getSimpleStakeDTOQuotationLabel(quotationFormat, stake);
        }

        function getStakeParticipants (stake: SimpleStakeDTO): string {
            return getParticipantsAsString(stake, stake.key.quotationKey);
        }

        return {
            getQuotationTitle,
            getStakeParticipants
        };
    }
};
