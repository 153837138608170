import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";

export class APi58CloseDayMessageHandler extends AbstractMessageHandler<string> {
    protected readonly requestType: string = "A.PI.5.8";

    buildRequest (request: XmlRequest) {
        request.addChild(request.body, "action", {
            type: this.requestType
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): string {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "2500") {
            throw new LocalizedError(`errors.mtl.aPi54.code${serverCode}`);
        }
        return serverCode;
    }
}
