import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatValue,
    getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";
import { getCurrencyName } from "@sportaq/model/common/point-settings";

export class AVc11SellVoucherMessageHandler extends AbstractMessageHandler<VoucherSellInfo> {
    protected readonly requestType: string = "A.VC.1.1";
    constructor (readonly amount: number, readonly currencyId: number, readonly name: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        const voucherElement = request.addChild(actionElement, "Voucher");
        request.addChildWithValue(voucherElement, "VoucherSum", this.amount.toString());
        request.addChildWithValue(voucherElement, "CurrencyTypeId", this.currencyId.toString());
        request.addChildWithValue(voucherElement, "PersonName", this.name);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): VoucherSellInfo {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");

        if (serverCode !== "2200") {
            throw new LocalizedError(`errors.mtl.aVc11.code${serverCode}`);
        }
        const voucherElement = getRequiredChild(action, "Voucher");
        return {
            amount: getRequiredFloatValue(getRequiredChild(voucherElement, "VoucherCost")),
            sellDate: new Date(Date.now()),
            voucherCode: getRequiredValue(getRequiredChild(voucherElement, "VoucherCode")),
            voucherNumber: getRequiredValue(getRequiredChild(voucherElement, "VoucherNumber")),
            name: getRequiredValue(getRequiredChild(voucherElement, "VoucherDescription")),
            currencyId: this.currencyId,
            currency: getCurrencyName(this.currencyId)
        };
    }
}
