import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { SetupContext } from "vue";
import { useBuyVoucherDialogCommon } from "@sportaq/vue/components/mobile-cashier/buy-voucher-dialog-common";

export default {
    name: "BuyVoucherDialog",
    emits: [CLOSE_EVENT],
    setup (props: unknown, context: SetupContext) {
        return useBuyVoucherDialogCommon(true, context);
    }
};
