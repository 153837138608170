import { PropType, ref, SetupContext } from "vue";
import { CommonSettings } from "@sportaq/services/base-setting-service/common-settings";
import { FinanceParams } from "@sportaq/vue/views/mobile-cashier/login/finance-params";
import { SettingsDialogProps } from "@sportaq/vue/views/mobile-cashier/login/settings-dialog-common";

const CLOSE_EVENT = "close";
const SUBMIT_EVENT = "submit";

export default {
    props: {
        commonSettings: { required: true, type: Object as PropType<CommonSettings> },
        financeParams: { required: false, type: Object as PropType<FinanceParams> },
        usePrintPreview: { required: true, type: Boolean }
    },
    emits: [CLOSE_EVENT, SUBMIT_EVENT],
    setup (props: SettingsDialogProps, context: SetupContext) {
        const commonSettingsRef = ref<CommonSettings>(new CommonSettings(props.commonSettings.appCode,
            props.commonSettings.partnerCode,
            props.commonSettings.restServerAddress,
            props.commonSettings.base64Logo));

        function submit () {
            context.emit(SUBMIT_EVENT, commonSettingsRef.value, false);
        }

        function cancel () {
            context.emit(CLOSE_EVENT);
        }

        return {
            commonSettingsRef,
            submit,
            cancel
        };
    }
};
