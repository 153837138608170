import { ref, SetupContext } from "vue";
import PointBalance from "@/views/monitoring/point-balance/PointBalance.vue";
import { EVENT, usePointPanelCommon } from "@sportaq/vue/components/mobile-cashier/monitoring/point-panel-common";

export default {
    name: "PointPanel",
    components: {
        PointBalance
    },
    emits: [EVENT],
    setup (props: [], context: SetupContext) {
        const {
            pointFilterItemsRef,
            pointSelected
        } = usePointPanelCommon(context);
        const selectedPoint = ref(-1);
        function onPointClick (pointId: number) {
            selectedPoint.value = pointId;
            pointSelected(pointId);
        }
        return {
            pointFilterItemsRef,
            selectedPoint,
            onPointClick
        };
    }
};
