import { CommonSettings } from "@sportaq/services/base-setting-service/common-settings";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { EGameService, PointSettings } from "@sportaq/model/common/point-settings";
import { EPaymentMethod } from "@sportaq/common/enums/bet-slip";
import { EQuotationFormat } from "@sportaq/common/enums/quotation-format";
import { ThemeClass } from "@sportaq/common/enums/theme";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { brandTypeToString, EBrandType, stringToBrandType } from "@sportaq/common/enums/brand-type";

export const APP_CODE_KEY = "base.appCode";
const PARTNER_CODE_KEY = "base.partnerCode";
export const REST_SERVER_ADDRESS_KEY = "mtlServerAddress";
export const QUOTATION_FORMAT_KEY = "base.quotationFormat";
const LOGO_KEY = "base.logo";
const BIG_BUTTONS_KEY = "base.bigButtons";
export const THEME_KEY = "theme";
export const BRAND_TYPE = "base.brandType";

export interface BaseSettingsService {
    appCode: string;
    readonly partnerCode: string;
    readonly restServerAddress: string;

    getStreamServerAddress (locale: string): string;

    readonly useSSL: boolean;
    readonly base64Logo: string;
    readonly usePrintPreview: boolean;
    readonly paymentMethod: EPaymentMethod;
    readonly disabledServices: EGameService[];
    pointSettings: PointSettings;
    quotationFormat: EQuotationFormat;
    bigButtons: boolean;
    currentTheme: ThemeClass | undefined;
    brand: EBrandType;

    setCommonSettings (settings: CommonSettings): void;
}

export abstract class BaseSettingsServiceImpl implements BaseSettingsService {
    private _settings: CommonSettings;
    private readonly _prefix: string;
    private _pointSettings!: PointSettings;
    private _quotationFormat: EQuotationFormat;
    private _bigButtons: boolean;
    private _brand: EBrandType;

    protected constructor (uuidProvider: UUIDProvider, generateAppCodeIfEmpty: boolean, prefix: string) {
        this._prefix = prefix;
        const storage = window.localStorage;
        const storageBrandType = storage.getItem(BRAND_TYPE);
        this._brand = storageBrandType ? stringToBrandType(storageBrandType) : stringToBrandType(process.env.__BRAND_TYPE__ as string);
        const appCode = BaseSettingsServiceImpl.getAppCode(prefix, storage, uuidProvider, generateAppCodeIfEmpty);
        const partnerCode = storage.getItem(prefix + PARTNER_CODE_KEY) ?? "";
        const restServerAddress = storage.getItem(REST_SERVER_ADDRESS_KEY) ?? this.getDefaultRestAddress();
        const qf = storage.getItem(QUOTATION_FORMAT_KEY);
        this._quotationFormat = qf ? parseInt(qf) : (this._brand === EBrandType.columbiaApuesta ? EQuotationFormat.American : EQuotationFormat.Decimal);
        const base64Logo = storage.getItem(LOGO_KEY) ?? "";
        this._bigButtons = (storage.getItem(BIG_BUTTONS_KEY) ?? "false") === "true";
        this._settings = new CommonSettings(
            appCode,
            partnerCode,
            restServerAddress,
            base64Logo
        );
    }

    protected getDefaultRestAddress () {
        return "bp.tubetgol.com";
    }

    setCommonSettings (settings: CommonSettings): void {
        this._settings = settings;
        const storage = window.localStorage;
        storage.setItem(this._prefix + APP_CODE_KEY, this._settings.appCode);
        storage.setItem(this._prefix + PARTNER_CODE_KEY, this._settings.partnerCode);
        storage.setItem(REST_SERVER_ADDRESS_KEY, this._settings.restServerAddress);
        storage.setItem(LOGO_KEY, this._settings.base64Logo);
    }

    private static getAppCode (prefix: string, storage: Storage, uuidProvider: UUIDProvider, generateAppCodeIfEmpty: boolean) {
        let appCode = storage.getItem(prefix + APP_CODE_KEY) ?? "";
        if (!appCode && generateAppCodeIfEmpty) {
            appCode = uuidProvider.getUUID();
            storage.setItem(APP_CODE_KEY, appCode);
        }
        return appCode;
    }

    get appCode () {
        return this._settings.appCode;
    }

    set appCode (value: string) {
        this._settings.appCode = value;
    }

    get base64Logo () {
        return this._settings.base64Logo;
    }

    get partnerCode () {
        return this._settings.partnerCode;
    }

    get restServerAddress () {
        return this._settings.restServerAddress;
    }

    set restServerAddress (address: string) {
        this._settings.restServerAddress = address;
    }

    getStreamServerAddress (locale: string) {
        return this._settings.getStreamServerAddress(locale);
    }

    get useSSL () {
        return this._settings.useSSL;
    }

    get usePrintPreview () {
        return false;
    }

    get quotationFormat () {
        return this._quotationFormat;
    }

    set quotationFormat (value: EQuotationFormat) {
        this._quotationFormat = value;
        window.localStorage.setItem(QUOTATION_FORMAT_KEY, value.toFixed(0));
    }

    get bigButtons (): boolean {
        return this._bigButtons;
    }

    set bigButtons (value: boolean) {
        this._bigButtons = value;
        window.localStorage.setItem(BIG_BUTTONS_KEY, value ? "true" : "false");
    }

    get currentTheme (): ThemeClass | undefined {
        const theme = window.localStorage.getItem(THEME_KEY);
        return theme as ThemeClass;
    }

    set currentTheme (theme) {
        if (theme) {
            window.localStorage.setItem(THEME_KEY, theme);
        }
    }

    get disabledServices (): EGameService[] {
        return this._pointSettings ? this._pointSettings.disabledServices : [];
    }

    get pointSettings (): PointSettings {
        return this._pointSettings;
    }

    set pointSettings (value: PointSettings) {
        this._pointSettings = value;
        const brand = value.brand;
        if (brand !== this._brand) {
            this._brand = brand;
            window.localStorage.setItem(BRAND_TYPE, brandTypeToString(brand));
            const qf = window.localStorage.getItem(QUOTATION_FORMAT_KEY);
            this._quotationFormat = qf ? parseInt(qf) : (this._brand === EBrandType.columbiaApuesta ? EQuotationFormat.American : EQuotationFormat.Decimal);
        }
        eventBus.emit(Events.APPLICATION_POINT_SETTINGS_CHANGED);
    }

    get brand (): EBrandType {
        return this._brand;
    }

    abstract get paymentMethod (): EPaymentMethod;
}
