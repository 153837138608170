import { nextTick, onMounted, onUnmounted, ref } from "vue";
import { usePrintService, useSettings } from "@sportaq/services/index";
import LogoPrint from "@sportaq/vue/components/common/logo-print/LogoPrint.vue";
import JsBarcode from "jsbarcode";
import { useRootStore } from "@sportaq/vuex/index";

export interface Stake {
    apuesta: string,
    cab: number,
    caballos: string,
    carrera: number,
    dividendo: number | undefined,
    monto: number,
    pista: string,
    id_pista: number,
    id_j: number
}

export interface Track {
    id: number,
    trackName: string;
    stakes: Stake[]
}

export interface PrintData {
    serial: number;
    date: Date;
    currency: string;
    tracks: Track[];
    total: number;
    userNumber: string | undefined;
}

export default {
    name: "TicketPrinter",
    components: {
        LogoPrint
    },
    props: {
        printAppCode: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    setup () {
        const printContent = ref<HTMLElement>();
        const printData = ref<PrintData>();
        const settingsService = useSettings();
        const printService = usePrintService();
        const rootStore = useRootStore();
        const pointName = settingsService.pointSettings.name;
        const pointAddress = settingsService.pointSettings.address;
        const pointDescription = settingsService.pointSettings.stationDescription;
        const appCode = settingsService.appCode;
        const barcode = ref<HTMLElement>();
        const workWithDNIonly = settingsService.pointSettings.workWithDNIonly;
        const userNumber = workWithDNIonly ? rootStore.userSharedInfo.userNumber : undefined;
        async function onMessage (event: MessageEvent) {
            if (event.data.resp === "muestra_tck2") {
                const date = event.data.hora;
                printData.value = {
                    serial: event.data.serial,
                    date: new Date(date * 1000),
                    currency: event.data.moneda,
                    tracks: [],
                    total: event.data.total,
                    userNumber
                };
                let lastTrackName = "";
                let lastTrack: Track | undefined;
                for (const stake of (event.data.tck as Stake[])) {
                    if (lastTrackName !== stake.pista) {
                        lastTrackName = stake.pista;
                        lastTrack = {
                            trackName: lastTrackName,
                            id: stake.id_pista,
                            stakes: []
                        };
                        printData.value.tracks.push(lastTrack);
                    }
                    if (lastTrack) {
                        lastTrack.stakes.push(stake);
                    }
                }
                await nextTick();
                JsBarcode(barcode.value).CODE128(event.data.serial, {
                    displayValue: false,
                    textMargin: 0,
                    width: 2,
                    height: 50
                }).render();
                await nextTick();
                settingsService.usePrintPreview ? printService.printPreview(printContent.value?.innerHTML) : printService.print(printContent.value?.innerHTML);
            }
        }

        onMounted(() => {
            window.addEventListener("message", onMessage, false);
        });

        onUnmounted(() => {
            window.removeEventListener("message", onMessage);
        });

        return {
            printContent,
            pointName,
            pointAddress,
            pointDescription,
            printData,
            barcode,
            appCode
        };
    }
};
