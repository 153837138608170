import { nextTick, PropType, ref } from "vue";
import { usePrintService, useSettings } from "@sportaq/services/index";
import JsBarcode from "jsbarcode";
import LogoPrint from "@sportaq/vue/components/common/logo-print/LogoPrint.vue";
import { HorseItemStatus, HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { PrintData, Track } from "@sportaq/vue/components/games/horse/ticket-printer/ticket-printer-component";
import { getCurrencyName } from "@sportaq/model/common/point-settings";

export default {
    name: "RaceCardPrint",
    props: {
        printCardData: {
            type: Object as PropType<HorseWagerContainer>,
            required: true
        },
        printAppCode: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        LogoPrint
    },
    setup: function (props: { readonly printCardData: HorseWagerContainer }) {
        const settingsService = useSettings();
        const printService = usePrintService();
        const printData = ref<PrintData>();
        const barcode = ref<HTMLElement>();
        const printContent = ref<HTMLElement>();
        const pointName = settingsService.pointSettings.name;
        const pointAddress = settingsService.pointSettings.address;
        const pointDescription = settingsService.pointSettings.stationDescription;
        const appCode = settingsService.appCode;
        function printCard () {
            const data = props.printCardData;
            printData.value = {
                serial: data.serial,
                date: data.acceptServerTime,
                currency: getCurrencyName(settingsService.pointSettings.currency.typeId),
                tracks: [],
                total: data.sumBet,
                userNumber: props.printCardData.userNumber
            };
            let lastTrackName = "";
            let lastTrack: Track | undefined;
            for (const stake of data.items) {
                if (lastTrackName !== stake.trackName) {
                    lastTrackName = stake.trackName;
                    lastTrack = {
                        trackName: lastTrackName,
                        id: stake.trackId,
                        stakes: []
                    };
                    printData.value.tracks.push(lastTrack);
                }
                if (lastTrack) {
                    lastTrack.stakes.push({
                        pista: "",
                        monto: stake.sumBet,
                        caballos: stake.horses,
                        carrera: stake.path,
                        apuesta: stake.gameType.toString(),
                        dividendo: stake.status === HorseItemStatus.Winner ? stake.sumWin : undefined,
                        cab: 0,
                        id_j: 0,
                        id_pista: 0
                    });
                }
            }
            nextTick().then(() => {
                JsBarcode(barcode.value).CODE128(props.printCardData.serial.toString(), {
                    displayValue: false,
                    textMargin: 0,
                    width: 2,
                    height: 50
                }).render();
                nextTick().then(() => {
                    settingsService.usePrintPreview ? printService.printPreview(printContent.value?.innerHTML) : printService.print(printContent.value?.innerHTML);
                });
            });
        }
        return {
            barcode,
            printContent,
            pointName,
            pointAddress,
            pointDescription,
            appCode,
            printData,
            printCard
        };
    }
};
