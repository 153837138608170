import { useCashierRestService } from "@sportaq/services/cashier";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { EPointType } from "@sportaq/common/enums/EPointType";
import { appLogger } from "@sportaq/common/utils/logger";

export function useActiveBetplacesCommon () {
    const restService = useCashierRestService();
    const store = useCashierStore();
    const pointsRef = computed(() => {
        const points = store.getPoints();
        return points.filter(p => p.pointTypeId === EPointType.Unknown ||
            p.pointTypeId === EPointType.BetPlace || p.pointTypeId === EPointType.Terminal || p.pointTypeId === EPointType.NEW_TERMINAL);
    });
    const i18n = useI18n();
    const depositAmountRef = ref<number>();
    const depositDialogVisibleRef = ref<boolean>();
    const depositPointRef = ref<ShortPointInfo>();
    const resetConfirmationVisible = ref(false);
    const requestPending = ref(false);

    function setPointDeposit (bpGuid: string, value: number) {
        store.setPointDeposit(bpGuid, value);
    }

    function nullify (point: ShortPointInfo) {
        depositPointRef.value = point;
        resetConfirmationVisible.value = true;
    }

    function doReset () {
        const point = depositPointRef.value;
        if (!point || requestPending.value) {
            return;
        }
        resetConfirmationVisible.value = false;
        appLogger.logger.info("Nullifying balance of " + point.bpGuid + " " + point.balance);
        requestPending.value = true;
        restService.pointBalanceNullify(point.bpGuid, point.balance).then(() => {
            setPointDeposit(point.bpGuid, 0);
            showMessageBoxEx(true, i18n.t("cashier.activeBetplaces.nullifysuccesscaption"),
                i18n.t("cashier.activeBetplaces.nullifysuccess"));
            requestPending.value = false;
        }).catch(() => {
            requestPending.value = false;
        });
    }

    function deposit (point: ShortPointInfo) {
        depositPointRef.value = point;
        depositDialogVisibleRef.value = true;
    }

    function depositSubmit () {
        if (depositAmountRef.value && depositAmountRef.value > 0 && depositPointRef.value?.bpGuid && depositPointRef && !requestPending.value) {
            requestPending.value = true;
            restService.balanceDeposit(depositPointRef.value.bpGuid, depositAmountRef.value).then(() => {
                if (depositPointRef.value && depositAmountRef.value) {
                    const balance = depositPointRef.value.balance + depositAmountRef.value;
                    setPointDeposit(depositPointRef.value?.bpGuid, balance);
                    requestPending.value = false;
                    showMessageBoxEx(true, i18n.t("cashier.activeBetplaces.depositsuccesscaption"),
                        i18n.t("cashier.activeBetplaces.depositsuccess"));
                }
            }).catch(() => {
                requestPending.value = false;
            });
        }
        depositDialogVisibleRef.value = false;
    }

    function depositCancel () {
        depositDialogVisibleRef.value = false;
    }

    return {
        pointsRef,
        depositAmountRef,
        depositDialogVisibleRef,
        resetConfirmationVisible,
        depositPointRef,
        requestPending,
        doReset,
        nullify,
        deposit,
        depositSubmit,
        depositCancel
    };
}
