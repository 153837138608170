<template>
    <div class="popup wide active" id="popup11">
        <div class="overlay" @click="onNoButtonClick"></div>
        <div class="popup-container">
            <div class="popup-title">{{ caption }}</div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="text">
                      {{message}}
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-general" @click.stop.prevent="onYesButtonClick">{{ $t("common.forms.yes") }}</a>
                    </div>
                    <div class="form-line">
                        <a href="#" class="button button-secondary" @click.stop.prevent="onNoButtonClick">{{ $t("common.forms.no") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./input-box.ts" />
