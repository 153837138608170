import { SetupContext } from "vue";
import { CLOSE_EVENT, useDailyReportCommon } from "@sportaq/vue/components/mobile-cashier/daily-report-common";
import InputBox from "@/components/input-box/InputBox.vue";

export default {
    name: "FinDay",
    emits: [CLOSE_EVENT],
    props: {
        isFinDay: {
            type: Boolean,
            default: true
        }
    },
    components: {
        InputBox
    },
    setup (props: unknown, context: SetupContext) {
        const {
            dailyReport,
            isCloseDayConfirmatonDialogVisible,
            onClose,
            reportDate,
            currencyName,
            calculateTotal,
            closeDayButtonPressedRef,
            refresh,
            onCloseDayCancel,
            onCloseDayConfirmed,
            onCloseDay
        } = useDailyReportCommon(true, context);
        return {
            dailyReport,
            reportDate,
            isCloseDayConfirmatonDialogVisible,
            closeDayButtonPressedRef,
            currencyName,
            refresh,
            onClose,
            onCloseDayConfirmed,
            onCloseDayCancel,
            calculateTotal,
            onCloseDay
        };
    }
};
