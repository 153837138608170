import { User } from "@sportaq/model/common/user-accounts";

export enum WithdrawalStatus { Waiting, Approved, Paid, Denied, UserCancelled, Unknown}

export interface WithdrawalData {
    user: User;
    id: number;
    guid: string;
    orderTime: Date;
    sumOrder: number;
    currencyId: number;
    currencyMark: string;
    changeStatusTime: Date;
    expirationTime: Date;
    withdrawalStatus: WithdrawalStatus;
}
