<template>
    <template v-if="appLoading">
        <main class="container">
            <div class="preloader">
                <div class="preloader-el"></div>
            </div>
        </main>
    </template>
    <template v-else-if="appStarted">
        <app-menu-bar @openDialog="onComponentSelected"/>
        <main class="container">
            <DniInputDialog v-if="needAuthorization"></DniInputDialog>
            <router-view v-else/>
        </main>
        <mobile-bet-slip v-if="isBetSlipShowed"/>
        <component :is="currentDialog" @close="onDialogClosed"/>
        <share-dialog></share-dialog>
        <app-bottom-bar/>
    </template>
    <template v-else>
        <main class="container"/>
    </template>
</template>
<script src="./main-view-component.ts" />
