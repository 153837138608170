import { SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { createQuotationTitleByQuotationId } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { BetSlipRowCommon } from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import EventType from "@sportaq/common/enums/event-type";
import { useSettings } from "@sportaq/services/index";
import { getParticipantsAsString } from "@sportaq/model/common/participants-functions";

interface Props {
    stake: SimpleStakeDTO;
    pointCurrency: string;
}

export default {
    props: {
        stake: {
            required: true,
            type: Object as PropType<SimpleStakeDTO>
        },
        pointCurrency: {
            type: String,
            required: true
        }
    },
    setup (props: Props) {
        const i18n = useI18n();
        const maxWin = computed(() => {
            if (props.stake.amount) {
                return (props.stake.amount * props.stake.coef);
            }
            return undefined;
        });
        const quotationFormat = useSettings().quotationFormat;
        const quotationLabel = computed(() => BetSlipRowCommon.getSimpleStakeDTOQuotationLabel(quotationFormat, props.stake));
        const quotationTitle = computed(() => {
            return createQuotationTitleByQuotationId(i18n, props.stake.event.sportType, props.stake, props.stake.key.quotationKey);
        });
        const isLive = computed(() => props.stake.event.eventType === EventType.LIVE);
        const participantsString = computed(() => getParticipantsAsString(props.stake, props.stake.key.quotationKey));

        return {
            maxWin,
            quotationLabel,
            quotationTitle,
            isLive,
            participantsString
        };
    }
};
