<template>
    <div style="display: none; width: 100%" ref="printContent" v-if="printData">
        <table>
            <tr><td colspan="2" align="center"><logo-print></logo-print></td></tr>
            <tr>
                <td colspan="2" class="text-big"><b>{{ pointName }}</b></td>
            </tr>
            <tr  v-if="printAppCode">
                <td colspan="2" class="text-big"><b>{{ appCode }}</b></td>
            </tr>
            <tr>
                <td colspan="2" class="text-big"><b>{{ pointAddress }}</b></td>
            </tr>
            <tr v-if="pointDescription">
                <td colspan="2" class="text-normal"><b>{{ pointDescription }}</b></td>
            </tr>
            <tr v-if="printData.userNumber">
                <td>{{$t("betting.betSlip.common.userNumber")}}</td><td><b>{{ printData.userNumber }}</b></td>
            </tr>
            <tr>
                <td colspan="2" class="text-divider">--------------------------------------------------</td>
            </tr>
            <tr><td>{{$t("betting.horse.ticket.serial")}}</td><td>{{printData.serial}}</td></tr>
            <tr><td>{{$t("betting.horse.ticket.date")}}</td><td>{{$d(printData.date, "long")}}</td></tr>
            <tr>
                <td colspan="2" class="text-divider">--------------------------------------------------</td>
            </tr>
            <template v-for="track of printData.tracks" :key="track.id">
                <tr><td colspan="2" class="text-big"><b>{{ track.trackName }}</b></td></tr>
                <template v-for="stake of track.stakes" :key="stake.id_j">
                    <tr>
                        <td><b>{{$t("betting.horse.ticket.horse")}}</b></td>
                        <td><b>CR</b></td>
                    </tr>
                    <tr>
                        <td>{{stake.caballos}}</td>
                        <td>{{stake.carrera}}</td>
                    </tr>
                    <tr v-if="stake.dividendo">
                        <td>Dividendo</td><td>{{stake.dividendo}}</td>
                    </tr>
                    <tr>
                        <td><b>{{$t("betting.horse.ticket.game")}}</b></td>
                        <td><b>{{$t("betting.horse.ticket.amount")}}</b></td>
                    </tr>
                    <tr>
                        <td>{{$t("betting.horse.ticket.outcomes." + stake.apuesta)}}</td>
                        <td>{{stake.monto}}</td>
                    </tr>
                </template>
            </template>
            <tr>
                <td colspan="2" class="text-big"><b>{{$t("betting.horse.ticket.total")}} {{printData.total}}&nbsp;{{printData.currency}}</b></td>
            </tr>
            <tr><td colspan="2" align="center"><svg ref="barcode" ></svg></td></tr>
            <tr>
                <td colspan="2" class="text-divider">---------------------------</td>
            </tr>
            <tr>
                <td colspan="2" class="text-big">{{ $t("betting.betSlip.print.confirm") }}</td>
            </tr>
        </table>
    </div>
</template>

<script src="./ticket-printer-component.ts" />
