import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { parseUser } from "@sportaq/services/cashier/rest/messages/q_us_2_2_find-user";
import { WithdrawalData, WithdrawalStatus } from "@sportaq/model/cashier/withdrawal-data";
import { parseDate } from "@sportaq/common/utils/time-utils";

export class QPo62FindWithdrawalMessageHandler extends AbstractMessageHandler<WithdrawalData | undefined> {
    protected readonly requestType: string = "Q.PO.6.2";
    constructor (readonly payCode: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const withdrawalFilterElement = request.addChild(filterElement, "OrderWithdrawalFilter", {
            logic: "AND"
        });
        request.addChildWithValue(withdrawalFilterElement, "PayCode", this.payCode.toString());
        const selectorElement = request.addChild(actionElement, "Selector", undefined);
        const userSelectorelement = request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.User", allFields: "True"
        });
        request.addChild(userSelectorelement, "SelectObject", {
            class: "ps.user.UserVerifyField", allFields: "True"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.finoperation.OrderWithdrawal", allFields: "True"
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): WithdrawalData | undefined {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "4500") {
            throw new LocalizedError(`errors.mtl.qPo62.code${serverCode}`);
        }
        const withdrawalElement = getChild(getRequiredChild(body, "OrderWithdrawalList"), "OW");
        if (withdrawalElement === undefined) {
            return undefined;
        }
        const user = parseUser(action);
        function parseStatus (statusStr: string) {
            switch (statusStr) {
                case "ORDER_WITHDRAWAL_STATUS_APPROVED":
                case "ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED":
                case "ORDER_WITHDRAWAL_STATUS_CASH_WAITING":{
                    return WithdrawalStatus.Approved;
                }
                case "ORDER_WITHDRAWAL_STATUS_USER_CANCELLED": return WithdrawalStatus.UserCancelled;
                case "ORDER_WITHDRAWAL_STATUS_CASH_PAID": return WithdrawalStatus.Paid;
                case "ORDER_WITHDRAWAL_STATUS_DENIED": return WithdrawalStatus.Denied;
            }
            return WithdrawalStatus.Unknown;
        }
        if (user === undefined) {
            throw new LocalizedError("errors.mtl.aUs14.code1152");
        }
        return {
            user,
            id: getRequiredIntAttribute(withdrawalElement, "Id"),
            currencyId: getRequiredIntAttribute(withdrawalElement, "currencyId"),
            currencyMark: getRequiredAttribute(withdrawalElement, "currencyMark"),
            changeStatusTime: parseDate(getRequiredAttribute(withdrawalElement, "changeStatusTime")),
            expirationTime: parseDate(getRequiredAttribute(withdrawalElement, "expirationTime")),
            guid: getRequiredAttribute(withdrawalElement, "GUID"),
            orderTime: parseDate(getRequiredAttribute(withdrawalElement, "orderTime")),
            sumOrder: getRequiredFloatAttribute(withdrawalElement, "sumOrder"),
            withdrawalStatus: parseStatus(getRequiredAttribute(withdrawalElement, "status"))
        };
    }
}
