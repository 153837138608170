import { computed, ref, SetupContext } from "vue";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import { useCashierRestService } from "@sportaq/services/cashier";
import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { onPositiveNumberKeyFilter } from "@sportaq/vue/utils/form-validation";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";

export function useSaleVoucherDialogCommon (isMobileCashier: boolean, context: SetupContext, settings: BaseSettingsService, successCallback?: (info: VoucherSellInfo) => void) {
    const restService = useCashierRestService();
    const amountRef = ref<number>();
    const store = useCashierStore();
    const vouchers = computed<VoucherSellInfo[]>(() => store.getVoucherSellHistory());
    const currentVoucher = ref<VoucherSellInfo>();
    const validUntil = ref<Date>();
    const balanceStore = useBalanceStore();
    const printContent = ref<HTMLElement>();
    const barcode = ref<HTMLElement>();
    const requestPending = ref(false);
    const currentVoucherCurrency = ref<string>(
        isMobileCashier ? balanceStore.currency : store.point?.pointSettings ? store.point.pointSettings.currencyName : ""
    );

    const isSaleButtonDisabled = computed(() => {
        if (requestPending.value) {
            return true;
        }
        if (amountRef.value === undefined) {
            return true;
        }
        return balanceStore.balance.numberValue <= (amountRef.value * 1) || amountRef.value < 0;
    });

    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function onSell () {
        if (amountRef.value && store.point?.pointSettings) {
            const currencyId = isMobileCashier
                ? balanceStore.currencyId
                : store.point.pointSettings.currency.typeId;
            if (currencyId === undefined) {
                showMessageBox("Can't resolve currency");
                return;
            }
            requestPending.value = true;
            restService.sellVoucher(amountRef.value, currencyId, store.point.pointSettings.name)
                .then(v => {
                    store.addVoucherSell(v);
                    amountRef.value = undefined;
                    currentVoucher.value = v;
                    const vu = new Date();
                    vu.setFullYear(currentVoucher.value.sellDate.getFullYear() + 1);
                    validUntil.value = vu;
                    if (successCallback) {
                        successCallback(v);
                    }
                });
        }
    }

    return {
        amountRef,
        vouchers,
        currentVoucher,
        currentVoucherCurrency,
        validUntil,
        isSaleButtonDisabled,
        printContent,
        barcode,
        onAmountKeyDown: onPositiveNumberKeyFilter,
        onClose,
        onSell
    };
}
