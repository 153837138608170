import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getAttribute, getChild,
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute, getRequiredIntAttribute, getRequiredIntValue,
    getRequiredValue,
    getValue
} from "@sportaq/common/utils/xml-helper-functions";
import { CashierPoint } from "@sportaq/model/cashier/point";
import {
    AbstractPointSettingsMessageHandler,
    readDisabledServices
} from "@sportaq/services/rest/messages/system/point-settings-message-handler";
import { EVerifyField, User, UserAccount, UserAccountsImpl } from "@sportaq/model/common/user-accounts";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { Currency } from "@sportaq/model/common/point-settings";

export interface AuthResult {
    cashierPoint: CashierPoint;
    sessionCode: string;
    user: User;
    currency: Currency;
}

export class AUs13AuthorizationMessageHandler extends AbstractPointSettingsMessageHandler<AuthResult> {
    protected readonly requestType: string = "A.US.1.3";
    constructor (readonly userNumber: string, readonly password: string, readonly partnerGUID?: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "UserNumber", this.userNumber);
        request.addChildWithValue(actionElement, "Password", this.password);
        if (this.partnerGUID) {
            request.addChildWithValue(actionElement, "PartnerGUID", this.partnerGUID);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, head: Element): AuthResult {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1140") {
            const sessionCode = getRequiredValue(getRequiredChild(head, "sessioncode"));
            const userElement = getRequiredChild(action, "User");
            const stElement = getChild(userElement, "ST");
            const userPointName = stElement ? getRequiredAttribute(stElement, "name") : undefined;
            const userPointAddress = stElement ? getRequiredAttribute(stElement, "address") : undefined;
            const userStationDescription = stElement ? getAttribute(stElement, "stationDescription") : undefined;
            const userAccountListElement = getChild(userElement, "UserAccountList");
            if (!userAccountListElement) {
                throw new LocalizedError("cashier.loginForm.noAccount");
            }
            const userAccountElements = userAccountListElement.getElementsByTagName("UA");
            const userAccounts: UserAccount[] = [];
            for (let i = 0; i < userAccountElements.length; i++) {
                const userAccountElement = userAccountElements.item(i);
                if (userAccountElement != null) {
                    userAccounts.push({
                        accountId: getRequiredIntAttribute(userAccountElement, "Id"),
                        accountNumber: getRequiredAttribute(userAccountElement, "accountNumber"),
                        currencyId: getRequiredIntAttribute(userAccountElement, "currencyId"),
                        currencyMark: getRequiredAttribute(userAccountElement, "currencyMark"),
                        currencyValue: getRequiredFloatAttribute(userAccountElement, "currencyValue"),
                        isBlocked: getRequiredIntAttribute(userAccountElement, "isBlocked") === 1,
                        isBonus: getRequiredIntAttribute(userAccountElement, "isBonus") === 1,
                        isUsed: getRequiredIntAttribute(userAccountElement, "isUsed") === 1,
                        sumAccount: getRequiredFloatAttribute(userAccountElement, "sumAccount"),
                        sumCaptive: getRequiredFloatAttribute(userAccountElement, "sumCaptive"),
                        sumStake: getRequiredFloatAttribute(userAccountElement, "sumStake"),
                        accountMark: getRequiredAttribute(userAccountElement, "accountMark")
                    });
                }
            }
            const user: User = {
                userId: getRequiredIntValue(getRequiredChild(userElement, "UserId")),
                userNumber: getRequiredValue(getRequiredChild(userElement, "UserNumber")),
                firstName: getValue(getChild(userElement, "FirstName")),
                lastName: getValue(getChild(userElement, "LastName")),
                middleName: getValue(getChild(userElement, "MiddleName")),
                address: getValue(getChild(userElement, "Address")),
                phone: getValue(getChild(userElement, "Phone")),
                email: getValue(getChild(userElement, "Email")),
                login: getRequiredValue(getRequiredChild(userElement, "Login")),
                partnerId: getRequiredIntValue(getRequiredChild(userElement, "PartnerId")),
                IdentificationCode: getAttribute(userElement, "IdentificationCode"),
                verifiedFields: new Set<EVerifyField>(),
                birthday: getValue(getChild(userElement, "Birthday")),
                userAccounts: new UserAccountsImpl(userAccounts),
                city: getValue(getChild(userElement, "CityText")),
                countryId: getRequiredIntValue(getRequiredChild(userElement, "CountryId")),
                registrationTime: undefined,
                isRegulatorBlocked: false,
                userRoles: [],
                paymentSystems: [],
                verified: true
            };
            const points: ShortPointInfo[] = [];
            const linkedPointListElement = getRequiredChild(userElement, "LinkedPoints");
            const linkedPoints = linkedPointListElement.getElementsByTagName("PT");
            for (let i = 0; i < linkedPoints.length; i++) {
                const linkedPointElement = linkedPoints.item(i);
                if (linkedPointElement != null) {
                    points.push(new ShortPointInfo(
                        getRequiredIntAttribute(linkedPointElement, "id"),
                        getRequiredIntAttribute(linkedPointElement, "pointTypeId"),
                        getRequiredAttribute(linkedPointElement, "GUID"),
                        getRequiredAttribute(linkedPointElement, "name"),
                        0));
                }
            }
            const userAccount = getRequiredChild(getRequiredChild(userElement, "UserAccountList"), "UA");
            return {
                cashierPoint: new CashierPoint(user, points, userPointName, userPointAddress, userStationDescription, readDisabledServices(userElement)),
                sessionCode,
                user,
                currency: {
                    rateId: 0,
                    typeId: getRequiredIntAttribute(userAccount, "currencyId"),
                    value: getRequiredIntAttribute(userAccount, "currencyValue")
                }
            };
        }
        throw new LocalizedError(`errors.mtl.aUs12.code${serverCode}`);
    }
}
