import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { nextTick, SetupContext } from "vue";
import { useSaleVoucherDialogCommon } from "@sportaq/vue/components/mobile-cashier/sale-voucher-dialog-common";
import { useSettings } from "@sportaq/services/index";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { useKeyHandler } from "@sportaq/vue/components/common/dialog-key-handler/dialog-key-handler";
import LogoPrint from "@sportaq/vue/components/common/logo-print/LogoPrint.vue";
import JsBarcode from "jsbarcode";

export default {
    name: "SaleVoucherDialog",
    emits: [CLOSE_EVENT],
    components: {
        LogoPrint
    },
    setup (props:[], context: SetupContext) {
        const {
            amountRef,
            vouchers,
            currentVoucher,
            currentVoucherCurrency,
            validUntil,
            isSaleButtonDisabled,
            printContent,
            barcode,
            onAmountKeyDown,
            onClose,
            onSell
        } = useSaleVoucherDialogCommon(true, context, useSettings());
        useKeyHandler(onClose);
        const i18n = useI18n();

        function onCopy () {
            if (currentVoucher.value) {
                navigator.clipboard.writeText(currentVoucher.value.voucherCode)
                    .then(() => {
                        showMessageBoxEx(true, "", i18n.t("mcashier.saleVoucherDialog.copySuccess"));
                        onClose();
                    });
            }
        }

        function onPrint () {
            if (currentVoucher.value) {
                JsBarcode(barcode.value).CODE128(currentVoucher.value.voucherCode, {
                    displayValue: false,
                    textMargin: 0,
                    width: 2,
                    height: 50
                }).render();
                nextTick().then(() => {
                    const printWindow = window.open("/worker.html", "_blank", "height=400,width=600");
                    if (printWindow) {
                        printWindow.addEventListener("load", () => {
                            if (printContent.value) {
                                printWindow.document.body.innerHTML = printContent.value.innerHTML;
                                setTimeout(() => {
                                    printWindow.print();
                                }, 500);
                            }
                        });
                    }
                });
            }
        }
        return {
            amountRef,
            vouchers,
            currentVoucher,
            currentVoucherCurrency,
            validUntil,
            isSaleButtonDisabled,
            printContent,
            barcode,
            onAmountKeyDown,
            onClose,
            onSell,
            onCopy,
            onPrint,
            formatVoucherNumber
        };
    }
};
