import { nextTick, PropType, ref } from "vue";
import { SelectedCard } from "@sportaq/vue/components/mobile-cashier/monitoring/monitoring-common";
import { usePrintService, useSettings } from "@sportaq/services/index";
import JsBarcode from "jsbarcode";
import LogoPrint from "@sportaq/vue/components/common/logo-print/LogoPrint.vue";
import { CardItem } from "@sportaq/model/cashier/card";
import { getLocalizedQuotationTitle } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { getParticipantsAsString } from "@sportaq/model/common/participants-functions";
import { useI18n } from "vue-i18n";

export default {
    name: "SportCardPrint",
    props: {
        printCardData: {
            type: Object as PropType<SelectedCard>,
            required: true
        },
        printAppCode: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        LogoPrint
    },
    setup: function (props: { readonly printCardData: SelectedCard }) {
        const settingsService = useSettings();
        const printService = usePrintService();
        const barcode = ref<HTMLElement>();
        const printContent = ref<HTMLElement>();
        const pointName = settingsService.pointSettings.name;
        const pointAddress = settingsService.pointSettings.address;
        const pointDescription = settingsService.pointSettings.stationDescription;
        const appCode = settingsService.appCode;
        const i18n = useI18n();

        function printCard () {
            JsBarcode(barcode.value).CODE128(props.printCardData.card.payCode.toString(), {
                displayValue: false,
                textMargin: 0,
                width: 2,
                height: 50
            }).render();
            nextTick().then(() => {
                settingsService.usePrintPreview ? printService.printPreview(printContent.value?.innerHTML) : printService.print(printContent.value?.innerHTML);
            });
        }

        function getQuotationTitle (item: CardItem): string {
            return getLocalizedQuotationTitle(i18n, item);
        }

        function getParticipants (item: CardItem): string {
            return getParticipantsAsString(item, item.quotationKey);
        }
        return {
            barcode,
            printContent,
            pointName,
            pointAddress,
            pointDescription,
            appCode,
            getQuotationTitle,
            getParticipants,
            printCard
        };
    }
};
