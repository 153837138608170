import {
    PointBalanceProps,
    usePointBalanceCommon
} from "@sportaq/vue/components/mobile-cashier/monitoring/point-balance-common";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { PropType } from "vue";

export default {
    name: "PointBalance",
    props: {
        point: {
            required: true,
            type: Object as PropType<ShortPointInfo>
        }
    },
    setup (props: PointBalanceProps) {
        return usePointBalanceCommon(props);
    }
};
