<template>
    <div class="line line-primary has-children" :class="{active: entry.value.expanded}" @dblclick="dblClick"
         @click="click">
        <div class="line-date"><span>{{ entry.value.id }}</span> <span>{{ $d(entry.value.acceptServerTime, 'long') }}</span></div>
        <div class="line-date"><span>{{$t("cashier.monitoring.payCode")}}</span> <span>{{ entry.value.serial }}</span></div>
        <div class="line-total smaller">
            <div class="name">
                <span>{{ pointName }}</span>&nbsp;
                <span>{{ $t(entry.value.isPaid ? 'cashier.monitoring.table.ispaid' : entry.value.isCalculated ? 'cashier.monitoring.table.iscalculated' : 'cashier.monitoring.table.isaccepted')}}</span>
            </div>
            <div class="value">{{ entry.value.sumBet.toFixed(2) }}</div>
            <div class="value">{{ entry.value.sumWin.toFixed(2) }}</div>
        </div>
    </div>
    <div v-if="entry.value.expanded" class="child-block">
        <div class="line line-secondary" v-for="(item, index) of entry.value.items" :key="index">
            <div class="line-total smaller">
                <div class="name">{{item.trackName}}; {{item.horses}}; {{item.gameType}}</div>
                <div class="value">{{ item.sumBet.toFixed(2) }}</div>
            </div>
            <div class="line-total smaller">
                <div class="name">{{ $t("cashier.monitoring.table.horse.status"+item.status) }}</div>
                <div class="value">{{ item.sumWin.toFixed(2) }}</div>
            </div>
        </div>
    </div>
</template>

<script src="./race-entry-row-component.ts" />
