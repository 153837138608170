import { useCashierStore } from "@sportaq/vuex/modules/cashier/cashier-module";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { useI18n } from "vue-i18n";
import { SetupContext } from "vue";

export const EVENT = "select";
export function usePointPanelCommon (context: SetupContext) {
    const store = useCashierStore();
    const i18n = useI18n();
    const pointFilterItemsRef = [new ShortPointInfo(-1, 0, "", i18n.t("cashier.monitoring.filter.cashier"), 0),
        new ShortPointInfo(-2, 0, "", i18n.t("cashier.monitoring.filter.allbp"), 0)].concat(store.getPoints().filter(p => p.pointTypeId !== 2));
    function pointSelected (index: number) {
        context.emit(EVENT, index);
    }
    return {
        pointFilterItemsRef,
        pointSelected
    };
}
