import { PropType } from "vue";
import { CardItem } from "@sportaq/model/cashier/card";
import { getParticipantsAsString } from "@sportaq/model/common/participants-functions";
import { getLocalizedQuotationTitle } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { useI18n } from "vue-i18n";

export default {
    props: {
        stake: {
            required: true,
            type: Object as PropType<CardItem>
        }
    },
    setup () {
        const i18n = useI18n();
        function getParticipants (item: CardItem): string {
            return getParticipantsAsString(item, item.quotationKey);
        }

        function getQuotationTitle (item: CardItem): string {
            return getLocalizedQuotationTitle(i18n, item);
        }

        return {
            getParticipants,
            getQuotationTitle
        };
    }
};
