import { PrintService } from "@sportaq/services/print/print-service";
import { provide } from "vue";
import { services } from "@sportaq/services/index";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";

class MobilePrintService implements PrintService {
    print (htmlContent: string | undefined): void {
        eventBus.emit(Events.PRINT_PREVIEW, htmlContent);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    printPreview (): void {

    }
}

export function provideMobilePrintService () {
    provide(services.PRINT, new MobilePrintService());
}
