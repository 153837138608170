import {
    ENTRY_TYPE_CARD, ENTRY_TYPE_HORSE,
    MonitoringEntry,
    useCashierStore
} from "@sportaq/vuex/modules/cashier/cashier-module";
import { useClockProvider, useMiscellaneousParamsProvider, useSettings } from "@sportaq/services/index";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Card, CardItem } from "@sportaq/model/cashier/card";
import { TIMEOUTS } from "@sportaq/common/consts/default-consts";
import { useCashierRestService } from "@sportaq/services/cashier";
import { CombinedStakeDTO, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { getLocalizedQuotationTitle } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { convertCardToStakeArrays } from "@sportaq/model/utils/convertors/converter-card-to-stake-arrays";
import { EOperationType } from "@sportaq/model/cashier/fin-operation";
import { ShortPointInfo } from "@sportaq/model/cashier/short-point-info";
import { EPointType } from "@sportaq/common/enums/EPointType";
import { getParticipantsAsString } from "@sportaq/model/common/participants-functions";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { HorseWagerContainer } from "@sportaq/model/cashier/wager-container";

export interface SelectedCard {
    card: Card;
    formattedPayCode: string;
    validUntil: Date;
    maxWin: number;
}

function findCashierPointIds (points: ShortPointInfo[]): number[] {
    const result: number[] = [];
    for (const point of points) {
        if (point.pointTypeId === EPointType.Cashier) {
            result.push(point.id);
        }
    }
    return result;
}

export function useMonitoringCommon () {
    const online = ref(false);
    const i18n = useI18n();
    const store = useCashierStore();
    const balanceStore = useBalanceStore();
    const searchNumberRef = ref<string>();
    const selectedPointRef = ref(-1);
    const selectedCardRef = ref<MonitoringEntry>();
    const isSearchDialogVisible = ref(false);
    const isCancelConfirmVisible = ref(false);
    const isCopyConfirmDialogVisible = ref(false);
    const sportCardPrinter = ref();
    const raceCardPrinter = ref();
    const simpleStakesForCopy = ref<SimpleStakeDTO[]>();
    const combinedStakesForCopy = ref<CombinedStakeDTO[]>();
    const settingsService = useSettings();
    const cashierPointIds = findCashierPointIds(store.getPoints());
    if (cashierPointIds.length === 0) {
        cashierPointIds.push(settingsService.pointSettings.id);
    }
    const clockProvider = useClockProvider();
    const miscellaneousParamsProvider = useMiscellaneousParamsProvider();
    const restService = useCashierRestService();
    const canBeCancelled = computed(() => {
        if (!selectedCardRef.value || selectedCardRef.value.entryType !== ENTRY_TYPE_CARD) {
            return false;
        }
        const selectedCard: Card = selectedCardRef.value.value;
        const currentDate = new Date();
        const now = currentDate.getTime();
        if (now - selectedCard.acceptServerTime.getTime() > TIMEOUTS.CASHIER_MAX_PREMATCH_CANCEL_TIMEOUT) {
            return false;
        }
        for (const cc of selectedCard.containers) {
            for (const ci of cc.items) {
                if (ci.isLive) {
                    return false;
                } else {
                    if (ci.position.startTime.getTime() < now) {
                        return false;
                    }
                }
            }
        }
        return true;
    });
    const cardsRef = computed(() => {
        const value = searchNumberRef.value ? parseInt(searchNumberRef.value) : 0;
        const selectedPoint = selectedPointRef.value;
        const result: MonitoringEntry[] = [];
        for (const c of store.getCards()) {
            if (value) {
                if (c.entryType !== ENTRY_TYPE_CARD) {
                    continue;
                }
                if (c.value.innerCardId !== value && c.value.payCode !== value) {
                    continue;
                }
            }
            if (selectedPoint === -1 && !cashierPointIds.includes(c.value.pointId)) {
                continue;
            }
            if (selectedPoint === -2 && cashierPointIds.includes(c.value.pointId)) {
                continue;
            }
            if (selectedPoint > 0 && c.value.pointId !== selectedPoint) {
                continue;
            }
            result.push(c);
        }
        return result;
    });

    const raceCardData = computed<HorseWagerContainer | undefined>((): HorseWagerContainer | undefined => {
        const card = selectedCardRef.value;
        if (!card || card.entryType !== ENTRY_TYPE_HORSE) {
            return undefined;
        }
        return card.value;
    });

    const printCardData = computed<SelectedCard | undefined>((): SelectedCard | undefined => {
        const card = selectedCardRef.value;
        if (!card || card.entryType !== ENTRY_TYPE_CARD) {
            return undefined;
        }
        const validUntil = new Date(card.value.acceptServerTime);
        validUntil.setMonth(validUntil.getMonth() + 1);
        let maxWin = 0;
        for (const c of card.value.containers) {
            maxWin += c.getMaxWin();
        }
        return {
            card: card.value,
            formattedPayCode: formatVoucherNumber(card.value.payCode.toString()),
            validUntil,
            maxWin
        };
    });

    function reloadCards () {
        store.clearCards();
        eventBus.emit(Events.MONITORING_REFRESH);
    }

    function selectItem (card: MonitoringEntry) {
        selectedCardRef.value = card;
    }

    function cancelCard () {
        if (selectedCardRef.value && canBeCancelled.value) {
            isCancelConfirmVisible.value = true;
        }
    }

    function onCancelConfirmationClose () {
        isCancelConfirmVisible.value = false;
    }

    function doCancel () {
        isCancelConfirmVisible.value = false;
        const selected = selectedCardRef.value;
        if (selected && selected.entryType === ENTRY_TYPE_CARD) {
            const cardId = selected.value.id;
            restService.cancelCard(cardId)
                .then(() => {
                    store.markAsCanceled(cardId);
                    showMessageBox(i18n.t("cashier.monitoring.deleteSuccess"));
                });
        }
    }

    function expandCard (id: number) {
        store.expandCollapseCard(id);
    }

    function getQuotationTitle (item: CardItem): string {
        return getLocalizedQuotationTitle(i18n, item);
    }

    function getParticipants (item: CardItem): string {
        return getParticipantsAsString(item, item.quotationKey);
    }

    function onSearchDialogClose () {
        isSearchDialogVisible.value = false;
    }

    function onSearchDialogSubmit (value: string) {
        isSearchDialogVisible.value = false;
        searchNumberRef.value = value;
    }

    function search () {
        isSearchDialogVisible.value = true;
    }

    function copyCard () {
        const selected = selectedCardRef.value;
        if (selected && selected.entryType === ENTRY_TYPE_CARD) {
            const stakeArrays = convertCardToStakeArrays(clockProvider, miscellaneousParamsProvider.dateTimeFormatter,
                selected.value);
            simpleStakesForCopy.value = stakeArrays.simples;
            combinedStakesForCopy.value = stakeArrays.combined;
            isCopyConfirmDialogVisible.value = true;
        }
    }

    function oncopyConfirmCancel () {
        isCopyConfirmDialogVisible.value = false;
    }

    function onCopyConfirmed () {
        isCopyConfirmDialogVisible.value = false;
        const simples = simpleStakesForCopy.value;
        const combined = combinedStakesForCopy.value;
        if (simples && combined) {
            restService.placeBet(balanceStore.currencyId, simples, combined, true, settingsService.paymentMethod).then(
                placeBetResult => {
                    let msg;
                    switch (placeBetResult.type) {
                        case "success": {
                            msg = i18n.t("cashier.userConfirmationForm.success");
                            break;
                        }
                        case "registrationSystemError": {
                            msg = i18n.t("errors.betSlip.response.registrationSystemError");
                            break;
                        }
                        case "notEnoughMoneyOnCenterBalance": {
                            msg = i18n.t("errors.betSlip.response.notEnoughMoneyOnCenterBalance");
                            break;
                        }
                        case "notEnoughMoneyOnUserBalance": {
                            msg = i18n.t("errors.betSlip.response.notEnoughMoneyOnUserBalance");
                            break;
                        }
                        case "userDontHaveOpenAccount": {
                            msg = i18n.t("errors.betSlip.response.userDontHaveOpenAccount");
                            break;
                        }
                        case "coefIsNotAllowedForBonus": {
                            msg = i18n.t("errors.betSlip.response.coefIsNotAllowedForBonus");
                            break;
                        }
                        case "detailedError": {
                            msg = i18n.t("cashier.monitoring.copyError");
                            break;
                        }
                    }
                    if (msg) {
                        showMessageBox(msg);
                    }
                }
            );
        }
    }

    onMounted(() => {
        store.isMonitoringActive = true;
    });

    onUnmounted(() => {
        store.isMonitoringActive = true;
    });

    function pointSelected (index: number) {
        selectedPointRef.value = index;
    }

    function printCard () {
        if (selectedCardRef.value) {
            const entryType = selectedCardRef.value.entryType;
            if (entryType === ENTRY_TYPE_CARD) {
                sportCardPrinter.value.printCard();
            } else if (entryType === ENTRY_TYPE_HORSE) {
                raceCardPrinter.value.printCard();
            }
        }
    }

    return {
        online,
        cardsRef,
        selectedCardRef,
        printCardData,
        isSearchDialogVisible,
        isCancelConfirmVisible,
        isCopyConfirmDialogVisible,
        simpleStakesForCopy,
        combinedStakesForCopy,
        sportCardPrinter,
        raceCardPrinter,
        raceCardData,
        canBeCancelled,
        EOperationType,
        expandCard,
        reloadCards,
        selectItem,
        cancelCard,
        copyCard,
        getQuotationTitle,
        getParticipants,
        onSearchDialogClose,
        onSearchDialogSubmit,
        search,
        doCancel,
        onCancelConfirmationClose,
        onCopyConfirmed,
        oncopyConfirmCancel,
        pointSelected,
        printCard,
        formatVoucherNumber
    };
}
