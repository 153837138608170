/* eslint quote-props: ["error", "always"] */
export default {
    "brands": {
        "betgol": "BetGol",
        "certabet": "Certabet",
        "support": {
            "betgol": "info{'@'}tubetgol.com",
            "certabet": "info{'@'}certa.bet"
        },
        "site": {
            "betgol": "tubetgol.com",
            "certabet": "certa.bet"
        }
    },
    "sports": {
        "1": "Футбол",
        "2": "Баскетбол",
        "3": "Хокей",
        "4": "Теніс",
        "5": "Формула1",
        "6": "Волейбол",
        "7": "Бейсбол",
        "8": "Регбі",
        "9": "Американський футбол",
        "10": "Бокс",
        "11": "Keno",
        "12": "SkiJumping",
        "13": "Skeleton",
        "14": "Luge",
        "15": "Snowboard",
        "16": "FreestyleSkiing",
        "17": "FigureSkating",
        "18": "ShortTrack",
        "19": "Biathlon",
        "20": "Bobsleigh",
        "21": "AlpineSkiing",
        "22": "Curling",
        "23": "SpeedSkating",
        "24": "NordicCombined",
        "25": "CrossCountry",
        "26": "SuperLotto",
        "27": "WaterPolo",
        "28": "Гандбол",
        "29": "Bandy",
        "30": "Swimming",
        "31": "Chess",
        "32": "Futsal",
        "33": "Contest",
        "35": "Policy",
        "36": "OlympicGames",
        "37": "FieldHockey",
        "38": "AutoSport",
        "39": "Motorsport",
        "40": "Billiards",
        "41": "Athletic",
        "42": "Lotto3",
        "43": "Badminton",
        "44": "Настільний теніс",
        "45": "BeachSoccer",
        "46": "BeachVolleyball",
        "47": "ESports"
    },
    "routes": {
        "home": "ГОЛОВНА",
        "sport": "СПОРТ",
        "live": "LIVE",
        "casino": "КАЗИНО",
        "registration": "Відкрити рахунок",
        "results": "Результати",
        "virtual": "Віртуальні",
        "horse": "Перегони",
        "games": "ІГРИ"
    },
    "appMenuBar": {
        "deposit": "Поповнення",
        "withdrawal": "Зняття",
        "verifyBetSlip": "Перевірити ставку"
    },
    "common": {
        "tempPassword": "Змініть тимчасовий пароль",
        "language": {
            "language": "Мова",
            "languageName": "Українська"
        },
        "pluralization": {
            "hour": "{hour} г|{hour} ч|{hour} г",
            "match": "{count} матчів|{count} матч|{count} матча|{count} матчей"
        },
        "days": {
            "tomorrow": "Завтра",
            "today": "Сьогодні"
        },
        "update": {
            "checkingForUpdates": "Проверка наличия новых версий...",
            "updatesAvailable": "Завантаження нової версії...\nПісля завантаження застосунок буде перезавантажено для встановлення нової версії",
            "updateError": "Під час оновлення сталася помилка:\n{error}\nБудь ласка, перезавантажте застосунок і спробуйте трохи пізніше.\nЯкщо помилка повторюватиметься, зверніться до системного адміністратора."
        },
        "settings": {
            "tabs": {
                "generalTab": "Загальні",
                "connectionTab": "Підключення"
            },
            "appCode": "Код програми:",
            "partnerCode": "Код партнера:",
            "address": "Адреса:",
            "network": {
                "restServerAddress": "Адреса сервера обробки:",
                "streamServerAddress": "Адреса сервера даних:",
                "useSSL": "Використовувати захищене з'єднання SSL"
            },
            "logo": "Логотип (формат BASE64) :"
        },
        "forms": {
            "yes": "Так",
            "no": "Ні",
            "ok": "Ок",
            "cancel": "Відміна",
            "close": "Закрити",
            "open": "Відкрити",
            "continue": "Продовжити",
            "print": "Друк",
            "clear": "Очистити",
            "copy": "Копіювати",
            "loadMore": "Більше",
            "validation": {
                "required": "Поле має бути заповнене",
                "email": "Вказана пошта не є коректною",
                "min": "Мінімальна довжина поля {param} символів.",
                "confirmed": "Паролі не співпадають",
                "invalidFormat": "Невірний формат",
                "birthday": "Вам має бути 18+ років",
                "invalidCharacters": "Символи + - , ; & є недопустимими"
            }
        },
        "blocked": {
            "btn": "Детальніше",
            "caption": "Акаунт тимчасово заблоковано.",
            "detailsMessage": "Акаунт тимчасово заблоковано до <span id='time-to'>{date}</span> за вашим запитом. Ставки на будь-який вид спорту, а також поповнення рахунку призупинено.",
            "regulatorBlocked": "Акаунт заблоковано на вимогу регулятора."
        },
        "games": {
            "tabs": {
                "all": "Всі игри",
                "popular": "Популярні",
                "new": "Нові",
                "favourites": "Обрані",
                "football": "Футбол",
                "tennis": "Теніс",
                "horse": "Коні",
                "dog": "Собаки",
                "moto": "Мотогонки",
                "fighting": "Бої",
                "providers": "Провайдери",
                "casual": "Казуальні",
                "slots": "Слоти",
                "card": "Настільні",
                "lottery": "Лотерея",
                "roulette": "Рулетка",
                "poker": "Покер",
                "jackpot": "Джекпот",
                "live": "Live",
                "blackjack": "Блекджек",
                "craps": "Craps",
                "buyfeature": "Buy feature",
                "megaways": "Megaways",
                "crash": "Crash",
                "fishing": "Fishing",
                "scratch": "Scratch",
                "basketball": "Basketball",
                "keno": "Keno",
                "bingo": "Bingo",
                "hockey": "Хокей"
            },
            "buttons": {
                "play": "ГРАТИ",
                "demo": "ДЕМО"
            },
            "zeroBalance": "Недостатньо коштів на рахунку казино"
        }
    },
    "appTitle": {
        "brand": {
            "betgol": "Ставки на спорт та Казино Онлайн | Betgol",
            "BETGOL": "Ставки на спорт та Казино Онлайн | BETGOL",
            "latino": "Apuestas Deportivas",
            "ligabet": "Apuestas Deportivas",
            "columbiaApuesta": "Apuestas",
            "certabet": "Apostas Esportivas e Cassino"
        },
        "cashier": "Cashier"
    },
    "homePage": {
        "widgets": {
            "coupon": {
                "coupons": "КУПОНИ",
                "today": "Сьогодні",
                "totalGoals": "Тотал матча",
                "bothToScore": "Обидві заб'ють",
                "weekend": "Матчі вихідних",
                "mostPopular": "Популярні"
            },
            "live": "LIVE",
            "starting-soon": "НЕЗАБАРОМ"
        }
    },
    "betting": {
        "depositAccount": {
            "success": "Поповнення балансу відбулося успішно!"
        },
        "countryMenu": {
            "all": "Всі",
            "hot": "Обрані"
        },
        "dniInputForm": {
            "dniCaption": "Введіть DNI",
            "ceCaption": "Введіть CE",
            "switchToCE": "Перейти на CE",
            "switchToDNI": "Перейти на DNI"
        },
        "codeInputForm": {
            "submit": "Перевірити",
            "close": "Закрити",
            "caption": "Перевірити номер"
        },
        "withdrawalForm": {
            "caption": "Введіть суму знаття",
            "cancel": "Відміна",
            "ticket": "Знати",
            "footer": "Зберігайте чек у недоступному для сторонніх місці.",
            "success": {
                "text": "Зняття у розмірі <b>{amount} {currency}</b> проведено успішно. <br> N коду <b>{code}</b>",
                "caption": "Знаття"
            }
        },
        "horse": {
            "ticket": {
                "serial": "Код",
                "date": "Дата",
                "horse": "Кінь",
                "game": "Гра",
                "amount": "Сума",
                "status": "Статус",
                "total": "Загалом:"
            }
        },
        "couponVerification": {
            "caption": "Перевірка ставки",
            "coupon": "Картка ставки",
            "bet": "Ставка",
            "date": "Дата",
            "event": "Подія",
            "coef": "Коеф.",
            "amount": "Ставка",
            "payment": "Виплата",
            "info": "Інфо",
            "cardNotFound": "Картка не знайдена",
            "paymentTotal": "Виплата становить {total} {curr} ",
            "transferButton": "Перевести на баланс",
            "transfer": "Перевести на баланс?",
            "success": "Нарахування проведено успішно"
        },
        "navigationSideBar": {
            "league": "Ліги",
            "countries": "A-Z",
            "search": "Пошук",
            "rules": "Правила",
            "faq": "Запитання",
            "reload": "Оновити",
            "time": "Час"
        },
        "searchDialog": {
            "placeholder": "Пошук",
            "emptyResults": "Відповідні події відсутні"
        },
        "mobile": {
            "favourites": "Обрані",
            "popular": "Популярні"
        },
        "event": {
            "scoreboardTitle": {
                "events": "Події",
                "menu": {
                    "item1x2": "Перемога в матчі",
                    "item12": "Перемога в матчі",
                    "item1x12x2": "Подвійний шанс",
                    "itemHandicap": "Перемога з урахуванням фори",
                    "itemTotalGoals": "Тотал матча",
                    "itemLocalTotalGoals": "Індивідуальний тотал 1 команди",
                    "itemVisitTotalGoals": "Індивідуальний тотал 2 команди",
                    "itemBothWillScore": "Обидві забʼють",
                    "itemTotalPoints": "Тотал матча",
                    "itemLocalTotalPoints": "Індивідуальний тотал 1 команди",
                    "itemVisitTotalPoints": "Індивідуальний тотал 2 команди",
                    "itemTotalGames": "Тотал матча",
                    "itemLocalTotalGames": "Індивідуальний тотал 1 команди",
                    "itemVisitTotalGames": "Індивідуальний тотал 2 команди",
                    "itemTotalRuns": "Тотал матча",
                    "itemTotal": "Тотал матча",
                    "itemMatchTotal": "Тотал матча",
                    "item1x2xFavourites": "Результат матча",
                    "itemTotalFavourites": "Тотал матча",
                    "itemHandicapFavourites": "Перемога з урахуванням фори"
                }
            },
            "scoreboardLine": {
                "startDate": "Сьогодні"
            },
            "periods": {
                "sport1": {
                    "period1": "1 тайм",
                    "period2": "2 тайм"
                },
                "sport2": {
                    "period1": "1 чверть",
                    "period2": "2 чверть",
                    "period3": "3 чверть",
                    "period4": "4 чверть",
                    "period100": "1 половина",
                    "period101": "2 половина"
                },
                "sport3": {
                    "period1": "1 період",
                    "period2": "2 період",
                    "period3": "3 період"
                },
                "sport4": {
                    "period1": "1 сет",
                    "period2": "2 сет",
                    "period3": "3 сет",
                    "period4": "4 сет",
                    "period5": "5 сет"
                },
                "sport6": {
                    "period1": "1 сет",
                    "period2": "2 сет",
                    "period3": "3 сет",
                    "period4": "4 сет",
                    "period5": "5 сет"
                },
                "sport7": {
                    "period1": "1 іннінг",
                    "period2": "2 іннінг",
                    "period3": "3 іннінг",
                    "period4": "4 іннінг",
                    "period5": "5 іннінг",
                    "period6": "6 іннінг",
                    "period7": "7 іннінг",
                    "period8": "8 іннінг",
                    "period9": "9 іннінг",
                    "period10": "10 іннінг",
                    "period11": "11 іннінг",
                    "period12": "12 іннінг",
                    "period13": "13 іннінг",
                    "period100": "1 половина"
                },
                "sport9": {
                    "period1": "1 чверть",
                    "period2": "2 чверть",
                    "period3": "3 чверть",
                    "period4": "4 чверть",
                    "period100": "1 половина"
                },
                "sport28": {
                    "period1": "1 тайм",
                    "period2": "2 тайм"
                },
                "sport44": {
                    "period1": "1 сет",
                    "period2": "2 сет",
                    "period3": "3 сет",
                    "period4": "4 сет",
                    "period5": "5 сет"
                }
            },
            "groupEvents": {
                "participant": "Учасник",
                "leaveTheGroup": "Вийде з групи",
                "stayTheGroup": "Не вийде з групи",
                "winner": "Переможець",
                "placeInGroup": "{p1} місце в групі",
                "finishTheRaceYes": "Закінчить перегони - Да",
                "finishTheRaceNo": "Закінчить перегони - Нет",
                "fastestLap": "Найшвидше коло",
                "firstPitstop": "Перший Pitstop",
                "lastPlace": "Останнє місце",
                "willQualifyToFinal": "Прохід у Фінал",
                "willQualifyToFinal1_2": "Прохід у 1/2 Финалу",
                "willQualifyToFinal1_4": "Прохід у 1/4 Финалу"
            }
        },
        "betSlip": {
            "title": "Картка",
            "shortTitle": "Картка",
            "acceptWithAllQuotations": "Прийняти з усіма змінами",
            "acceptOnlyWithQuotationsAreRaised": "Прийняти тільки за умови підвищення коефіцієнтів",
            "oneClickBet": "Ставка в одни клік",
            "simple": "Ординар",
            "combined": "Комбінована ставка",
            "maxWin": "Максимальний выграш",
            "ordinar": "Ординар",
            "express": "Экспрес",
            "expressWithCoef": "Экспрес (коеф. {coef})",
            "system": "Система (з {limit} по {base})",
            "variants": "Варіанти:",
            "columns": {
                "event": "Подія",
                "sum": "Сума"
            },
            "buttons": {
                "clear": "Очистити",
                "continue": "Оплатити",
                "accept": "Прийняти",
                "verify": "Продовжити"
            },
            "confirmCoefDiffs": "Лінія, коефіцієнти або доступність результатів на вашому купоні ставок змінилася.",
            "acceptButton": "Прийняти",
            "common": {
                "total": "Сума ставки:",
                "maxWin": "Можливий виграш",
                "userNumber": "Номер користувача:"
            },
            "print": {
                "card": "Картка",
                "code": "Код",
                "time": "Час:",
                "endTime": "Дійсний до:",
                "confirm": "Зробивши ставку, Ви підтверджуєте, що ознайомлені з правилами."
            },
            "dialogBetAccepted": {
                "caption": "Ставка прийнята",
                "code": "Код",
                "columns": {
                    "date": "Дата, Час",
                    "event": "Подія",
                    "count": "Кількість",
                    "coef": "Кф",
                    "amount": "Ставка",
                    "maxWin": "Макс. виграш",
                    "variants": "{variants} вар."
                },
                "timer": "Ви маєте {timer} секунд, щоб скасувати ставку.",
                "copy": "Копіювати",
                "cancel": "Відмінити"
            },
            "dialogBetVerification": {
                "caption": "Перевірте правильність даних вашої ставки"
            },
            "numberInputDialog": {
                "caption": "Введіть суму ставки",
                "confirmButton": "OK"
            }
        },
        "registration": {
            "title": "Реєстрація",
            "tabs": {
                "logoTab": "Логотип"
            },
            "fields": {
                "pointName": "Назва залу:",
                "city": "Місто:",
                "countryLabel": "Країна:",
                "phone": "Номер телефону:"
            }
        }
    },
    "cashier": {
        "loginForm": {
            "caption": "Авторизація",
            "user": "Користувач",
            "password": "Пароль",
            "parameters": "Параметри",
            "settings": "Налаштування",
            "registration": "Реєстрація",
            "settingsNotFilled": "Не заповнені налаштування програми",
            "serverAddressNotFilled": "Не заповнена адреса сервера",
            "noAccount": "Відсутня валюта рахунку"
        },
        "registrationForm": {
            "caption": "Ім'я користувача",
            "number": "#",
            "nameGroup": "Ім'я користувача",
            "lastName": "Прізвище:*",
            "firstName": "Ім'я:*",
            "login": "Логін:*",
            "requiredFields": "* - поля, обов'язкові для заповнення",
            "contactsGroup": "Контакти",
            "address": "Адреса:",
            "phone": "Телефон:*",
            "email": "E-mail:*",
            "passwordGroup": "Пароль",
            "password": "Пароль:*",
            "confirmPassword": "Підтвердження паролю:*",
            "partnerGuid": "Код партнера:*"
        },
        "expressAccount": {
            "caption": "Створення експрес-рахунку",
            "firstName": "Ім'я: *",
            "lastName": "Прізвище: *",
            "orderSum": "Сума: *",
            "document": "Документ: *",
            "phone": "Номер телефону:",
            "print": {
                "number": "Номер експрес-рахунку:",
                "balance": "Баланс рахунку:",
                "siteCaption": "Дані для входу на сайт:",
                "login": "Логін:",
                "password": "Пароль:",
                "warning": "Увага! Відразу ж після входу необхідно змінити пароль!"
            }
        },
        "settingsForm": {
            "caption": "Параметри",
            "locationTab": "Розташування",
            "optionsTab": "Опції",
            "requisites": "Реквізити",
            "remoteAccess": "Налаштування віддаленого доступу",
            "partnerCode": "Код партнера:",
            "cashboxName": "Назва каси:",
            "finance": "Фінанси",
            "currency": "Валюта:",
            "minStakeOrdinar": "Мінімальна ставка (Ординар):",
            "minStakeExpress": "Мінімальна ставка (Експрес):",
            "minStakeSystem": "Мінімальна ставка (Система):",
            "proxy": "Проксі-сервер:",
            "useProxy": "Використовувати проксі-сервер",
            "proxyAddress": "Адреса:",
            "proxyPort": "Порт:",
            "captionImage": "Файл картинок для заголовків",
            "multiscreen": "Мультіскрин Лайв",
            "multiscreenFile": "Exe-файл:",
            "hotkey": "Гаряча клавиша Ctrl+",
            "directOutput": "Пряме виведення чека на принтер",
            "cashbox": "Каса",
            "voucher": "Ваучери",
            "express": "Экспрес-рахунки",
            "terminal": "Терминал рахунки",
            "checkFormat": "Формат чека:",
            "leftPad": "Лівий відступ:",
            "barcodeScanner": "Сканер штрих-кодів",
            "barcodeScannerPort": "Номер порта:",
            "depositMenu": "Пункт меню Депозит"
        },
        "finDayForm": {
            "caption": "Фінансовий день - Звірка готівки"
        },
        "dailyReportForm": {
            "caption": "Звіт за день",
            "totalGenerationVoucher": "Всього проданих ваучерів ",
            "totalCashCheque": "Всього погашених ваучерів",
            "totalBalanceNulling": "Всього обнулень балансу БП",
            "totalBalanceDeposit": "Всього поповнень",
            "totalBalanceWithdowal": "Всього знаття",
            "totalStake": "Всього ставок Спорт",
            "totalPaid": "Всього виплат Спорт (каса)",
            "totalStakeRace": "Всього ставок Забіги",
            "totalPayRace": "Всього виплат Забіги",
            "totalCancelStakeBP": "Всього скасованих БП",
            "totalPaidTerminal": "Всього виплат (терминал)",
            "total": "Разом",
            "closeDay": "Закрити фін. день",
            "success": "Фінансовий день успішно закритий",
            "confirm": "Закрити фінансовий день?",
            "totalBalanceDepositBetPlace": "Пряме поповнення балансу BP",
            "refresh": "Оновити"
        },
        "userConfirmationForm": {
            "caption": "Пошук Користувача",
            "accountId": "Номер рахунку:",
            "document": "Документ:",
            "phone": "Телефон:",
            "findButton": "Пошук користувача",
            "personalData": "Персональні дані",
            "personalCode": "Персональний код:",
            "name": "Імʼя:",
            "birthdate": "Дата нарождення:",
            "lastName": "Прізвище:",
            "docs": "Документи корустувача:",
            "verify": "Верифікація користувача:",
            "confirmButton": "Підтвердіть дані",
            "verifiedYes": "Так",
            "verifiedNo": "Ні",
            "success": "Виконано успішно"
        },
        "refillAccountForm": {
            "caption": "Пряме поповнення рахунку",
            "user": "Користувач:",
            "findUser": "Пошук користувача",
            "accountNumber": "Номер рахунку, Логін или e-mail:",
            "phone": "Телефон: +",
            "document": "Документ:",
            "checkButton": "Перевірити",
            "accountRefill": "Поповнення рахунку",
            "amount": "Сума:",
            "getBonus": "Отримати бонус",
            "refillButton": "Поповнити",
            "insufficientFunds": "Недостатньо коштів",
            "empty": "Поле має бути заповнено",
            "blocked": "Користувача заблоковано до:",
            "table": {
                "time": "Час",
                "num": "Номер",
                "name": "Ім'я",
                "amount": "Сума"
            },
            "print": {
                "accountNumber": "Номер рахунку",
                "amount": "Баланс"
            }
        },
        "winPaymentForm": {
            "caption": "Виплата виграшу",
            "cardCode": "Код картки:",
            "find": "Пошук",
            "doPayment": "Виплатити",
            "success": "Виплату виграшу ставки виконано успішно",
            "notfound": "Картку не знайдено",
            "expired": "Термін дії виплати виграшу закінчився",
            "alreadyPaid": "Картку вже виплачено"
        },
        "cashWithdrawalForm": {
            "status0": "Очікування",
            "status1": "Схвалено",
            "status2": "Сплачено",
            "status3": "Відхилена",
            "status4": "Скасовано користувачем",
            "status5": "Невідомо",
            "caption": "Зняття з рахунку",
            "payCode": "Код виплати:",
            "checkWithdrawalButton": "Перевірити код виплати",
            "withdrawalCaption": "Деталі",
            "withdrawalNum": "Номер заявки:",
            "amount": "Сума:",
            "crdate": "Створено:",
            "expdate": "Дійсно до:",
            "cashbox": "Каса:",
            "status": "Статус заявки:",
            "document": "Пред'явлений документ",
            "documentType": "Тип документа",
            "documentTypes": {
                "idcard": "ID ",
                "passport": "Паспорт",
                "driver": "Водійське посвідчення"
            },
            "docNumber": "Номер пред'явленого документа:",
            "makeWithdrawalButton": "Перевірити виплату",
            "personalData": "Особисті дані",
            "userNumber": "Персональний код:",
            "firstName": "Ім'я:",
            "lastName": "Прізвище:",
            "birthday": "Дата народження:",
            "userDocuments": "Документи користувача:",
            "verify": "Верифікація користувача:",
            "confirmUserButton": "Перевірити дані",
            "print": {
                "caption": "Списання з рахунку",
                "sum": "Сума:",
                "account": "Номер рахунку"
            },
            "notFound": "Замовлення не знайдено"
        },
        "saleVoucherForm": {
            "caption": "Ваучери - продаж",
            "amount": "Сума:",
            "saleButton": "Сплатити",
            "table": {
                "time": "Час",
                "num": "Номер",
                "amount": "Сума"
            },
            "print": {
                "time": "Час операції:",
                "voucher": "Ваучер №:",
                "amount": "Сума виплати:",
                "until": "Дійсний до:"
            }
        },
        "buyVoucherForm": {
            "caption": "Ваучери - купівля",
            "voucher": "Ваучер №:",
            "buyButton": "Купівля",
            "success": "Виконано успішно, сума {amount}"
        },
        "menu": {
            "monitoring": "Моніторинг",
            "payments": "Виплати",
            "vouchers": "Ваучери",
            "account": "Ігровий рахунок",
            "finance": "Фінанси",
            "service": "Сервіс",
            "sport": "СПОРТ",
            "live": "LIVE",
            "state": "Не виплачені",
            "payment": "Виплата виграшу",
            "withdrawal": "Списання з рахунку",
            "refill": "Поповнення рахунку",
            "userFind": "Пошук користувача",
            "profile": "Параметри реєстрації",
            "settings": "Налаштування",
            "salevoucher": "Продаж",
            "buyvoucher": "Купівля",
            "dailyreport": "Звіт за день",
            "finday": "Фінансовий день",
            "betplaces": "Точки",
            "express": "Експрес рахунок"
        },
        "monitoring": {
            "table": {
                "id": "Id",
                "time": "Час",
                "position": "Об'єкт/Позиція",
                "quotation": "Джерело/Котировка",
                "coef": "Кф",
                "stake": "Сума",
                "status": "Статус",
                "pay": "Виплата",
                "income": "Дохід",
                "print": "Друк",
                "number": "#",
                "iscancel": "Скасована",
                "isaccepted": "Прийнята",
                "iscalculated": "Розрахована",
                "ispaid": "Виплачена",
                "object": "Вн № {innerCardId} Код виплати: {payCode}",
                "containerType1": "Ординар",
                "containerType3": "Система (з {itemCount} по {express})",
                "containerType2": "Експрес",
                "itemStatus0": "У грі",
                "itemStatus1": "Виграш",
                "itemStatus2": "Без виграшу",
                "itemStatus3": "Повернення",
                "itemStatus4": "Невідомо",
                "itemStatus5": "Скасовано",
                "horse": {
                    "statusG": "Виграш",
                    "statusJ": "У грі",
                    "statusP": "Без виграшу",
                    "statusD": "Повернення",
                    "statusA": "Void"
                }
            },
            "operations": {
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH": "Поповнення ігрового рахунку",
                "PAY_OPERATION_WITHDRAWAL_CASH": "Зняття з ігрового рахунку",
                "PAY_OPERATION_VOUCHER_GENERATION_CASH": "Продаж ваучера",
                "PAY_OPERATION_VOUCHER_REDEMPTION_CASH": "Купівля ваучера",
                "PAY_OPERATION_VOUCHER_REDEMPTION_TRM": "Купівля ваучера",
                "PAY_OPERATION_VOUCHER_GENERATION_BP": "Продаж ваучера на Betplace",
                "PAY_OPERATION_INCREASE_BALANCE_BP": "Поповнення балансу Betplace",
                "PAY_OPERATION_WINPAID": "Виплата виграшу",
                "PAY_OPERATION_FOR_RACE_WINPAID_BP": "Виплата за карткою Перегони BP",
                "PAY_OPERATION_FOR_RACE_WINPAID_CASH": "Виплата за карткою Перегони",
                "PAY_OPERATION_NULLIFICATION_POINT_BALANCE": "Обнуління балансу Betplace",
                "PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT": "Партнерське поповнення",
                "PAY_OPERATION_REFERRAL_ACCOUNT_WITHDRAWA": "Виведення коштів через партнера",
                "PAY_OPERATION_INCREASE_ACCOUNT": "Поповнення рахунку",
                "PAY_OPERATION_WITHDRAWAL": "Зняття з рахунку ",
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT": "Пряме поповнення",
                "PAY_OPERATION_DEPOSIT_BONUS_REFILL": "Бонус",
                "PAY_OPERATION_INCREASE_BONUS_ACCOUNT": "Поповнення бонусного рахунку (Спорт)",
                "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT": "Поповнення бонусного рахунку (Казино)",
                "PAY_OPERATION_CANCEL_BONUS_ACCOUNT": "Скасування бонусу (Cпорт)",
                "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT": "Скасування бонусу (Казино)",
                "PAY_OPERATION_CANCEL_STAKE_BP": "Скасування ставки Betplace",
                "PAY_OPERATION_WITHDRAWAL_BONUS_ACCOUNT": "Зарахування з бонусного рахунку (Спорт)",
                "PAY_OPERATION_WITHDRAWAL_CASINO_BONUS_ACCOUNT": "Зарахування з бонусного рахунку (Казино)",
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL": "Переказ на основний рахунок",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT": "Переказ на ігровий рахунок",
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP": "Переказ на ігровий рахунок",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP": "Переказ на ігровий рахунок",
                "PAY_OPERATION_FOR_RACE_WINPAID": "Виграш на Забігах",
                "PAY_OPERATION_FOR_RACE_CARD": "Ставка на Забіги"
            },
            "filter": {
                "cashier": "Cashier",
                "allbp": "Всі БП"
            },
            "search": {
                "caption": "Пошук",
                "inputLabel": "Введіть номер"
            },
            "copyError": "Неможливо скопіювати картку",
            "deleteComfirm": "Ви дійсно хочете видалити ставку #{innerCardId}?",
            "deleteSuccess": "Ставка видалена успішно",
            "payCode": "Код виплати:"
        },
        "cardsState": {
            "innerCardId": "Номер",
            "cardFilter": "Картка:",
            "sumPay": "Сума",
            "status": "Статус",
            "payCode": "Код виплати: ",
            "caption": "Невиплачені",
            "unpaidSum": "Невиплачена сума: {sumPay}",
            "unpaidRadio": "Непроплачені виграші",
            "paidRadio": "Виплачені виграші",
            "loseRadio": "Програні ставки",
            "allRadio": "Розраховані ставки",
            "footer": "Картка:",
            "unpaid": "Не виплачено",
            "empty": "Немає неоплачених карток."
        },
        "updateProfile": {
            "success": "Редагування профілю виконано коректно"
        },
        "activeBetplaces": {
            "deposit": "Поповнити рахунок",
            "depositDialog": {
                "caption": "Поповнення рахунку"
            },
            "depositsuccess": "Рахунок успішно поповнено",
            "depositsuccesscaption": "Поповнення рахунку",
            "nullify": "Обнулити рахунок",
            "nullifyConfirm": "Ви дійсно хочете обнулити баланс бетплейса {betplace}?",
            "nullifysuccess": "Баланс обнулено",
            "nullifysuccesscaption": "Обнулення балансу",
            "sum": "Баланс"
        },
        "forms": {
            "print": "Друк"
        }
    },
    "errors": {
        "mtl": {
            "serverError": "Непередбачена помилка сервера",
            "status": {
                "code201": "На сервер було надіслано некоректний запит",
                "code202": "Помилка визначення запиту на сервері",
                "code203": "Помилка розбору запиту на сервері: запит не відповідає протоколу",
                "code101": "Опрацювання запиту зараз неможливе. Спробуйте пізніше",
                "code102": "Помилка опрацювання запиту на сервері",
                "code401": "У запиті не вказано код точки",
                "code402": "Точка не активована",
                "code403": "Точка заблокована"
            },
            "aUs11": {
                "code1103": "Мінімальна довжина пароля 8 символів",
                "code1104": "Мінімальна довжина логіна 8 символів",
                "code1106": "Користувач із таким e-mail вже існує",
                "code1107": "Користувач з таким login вже існує",
                "code1112": "Невірна дата народження",
                "code1113": "Зазначений номер документа вже використовується",
                "code1114": "Реферальний код некоректний або відсутній у базі",
                "code1115": "Користувач із таким телефоном вже існує",
                "code1118": "Невірно вказано номер телефону",
                "code1119": "Партнера не знайдено"
            },
            "aUs111": {
                "code1191": "Користувача сеансу не знайдено"
            },
            "aUs16": {
                "code1172": "Некоректно вказано поточний пароль",
                "code1173": "Мінімальна довжина пароля 8 символів"
            },
            "aUs17": {
                "code1181": "Поточний користувач не встановлений у сесії",
                "code1182": "Некоректно вказано електронну адресу",
                "code1183": "Електронна адреса не існує",
                "code1184": "Некоректно вказано номер телефону"
            },
            "acn11": {
                "code1051": "Код підтвердження не знайдено",
                "code1052": "Код підтвердження вже використано",
                "code1053": "Можливий час на підтвердження операції минув"
            },
            "aUs12": {
                "code1133": "Акаунт заблоковано після 3 невірних спроб введення пароля. Для розблокування скористайтеся функцією відновлення пароля.",
                "code1122": "Невірно вказані номер користувача і пароль",
                "code1123": "Користувач заблокований",
                "code1124": "Користувач не може виконувати вхід із зазначеного робочого місця",
                "code1125": "Відсутня валюта рахунку",
                "code1126": "У користувача кілька рахунків",
                "code1127": "Не співпадає валюта",
                "code1161": "Некоректна адреса електронної пошти або телефон",
                "code1162": "Користувач не активований"
            },
            "aUs22": {
                "code1703": "Телефон вже зареєстровано",
                "code1106": "Користувач із таким e-mail вже існує",
                "code1107": "Користувач з таким login вже існує",
                "code1113": "Зазначений персональний код користувача вже використано",
                "code1114": "Реферальний код некоректний або відсутній у базі",
                "code1115": "Користувач із таким телефоном вже існує"
            },
            "aPi55": {
                "code2601": "Баланси не співпадають",
                "code2604": "Невідповідність Валюти"
            },
            "aUs25": {
                "code1121": "Поточний користувач не має прав для редагування інформації"
            },
            "aPi54": {
                "code1001": "Користувач не авторизований"
            },
            "qSt100": {
                "code1311": "вказаний у фільтрі об'єкт не існує",
                "code1312": "не існує карток, що відповідають зазначеному фільтру",
                "code1313": "не встановлено користувача в сесії",
                "code1314": "у користувача немає прав для запиту списку ставок"
            },
            "aSt13": {
                "code1221": "Картка не знайдена в базі даних",
                "code1222": "Перевищено час відведений на скасування картки"
            },
            "aSt22": {
                "code1281": "Картка не знайдена",
                "code1282": "Картку вже сплачено",
                "code1283": "Картка не має виграшу",
                "code1284": "Помилка операції оплати"
            },
            "aSt25": {
                "code1381": "Картка не знайдена",
                "code1382": "Картку вже сплачено",
                "code1383": "Картку вже сплачено",
                "code1384": "Картка не має виграшу"
            },
            "qSt106": {
                "code1411": "Картка не знайдена"
            },
            "qSt107": {
                "code1421": "Картка не знайдена"
            },
            "qUs22": {
                "code1191": "Немає прав для запиту даної інформації",
                "code1192": "Запит не повернув результатів"
            },
            "qUs113": {
                "code1301": "Користувача не знайдено, зверніться до підтримки",
                "code1303": "Акаунт заблоковано на вимогу регулятора."
            },
            "qRs12": {
                "code1411": "Купон не знайдено"
            },
            "aPo51": {
                "code5103": "Перевищено ліміт депозиту",
                "code5108": "Помилка створення ордера, перевірте дані",
                "code5109": "Платіжна система недоступна. Для поповнення рахунку виберіть інший спосіб або зверніться до підтримки."
            },
            "aPo510": {
                "code4301": "Загальна помилка поповнення",
                "code4303": "Застосунок не має прав для проведення операції",
                "code4304": "У користувача немає прав для проведення операції",
                "code4305": "Користувача із вказаними реквізитами не існує",
                "code4306": "У користувача не відкритий рахунок із зазначеною валютою",
                "code4307": "Користувач заблокований",
                "code4309": "Для цього користувача бонус недоступний",
                "code4310": "Неспівпадіння партнерів",
                "code4308": "Не авторизована операція, зверніться до служби підтримки",
                "code4314": "Телефон користувача вже існує",
                "code4404": "Невідповідність валюти"
            },
            "aPo513": {
                "code4403": "Недостатній баланс для проведення операції",
                "code4404": "Не співпадає валюта",
                "code4406": "Недостатньо коштів"
            },
            "aUs14": {
                "code1151": "Не вдалося змінити дані користувача",
                "code1152": "Користувача не знайдено",
                "code1153": "Логін повинен мати 8 символів та більше",
                "code1154": "Користувач з таким login вже існує",
                "code1155": "Не вказано прізвище",
                "code1156": "Не вказано ім'я",
                "code1157": "Некоректно вказана дата народження",
                "code1158": "Вказаний ID користувача вже існує "
            },
            "aVc14": {
                "code4811": "Відсутні права доступу",
                "code2302": "ВАУЧЕР вже ВИКОРИСТАНО",
                "code2303": "Неспівпадіння партнерів",
                "code2305": "Неспівпадіння партнерів",
                "code2308": "Не співпадає валюта"
            },
            "aVc11": {
                "code1": "Неавторизована сесія",
                "code4801": "Відсутні права доступу",
                "code2202": "Неправильно вказано суму",
                "code2201": "Сума операції перевищує баланс користувача",
                "code2203": "Невірна валюта"
            },
            "aPo67": {
                "code4441": "У користувача немає прав для проведення операції",
                "code4442": "У користувача немає прав для проведення операції",
                "code4443": "Вказана заявка не знайдена",
                "code4444": "Статус заявки не дозволяє провести операцію",
                "code4445": "Не співпадає заявлений розрахунковий пункт",
                "code4446": "Загальна помилка зняття",
                "code4447": "Перевищено кількість зняття на касі на день",
                "code4463": "Вказаний запит не знайдено",
                "code4464": "Заявку виплачено",
                "code4465": "Заявка не може бути виплачена через касу",
                "code4466": "ОПЕРАЦІЯ ЗІ ЗНЯТТЯ З РАХУНКУ ЗАБОРОНЕНА"
            },
            "aVc21": {
                "code5103": "Невірна сума",
                "code2302": "Ваучер вже використано",
                "code4316": "Невірна валюта"
            },
            "aGe21": {
                "code1010": "Реєстрацію виконано успішно, програму буде закрито. Будь ласка, відкрийте програму після мануальної активації точки",
                "code1011": "Точку за кодом не знайдено",
                "code1012": "Некоректно вказано назву точки",
                "code1013": "Некоректно вказано поштову адресу",
                "code1014": "Не вказана країна",
                "code1015": "Не вказано адресу",
                "code1016": "Партнера не визначено ",
                "code1017": "Код точки вже визначено"
            },
            "aGe22": {
                "code1021": "Розрахунковий пункт заблоковано",
                "code1131": "Помилка реєстрації сесії",
                "code1142": "Час сесії закінчився"
            },
            "aVc18": {
                "code1001": "Користувач не авторизований",
                "code2301": "Ваучер не знайдено",
                "code2302": "Ваучер використано",
                "code2305": "Відсутні права на погашення ваучера",
                "code2307": "Неспівпадіння партнерів",
                "code4316": "Невідповідність валют",
                "code4301": "Загальна помилка поповнення",
                "code4307": "Користувача заблоковано",
                "code4310": "Неспівпадіння партнерів"
            },
            "aVc22": {
                "code1211": "Недостатньо коштів на балансі",
                "code1214": "ОПЕРАЦІЯ НЕ ДОЗВОЛЕНА ДЛЯ ЦЬОГО ТИПУ ТОЧКИ"
            },
            "aPo512": {
                "code4301": "Застосунок не має прав для проведення операції",
                "code4302": "У користувача немає прав для проведення операції",
                "code4303": "Індифікаційний код вже використовується",
                "code4304": "Телефон вже використовується",
                "code4305": "Невірна сума депозиту",
                "code4313": "Номер документа вже використовується",
                "code4306": "Неправильний код валюти",
                "code4311": "Не авторизована операція, зверніться до служби підтримки",
                "code4314": "Користувач із таким телефоном вже існує",
                "code4315": "Не авторизована операція, зверніться до служби підтримки"
            },
            "aPo68": {
                "code4451": "Застосунок не має прав для проведення операції",
                "code4452": "Недостатньо прав для проведення операції",
                "code4453": "Не відкрито рахунок із зазначеною валютою",
                "code4454": "Сума запиту перевищує наявну",
                "code4455": "Не встановлено контакт для надсилання підтвердження",
                "code4456": "Немає прав для використання зазначеного методу підтвердження операції"
            },
            "aPo617": {
                "code4443": "ЗНЯТТЯ КОШТІВ НЕ ДОЗВОЛЕНО ДЛЯ КОРИСТУВАЧА",
                "code4445": "Сума запиту перевищує наявну",
                "code4448": "Перевищено ліміт виведення за день"
            },
            "aCs11": {
                "code8101": "Сесія не авторизована",
                "code8132": "Гра недоступна",
                "code8136": "Для активації застосунку поповніть баланс"
            },
            "aCs12": {
                "code8101": "Сесія не авторизована",
                "code8121": "Сесія не авторизована"
            },
            "aCs27": {
                "code8125": "На основному рахунку недостатньо коштів для здійснення переказу!"
            },
            "aCs28": {
                "code8135": "На рахунку казино недостатньо коштів для здійснення переказу!"
            },
            "aCn11": {
                "code1051": "Підтвердження не знайдено"
            },
            "aBn11": {
                "code4231": "Операція не дозволена для неактивного сеансу",
                "code4232": "Операція не дозволена для експрес-користувачів",
                "code4233": "Бонус не дозволено без депозиту",
                "code4236": "Для активації бонусу зробіть депозит",
                "code4234": "Сума депозиту не відповідає Правилам отримання бонусу",
                "code4240": "Бонус не дозволено після ставки",
                "code4238": "Цей тип бонусу більше не доступний згідно з Правилами",
                "code4235": "Бонус вже активовано",
                "code4237": "Одночасно може бути активний тільки один бонус",
                "code4241": "Сума депозиту не відповідає Правилам отримання бонусу"
            }
        },
        "betSlip": {
            "duplicateEventWarning": "Ставки на одну й ту саму подію в експреси та системи не приймаються!",
            "exceedsMaxStakeAmount": "Сума ставки більша за максимальну",
            "lessMinStakeAmount": "Сума ставки менша за мінімальну",
            "coefWasChanged": "Коефіцієнт змінився",
            "quotationBlocked": "Прийом ставок припинено",
            "positionBlocked": "Прийом ставок тимчасово припинено",
            "eventFullTime": "Ставки на цю подію закриті - матч закінчено",
            "eventStarted": "Ставки на цю подію закрито",
            "betSlipEmpty": "Картка не містить подій",
            "betSlipInvalidItems": "Картка містить помилки",
            "response": {
                "registrationSystemError": "Системна помилка реєстрації",
                "incorrectNumberOfPositions": "Системна помилка реєстрації комбінованої ставки",
                "notEnoughMoneyOnCenterBalance": "Недостатньо грошей на рахунку",
                "notEnoughMoneyOnUserBalance": "Недостатньо грошей на рахунку",
                "userDontHaveOpenAccount": "Для продовження увійдіть у свій акаунт або зареєструйтеся",
                "positionLiveServiceNotAvailable": "Наразі приймання ставок Live на цю подію призупинено",
                "coefIsNotAllowedForBonus": "Коефіцієнт нижче допустимого"
            }
        },
        "games": {
            "errorStartingGame": "Гра недоступна"
        }
    },
    "webdesktop": {
        "pageTitle": {
            "default": {
                "title": "{brand} - офіційний сайт: казино, ставки на спорт, лайв ігри",
                "description": "Офіційний сайт компанії {brand} ✅Букмекерська компанія ☝️Онлайн Казино 🎰 Лайв ігри. Отримайте при реєстрації до $300"
            },
            "sport": {
                "title": "{sport} - {brand} Букмекерська контора: робіть ставки на {sport} з упевненістю!",
                "description": "Робіть ставки на {sport} з упевненістю в букмекерській конторі {brand}! ☝Швидка реєстрація та легкий доступ ☝☝Обирайте з широкого спектра спортивних ставок ☝ Отримуйте безкоштовні ставки, промокоди та ексклюзивні бонуси"
            },
            "live": {
                "title": "{sport} в букмекерській конторі {brand}: зробити ставку Live на {sport} і вигравайте по-крупному!",
                "description": "Готові робити ставки Live на {sport}? Букмекерська контора {brand} допоможе вам! ☝Швидка реєстрація та легкий доступ ☝ Отримуйте безкоштовні ставки, промокоди та спеціальні бонуси ☝ Ми пропонуємо різноманітні види спорту"
            },
            "casino": {
                "title": "{brand} Онлайн-казино: грайте та вигравайте реальні гроші!",
                "description": "Пориньте у світ розваг онлайн-казино з {brand}! ☝Приєднуйтесь зараз, щоб спростити реєстрацію та отримати доступ до широкого спектру ігрових автоматів. ☝ Отримайте захопливі бонуси та безкоштовні спіни до $300 ☝"
            },
            "virtual": {
                "title": "{brand} Онлайн-казино: грайте та вигравайте реальні гроші!",
                "description": "Пориньте у світ розваг онлайн-казино з {brand}! ☝Приєднуйтесь зараз, щоб спростити реєстрацію та отримати доступ до широкого спектру ігрових автоматів. ☝ Отримайте захопливі бонуси та безкоштовні спіни до $300 ☝"
            },
            "registration": {
                "title": "{brand} Зареєструйся і Обери Свій Welcome Bonus!",
                "description": "Офіційний сайт компанії {brand} ✅Букмекерська компанія ☝️Онлайн Казино 🎰 Лайв ігри. Отримайте під час реєстрації до $300"
            }
        },
        "race": {
            "bonusActive": "Ставки на Racing недоступні для гри з бонусного рахунку"
        },
        "idle": {
            "message": "Сеанс завершено через неактивність."
        },
        "verification": {
            "emailCaption": "Верифікація E-MAIL",
            "phoneCaption": "Верифікація номеру телефону",
            "caption": "Перевірка безпеки",
            "text": "Щоб захистити свій рахунок, пройдіть наступну перевірку",
            "phone": "Код підтвердження номера телефона",
            "email": "Код перевірки електронної пошти",
            "placeholder": "Введіть 8-значний код, відправлений на ",
            "phoneVerified": "Ваш номер телефону успішно перевірено",
            "emailVerified": "Вашу електронну пошту успішно перевірено",
            "getCode": "Отримати код",
            "sent": "Код перевірки надіслано на "
        },
        "accountSwitch": {
            "sport": {
                "primary": "Ви перейшли на основний рахунок Спорт",
                "bonus": "Ви перейшли на бонусний рахунок Спорт"
            },
            "casino": {
                "primary": "Ви перейшли на основний рахунок Казино",
                "bonus": "Ви перейшли на бонусний рахунок Казино"
            }
        },
        "casino": {
            "balance": "Рахунок казино",
            "transferFromCasino": "Переказ з рахунку казино:",
            "transferToCasino": "Переказ на рахунок казино:",
            "depositSuccess": "Переказ з основного рахунку здійснено успішно!",
            "withdrawalSuccess": "Переказ з рахунку казино здійснено успішно!"
        },
        "preferences": {
            "languages": "Мови",
            "quotations": {
                "caption": "Формат коефіцієнтів",
                "decimal": "Дес.",
                "american": "Амер.",
                "english": "Дроб."
            },
            "theme": {
                "caption": "Тема",
                "light": "Світла",
                "dark": "Темна"
            },
            "view": "Вид"
        },
        "registration": {
            "success": "Дякуємо за реєстрацію",
            "oneclick": {
                "tabName": "Один Клік",
                "confirm": {
                    "title": "ДЯКУЄМО ЗА РЕЄСТРАЦІЮ",
                    "subtitle1": "Будь ласка, ",
                    "subtitle2": "!запишіть свій логін і пароль!",
                    "login": "Логін",
                    "password": "Пароль",
                    "copy": "Копіювати",
                    "savetofile": "Зберегти в файл",
                    "savetoimage": "Зберегти як картинку",
                    "emailplaceholder": "E-mail",
                    "emailbutton": "Надіслати на e-mail",
                    "loginCopied": "Логін скопійовано",
                    "passwordCopied": "Пароль скопійовано"
                }
            },
            "phone": {
                "tabName": "По телефону",
                "sendSmsButton": "Відправити SMS",
                "confirmCode": "Код підтвердження",
                "confirmButton": "Підтвердити"
            },
            "email": {
                "tabName": "Через E-mail",
                "emailLabel": "E-mail",
                "countryLabel": "Країна",
                "phoneLabel": "Номер телефону",
                "firstNameLabel": "Ім'я",
                "lastNameLabel": "Прізвище",
                "birthdayLabel": "Дата народження",
                "passwordLabel": "Пароль",
                "passwordConfirmLabel": "Повтор пароля",
                "promoLabel": "Бонус",
                "registerLabel": "Зареєструватися",
                "promoPlaceholder": "Введіть промо код",
                "documentLabel": "Документ",
                "loginLabel": "Логін",
                "cityLabel": "Місто",
                "policy1": "Цей сайт захищено reCAPTCHA и ",
                "policy2": " Політика конфіденційності ",
                "policy3": " от Google и применяются ",
                "policy4": "Условия и положения",
                "policy6": "Нажимая эту кнопку, вы подтверждаете, что прочитали и принимаете ",
                "policy7": "Условия и положения",
                "policy8": "и",
                "policy9": " Политика конфіденційності ",
                "policy10": "компанії та підтверджуєте, що ви досягли повноліття."
            }
        },
        "emailConfirm": {
            "title": "!Ваш рахунок активовано!",
            "userNumber": "Ваш номер рахунку:",
            "userLogin": "Ім'я користувача:",
            "ref1": "Для того, щоб робити ставки, вам необхідно",
            "ref2": "внести депозит на свій рахунок"
        },
        "withdrawalConfirm": {
            "title": "Платіжний запит №",
            "total": "Всього до сплати: ",
            "withdrawalType": "Тип виплати: ",
            "payCode": "Код виплати: ",
            "expiration": "Оплата цього запиту може бути здійснена до: ",
            "WITHDRAWAL_METHOD_VOUCHER": "Агенти {brandName}",
            "WITHDRAWAL_METHOD_VISA": "Visa/MasterCard",
            "WITHDRAWAL_METHOD_SETTLEMENTACCOUNT": "Розрахунковий рахунок"
        },
        "loginDialog": {
            "menuButton": "Увійти",
            "emailPlaceholder": "ID, Логін або E-MAIL",
            "passwordPlaceholder": "Пароль",
            "save": "Запамʼятати",
            "forgetPassword": "Забув свій пароль?",
            "loginButton": "Увійти",
            "social": "Ви можете отримати доступ до веб-сайту через:",
            "no-register": "Ще не зареєстровані?"
        },
        "confirmCodeDialog": {
            "success": "На вашу контактну адресу, вказану під час реєстрації, надіслано новий код!",
            "caption": "Необхідно підтвердити операцію!",
            "text": "Введіть код, надісланий на вашу контактну адресу, зазначену під час реєстрації, у наступне поле:",
            "inputLabel": "Код підтвердження",
            "submitButton": "Перевірити"
        },
        "resetPasswordDialog": {
            "caption": "Відновити пароль",
            "emailTabCaption": "По e-mail",
            "phoneTabCaption": "По телефону",
            "submitButton": "Створити тимчасовий пароль",
            "emailPlaceholder": "Введіть свій e-mail",
            "emailTabText1": "Для відновлення пароля введіть адресу електронної пошти, вказану вами під час реєстрації.",
            "emailTabText2": "Ми надішлемо вам електронного листа з подальшими інструкціями.",
            "phoneTabText1": "Для відновлення пароля введіть номер телефону, який ви вказали під час реєстрації.",
            "phoneTabText2": "Ми надішлемо вам SMS із подальшими інструкціями.",
            "success": "Вам надіслано тимчасовий пароль",
            "phonePlaceholder": "Введіть свій номер телефону"
        },
        "changePasswordDialog": {
            "caption": "Зміна пароля",
            "currentPasswordLabel": "Поточний пароль",
            "newPasswordLabel": "Новий пароль",
            "newPasswordConfirmLabel": "Підтвердити новий пароль",
            "submitButton": "Змінити пароль",
            "successMessage": "Пароль успішно змінено"
        },
        "expressChangePasswordDialog": {
            "caption": "Вітаємо",
            "text1": "З метою безпеки вашого облікового запису ми рекомендуємо змінити пароль прямо зараз!",
            "submitButton": "Змініть пароль зараз",
            "closeButton": "Змінити пізніше"
        },
        "userMenu": {
            "personalData": "Персональні дані",
            "settings": "Налаштування акаунта",
            "bids": "Ставки у грі",
            "allbids": "Всі ставки",
            "finance": "Фінанси",
            "stakes": "Мої ставки",
            "bonuses": "Бонуси",
            "logout": "Закрити сесію"
        },
        "changePhoneDialog": {
            "caption": "Змінити номер телефону",
            "phone": "Номер телефону",
            "submitButton": "Ок",
            "success": "Ваш номер телефону успішно змінено"
        },
        "changeEmailDialog": {
            "caption": "Новий e-mail",
            "email": "Введіть адресу електронної пошти:",
            "success": "Адресу вашої електронної пошти було успішно змінено"
        },
        "blockDialog": {
            "week": "Тиждень",
            "month": "Місяць",
            "year": "Рік",
            "caption": "Заблокувати рахунок"
        },
        "limitDialog": {
            "caption": "Ліміт депозиту",
            "daily": "День",
            "weekly": "Тиждень",
            "monthly": "Місяць",
            "amount": "Сума",
            "success": "Ліміт депозиту успішно змінено"
        },
        "profileView": {
            "title": "ОСОБИСТА ІНФОРМАЦІЯ",
            "subtitle": "Заповніть порожні поля, щоб використовувати функціональні можливості сайту!",
            "titleLine": "ДАНІ ОБЛІКОВОГО ЗАПИСУ",
            "accountNumber": "Номер рахунку",
            "passwordLabel": "Ваш пароль",
            "registrationDate": "Дата реєстрації",
            "information": "Контактна інформація",
            "submitButton": "Зберегти",
            "success": "Дані збережено успішно",
            "block": "Заблокувати",
            "limit": "Ліміт",
            "blocked": "Акаунт заблоковано до"
        },
        "routes": {
            "inPlay": "Ставки у грі",
            "account": "Рахунок",
            "allStakes": "Всі ставки",
            "winstakes": "Результативні",
            "finances": "Фінанси",
            "raceStakes": "Перегони",
            "withdrawalOrders": "Зняття",
            "depositOperations": "Поповнення",
            "bonusOperations": "Бонуси"
        },
        "withdrawalOrders": {
            "dateColumn": "Дата",
            "amount": "Сума",
            "method": "Тип зняття",
            "status": "Статус",
            "cancel": "Скасування",
            "wt": {
                "WITHDRAWAL_METHOD_VOUCHER": "Ваучер",
                "WITHDRAWAL_METHOD_VOUCHER_INSTANT": "Ваучер експрес",
                "WITHDRAWAL_METHOD_VISA": "Visa",
                "WITHDRAWAL_METHOD_LUKITA": "Lukita",
                "WITHDRAWAL_METHOD_YAPE": "Yape",
                "WITHDRAWAL_METHOD_TUNKI": "Tunki"
            },
            "statusText": {
                "ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION": "Потрібно підтвердити заявку за допомогою секретного коду протягом 24 годин",
                "ORDER_WITHDRAWAL_STATUS_CONFIRMATION_EXPIRED": "Заявка на виплату не була підтверджена користувачем протягом 24 годин",
                "ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE": "Заявка на виплату очікує на розгляд",
                "ORDER_WITHDRAWAL_STATUS_APPROVED": "Заявка схвалена адміністрацією і готується до виплати",
                "ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED": "Заявка схвалена і буде передана на виплату після підтвердження особи. Потрібно надати документ, що засвідчує особу. зверніться до підтримки для отримання детальної інформації",
                "ORDER_WITHDRAWAL_STATUS_PAID": "Заявка передана на оплату",
                "ORDER_WITHDRAWAL_STATUS_CASH_WAITING": "Заявка очікує виплати клієнту на касі протягом 3 діб.",
                "ORDER_WITHDRAWAL_STATUS_CASH_PAID": "Заявку виплачено на касі",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_EXPIRED": "Виплата не була отримана клієнтом протягом 3 діб і була анульована",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_ERROR": "Виплата неможлива. Для отримання детальної інформації зверніться до підтримки",
                "ORDER_WITHDRAWAL_STATUS_DENIED": "В виплаті відмовлено. Заявка анульована. Для отримання детальної інформації зверніться до підтримки",
                "ORDER_WITHDRAWAL_STATUS_USER_CANCELLED": "Заявку на виплату було скасовано кліентом",
                "ORDER_WITHDRAWAL_STATUS_PAYSYSTEM_PAID": "Заявку виплачено"
            },
            "confirm": {
                "caption": "Скасування платіжної операції",
                "text": "Ви дійсно хочете скасувати платіжну операцію?"
            },
            "cancelSuccess": "Платіжну операцію було успішно скасовано"
        },
        "cardsTable": {
            "dateColumn": "Дата, час",
            "eventColumn": "Подія/результат",
            "quotationColumn": "Коефіцієнт",
            "amountColumn": "Ставка",
            "winColumn": "Виплати",
            "infoColumn": "Інформація",
            "bonus": "Бонусний рахунок"
        },
        "inPlay": {
            "cardsTotal": "Ставки у грі:",
            "amount": "Всього:"
        },
        "allStakes": {
            "beginDate": "Період з:",
            "endDate": "По:",
            "itemsPerPage": "Строк на сторінці:",
            "dateFilter": "Фільтр по даті"
        },
        "operations": {
            "operationColumn": "Операція",
            "amountColumn": "Сума"
        },
        "deposit": {
            "amountInput": {
                "caption": "Поповнення рахунка {type}",
                "amount": "Сума: ",
                "currency": "Валюта: ",
                "dialogText": "Перевірте дані та підтвердіть операцію: ви запросили платіж на суму {amount}{currency}",
                "min": "Мінімальна сума поповнення становить {amount}{currency}."
            },
            "monnetCard": {
                "description": "Керування депозитами з використанням карток Visa або Mastercard здійснюється через платформу (). Залежно від обставин ця організація має право схвалювати або відхиляти транзакції відповідно до своєї внутрішньої політики. Для здійснення депозитів з використанням карток Visa або Mastercard необхідно бути власником картки."
            },
            "tupay": {
                "description": "С Tupay у вас все під контролем! Робіть покупки онлайн і обирайте, як платити. Чи то готівка, чи то банківський переказ, чи то кредитні або дебетові картки або QR-цифрові гаманці, вибір за вами. Ми робимо ваші онлайн-покупки простими і гнучкими. Відкрийте для себе свободу платежів з Tupay."
            },
            "DEPOSIT_MONNET_CARD": "MONNET CARD",
            "SAFETYPAY_ONLINE": "SAFETYPAY",
            "SAFETYPAY_CASH": "SAFETYPAY",
            "DEPOSIT_MONNET": "SAFETYPAY ONLINE",
            "DEPOSIT_MONNET_CASH": "SAFETYPAY",
            "DEPOSIT_TUPAY": "TUPAY",
            "PAGOEFECTIVO_INVOICE": "Pago Efectivo",
            "caption": "Способи поповнення",
            "method": "Спосіб поповнення",
            "time": "Час переказу на рахунок",
            "comment": "Примітки",
            "yape": "Yape, Lukita, Tunki",
            "zeroCommission": "Комісія, 0%",
            "refillRef": "Поповнити рахунок",
            "tunki": "izipayYA",
            "yapeText": "Зробіть своє життя простішим з Yape і забудьте про готівку! <br> Поповнюйте рахунок швидко, легко і безпечно за допомогою Yape.",
            "tunkiText": "За допомогою izipayYA ви можете надсилати гроші за номером мобільного телефону або QR, розплачуватися в тисячах винних магазинів і філій по всій країні, поповнювати баланс мобільного телефону та оплачувати послуги БЕЗКОШТОВНО, ШВИДКО І БЕЗПЕЧНО. <br>" +
                "Поповнюйте баланс швидко, легко та безпечно за допомогою izipayYA.",
            "safetyPayInternet": "Інтернет-банкінг SAFETYPAY",
            "auto": "Автоматично",
            "safetyPayDescription": "Це безпечний і швидкий спосіб оплати, що дає змогу вносити кошти на Ваш рахунок у {brandName} через Інтернет-банк або через агентів і агентства вВСР, BBVA, INTERBANK або SCOTIABANK. <br> Поповнення рахунку відбувається миттєво і {brandName} не стягує комісію за використання цього методу.",
            "pagoEfectivo": "PagoEfectivo",
            "pagoSeguro": "PAGO SEGURO",
            "pagoSeguroEfectivo": "Efectivo",
            "immediate": "Негайно",
            "pagoEfectivoDescription": "Поповнення рахунку в банках BBVA, BCP, Interbank, Scotiabank, BanBif, Caja Arequipa и Banco Pichincha через мобільний банк, QR або через агентів Red Digital, Kasnet, Agente Niubiz, Tambo+, Western Union и Disashop.",
            "agents": "Агенты {brandName}",
            "iPay": "Ticket iPay",
            "iPayDescription": "Платіжна система IPAY. Напротязі банківського дня. Комісія 0%. Ви можете вивести кошти зі свого ігрового рахунку через платіжну систему iPay. Створіть замовлення на оплату через платіжну систему iPay і затвердіть його. Після підтвердження замовлення ви отримаєте секретний код виплати, і сума виплати буде списана з вашого ігрового рахунку. Пред'явіть Секретний Код представнику системи IPay і після перевірки коду, ви отримаєте суму виплати готівкою. Комісія за оплату через платіжну систему IPay відсутня.",
            "voucher": {
                "success": "Поповнення на суму {amount} здійснено вдало.",
                "found": "Талон №{code} знайден, на суму {amount}",
                "numberLabel": "Введіть код билету:",
                "caption": "Ваучер iPay"
            }
        },
        "withdrawal": {
            "minAmountError": "Мінімальна сума виведення {minAmount}",
            "maxAmount": "Максимальна сума для виведення {maxAmount} в день",
            "general": {
                "WITHDRAWAL_METHOD_YAPE": "Виведення на YAPE",
                "WITHDRAWAL_METHOD_LUKITA": "Виведення на LUKITA",
                "WITHDRAWAL_METHOD_TUNKI": "Виведення на TUNKI",
                "phoneNumber": "Номер телефону {phoneNumber} буде використаний для зняття"
            },
            "partner": {
                "caption": "Виведення коштів з рахунку",
                "confirmationMethod": "Виберіть спосіб перевірки:",
                "confirmationEmail": "Електронна пошта",
                "confirmationPhone": "Телефон"
            },
            "visaForm": {
                "caption": "Банківський переказ",
                "document": "Документ",
                "email": "Електронна пошта",
                "bank": "Назва банку:",
                "cardName": "Повне ім'я:",
                "accountType": "Тип рахунка:",
                "fullNumber": "Номер рахунка:",
                "orderedSum": "Кількість (мінімум {minAmount}):",
                "dni": "ID:"
            },
            "minAmount": "Мінімальна сума",
            "caption": "Способи вивода коштів",
            "paymentMethod": "Спосіб оплати",
            "commission": "Комісія",
            "agents": "АГЕНТИ {brandName}",
            "agentsDescription": "Ви можете вивести кошти із свого ігрового рахунку через платіжну систему IPAY. Для цього необхідно надіслати запит на виплату через платіжну систему IPAY і підтвердити. Після підтвердження запиту Ви отримуєте Секретний платіжний код, а сума платежу буде знята з Вашого ігрового рахунку. Ви повинні повідомити свій Секретний код представнику системи IPAY і після перевірки коду, ви отримаєте оплату готівкою. При оплаті через систему IPAY комісія не стягується.",
            "agentsLink": "Створити платіжний запит через IPAY >>",
            "visaLink": "Зробити запит",
            "ipay": {
                "description": "Платіжна система IPAY. Протягом банківського дня. Комісія 0%. Ви можете вивести кошти вз свого ігрового рахунка через платіжну систему iPay. Створіть замовлення на оплату через платіжну систему iPay і підтвердіть його. Після підтвердження замовлення ви отримаєте секретний код виплати, суму виплати буде списано з вашого ігрового рахунку. Пред'явіть Секретний Код представнику системи IPay і після перевірки коду, ви отримаєте суму виплати готівкою. Комісія за виплату через платіжну систему IPay відсутня."
            }
        },
        "emailDone": {
            "caption": "АКТИВАЦІЯ АКАУНТА",
            "text": "Щоб захистити свій обліковий запис, підтвердіть зареєстровану адресу електронної пошти.\n" +
                "                    <br><br>\n" +
                "                    Ви отримаєте підтвердження електронною поштою протягом наступних 10 хвилин.\n" +
                "                    Якщо ви не отримали його, перевірте папку зі спамом.",
            "codeButton": "Ввести код"
        },
        "homeView": {
            "articles": {
                "sport": "Спорт",
                "live": "Live",
                "casino": "Казино",
                "virtual": "Віртуальні <br> ігри",
                "bonuses": "Бонуси"
            }
        },
        "footer": {
            "sport": "Спорт",
            "live": "Live",
            "casino": "Казино",
            "virtual": "Віртуальні ігри",
            "bonuses": "Бонуси",
            "privacyPolicy": "Політика конфиденційності",
            "rules": "Правила",
            "popular": "Популярні ставки",
            "about": {
                "caption": "Про нас",
                "terms": "Умови та положення",
                "privacy": "Політика конфиденційності",
                "aml": "Політика AML/KYC",
                "resp": "Відповідальна гра",
                "cookies": "Політика Сookies"
            },
            "bets": "Ставки на спорт",
            "affiliates": "Партнерська програма",
            "rights": "This website tubetgol.com is owned and operated by OG OVERSEAS SERVICES LTD. registration number: 14942707, registered address: 128 City Road, London, EC1V 2NX.. Contact us info&#64;tubetgol.com. tubetgol.com is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. ALSI-022307004-FI2. tubetgol.com has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.",
            "rightsCerta": "This website www.certa.bet is owned and operated by OG OVERSEAS SERVICES LTD. registration number: 14942707, registered address: 128 City Road, London, EC1V 2NX.. Contact us info&#64;www.certa.bet. www.certa.bet is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. ALSI-022307004-FI2. www.certa.bet has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.",
            "warning": "Надмірне захоплення азартними іграми може викликати залежність",
            "warningDialog": {
                "title": "Відповідальна гра",
                "description": "Азартні ігри можуть викликати звикання та його важко уникнути, навіть якщо ви знаєте, що вони викликають проблеми. Азартні ігри не є розв'язанням фінансових проблем, грати слід лише за умови, якщо ви можете дозволити собі не виграти! Вам слід відстежувати, скільки часу і грошей ви витрачаєте в Інтернеті, і за необхідності вживати заходів. Грайте відповідально і зв'яжіться з нами або скористайтеся онлайн-інструментами, якщо у вас виникнуть будь-які труднощі."
            }
        },
        "privacyPolicy": {
            "textContent": "<b><u>Політика конфіденційності</u></b><br>" +
                "Дана політика конфіденційності опублікована для інформування про принципи обробки персональних даних користувачів веб-сайту www.tubetgol.com.<br>" +
                "<br>" +
                "<b>Загальні характеристики</b><br>" +
                "Веб-сайт www.tubetgol.com (надалі «веб-сайт») піклується про надання клієнту та користувачу конфіденційності їх персональних даних (надалі «Користувачі», «Персональні дані»). Під персональними даними розуміється будь-яка інформація про користувача сайту, зокрема ім'я, адреса, дата народження, контактні дані, платіжні вимоги та будь-яка інша інформація, яку користувач міг надати Компанії. Компанія намагатиметься гарантувати використання особистої інформації користувача відповідно до міжнародних стандартів захисту даних і конфіденційності.<br>" +
                "Коли користувач надає Компанії свої персональні дані Коли користувач на сайті запитує інформацію про продукти та послуги Компанії, він добровільно надає Компанії свої персональні дані. Коли ви реєструєте новий ігровий обліковий запис, ви повинні надати Компанії свою особисту інформацію. Якщо у користувача вже є зареєстрований обліковий запис (він є клієнтом Компанії), він повинен використовувати своє ім'я і пароль для доступу до сайту. Файли cookie можуть використовуватися для ідентифікації повторних входів користувача на веб-сайт. Сервер Компанії фіксує відвідувані користувачем сторінки на веб-сайті.<br>" +
                "<b>Використання персональних даних</b><br>" +
                "Компанія збирає, зберігає та використовує персональні дані користувачів відповідно до чинного законодавства про захист даних для наступних цілей: управління ігровим обліковим записом користувача, надання послуг користувачеві, аналіз і контроль використання користувачем веб-сайту та послуг, що надаються Компанією, контроль якості послуг користувачам, періодичне інформування про продукти та послуги, що можуть зацікавити користувачів (далі «ціли»). Інформація про дії користувачів зберігається у Компанії, є її власністю і може бути використана для зазначених Цілей.<br>" +
                "<b>Відкриття особистих даних</b><br>" +
                "Персональна інформація користувача може бути передана або виявлена Компанією її діловими партнерами, які можуть спільно з Компанією використовувати цю інформацію для встановлених Цілей. Використання персональних даних користувача в цих випадках буде регулюватися договором у встановленій законодавством формі. У випадках, коли це є вимогою законодавства або постановами судових органів, персональні дані користувача можуть бути надані відповідним державним, адміністративним та виконавчим органам.<br>" +
                "<b>Угода про використання персональних даних</b><br>" +
                "Надаючи особисті дані на веб-сайті та/або реєструючись на веб-сайті та/або входячи у свій обліковий запис веб-гри, користувач добровільно дозволяє Компанії збирати, обробляти та отримувати доступ до своєї особистої інформації для потреб, зазначених вище. Якщо ви, як користувач сайту, бажаєте будь-яким чином уточнити, змінити або обмежити свою авторизацію, просимо повідомити про це Компанію, використовуючи контактну інформацію, зазначену на сайті."
        },
        "rules": {
            "generalTabName": "Загальне",
            "specialTabName": "Особливі випадки",
            "stakeTypesTabName": "Типи ставок",
            "financeTabName": "Фінансові операції",
            "resultsTabName": "Результати"
        },
        "bonus": {
            "playGame": "Грати",
            "saldo": "Бонусний баланс",
            "rest": "Ви завершили робити ставки",
            "amount": "Сума ставки",
            "useBonusButton": "Використати бонус",
            "cancelBonusButton": "Скасувати бонус",
            "terms": "Положення та умови",
            "init": "Активація бонуса",
            "endTime": "Строк дії до",
            "beaten": "Бонус використан",
            "cancelled": "Скасування бонуса",
            "expired": "Термін дії бонусу закінчено",
            "winning": "Зарахування з бонусного рахунку",
            "cancelBonusConfirmCaption": "Увага!",
            "cancelBonusConfirmMessage": "Всі бонусні кошти будуть обнулені"
        },
        "results": {
            "goals": {
                "goal1": "Перший гол: {participantName} на {time} min.",
                "goal2": "Другий гол: {participantName} на {time} min.",
                "goal3": "Третій гол: {participantName} на {time} min.",
                "goal4": "Четвертий гол: {participantName} на {time} min.",
                "goal5": "П'ятий гол: {participantName} на {time} min.",
                "goal6": "Шостий гол: {participantName} на {time} min.",
                "goal7": "Сьомий гол: {participantName} на {time} min.",
                "goal8": "Восьмий гол: {participantName} на {time} min.",
                "goal9": "Дев'ятий гол: {participantName} на {time} min.",
                "goal10": "Десятий гол: {participantName} на {time} min.",
                "goal11": "Одинадцятий гол: {participantName} на {time} min.",
                "goal12": "Дванадцятий гол: {participantName} на {time} min."
            },
            "set": "сет: {set} гейм: {game} перемога {participant}",
            "caption": "РЕЗУЛЬТАТИ",
            "dateFilter": "Дата",
            "all": "Всі",
            "today": "Сьогодні",
            "yesterday": "Вчора",
            "select": "Вибрати",
            "calendar": "Календар",
            "invalidDate": "Невірна дата"
        },
        "copyright": "© 2019-2024 Компанія"
    },
    "mobile": {
        "profileView": {
            "profileTab": "Особиста інформація",
            "settingsTab": "Налаштування",
            "loginLabel": "Логін"
        },
        "bottomBar": {
            "menuButton": "Меню"
        },
        "menuBar": {
            "installApplication": "Встановити застосунок",
            "bets": "Історія ставок"
        },
        "navigationView": {
            "depositButton": "Депозит",
            "withdrawalButton": "Вивести гроші",
            "stakesButton": "Ставки на спорт",
            "casinoButton": "Ставки на казино",
            "historyButton": "Історія",
            "raceButton": "Ставки гонки",
            "financesButton": "Фінанси",
            "bonusButton": "Бонус",
            "sportBalance": "Баланс:",
            "sportBalanceAvailable": "Доступно для виведення:"
        },
        "homeView": {
            "sport": {
                "caption": "Ставки на спорт",
                "sportButton": "Відкрити спорт >>",
                "prematch": "Прематч",
                "live": "Live",
                "soccer": "Футбол",
                "basketball": "Баскетбол",
                "baseball": "Бейсбол",
                "boxing": "Бокс",
                "rugby": "Регбі",
                "tennis": "Теніс",
                "volleyball": "Волейбол",
                "allMatches": "Всі Матчі"
            },
            "casino": {
                "caption": "Казино",
                "casinoButton": "Відкрити казино >>"
            },
            "virtual": {
                "caption": "Віртуальні ігри",
                "virtualButton": "Відкрити віртуальні ігри"
            },
            "horse": {
                "caption": "Перегони",
                "horseButton": "Перейти на Перегони",
                "game1": "Коні",
                "game2": "Собаки",
                "game3": "Забіги"
            },
            "bonus": "Welcome Bonus",
            "allGames": "Всі ігри"
        },
        "depositView": {
            "min": "Мін. сума:",
            "max": "Макс. сума:",
            "depositButton": "Депозит"
        },
        "withdrawalView": {
            "withdrawalButton": "Вивести гроші"
        },
        "financesView": {
            "caption": "Мої фінанси"
        },
        "stakesView": {
            "caption": "Мої ставки",
            "cardCaption": "N° карт. {innerCardId}",
            "cardStatus": {
                "accepted": "В очікуванні",
                "win": "Виграш",
                "lost": "Виграшу немає"
            },
            "containerStatusCaption": "Статус"
        },
        "historyView": {
            "caption": "Історія"
        },
        "routes": {
            "sport": "Спорт",
            "live": "Live",
            "casino": "Казино",
            "coupon": "Купон",
            "operationsHistory": "Операції",
            "sessionsHistory": "Сесії",
            "balance": "Баланс",
            "casinoStakes": "Казино",
            "search": "Пошук",
            "virtual": "Віртуальні",
            "bonus": "Бонус",
            "racing": "Перегони"
        }
    },
    "mcashier": {
        "saleVoucherDialog": {
            "copyButton": "Скопіювати номер",
            "printButton": "Друк",
            "copySuccess": "Номер успішно скопійован"
        },
        "cashWithdrawal": {
            "success": "Зняття з рахунку відбулось успішно"
        },
        "menu": {
            "saleVoucher": "Продаж ваучера",
            "buyVoucher": "Купівля ваучера"
        },
        "refillAccount": {
            "success": "Рахунок успішно поповнений"
        },
        "expressAccount": {
            "success": "Обліковий запис успішно створено"
        },
        "share": {
            "caption": "Поділитися"
        }
    },
    "landing": {
        "agreement": "Мені більше 18 років і я підтверджую, що ознайомився та прийняв умови"
    },
    "bonusNotification": {
        "body": "Поповніть рахунок, щоб активувати Бонус"
    },
    "bonusRegistration": {
        "sportBetting": {
            "title": "СТАВКИ НА СПОРТ"
        },
        "casino": {
            "title": "КАЗИНО"
        },
        "title": "Зареєструйтесь",
        "subtitle": "Виберіть свій вітальний бонус",
        "freeSpins": {
            "title": "Free spins"
        },
        "noBonus": {
            "title": "БЕЗ БОНУСА",
            "description": "ГРАЙТЕ НА<br/> {site}",
            "list": `
                <li>Бонуси можна активувати післе реєстрації.</li>
                <li>Ця опція не вплине на вашу реєстрацію.</li>
            `
        }
    },
    "cookies": {
        "cookieDialog": {
            "message": "Ми використовуємо файли cookie, щоб забезпечити правильну роботу нашого веб-сайту, персоналізувати рекламні оголошення та інші матеріали, забезпечити роботу функцій соціальних мереж і аналізувати мережевий трафік. Ми також надаємо інформацію про використання вами нашого веб-сайту своїм партнерам по соціальним мережам, рекламі та аналітичним системам.",
            "policeLink": "Політика cookies",
            "buttons": {
                "settings": "Налаштування файлів cookie",
                "accept": "Погодитися з використанням файлів cookie"
            }
        },
        "privacySettingsCenterDialog": {
            "caption": "Центр налаштування конфіденційності",
            "yourPrivacy": {
                "head": "Ваша конфіденційність",
                "description": "Коли ви відвідуєте будь-який веб-сайт, він може зберігати інформацію у вашому браузері або отримувати з нього дані, в основному у вигляді файлів cookie. Ця інформація може стосуватись вас, ваших вподобань, вашого пристрою або буде використовуватися для правильної роботи веб-сайту з ваших поглядів. Такі дані зазвичай не ідентифікують вас безпосередньо, але можуть надавати вам індивідуалізовані можливості роботи в інтернеті. Ви можете відмовитися від використання деяких типів файлів cookie. Натискайте на заголовки категорій, щоб дізнатися подробиці та змінити налаштування, задані за замовчуванням. Однак ви маєте розуміти, що блокування деяких типів cookie може вплинути на використання вами веб-сайту та обмежити пропоновані нами послуги.",
                "approveAllButton": "Підтвердити все"
            },
            "strictlyNecessaryCookies": {
                "head": "Обов'язково необхідні файли cookie",
                "description": "Ці файли cookie необхідні для функціонування веб-сайту і не можуть бути відключені в наших системах. Як правило, вони активуються тільки у відповідь на ваші дії, аналогічні запиту послуг, такі як налаштування рівня конфіденційності, вхід до системи або заповнення форм. Ви можете налаштувати браузер таким чином, щоб він блокував ці файли cookie або повідомляв вас про їхнє використання, але в такому разі можливо, що деякі розділи веб-сайту не працюватимуть.",
                "alwaysActive": "Завжди активно"
            },
            "functionalCookies": {
                "head": "Функціональні файли cookie",
                "description": "Ці файли cookie дають змогу надавати покращену функціональність і персоналізацію, наприклад для онлайн-чатів і відеороликів. Вони можуть налаштовуватися нами або сторонніми постачальниками, послуги яких містяться на наших сторінках. Якщо ви не погодитеся на використання цих файлів cookie, то, можливо, деякі або всі ці функції працюватимуть неналежним чином."
            },
            "performanceCookies": {
                "head": "Експлуатаційні файли cookie",
                "description": "Ці файли cookie дають нам змогу порахувати кількість відвідувань і джерел трафіку, щоб оцінити та покращити роботу нашого веб-сайту. Завдяки їм ми знаємо, які сторінки є найбільш і найменш популярними, і бачимо, яким чином відвідувачі переміщуються по веб-сайту. Усі дані, що збираються за допомогою цих cookie, групуються в статистику, а отже, є анонімними. Якщо ви не погодитеся на використання цих файлів cookie, у нас не буде даних про відвідування вами нашого веб-сайту."
            },
            "targetingCookies": {
                "head": "Цільові файли cookie",
                "description": "Ці файли cookie налаштовуються через наш веб-сайт нашими партнерами з реклами. Вони можуть використовуватися цими компаніями для сбору даних про ваші інтереси та відображення для вас актуальної реклами на інших веб-сайтах. Вони працюють за допомогою унікальної ідентифікації вашого браузера та пристрою. Якщо ви не схвалите використання цих файлів cookie, вам не буде демонструватися наша адресна реклама на різних веб-сайтах."
            },
            "buttons": {
                "rejectAll": "Відхилити все",
                "saveSettings": "Підтвердити вибір"
            }
        }
    }
};
