import { createStore, Store } from "vuex";
import {
    createRootStoreOptions,
    RootState,
    rootStoreByPackageHolder,
    RootStoreImpl
} from "@sportaq/vuex/index";
import { ScoreboardVuex } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex";
import { BetSlipVuex } from "@sportaq/vuex/modules/betting/bet-slip/internal/bet-slip-vuex";
import { BalanceModule, balanceModule } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { CashierStoreModule, cashierStoreModule } from "@sportaq/vuex/modules/cashier/cashier-module";

const storeOptions = createRootStoreOptions({
    [ScoreboardVuex.moduleName]: ScoreboardVuex.ScoreboardStoreModule,
    [BetSlipVuex.moduleName]: BetSlipVuex.BetSlipStoreModule,
    [cashierStoreModule]: CashierStoreModule,
    [balanceModule]: BalanceModule
});

const store: Store<RootState> = createStore(storeOptions);

export default store;

rootStoreByPackageHolder.rootStoreByPackage = new RootStoreImpl(store);
