import HorseGames from "@sportaq/vue/components/games/horse/HorseGames.vue";
import TicketPrinter from "@sportaq/vue/components/games/horse/ticket-printer/TicketPrinter.vue";

export default {
    name: "HorseGameView",
    components: {
        HorseGames,
        TicketPrinter
    }
};
